<template>
    <!-- Display as stand alone button -->
    <btn
        v-if="!child && hasExtension"
        v-tooltip="tooltipOpts"
        :class="{ 'text-primary': editor.isEditable && isActive }"
        variant="default"
        emphasis="low"
        :icon="icon ? Array.isArray(icon) ? icon : ['fal', icon || 'question-circle'] : undefined"
        :disabled="!editor.isEditable || !canCommand"
        pill
        v-bind="$attrs"
        @click="execute"
    />
    <!-- Display as a dropdown item button -->
    <b-dropdown-item-button
        v-else-if="child && hasExtension"
        button-class="d-flex align-items-center"
        :active="isActive"
        :disabled="!canCommand"
        v-bind="$attrs"
        @click="execute"
    >
        <fa
            v-if="icon"
            fixed-width
            class="mr-2"
            :icon="Array.isArray(icon) ? icon : ['fal', icon]"
        />
        {{ label }}
        <span
            v-if="shortcut"
            class="ml-auto"
        >
            <shortcut
                class="ml-2 "
                tag="small"
                :code="shortcut"
                badge
            />
        </span>
    </b-dropdown-item-button>
</template>

<script lang="ts">
    import Vue, { PropType, VNode, VueConstructor } from 'vue'
    import { BDropdownItemButton } from 'bootstrap-vue'
    import { ChainedCommands } from '@tiptap/core'
    import { Inject } from './TiptapEditorProvider.vue'
    import Shortcut from '@common/components/Shortcut.vue'

    export default (Vue as VueConstructor<Vue & Inject>).extend({
        components: {
            BDropdownItemButton,
            Shortcut,
        },
        inject: ['editor', 'extensions'],

        props: {
            /**
             * Icon to display in button
             */
            icon: {
                type: [String, Array] as PropType<string | string[]>,
                default: undefined,
            },
            /**
             * Tooltip label
             */
            label: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Should button render as a dropdown item?
             */
            child: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Type of extension
             * You can use a special key here `core` if you're referring to
             * a command from tip taps's core
             */
            extension: {
                type: String as PropType<string>,
                required: true,
            },
            /**
             * Command to execute on click
             */
            command: {
                type: String as PropType<keyof ChainedCommands>,
                default: null,
            },
            /**
             * Any Parameters passed to the command
             */
            commandParams: {
                type: [Object, String] as PropType<object | string>,
                default: undefined,
            },
            /**
             * Keyboard shortcut to display for button
             * Example values
             * - Mod+B
             * - Ctrl+Shift+K
             */
            shortcut: {
                type: String as PropType<string>,
                default: null,
            },
        },

        computed: {
            hasExtension(): boolean {
                if (this.extension === 'core')
                    return true

                return this.extensions.has(this.extension)
            },

            canCommand(): boolean {
                return (this.editor as unknown as any).can()[this.command]?.(this.commandParams) ?? false
            },

            isActive(): boolean {
                const isActive = this.editor.isActive(this.extension, this.commandParams)

                return !this.child
                    ? isActive && this.editor.isFocused
                    : isActive
            },

            tooltipOpts(): object {
                const addon = !this.child
                    ? this.getShortcutTemplate()
                    : ''

                return {
                    title: `${this.label ?? ''}${addon}`,
                    html: !!addon,
                    disabled: !this.editor.isEditable,
                }
            },
        },

        methods: {
            execute(): void {
                if (!this.command) return

                // Reset typing of the editor, until we find a good way to type
                // This properly.
                (this.editor as unknown as any)
                    .chain()[this.command]?.(this.commandParams)
                    .focus()
                    .run()
            },

            getShortcutTemplate(): string {
                if (!this.shortcut) return ''

                const tempComponent = new Vue({
                    render: (h): VNode => h(Shortcut, {
                        props: { code: this.shortcut, tag: 'small', badge: true, dark: true },
                        staticClass: 'text-gray-light ml-2',
                    }),
                }).$mount()

                const html = tempComponent.$el.outerHTML

                tempComponent.$destroy()

                return html
            },
        },
    })
</script>