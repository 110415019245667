import routesFactory from '@router/routes-factory'
import { auth } from '@middleware'

const routerConfig = {
    baseURI: '',
    module: 'AnalyticsReports',
    middleware: [
        auth('admin', { name: '404' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: 'analytics/reports',
        name: 'analyticsReports.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/AnalyticsReports" */
                './AnalyticsReportsIndex.vue'
            ),
    },
    {
        path: 'analytics/reports/results',
        name: 'analyticsReports.results',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/AnalyticsReports" */
                './AnalyticsReportsResults.vue'
            ),
    },
])
