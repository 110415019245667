<template>
    <base-modal
        :title="$t('UPLOADER.FILE_UPLOADER')"
        centered
    >
        <dropzone
            multiple
            @input="onDrop"
        />
        <template #ok-btn="{ resolve }">
            <btn
                variant="primary"
                :label="$t('TERMS.UPLOAD')"
                :disabled="!files.length || busy"
                @click="submit(resolve)"
            />
        </template>
    </base-modal>
</template>

<script lang="ts">
    import Vue from 'vue'
    import { BaseModal } from '@common/Modals'
    import Dropzone from '@common/components/Dropzone.vue'

    export default Vue.extend({
        components: {
            BaseModal,
            Dropzone,
        },

        data() {
            return {
                busy: false,
                files: [] as File[],
            }
        },

        methods: {
            onDrop(files: File[]): void {
                this.files = files
            },

            submit(resolve: (files: File[]) => void): void {
                resolve(this.files)
            },
        },
    })
</script>
