<template>
    <component
        :is="togglable ? 'b-dropdown' : 'div'"
        ref="dropdown"
        v-bind="dropdownBindings"
        @show="fetchExtra"
        @mouseenter.native="onMouseOver"
        @mouseleave.native="onMouseLeave"
    >
        <template #button-content>
            <fa
                class="text-muted"
                fixed-width
                :icon="['fal', 'trophy']"
            />
            {{ userScore | round }}%
        </template>
        <div
            :style="mainStyle"
            class="d-flex"
        >
            <fa
                fixed-width
                class="text-muted mr-3"
                size="2x"
                :icon="['fal', 'trophy']"
            />
            <div>
                <p
                    class="text-nowrap text-larger"
                >
                    {{ $t('ACHIEVEMENT.SCORE.COUNT', { count: $filter('round')(userScore) + '%' }) }}
                </p>
                <p
                    class="text-muted"
                    v-text="$t('ACHIEVEMENT.SCORE.INFO')"
                />
                <ul
                    class="list-unstyled"
                >
                    <li>
                        {{ $t('ACHIEVEMENT.SCORE.JOB_PROFILES') }}:
                        <fa
                            v-if="busy && !jobProfilesScore"
                            class="text-gray"
                            spin
                            :icon="['fal', 'circle-notch']"
                        />
                        <strong v-else>{{ jobProfilesScore | round }}%</strong>
                    </li>
                    <li>
                        {{ $t('ACHIEVEMENT.SCORE.DIVISIONS') }}:
                        <fa
                            v-if="busy && !divisionsScore"
                            class="text-gray"
                            spin
                            :icon="['fal', 'circle-notch']"
                        />
                        <strong v-else>{{ divisionsScore | round }}%</strong>
                    </li>
                    <li>
                        {{ $t('ACHIEVEMENT.SCORE.ORGANIZATION') }}:
                        <fa
                            v-if="busy && !organizationScore"
                            class="text-gray"
                            spin
                            :icon="['fal', 'circle-notch']"
                        />
                        <strong v-else>{{ organizationScore | round }}%</strong>
                    </li>
                </ul>
            </div>
        </div>
    </component>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import infoMixin from './info.mixin'

    export default (Vue as VueConstructor<Vue & InstanceType<typeof infoMixin>>).extend({
        mixins: [infoMixin],
        computed: {
            userScore(): number | null {
                return this.$store.state.Achievements?.Score.user
            },

            divisionsScore(): number | null {
                return this.$store.state.Achievements?.Score.divisions
            },

            jobProfilesScore(): number | null {
                return this.$store.state.Achievements?.Score.jobProfiles
            },

            organizationScore(): number | null {
                return this.$store.state.Achievements?.Score.organization
            },
        },

        methods: {
            async fetchData(): Promise<void> {
                this.busy = true
                await this.$store.load('Achievements')
                await this.$store.cache.dispatch('Achievements/Score/getUser')
                if (!this.togglable)
                    await this.fetchExtra()
                this.busy = false
            },
            /**
             * Fetch averages of other metrics
             * - We only fire this if in toggle mode and item gets toggled
             *   due to endpoints being slow
             */
            async fetchExtra(): Promise<void> {
                this.busy = true
                await Promise.all([
                    this.$store.cache.dispatch('Achievements/Score/getDivisions'),
                    this.$store.cache.dispatch('Achievements/Score/getJobProfiles'),
                    this.$store.cache.dispatch('Achievements/Score/getOrganization'),
                ])
                this.busy = false
            },
        },
    })
</script>