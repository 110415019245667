<template>
    <div
        class="pointer"
        @click="onClick"
    >
        <p
            v-line-clamp="2"
            class="font-weight-bold"
            v-text="value.name"
        />
        <div class="d-flex mx-n1 mb-3">
            <div
                v-for="(item, key) in previewContent"
                :key="key"
                class="w-25 p-1"
            >
                <journey-content-img :value="item" />
            </div>
            <!-- Last item -->
            <div
                v-if="allContent.length > previewContent.length"
                class="w-25 p-1"
            >
                <div class="embed-responsive embed-responsive-1by1 rounded bg-off-white">
                    <strong
                        class="centerer text-center text-muted"
                        v-text="`+${allContent.length - previewContent.length}`"
                    />
                </div>
            </div>
        </div>
        <!-- Progress bar -->
        <div class="d-flex align-items-center mb-3">
            <b-progress
                class="flex-grow-1"
                :value="value.completionRate"
                variant="primary"
                height="6px"
            />
            <small
                class="font-weight-bold ml-2"
                v-text="$filter('pct')(value.completionRate)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { BProgress } from 'bootstrap-vue'
    import JourneyContentImg from './JourneyContentImg.vue'
    import JourneyModal from './JourneyModal.vue'
    import { getAllContent } from '../utils'

    export default Vue.extend({
        components: {
            JourneyContentImg,
            BProgress,
        },

        props: {
            value: {
                type: Object as PropType<JourneyModel>,
                required: true,
            },
        },

        computed: {
            allContent(): LearningModel<'container' | 'module' | 'development-plan'>[] {
                return getAllContent(this.value)
            },

            previewContent(): LearningModel<'container' | 'module' | 'development-plan'>[] {
                if (this.allContent.length === 4)
                    return this.allContent

                return this.allContent.slice(0, 3)
            },
        },

        methods: {
            onClick(): void {
                this.$dialog.modal(JourneyModal, {
                    value: this.value,
                })
            },
        },
    })
</script>