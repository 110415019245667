import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'


const routerConfig = {
    baseURI: 'users',
    module: 'Users',
    middleware: [
        auth(['admin', 'lb'], { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'users.index',
        meta: {
            middleware: [
                hasFeatures('UserManagement', { name: '401' }),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Users" */
                './UsersIndex.vue'
            ),
    },
    {
        path: 'import',
        name: 'users.import',
        props: true,
        meta: {
            middleware: [
                hasFeatures('UserManagement', { name: '401' }),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Users" */
                './UsersImportResults.vue'
            ),
    },
    {
        path: 'collaborators',
        name: 'collaborators.manage',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Users" */
                './CollaboratorsManageIndex.vue'
            ),
    },
])
