import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'


const routerConfig = {
    baseURI: 'permissions',
    module: 'Permissions',
    middleware: [
        auth('admin', { name: '401' }),
        hasFeatures('Organization', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'permissions.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Permissions" */
                './PermissionsIndex.vue'
            ),
    },
    {
        path: 'new',
        name: 'permissions.create',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Permissions" */
                './PermissionsManage.vue'
            ),
    },
    {
        path: ':id',
        name: 'permissions.edit',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Permissions" */
                './PermissionsManage.vue'
            ),
    },
])
