import { library, dom, config, IconDefinition } from '@fortawesome/fontawesome-svg-core'
import * as solidIcons from './icons.solid'
import * as lightIcons from './icons.light'
import * as customIcons from './icons.custom'

config.showMissingIcons = false
library.add(
    solidIcons,
    lightIcons,
    customIcons as unknown as IconDefinition,
)
dom.watch()