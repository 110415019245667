import routesFactory from '@router/routes-factory'
import { auth } from '@middleware'

const routerConfig = {
    baseURI: 'metrics',
    module: 'ProductMetrics',
    middleware: [
        auth('lb', { name: '404' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: 'export',
        name: 'metrics.export',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/ProductMetrics" */
                './MetricsExport.vue'
            ),
    },
])
