<template>
    <b-dropdown-text
        class="dropdown-menu__top position-sticky top-0"
        text-class="d-flex align-items-center"
    >
        <fa
            class="text-muted"
            :icon="['fal', 'search']"
        />
        <b-form-input
            v-model="internalValue"
            class="border-0 py-0"
            :placeholder="$t('TERMS.SEARCH_CONTENT')"
            debounce="500"
            @keydown.esc.stop="onClear"
        />
        <fa
            v-if="internalValue"
            class="pointer"
            size="sm"
            :icon="['fal', 'times']"
            @click.stop="onClear"
        />
    </b-dropdown-text>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    export default Vue.extend({
        props: {
            value: {
                type: String as PropType<string>,
                required: true,
            },
        },

        computed: {
            internalValue: {
                get(): string {
                    return this.value
                },

                set(value: string): void {
                    this.$emit('input', value)
                },
            },
        },

        methods: {
            onClear(): void {
                this.$emit('clear-search', '')
            },
        },
    })
</script>
