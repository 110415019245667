import { Module } from 'vuex'

interface Timer {
    startTime: number | null;
    timeSpent: number;
}

const state: any = {
    timers: new Map<string, Timer>(),
}

const store: Module<any, any> = {
    namespaced: true,

    state,

    getters: {
        getTimer: (state) => (id: string): Timer | null => {
            return state.timers.get(id) || null
        },

        getTime: (state) => (id: string, unit?: 's' | 'm' | 'h'): number => {
            const timer = state.timers.get(id)
            if (!timer)
                return 0

            if (unit === 's')
                return timer.timeSpent / 1000
            else if (unit === 'm')
                return timer.timeSpent / 1000 / 60
            else if (unit === 'h')
                return timer.timeSpent / 1000 / 60 / 60
            else
                return timer.timeSpent
        },
    },

    mutations: {
        CREATE_TIMER(state, id: string): void {
            if (state.timers.has(id))
                return console.warn(`Timer with id "${id}" already exists`)

            state.timers.set(id, {
                startTime: null,
                timeSpent: 0,
            })
        },

        START_TIMER(state, id: string): void {
            const timer = state.timers.get(id)
            if (!timer)
                return console.warn(`No timer found with id: ${id}`)

            if (timer.startTime)
                return

            timer.startTime = Date.now()
        },

        PAUSE_TIMER(state, id: string): void {
            const timer = state.timers.get(id)
            if (!timer)
                return console.warn(`No timer found with id: ${id}`)

            if (!timer.startTime)
                return

            timer.timeSpent += Date.now() - timer.startTime
            timer.startTime = null
        },

        RESUME_TIMER(state, id: string): void {
            const timer = state.timers.get(id)
            if (!timer)
                return console.warn(`No timer found with id: ${id}`)

            if (timer.startTime)
                return

            timer.startTime = Date.now()
        },

        RESET_TIMER(state, id: string): void {
            const timer = state.timers.get(id)
            if (!timer)
                return console.warn(`No timer found with id: ${id}`)

            timer.startTime = null
            timer.timeSpent = 0
        },

        REMOVE_TIMER(state, id: string): void {
            state.timers.delete(id)
        },

        ADD_TIME(state, { id, timeSpent }: { id: string; timeSpent: number }): void {
            const timer = state.timers.get(id)
            if (!timer)
                return console.warn(`No timer found with id: ${id}`)

            timer.timeSpent += timeSpent
            state.timers.set(timer.id, timer)
        },
    },

    actions: {
        start({ commit, state }, id: string): void {
            if (!state.timers.has(id))
                commit('CREATE_TIMER', id)

            commit('START_TIMER', id)
        },

        pause({ commit }, id: string): void {
            commit('PAUSE_TIMER', id)
        },

        resume({ commit }, id: string): void {
            commit('RESUME_TIMER', id)
        },

        reset({ commit }, id: string): void {
            commit('RESET_TIMER', id)
        },

        remove({ commit }, id: string): void {
            commit('RESET_TIMER', id)
        },

        addTime({ commit }, timer: { id: string; timeSpent: number }): void {
            commit('ADD_TIME', timer)
        },
    },
}

export default store
