import { Module } from 'vuex'
import Http from '@utils/Http'

const store: Module<any, any> = {
    namespaced: true,

    actions: {
        /**
         * Issue a new signed cookie for resources
         */
        async issue(): Promise<void> {
            await Http.api().get(`api2/cookie/cloud-front/issue`, { withCredentials: true })
        },
    },
}

export default store
