import Vue, { CreateElement, PropType } from 'vue'

/**
 * Table column is used in the default slot of our table components
 */
export default Vue.extend({
    props: {
        /**
         * The key for selecting data from the record in the items array.
         *
         * You can use dot notation eg `profile.first_name`
         */
        field: {
            type: String as PropType<string>,
            required: true,
        },
        /**
         * Column name in `<thead />`
         */
        label: {
            type: String as PropType<string>,
            default: '',
        },
        /**
         * Allow sortable column, `field` prop will be used as sort key
         */
        sortable: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Sort the column by the result of the field's formatter callback function.
         * @see https://bootstrap-vue.org/docs/components/table#fields-column-definitions
         */
        sortByFormatted: {
            type: [Boolean, Function] as PropType<boolean | ((...args: any[]) => any)>,
            default: false,
        },
        /**
         * Hide column by default
         */
        hidden: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * `<td /> class for every single row of column`
         */
        tdClass: {
            type: [String, Array, Object] as PropType<string | string[] | Record<string, boolean>>,
            default: undefined,
        },
        /**
         * `<th /> class in column header`
         */
        thClass: {
            type: [String, Array, Object] as PropType<string | string[] | Record<string, boolean>>,
            default: undefined,
        },
        /**
         * `<th /> styles in column header`
         */
        thStyle: {
            type: Object as PropType<Partial<CSSStyleDeclaration>>,
            default: undefined,
        },
        /**
         * Classes to be applied to both <th /> and <td /> items to the column
         */
        columnClass: {
            type: [String, Array, Object] as PropType<string | string[] | Record<string, boolean>>,
            default: undefined,
        },
        /**
         * Column will be set to `width: 1%`, this will overwrite any width set with `thClass`
         */
        compact: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Format display value for every cell in column
         */
        formatter: {
            type: Function,
            default: undefined,
        },
    },

    methods: {
        /**
         * Transform object class definition to string[] since bsVue doesn't support it.
         */
        formatClass(value?: string | string[] | Record<string, boolean>): string[] | string | void {
            if (!value || typeof value === 'string' || Array.isArray(value))
                return value

            return Object.keys(value).filter((key) => value[key])
        },

        getColumn(): any {
            const column = {
                key: this.field,
                label: this.label,
                hidden: this.hidden,
                sortable: this.sortable,
                sortByFormatted: this.sortByFormatted,
                class: this.formatClass(this.columnClass),
                tdClass: this.formatClass(this.tdClass),
                thClass: this.formatClass(this.thClass),
                template: this.$scopedSlots.default,
                headerTemplate: this.$scopedSlots.header,
                thStyle: this.thStyle ?? {} as Partial<CSSStyleDeclaration>,
                formatter: this.formatter,
            }

            if (this.compact) {
                column.thStyle.width = '1%'
            }

            return column
        },
    },

    render(h: CreateElement) {
        return h('span', { style: { display: 'none' } })
    },
})