import { auth } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'tags',
    module: 'Tags',
    middleware: [
        auth(['admin', 'lb'], { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'tags.index',
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Tags" */
            './TagsIndex.vue'
        ),
    },
])
