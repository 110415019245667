import Vue, { PropType } from 'vue'
import { TopbarAction } from './typings'

export default Vue.extend({
    name: 'TopbarActionItem',
    functional: true,
    props: {
        action: {
            type: Object as PropType<TopbarAction>,
            required: true,
        },
        type: {
            type: String as PropType<string>,
            default: 'primary',
            validator: (value: string): boolean => ['primary', 'secondary'].includes(value),
        },
        isMobile: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },

    render(h, { props, data }) {
        const { action, isMobile, type } = props

        const actionProps = {
            variant: 'primary',
            emphasis: type === 'primary' ? 'high' : 'medium',
            pill: isMobile,
            disabled: action.disabled,
            icon: action.icon ? ['fal', action.icon] : null,
            label: !isMobile ? action.label : null,
        }

        if (action.type === 'callback') {
            return h('btn', {
                class: data.class,
                props: actionProps,
                on: { click: (): any => action.handler() },
            })
        }

        if (action.type === 'route') {
            return h('btn', {
                class: data.class,
                props: {
                    ...actionProps,
                    to: action.to,
                },
            })
        }

        if (action.type === 'link') {
            return h('btn', {
                class: data.class,
                props: {
                    ...actionProps,
                    href: action.href,
                },
            })
        }

        return h()
    },

})
