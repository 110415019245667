import scss from '@scss/exports.module.scss'
import { unflattenObject } from '@utils'

type ScssColors = 'body' | 'danger' | 'success' | 'warning'
| 'gray100' | 'gray200' | 'gray400' | 'gray600'

type ScssSizes = 'navWidth' | 'navWidthSm'

type SharedScssVariables = {
    sizes: Record<ScssSizes, string>;
    color: Record<ScssColors, string>;
    breakpoints: Record<TShirtSizes, string>;
}

/**
 * Typings for vue to recognize our constants
 * Please add comment descriptions to describe
 * our constsants
 */
export interface ConfigPluginInterface {
    /** Current build environment */
    env: typeof NODE_ENV;
    /** Shared scss variables */
    scss: SharedScssVariables;
    /** Module namespace */
    modules: {
        /** Fontawesome type map to module types */
        typeIcon: { [key: number]: string };
    };
}

/**
 * App Config constants
 */
const config: ConfigPluginInterface = {
    env: NODE_ENV,
    scss: unflattenObject(scss, '-') as SharedScssVariables,
    modules: {
        typeIcon: {
            1: 'book-open',   // Lesson/Content
            2: 'list',        // Survey
            4: 'question',         // Quiz/Test
        },
    },
}

export default config
