import { TextAlign } from '@tiptap/extension-text-align'
export * from '@tiptap/extension-text-align'

/**
 * Extending commands only to change 'every' to 'some'
 * This is due to validation issue on the can() method, if headings are
 * disabled in current editor
 */
const ExtendedTextAlign = TextAlign.extend({
    addCommands() {
        return {
            setTextAlign: (alignment: string) => ({ commands }) => {
                if (!this.options.alignments.includes(alignment)) {
                    return false
                }

                return this.options.types
                    .map(type => commands.updateAttributes(type, { textAlign: alignment }))
                    .some(response => response)
            },

            unsetTextAlign: () => ({ commands }) => {
                return this.options.types
                    .map(type => commands.resetAttributes(type, 'textAlign'))
                    .some(response => response)
            },
        }
    },
})

export default ExtendedTextAlign.configure({
    types: ['heading', 'paragraph'],
})