<template>
    <div
        class="flat-modal"
        tabindex="-1"
        role="dialog"
        @click.self="outsideClick"
    >
        <div
            v-if="closeBtn"
            class="flat-modal__close"
            @click="reject('cancel')"
        >
            <fa-layers class="fa-3x pointer">
                <fa
                    icon="circle"
                    class="text-white"
                />
                <fa
                    class="text-primary-hover"
                    transform="shrink-7"
                    :icon="['fal', 'times']"
                />
            </fa-layers>
        </div>
        <div
            class="modal-dialog"
            :class="dialogClass"
            role="document"
            @mousedown.stop
            @mouseup.stop
        >
            <div class="modal-content">
                <!--
                    @slot Modal content
                    @binding {function} resolve Resolve modal promise
                    @binding {function} reject Reject  modal promise
                -->
                <slot
                    :reject="reject"
                    :resolve="resolve"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedModalMixin from './SharedModalMixin'

    /**
     * Flat modal is a bare minimum of a modal by itself it
     * does not render anything, anything you set in its default
     * slot will be rendered as a modal.
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedModalMixin>
    >).extend({
        mixins: [ SharedModalMixin ],

        props: {
            /**
             * Show fixed close button in top right corner
             */
            closeBtn: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .flat-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $zindex-modal;
        overflow-x: none;
        overflow-y: auto;

        &__close {
            position: fixed;
            top: .5rem;
            right: 1rem;
        }

        .modal-content {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            box-shadow: none;

        }
    }

</style>
