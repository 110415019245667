<script lang="ts">
    import Vue, { PropType, VNode } from 'vue'
    import ButtonBase from '@common/Buttons/ButtonBase.vue'

    type BtnStatuses = 'incomplete' | 'completed' | 'failed' | 'pending' | 'locked'
    type BtnProps = Pick<InstanceType<typeof ButtonBase>, 'icon' | 'variant' | 'label' | 'emphasis' | 'disabled'>

    const btnStatusOptions = {
        incomplete: {
            icon: ['fas', 'play'],
            variant: 'primary',
            label: 'TOOLTIP.PLAY',
            emphasis: 'high',
            disabled: false,
        },
        completed: {
            icon: ['fas', 'play'],
            variant: 'primary',
            label: 'TERMS.RETRY',
            emphasis: 'high',
            disabled: false,
        },
        failed: {
            icon: ['fal', 'sync'],
            variant: 'warning',
            emphasis: 'medium',
            label: 'TERMS.RETRY',
            disabled: false,
        },
        pending: {
            icon: ['fal', 'hourglass-half'],
            variant: 'default',
            emphasis: 'medium',
            label: 'TERMS.WAITINGAPPROVAL',
            disabled: true,
        },
        locked: {
            icon: ['fal', 'lock'],
            variant: 'primary',
            emphasis: 'medium',
            label: 'TERMS.LOCKED',
            disabled: true,
        },
    } as Record<BtnStatuses, BtnProps>

    /**
     * This component is a wrapper component for button with some presets.
     * We use this button to start playing a module, you'll need to provide
     * some basic props (required props) in order to use the component,
     * then in the end it will build a click listener that will navigate you to
     * the module player correctly.
     *
     * Notable features:
     * - You can use this component as a render-less component by providing
     *   a default scoped slot, then you'll have access to the click listener
     *   with in the scope of the slot as well as a generated button props
     *   if you need to recreate the original button inside of your default slot.
     *
     * - If using the default slot and you have more then 1 child then the
     *   component will automatically wrap the children in a element of
     *   your choosing, by using the `tag` property
     */
    export default Vue.extend({
        props: {
            /**
             * Module to start
             */
            moduleId: {
                type: Number as PropType<number>,
                default: null,
            },
            /**
             * Module's container id
             */
            containerId: {
                type: Number as PropType<number>,
                default: null,
            },
            /**
             * Module's container id
             */
            userId: {
                type: Number as PropType<number>,
                default: null,
            },
            /**
             * Start module on specific slide by slideId
             */
            slideId: {
                type: Number as PropType<number>,
                default: null,
            },
            /**
             * Module's status
             */
            status: {
                type: String as PropType<BtnStatuses>,
                default: null,
                validator: (value: BtnStatuses): boolean => Object.keys(btnStatusOptions).includes(value),
            },
            /**
             * Modules concluder
             */
            concluder: {
                type: String as PropType<BaseModule['concluder']>,
                default: 'user',
            },
            /**
             * Display label on button
             */
            displayText: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Display icon on button
             */
            displayIcon: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Use router replace when navigation to player
             */
            replace: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * When using the default slot you can set a specific
             * tag for the container of the slot, if you have more then
             * 1 child in the slot, defaults to span
             */
            tag: {
                type: String as PropType<string>,
                default: 'span',
            },
            /**
             * Disable button state, also disable the click handler
             */
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            isDisabled(): boolean {
                return this.disabled || (!this.moduleId || !this.containerId)
            },

            btnProps(): any {
                if (this.concluder === 'manager') {
                    const props = btnStatusOptions.pending
                    props.label = 'MANAGER_DRIVEN.PENDING'

                    return this.prepProps(props)
                }

                return this.prepProps(btnStatusOptions[this.status] ?? btnStatusOptions.incomplete)
            },

            hasDefaultSlot(): boolean {
                return !!this.$slots.default || !!this.$scopedSlots.default
            },
        },

        methods: {
            onClick(): void {
                // cancel routing if button is set to disabled
                if (this.isDisabled || this.btnProps.disabled)
                    return

                const method = this.replace
                    ? 'replace'
                    : 'push'

                this.$router[method]({
                    name: 'modulePlayer.index',
                    params: {
                        moduleId: String(this.moduleId),
                        slideId: String(this.slideId),
                    },
                    query: {
                        containerId: String(this.containerId),
                        ...(this.userId && { userId: String(this.userId) }),
                    },
                })
            },

            prepProps(props: BtnProps): Partial<BtnProps> {
                return {
                    icon: this.displayIcon ? props.icon : undefined,
                    variant: props.variant,
                    emphasis: props.emphasis,
                    label: this.displayText ? this.$t(props.label) as string : undefined,
                    disabled: this.isDisabled || props.disabled,
                }
            },
        },

        render(h): VNode {
            if (this.hasDefaultSlot) {
                /**
                 * @slot default Make component render-less
                 *
                 * @binding {Function} start The original button click handler to start a module
                 * @binding {object} btnProps Generated btn props for the original button
                 */
                const children = this.$scopedSlots.default?.({
                    start: this.onClick.bind(this),
                    btnProps: this.btnProps,
                }) ?? []

                return children.length <= 1
                    ? children[0]
                    : h(this.tag, { on: this.$listeners }, children)
            }

            return h('btn', {
                props: this.btnProps,
                attrs: this.$attrs,
                on: {
                    ...this.$listeners,
                    click: this.onClick.bind(this),
                },
            })
        },
    })
</script>
