<template>
    <topbar-base shadow="xs">
        <template
            v-if="$slots.nav"
            #center
        >
            <!--
                @slot Nav slot, should take `<topbar-nav-item />` components
             -->
            <slot name="nav" />
        </template>

        <template #left>
            <!-- Back button -->
            <button
                v-if="hasBack"
                class="btn btn-link text-reset pl-0"
                @click="handleBack"
            >
                <fa :icon="['fal', 'arrow-left']" />
            </button>

            <!-- @slot Overwrite title with anything -->
            <slot name="title">
                <div class="text-truncate">
                    {{ title }}
                </div>
            </slot>


            <!-- Search field -->
            <div
                v-if="hasSearch && searchIsOpen"
                class="topbar__search"
                tabindex="-1"
                :class="[isMobile ? 'topbar__search--mobile' : 'topbar__search--focus mr-2',
                         { 'topbar__search--query': !isMobile && query }]"
            >
                <button
                    v-if="isMobile"
                    class="btn btn-link text-reset px-0 mr-3"
                    @click="onCancelSearch"
                >
                    <fa :icon="['fal', 'arrow-left']" />
                </button>
                <input-field
                    class="mb-0 flex-grow-1"
                    type="search"
                    :value="query"
                    :placeholder="queryPlaceholder"
                    :debounce="queryDebounce"
                    :autofocus="isMobile"
                    clearable
                    @input="onSearchInput"
                />
            </div>
        </template>

        <template #right>
            <slot name="right" />
            <!-- Mobile search toggle button -->
            <button
                v-if="isMobile && hasSearch"
                class="btn btn-light rounded-pill"
                @click="openSearch = !openSearch"
            >
                <fa :icon="['fas', 'search']" />
            </button>
            <!-- Secondary action item -->
            <topbar-action-item
                v-if="secondaryAction"
                :action="secondaryAction"
                type="secondary"
                :is-mobile="isMobile"
            />
            <!-- Primary action item -->
            <topbar-action-item
                v-if="primaryAction"
                :action="primaryAction"
                type="primary"
                :is-mobile="isMobile"
                :class="primaryClass"
            />

            <topbar-action-dropdown
                v-if="showDropdown"
                :actions="dropdownActions"
                :is-mobile="isMobile"
            >
                <!--
                    @slot Any items that will always be in a dropdown.
                    Items such as `<b-dropdown-item-* />` should be used
                -->
                <slot name="dropdown" />
            </topbar-action-dropdown>
            <span v-show="false">
                <!--
                    @slot Collection of `<top-bar-action />` components,
                    mounted hook takes over, does not render content here
                -->
                <slot />
            </span>
        </template>
    </topbar-base>
</template>

<script lang="ts">
    import Vue, { PropType, VNode } from 'vue'
    import { Location, Route } from 'vue-router'
    import TopbarBase from './TopbarBase.vue'
    import TopbarActionItem from './TopbarActionItem'
    import TopbarActionDropdown from './TopbarActionDropdown'
    import { TopbarAction } from './typings'
    import { InputField } from '@common/Forms'

    /**
     * Our Topbar supports multiple actions and will display max of 2
     * actions. The rest will be collapsed into a dropdown.
     * You can also set fixed items in the dropdown
     *
     * You can sync a search query property and display search in the topbar
     *
     * This component should be used with in our `header portal target`
     *
     * @requires ./TopbarAction.ts
     */
    export default Vue.extend({
        components: { TopbarBase, TopbarActionItem, TopbarActionDropdown, InputField },
        props: {
            /** Title of the page, can be overwritten with title slot */
            title: { type: String as PropType<string>, default: null },
            /** Disable search even if has `query` prop */
            searchDisabled: { type: Boolean as PropType<boolean>, default: false },
            /** Search query use the .sync modifier */
            query: { type: String as PropType<string>, default: undefined },
            /** Debounce value for search input */
            queryDebounce: { type: Number as PropType<number>, default: 350 },
            /** Query placeholder */
            queryPlaceholder: {
                type: String as PropType<string>,
                default: function(): string {
                    return this.$t('TERMS.SEARCH') as string
                },
            },
            /** Displays a back button that goes history -1 */
            withBack: { type: Boolean as PropType<boolean>, default: false },
            /** Displays a back button and routes to a specific location */
            backTo: { type: Object as PropType<Location>, default: null },
            /** Force topbar to render as mobile topbar */
            forceMobile: { type: Boolean as PropType<boolean>, default: false },
        },

        data() {
            return {
                openSearch: false,
                actionsLocal: [] as TopbarAction[],
            }
        },

        computed: {
            isMobile(): boolean {
                return this.forceMobile || this.$device.isMobile
            },

            hasBack(): boolean {
                return !!this.backTo || this.withBack
            },

            hasSearch(): boolean {
                return !this.searchDisabled && this.query !== undefined
            },

            searchIsOpen(): boolean {
                return !this.isMobile || this.openSearch
            },

            primaryAction(): TopbarAction | undefined {
                return this.actionsLocal[0]
            },

            primaryClass(): string | undefined {
                return this.actionsLocal[0].primaryClass
            },

            secondaryAction(): TopbarAction | undefined {
                if (this.isMobile && this.hasSearch) {
                    return undefined
                }

                return this.actionsLocal[1]
            },

            dropdownActions(): TopbarAction[] {
                return this.actionsLocal.filter((_item, index) => {
                    return this.isMobile && this.hasSearch
                        ? ![0].includes(index)
                        : ![0, 1].includes(index)
                })
            },

            sortedActions(): any {
                const [primary, secondary = null, ...dropdown] = this.actionsLocal

                return {
                    primary, secondary, dropdown,
                }
            },

            showDropdown(): boolean {
                return !!this.$scopedSlots.dropdown || !!this.dropdownActions.length
            },

        },

        mounted() {
            if (!this.$slots.default) return

            const actionComponents = this.$slots.default
                .filter((column: VNode) => column.componentInstance)
                .map((column: any) => column.componentInstance)
                .filter((column: any) => column.getAction)

            this.actionsLocal = actionComponents
                .map((column: any) => column.getAction())
                .filter((column) => !column.hidden)

            /** Set watcher on all props of action components */
            actionComponents.forEach((actionCom) => {
                Object.keys(actionCom.$options.props).forEach(
                    (prop) => actionCom.$watch(prop, () => {
                        this.actionsLocal = actionComponents
                            .map((column: any) => column.getAction())
                            .filter((column) => !column.hidden)
                    }),
                )
            })
        },

        methods: {
            handleBack(): Promise<Route> | void {
                if (this.backTo) {
                    return this.$router.push(this.backTo)
                }

                return this.$router.go(-1)
            },

            onCancelSearch(): void {
                this.openSearch = false
                this.onSearchInput('')
            },

            onSearchInput(value: string): void {
                /**
                 * On search query update, remember to use .sync modifier on `query` prop
                 * @arg {string} value Search query
                 */
                this.$emit('update:query', value)
            },
        },
    })
</script>

<style lang="scss">
@import '@scss/vue.scss';

.topbar {
    &__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__search {
        &--query, &--focus:focus-within {
            width: 25rem;
            max-width: 50%;
        }
        margin-left: spacer(3);

        &--mobile {
            display: flex;
            align-items: center;
            margin: 0 spacer(3);
            position: absolute;
            left: 0;
            right: 0;
            height: 100%;
            background-color: $white;
            z-index: $zindex-popover;

            .input-group {
                .form-control, .input-field__clear {
                    border-top-right-radius: $rounded-pill;
                    border-bottom-right-radius: $rounded-pill;
                }

                .input-group-text, .input-field__clear {
                    border-top-left-radius: $rounded-pill;
                    border-bottom-left-radius: $rounded-pill;
                }
            }
        }
    }


    &__actions {
        white-space: nowrap;
    }
}

</style>