<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <div
                class="rating-field d-flex flex-row-reverse align-items-center justify-content-end"
                :class="[{
                    'rating-field--readonly': readonly,
                    'rating-field--disabled': disabled,
                    'rating-field--keep-opacity': keepOpacity,
                    'rating-field--one-color': oneColor,
                }, inputClass]"
            >
                <div
                    v-for="star in starsLocal"
                    :key="star"
                    class="rating-field__item"
                    :class="[{ 'pointer': !disabled && !readonly }, inputClass]"
                    @click="onSelect(star)"
                >
                    <!--
                        @slot Overwrite selected star icon
                            @binding {boolean} disabled disabled state
                            @binding {boolean} readonly readonly state
                    -->
                    <slot
                        v-if="!disabled && star <= internalValue"
                        name="selected"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <fa
                            class="rating-field__item__selected"
                            :size="sizeDefaultIcon"
                            :icon="iconSelected"
                        />
                    </slot>
                    <!--
                        @slot Overwrite unselected star icon
                            @binding {boolean} disabled disabled state
                            @binding {boolean} readonly readonly state
                    -->
                    <slot
                        v-else
                        name="unselected"
                        :disabled="disabled"
                        :readonly="readonly"
                    >
                        <fa
                            class="rating-field__item__unselected"
                            :size="sizeDefaultIcon"
                            :icon="iconUnselected"
                        />
                    </slot>
                    <div
                        v-if="showValues"
                        class="text-center mt-3"
                        v-text="star"
                    />
                </div>
            </div>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'

    /**
     * A Star rating field
     *
     * - Use custom FA icons for "stars"
     * - Read only mode
     * - Show values below icons
     * - You can use slots to determine what icons (selected/unselected) are
     *   used to replace stars, keep in mind, size prop will not be usable
     *   if you overwrite icons with slots
     *
     * @example ./__docs__/RatingField.examples.md
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedField>
    >).extend({
        mixins: [SharedField],
        props: {
            /**
             * Disables user interaction but shows the current value
             */
            readonly: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Amount of stars to choose from
             */
            stars: {
                type: Number as PropType<number>,
                default: 5,
                validator: (value: number): boolean => value >= 2,
            },
            /**
             * Font awesome icon for selected state
             */
            iconSelected: {
                type: Array as PropType<string[]>,
                default: (): string[] => ['fas', 'star'],
            },
            /**
             * Font awesome icon for unselected state
             */
            iconUnselected: {
                type: Array as PropType<string[]>,
                default: (): string[] => ['fas', 'star'],
            },
            /**
             * Show values below the icons
             */
            showValues: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Use the same color for selected and unselected icons (use outlined icons for unselected).
             */
            oneColor: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Keep the opacity even when readonly is true
             */
            keepOpacity: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            starsLocal(): number[] {
                return Array.from({ length: this.stars }, (_, i) => i + 1).reverse()
            },

            sizeDefaultIcon(): string {
                switch (this.size) {
                case 'sm':
                    return 'lg'
                case 'lg':
                    return '3x'
                default:
                    return '2x'
                }
            },
        },

        methods: {
            onSelect(star: number): void {
                if (this.disabled || this.readonly) return
                this.internalValue = star
            },
        },
    })
</script>
<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .rating-field {
        $self: &;

        &__item {
            .svg-inline--fa {
                @include transition();
            }

            &__selected,
            &__unselected {
                color: $warning;
            }
        }

        &:not(&--one-color) &__item {
            &__unselected {
                color: color('gray-light');
            }
        }

        // Handle hover state by filling in previous stars on hover too
        &:not(&--readonly):not(&--disabled) &__item:hover,
        &:not(&--readonly):not(&--disabled) &__item:hover ~ &__item {
            #{$self}__item__unselected {
                color: $warning !important;
            }
        }
        // Handle hover state one current hover, scale up
        &:not(&--readonly):not(&--disabled) & {
            &__item:hover .svg-inline--fa {
                transform: scale(1.2, 1.2);
            }
        }

        &--disabled:not(&--keep-opacity),
        &--readonly:not(&--keep-opacity) {
            opacity: .4;
        }

        &--disabled {
            filter: grayscale(100%);
        }
    }

</style>
