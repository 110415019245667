import Vue, { VueConstructor } from 'vue'
import { WebStorage, $localStorage, $sessionStorage } from './storage'

/**
 * Add typed $config to vue prototype
 */
declare module 'vue/types/vue' {
    interface Vue {
        $localStorage: InstanceType<typeof WebStorage>;
        $sessionStorage: InstanceType<typeof WebStorage>;
    }
}

/**
 * Installation
 */
const StoragePlugin = {
    install(Vue: VueConstructor): void {
        Vue.prototype.$localStorage = $localStorage
        Vue.prototype.$sessionStorage = $sessionStorage
    },
}

Vue.use(StoragePlugin)

/**
 * Named exports
 */
export {
    $localStorage,
    $sessionStorage,
}

