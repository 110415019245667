<template>
    <fa
        v-if="icon"
        fixed-width
        :icon="['fal', icon]"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { SKILL_DEVELOPER_TYPES_ICONS } from '@modules/SkillsDeveloper/utils'

    export default Vue.extend({
        props: {
            value: {
                type: Object as PropType<LearningModel<'module' | 'container' | 'development-plan'>>,
                required: true,
            },
        },

        computed: {
            icon(): string | null {
                if (this.value.type === 'container')
                    return 'signs-post'

                if (this.value.type === 'development-plan')
                    return SKILL_DEVELOPER_TYPES_ICONS[this.value.data.type]

                if (this.value.type === 'module')
                    return this.$config.modules.typeIcon[this.value.data.moduleTypeId]

                return null
            },
        },
    })
</script>
