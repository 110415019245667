import routesFactory from '@router/routes-factory'
import { auth, hasFeatures } from '@middleware'

const routerConfig = {
    baseURI: 'settings',
    module: 'Settings',
}

export default routesFactory(routerConfig, [
    {
        path: '',
        redirect: { name: '404', replace: true },
    },
    {
        path: 'integration',
        name: 'settings.integration',
        meta: {
            middleware: [
                auth('admin', { name: '404' }),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Settings" */
                './SettingsIntegrationIndex.vue'
            ),
    },
    {
        path: 'api-keys',
        name: 'settings.apiKeys',
        meta: {
            middleware: [
                auth('admin', { name: '404' }),
                hasFeatures('ApiAccess', { name: '401' }),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Settings" */
                './SettingsApiKeysIndex.vue'
            ),
    },
    {
        path: 'security',
        name: 'settings.security',
        meta: {
            middleware: [
                auth('admin', { name: '404' }),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Settings" */
                './SettingsSecurityIndex.vue'
            ),
    },
    {
        path: 'user',
        name: 'settings.user',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Settings" */
                './SettingsUserIndex.vue'
            ),
    },
])
