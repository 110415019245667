<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <b-form-select
                v-model="internalValue"
                :class="inputClass"
                :autofocus="autofocus"
                :disabled="disabled"
                :options="options"
                :name="nameLocal"
                :value-field="valueField"
                :text-field="textField"
                :disabled-field="disabledField"
                :size="size"
            >
                <template
                    v-if="placeholder"
                    #first
                >
                    <option
                        :value="null"
                        :disabled="availableRules.includes('required')"
                    >
                        {{ placeholder }}
                    </option>
                </template>
            </b-form-select>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'

    /**
     * A Basic html select field
     *
     * @example ./__docs__/SelectField.examples.md
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedField>
    >).extend({
        mixins: [SharedField],
        props: {
            /**
             * Array of items to render as options for select input
             */
            options: {
                type: Array as PropType<any[]>,
                required: true,
            },
            /**
             * Field name in the `options` prop array that should be
             * used for the value when option is an object
             */
            valueField: {
                type: String as PropType<string>,
                default: 'value',
            },
            /**
             * Field name in the `options` prop array that should be
             * used for the label when option is an object
             */
            textField: {
                type: String as PropType<string>,
                default: 'text',
            },
            /**
             * Field name in the `options` prop array that should be
             * used for disabling option. Note that this property
             * should be a boolean
             */
            disabledField: {
                type: String as PropType<string>,
                default: 'disabled',
            },
        },
    })
</script>
