import routesFactory from '@router/routes-factory'
import { auth } from '@middleware'

const routerConfig = {
    baseURI: 'player',
    module: 'ModulePlayer',
    middleware: [
        auth(['admin', 'client'], { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: ':moduleId',
        name: 'modulePlayer.index',
        meta: {
            hideAside: true,
        },
        props: (route): object => ({
            moduleId: parseInt(route.params.moduleId) || null,
            slideId: parseInt(route.params.slideId) || null,
            languageId: route.params.languageId || route.query.languageId || null,
            containerId: parseInt(String(route.query.containerId)) || null,
            userId: parseInt(String(route.query.userId)) || null,
        }),
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/ModulePlayer" */
                './ModulePlayerIndex.vue'
            ),
    },

    {
        path: ':moduleId/results',
        name: 'modulePlayer.results',
        meta: {
            hideAside: true,
        },
        props: ({ params }): object => ({
            value: params.value && JSON.parse(params.value),
        }),
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/ModulePlayer" */
                './ModulePlayerResults.vue'
            ),
    },
    {
        path: ':moduleId/preview',
        name: 'modulePlayer.preview',
        meta: {
            hideAside: true,
            middleware: [
                auth('admin', { name: '401' }),
            ],
        },
        props: (route): object => ({
            moduleId: route.params.moduleId || null,
            slideId: route.params.slideId || null,
            languageId: route.params.languageId || null,
            mode: route.params.mode || null,
        }),
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/ModulePlayer" */
                './ModulePlayerPreview.vue'
            ),
    },
])
