import { Heading, HeadingOptions as HeadingOptionsDefault } from '@tiptap/extension-heading'

export interface HeadingOptions extends HeadingOptionsDefault {
    /**
     * Default heading level
     * Useful when using content: 'heading' to set the default heading level
     * Will also reflect on the placeholder
     */
    defaultLevel: number,
}

const ExtendedHeading = Heading.extend<HeadingOptions>({
    addOptions() {
        return {
            ...this.parent?.(),
            defaultLevel: 1,
        }
    },
    addAttributes() {
        return {
            level: {
                default: this.options.defaultLevel,
                rendered: false,
            },
        }
    },
})

export default ExtendedHeading.configure({
    levels: [1, 2, 3, 4],
})