import { Module } from 'vuex'
import Http from '@utils/Http'
import { NONCE_TYPE } from '../utils'

type NoncePayload = {
    type: string;
    nonce: string;
}

const store: Module<any, any> = {
    namespaced: true,
    actions: {
        /**
         * Validate Nonce
         */
        async validate({ rootGetters }, payload: NoncePayload): Promise<void> {
            if (!Object.values(NONCE_TYPE).includes(payload.type) || !payload.nonce)
                throw new Error('[Auth/Nonce/validate] Invalid parameters')

            const organizationUUID = rootGetters['AppConfig/orgUuid']
            const { data } = await Http.api().get(`nonce/validation/${payload.type}/${payload.nonce}`, {
                params: {
                    organizationUUID,
                },
            })

            return data
        },

        /**
         * Resend nonce
         */
        async resend({ rootGetters }, payload: NoncePayload): Promise<void> {
            if (!Object.values(NONCE_TYPE).includes(payload.type) || !payload.nonce)
                throw new Error('[Auth/Nonce/resend] Invalid parameters')

            const nonceMap = {
                passwordReset: 'forgot_password',
                activation: 'activation',
            } as Record<string, string>

            const organizationUUID = rootGetters['AppConfig/orgUuid']
            const uriPart = nonceMap[payload.type]
            const { data } = await Http.api().put(`user/resend/${uriPart}`, {
                organizationUuid: organizationUUID,
                nonce: payload.nonce,
            })

            return data
        },
    },
}

export default store