<template>
    <div
        class="calendar-icon d-flex flex-column align-items-center justify-content-center shadow-xs bg-white"
        :class="[border, `calendar-icon-${size}`]"
    >
        <span
            v-if="month"
            class="calendar-icon__month text-uppercase text-white"
            v-text="month"
        />
        <component
            :is="tag"
            v-if="day"
            class="mb-0 font-weight-bold"
            v-text="day"
        />
        <fa
            v-else
            :icon="['fas', 'star']"
            :size="size"
            class="text-muted opacity-50"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    export default Vue.extend({
        props: {
            date: {
                type: [String, Date] as PropType<string | Date>,
                default: null,
            },

            variant: {
                type: String as PropType<string>,
                default: '',
            },

            size: {
                type: String as PropType<string>,
                default: 'md',
            },
        },

        computed: {
            tag(): string {
                if (this.size === 'lg')
                    return 'h3'

                return 'span'
            },

            border(): string {
                return `border-${this.variant}`
            },

            dateSplit(): [string, string] | null {
                if (!this.date) return null

                return this.$filter('daterange')(this.date, 'D MMM').split(' ')
            },

            day(): string | null {
                return this.dateSplit ? this.dateSplit[0] : null
            },

            month(): string | null {
                if (this.size === 'sm') return null

                return this.dateSplit ? this.dateSplit[1] : null
            },
        },
    })
</script>

<style lang="scss" scoped>
@import '@scss/vue.scss';

.calendar-icon {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: $border-radius;
    border-top: .9rem solid;

    .calendar-icon__month {
        margin-top: -.9rem;
        font-size: 60%;
    }

    &.calendar-icon-sm {
        height: 2rem;
        width: 2rem;
        border-radius: $border-radius-sm;
        border-top: .55rem solid;
    }

    &.calendar-icon-lg {
        height: 4rem;
        width: 4rem;
        border-radius: $border-radius-lg;
        border-top: 1.25rem solid;

        .calendar-icon__month {
            margin-top: -2rem;
            font-size: 100%;
        }
    }
}
</style>
