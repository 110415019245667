
import axios, { AxiosRequestConfig, AxiosInstance, AxiosError, AxiosResponse } from 'axios'
import { pruneEmpty } from './object-helpers'
import store from '@store'

interface HttpServiceConfig {
    // Overwrite default baseURI
    baseUrl?: string;
}

const API_URL = LB_ENV.common.serviceEndpoints.api.public.url
const MAIL_URL = LB_ENV.common.serviceEndpoints.mail.public.url
const RESOURCE_URL = LB_ENV.common.serviceEndpoints.resource.public.url
const CLIENT_URL = LB_ENV.common.serviceEndpoints.client.public.url

const getClient = (baseUrl: undefined | string): AxiosInstance => {
    const options: AxiosRequestConfig = {
        baseURL: baseUrl,
    }

    const token = store.get('Auth/token')

    if (token) {
        options.headers = {
            'x-access-token': token,
        }
    }

    const client = axios.create(options)

    /**
     * Run pruneEmpty on all query parameters on all requests
     */
    client.interceptors.request.use((config) => {
        if (config.params)
            config.params = pruneEmpty(config.params)

        return config
    })

    return client
}

class Http {
    baseURL!: string

    /**
     * Set base url to API Service
     * @returns {Http}
     */
    api(conf?: HttpServiceConfig): this {
        this.baseURL = conf?.baseUrl ?? API_URL

        return this
    }

    /**
     * Set base url to Resource Service
     * @returns {Http}
     */
    resource(conf?: HttpServiceConfig): this {
        this.baseURL = conf?.baseUrl ?? RESOURCE_URL

        return this
    }

    /**
     * Set base url to Mail Service
     * @returns {Http}
     */
    mail(conf?: HttpServiceConfig): this {
        this.baseURL = conf?.baseUrl ?? MAIL_URL

        return this
    }

    /**
     * Set base url to client Service
     * @returns {Http}
     */
    client(conf?: HttpServiceConfig): this {
        this.baseURL = conf?.baseUrl ?? CLIENT_URL

        return this
    }

    /**
     * GET request
     *
     * @param {String} url
     * @param {Object} conf
     * @returns {Promise}
     */
    get<T = any>(url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return getClient(this.baseURL)
            .get(url, conf)
            .then((response: AxiosResponse) => Promise.resolve(response))
            .catch((error: AxiosError) => Promise.reject(error))
    }
    /**
     * DELETE request
     *
     * @param {String} url
     * @param {Object} conf
     * @returns {Promise}
     */
    delete<T = any>(url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return getClient(this.baseURL)
            .delete(url, conf)
            .then((response: AxiosResponse) => Promise.resolve(response))
            .catch((error: AxiosError) => Promise.reject(error))
    }

    /**
     * POST request
     *
     * @param {String} url
     * @param {Object} data
     * @param {Object} conf
     * @returns {Promise}
     */
    post<T = any>(url: string, data = {}, conf: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return getClient(this.baseURL)
            .post(url, data, conf)
            .then((response: AxiosResponse) => Promise.resolve(response))
            .catch((error: AxiosError) => Promise.reject(error))
    }

    /**
     * PUT request
     *
     * @param {String} url
     * @param {Object} data
     * @param {Object} conf
     * @returns {Promise}
     */
    put<T = any>(url: string, data = {}, conf: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return getClient(this.baseURL)
            .put(url, data, conf)
            .then((response: AxiosResponse) => Promise.resolve(response))
            .catch((error: AxiosError) => Promise.reject(error))
    }

    /**
     * PATCH request
     *
     * @param {String} url
     * @param {Object} data
     * @param {Object} conf
     * @returns {Promise}
     */
    patch<T = any>(url: string, data = {}, conf: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return getClient(this.baseURL)
            .patch(url, data, conf)
            .then((response: AxiosResponse) => Promise.resolve(response))
            .catch((error: AxiosError) => Promise.reject(error))
    }
}

export default new Http()
