import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'documents',
    module: 'Documents',
    middleware: [
        auth('client', { name: '401' }),
        hasFeatures('Material', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [])
