import routesFactory from '@router/routes-factory'
import { auth, hasFeatures } from '@middleware'

const routerConfig = {
    baseURI: 'branding',
    module: 'Whitelabels',
    middleware: [
        auth('admin', { name: '401' }),
        hasFeatures('WhitelabelConfiguration', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'whitelabels.index',
        redirect: { name: 'whitelabels.design' },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Whitelabels" */
                './WhitelabelsMaster.vue'
            ),
        children: [
            {
                path: 'design',
                name: 'whitelabels.design',
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Whitelabels" */
                        './WhitelabelsConfigIndex.vue'
                    ),
            },
            {
                path: 'themes',
                name: 'whitelabels.themes',
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Whitelabels" */
                        './WhitelabelsThemesIndex.vue'
                    ),
            },
            {
                path: 'fonts',
                name: 'whitelabels.fonts',
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Whitelabels" */
                        './WhitelabelsFontsIndex.vue'
                    ),
            },
        ],
    },
])
