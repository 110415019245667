import { Module } from 'vuex'
import Http from '@utils/Http'
import { sanitizeFilename } from '@utils'


const store: Module<any, any> = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        /**
         * Download s3 resource by key
         *
         * @param ctx
         * @param key
         */
        async download(_ctx, key: string): Promise<void> {
            const windowRef = window.open()
            try {
                const { data } = await Http.resource().get('resource/get/file', {
                    params: { key, download: 1 },
                })

                if (windowRef) windowRef.location = data
            } catch (e) {
                windowRef?.close()
                throw e
            }
        },

        /**
         * S3 sign file
         *
         * @param _ctx
         * @param payload
         */
        async signFile(
            { rootGetters },
            { location, uuid, signingUrl }: {
                location: ResourceLocation;
                uuid?: string;
                signingUrl?: string;
            },
        ): Promise<any> {
            // Fallback ID for resource path to current user ID
            const id = rootGetters['Auth/authUser'].id
            // What url to use for signing file.
            const url = signingUrl ?? 'file/sign'

            const { data } = await Http.resource()
                .post(`resource/${url}`, {
                    location: { id, ...location },
                    uuid,
                })

            return data
        },

        /**
         * Get S3 signature object for Froala editor to use file uploading
         * @param _ctx
         * @param options
         * @returns
         */
        async froalaSigning(_ctx, options: ResourceFroalaS3Params): Promise<any> {
            const { data } = await Http.resource()
                .get(`resource/froala/signing`, {
                    params: options,
                })

            return data
        },

        /**
         * Delete file by key from s3 - PUBLIC BUCKET
         *
         * @param key s3 key
         */
        async deletePublicKey(_ctx, key: string): Promise<void> {
            await Http.resource().delete(`resource/public`, {
                params: { key },
            })
        },

        /**
         * Upload file to S3
         *
         * @param _ctx
         * @param payload
         */
        async uploadFile(
            { dispatch },
            { file, location, signingUrl, onUploadProgress }: {
                file: File;
                location: ResourceLocation;
                signingUrl?: string;
                onUploadProgress?: (event: ProgressEvent) => void;
            },
        ): Promise<{ key: string; file: File }> {
            const { key, signed_request } = await dispatch('signFile', {
                signingUrl,
                location: {
                    ...location,
                    name: location.name || sanitizeFilename(file.name),
                    type: location.type || file.type,
                },
            })

            await Http.put(signed_request, file, {
                headers: {
                    'x-amz-server-side-encryption': 'AES256',
                    'Content-Type': file.type,
                },
                onUploadProgress: onUploadProgress,
            })

            return { key, file }
        },

        /**
         * Delete a file on s3 by it's key
         */
        async deleteFile(_ctx, key: string): Promise<void> {
            await Http.resource().delete('resource/removeFile', {
                params: {
                    key,
                },
            })
        },

        /**
         * Upload a SCORM file (zip) to S3
         */
        async uploadScorm(
            _ctx,
            { file, moduleId, resourceId, languageId }: UploadScormPayload,
        ): Promise<string> {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('moduleId', `${moduleId}`)
            formData.append('resourceId', `${resourceId}`)
            formData.append('languageId', languageId)

            const { data } = await Http.resource().put('resource/scorm', formData)

            return data
        },

        /**
         * Delete a SCORM file from S3
         */
        async deleteScorm(_ctx, key: string): Promise<void> {
            const options = {
                data: { key },
            }
            await Http.resource().delete('resource/scorm', options)
        },
    },
}

export default store
