<template>
    <div>
        <ul class="list-unstyled">
            <li
                v-for="(item, index) in rulesComputed"
                :key="index"
                :class="{ 'text-muted': !item.valid }"
            >
                {{ item.label }}
                <fa
                    v-if="item.valid"
                    class="text-success"
                    fixed-width
                    :icon="['fas', 'check']"
                />
            </li>
        </ul>
        <template v-if="passwordStrength">
            <p class="mb-0">
                {{ $t('VALIDATION.PW_STRENGTH.TITLE') }}:
                <strong>{{ $t('VALIDATION.PW_STRENGTH.' + passwordStrength.verdict) }}</strong>
            </p>
            <meter
                class="w-100"
                max="45"
                :value="passwordStrength.score"
                low="25"
                high="34"
                optimum="35"
            />
        </template>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { passwordStrength } from '@utils'

    export default Vue.extend({
        props: {
            /** The password to validate strength */
            value: { type: String as PropType<string>, default: null },
            /** List of failed rules from vee-validate to determine validity */
            failedRules: { type: Array as PropType<string[]>, default: (): never[] => [] },
            /** Hide password strength meter */
            noPasswordMeter: { type: Boolean as PropType<boolean>, default: false },
            /** Min password length */
            minPasswordLength: {
                type: Number as PropType<number>,
                default: 8,
            },
        },

        data() {
            return {
                /** vee validate rules we use to validate password content */
                rules: [
                    {
                        name: 'min',
                        label: this.$t('SETTINGS.PASSWORD.REQ_LENGTH', { count: this.minPasswordLength }),
                        valid: false,
                    },
                    {
                        name: 'upper_and_lower_case',
                        label: this.$t('SETTINGS.PASSWORD.REQUIREMENT_GROUPS'),
                        valid: false,
                    },
                    {
                        name: 'number_or_special',
                        label: this.$t('SETTINGS.PASSWORD.REQUIREMENT_SPECIALS'),
                        valid: false,
                    },
                ],
            }
        },

        computed: {
            passwordStrength(): any {
                if (this.noPasswordMeter || !this.value && !this.value.length) {
                    return null
                }

                return passwordStrength(this.value)
            },

            rulesComputed(): any[] {
                return this.rules.map((rule)=> {
                    rule.valid = !this.failedRules.includes(rule.name)

                    return rule
                } )
            },
        },
    })
</script>