import routesFactory from '@router/routes-factory'
import { auth, hasFeatures } from '@middleware'

const routerConfig = {
    baseURI: 'analytics',
    module: 'Analytics',
    middleware: [
        auth('admin', { name: '401' }),
        hasFeatures('AnalyticsDashboard', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'analytics.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Analytics" */
                './AnalyticsIndex.vue'
            ),
    },
])
