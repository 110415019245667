import Vue, { PropType } from 'vue'

/**
 * @mixin AspectRatioMixin
 */
export default Vue.extend({
    props: {
        /**
         * Aspect Ratio.
         * String with the following pattern `<width>:<height>`
         */
        ar: {
            type: String as PropType<string>,
            default: null,
            validator: (v: string): boolean => {
                const [w, h] = v.split(':').map((v) => parseInt(v))

                return !Number.isNaN(w) && !Number.isNaN(h)
            },
        },
    },

    data() {
        return {
            width: 0,
            height: 0,
        }
    },


    computed: {
        aspectRatioStyle(): string | null {
            if (!this.ar)
                return null

            return `aspect-ratio: ${this.width}/${this.height};`
        },

        /**
         * Use aspectRatioStyle instead
         * @deprecated
         */
        aspectRatioClass(): string | null {
            if (!this.ar)
                return null

            return `embed-responsive embed-responsive-${this.ar.replace(':', 'by')}`
        },

        /**
         * Use aspectRatioStyle instead
         * @deprecated
         */
        aspectRatioPadding(): object | null {
            if (!this.ar)
                return null

            return {
                paddingTop: (this.height / this.width) * 100 + '%',
            }
        },
    },

    watch: {
        ar: {
            handler(value, oldValue): void {
                if (this.ar && value !== oldValue) {
                    const [width, height] = this.ar.split(':').map((v) => parseFloat(v))
                    this.width = width
                    this.height = height
                }
            },
            immediate: true,
        },
    },
})
