import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
    /**
     * Catch legacy v/ routes and reroute to actual
     */
    {
        path: '/v*',
        beforeEnter: (to, _from, next): void => next({
            path: to.path.replace('/v', ''),
            query: to.query,
            replace: true,
        }),
    },
    /**
     * Redirect old public routes to new ones
     */
    {
        path: '/welcome/signin',
        redirect: { name: 'auth.signin', replace: true },
    },
    {
        path: '/welcome/first-setup',
        redirect: { name: 'auth.activationAccount', replace: true },
    },
    {
        path: '/welcome/signup',
        redirect: { name: 'auth.signup', replace: true },
    },
    {
        path: '/welcome/new-password',
        redirect: { name: 'auth.passwordReset', replace: true },
    },
    {
        path: '/welcome/activate',
        redirect: { name: 'auth.activationApp', replace: true },
    },
    {
        path: '/welcome/error',
        redirect: { name: 'auth.error', replace: true },
    },
    // ? Temporary redirect route since we moved client learning paths over to Learning module
    {
        path: '/academy/:academyId',
        name: 'academy',
        redirect: { name: 'learning.path.index' },
    },
    // ? Temporary redirect after manager attention route changes
    {
        path: '/events/session/approvals',
        name: 'course.session.approvals',
        redirect: { name: 'manager.attention.session-request', replace: true },
    },
    // ? Temporary redirect after manager attention route changes
    {
        path: '/attention',
        name: 'modules.attention',
        redirect: { name: 'manager.attention.attempt-approval', replace: true },
    },
    // ? Temporary redirect after manager attention route changes
    {
        path: '/academy/approvals',
        name: 'academy.approvals.index',
        redirect: { name: 'manager.attention.learningpath-request', replace: true },
    },
    // ? Temporary redirect after manager attention route changes
    {
        path: '/academy/manager-driven',
        name: 'academy.manager',
        redirect: { name: 'manager.attention.manager-driven', replace: true },
    },

    /**
     * Catch all
     * - If a page doesn't exist then we reroute to 404 page
     * ! This route needs to always be last in the array!
     */
    {
        path: '*',
        redirect: { name: '404', replace: true },
    },
]

export default routes