import { Location } from 'vue-router'
import routesFactory from '@router/routes-factory'
import { auth, hasFeatures } from '@middleware'

const routerConfig = {
    baseURI: '',
    module: 'UserProfile',
    middleware: [
        auth(['client', 'admin'], { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: 'profile/:userId?',
        name: 'userProfile.index',
        props: true,
        redirect: (to): Location => ({
            name: 'userProfile.overview',
            params: to.params,
        }),
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/UserProfile" */
            './UserProfileMaster.vue'
        ),
        children: [
            {
                path: 'settings',
                name: 'userProfile.settings',
                props: true,
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/UserProfile" */
                    './UserProfileSettings.vue'
                ),
            },
            {
                path: 'overview',
                name: 'userProfile.overview',
                props: true,
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/UserProfile" */
                    './UserProfileOverview.vue'
                ),
            },
            {
                path: 'learning/:type?',
                name: 'userProfile.learning',
                props: true,
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/UserProfile" */
                    './UserProfileLearning.vue'
                ),
            },
            {
                path: 'achievements/:type?',
                name: 'userProfile.achievements',
                props: true,
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/UserProfile" */
                    './UserProfileAchievements.vue'
                ),
            },
            {
                path: 'documents',
                name: 'userProfile.documents',
                props: true,
                meta: {
                    middleware: [
                        hasFeatures('Material', { name: '401' }, ['admin', 'client']),
                    ],
                },
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/UserProfile" */
                    './UserProfileDocuments.vue'
                ),
            },
            {
                path: 'team',
                name: 'userProfile.team',
                props: true,
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/UserProfile" */
                    './UserProfileTeam.vue'
                ),
            },
            {
                path: 'history',
                name: 'userProfile.history',
                props: true,
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/UserProfile" */
                    './UserProfileHistory.vue'
                ),
            },

            /*
                Development Plans
             !  This feature is scheduled to be removed in fall 2024
            */
            {
                path: 'development-plans',
                name: 'userProfile.developmentPlans',
                props: true,
                meta: {
                    middleware: [
                        hasFeatures('DevelopmentPlan', { name: '401' }),
                    ],
                },
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/DevelopmentPlans" */
                    '@modules/DevelopmentPlans/DevelopmentPlansProfileIndex.vue'
                ),
            },
        ],

    },
])
