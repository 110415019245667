import routesFactory from '@router/routes-factory'
import { auth, hasFeatures, hasPermissions } from '@middleware'

const routerConfig = {
    baseURI: '',
    module: 'Manager',
    middleware: [
        hasFeatures('MiddleManagement', '404'),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: 'team/:page?',
        name: 'manager.team.index',
        props: true,
        meta: {
            middleware: [
                auth('client'),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Manager" */
                './ManagerTeamIndex.vue'
            ),
    },
    {
        path: 'attention/learningpath-requests',
        name: 'manager.attention.learningpath-request',
        props: {
            type: 'learningpath-request',
        },
        meta: {
            middleware: [
                auth('admin'),
                hasPermissions('learningPathAdminAttendApproval', { name: '403' }),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Manager" */
                './ManagerActionsIndex.vue'
            ),
    },
    {
        path: 'attention/session-requests',
        name: 'manager.attention.session-request',
        props: {
            type: 'session-request',
        },
        meta: {
            middleware: [
                auth('admin'),
                hasPermissions('courseAdminAttendApproval', { name: '403' }),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Manager" */
                './ManagerActionsIndex.vue'
            ),
    },
    {
        path: 'attention/otj-training',
        name: 'manager.attention.manager-driven',
        props: {
            type: 'manager-driven',
        },
        meta: {
            middleware: [
                auth('admin'),
                hasPermissions('managerDrivenComplete', { name: '403' }),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Manager" */
                './ManagerActionsIndex.vue'
            ),
    },
    {
        path: 'attention/feedback',
        name: 'manager.attention.attempt-approval',
        props: {
            type: 'attempt-approval',
        },
        meta: {
            middleware: [
                auth('admin'),
                hasPermissions('moduleAttemptApproval', { name: '403' }),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Manager" */
                './ManagerActionsIndex.vue'
            ),
    },


])
