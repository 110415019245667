import { hasFeatures } from '@modules/Auth/utils'
import { RawLocation } from 'vue-router'

type HasFeaturesParams = Parameters<typeof hasFeatures>

/**
 * Checks current user type
 *
 * @param {String} userType
 * @param {Boolean|Object} nextParam
 * @returns {MiddlewareCallback}
 */
export default function(
    featureName: HasFeaturesParams[0],
    nextParam: RawLocation | false = false,
    role?: HasFeaturesParams[1],
): MiddlewareCallback {
    return ({ proceed, next }): void => {
        if (!hasFeatures(featureName, role)) {
            return next(nextParam)
        }

        return proceed()
    }
}
