import Vue, { VueConstructor } from 'vue'
import { hasFeatures } from '@modules/Auth/utils/hasFeatures'
import { hasPermissions } from '@modules/Auth/utils/hasPermissions'

/**
 * Add typed acl methods to vue prototype
 */
declare module 'vue/types/vue' {
    interface Vue {
        $hasFeatures: typeof hasFeatures;
        $can: typeof hasPermissions;
    }
}

const AclPlugin = {
    install(Vue: VueConstructor): void {
        Vue.prototype.$hasFeatures = hasFeatures
        Vue.prototype.$can = hasPermissions
    },
}

Vue.use(AclPlugin)