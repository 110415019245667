import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility)
/**
 * Custom Directives
 */
import './Can'
import './Cannot'
import './ClickOutside'
import './LineClamp'
import './Knowbot'
