<template>
    <video-aspect-ratio
        v-if="showVideo"
        v-bind="$attrs"
        :src="srcLocal"
        v-on="$listeners"
    >
        <!--
            @slot passes on the default slot to aspect ratio
            video component
         -->
        <slot />
    </video-aspect-ratio>
    <span v-else>
        <!-- eslint-disable vue/no-bare-strings-in-template -->
        Loading video
        <!-- eslint-enable vue/no-bare-strings-in-template-->
    </span>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { buildFullPath } from '@common/Resources/utils'
    import VideoAspectRatio from './VideoAspectRatio.vue'

    export default Vue.extend({
        components: {
            VideoAspectRatio,
        },

        inheritAttrs: false,

        props: {
            /**
             * Video source
             *
             * Can be an url string or s3 key
             */
            src: { type: String as PropType<string>, default: null },
            /**
             * Video MIME type
             */
            type: { type: String as PropType<string>, default: null },
        },

        data() {
            return {
                srcLocal: null as null | string,
            }
        },

        computed: {
            showVideo(): boolean {
                return !!this.srcLocal
            },
        },

        watch: {
            src: {
                handler(value, oldValue): void {
                    if (!oldValue || value !== oldValue)
                        this.srcLocal = buildFullPath(value)
                },
                immediate: true,
            },
        },
    })
</script>
