<template>
    <svg
        viewBox="0 0 162 102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <pattern
                id="image-preview"
                patternUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="100%"
                height="100%"
            >
                <image
                    :xlink:href="src"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMid slice"
                />
            </pattern>
        </defs>

        <rect
            x="0.5"
            y="0.5"
            width="161"
            height="101"
            rx="5"
            fill="white"
            stroke="#D8D8D8"
        />

        <!-- Image -->
        <rect
            v-if="src"
            x="0.5"
            y="0.5"
            width="161"
            height="101"
            rx="5"
            fill="url(#image-preview)"
        />

        <!-- Color overlay -->
        <rect
            v-if="color"
            x="0.5"
            y="0.5"
            width="161"
            height="101"
            rx="5"
            :style="colorStyle"
        />
    </svg>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { colorUtils } from '@learningbank/lb-utils'
    import { IRgb } from '@learningbank/lb-utils/lib/types'

    export default Vue.extend({
        props: {
            /**
             * Image source
             */
            src: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Hex or RGB color overlay
             * This will take priority over the RGB prop in case both are present
             */
            color: {
                type: [String, Object] as PropType<string | IRgb>,
                default: null,
            },

            /**
             * Color overlay alpha
             */
            alpha: {
                type: Number as PropType<Alpha>,
                default: 0,
            },
        },

        computed: {
            colorStyle(): object | null {
                if (!this.color)
                    return null

                const rgb = typeof this.color === 'string' ? colorUtils.hexToRgb(this.color) : this.color
                if (!rgb)
                    return null

                return {
                    fill: `rgba(${rgb.r},${rgb.g},${rgb.b}, ${this.alpha})`,
                }
            },
        },
    })
</script>
