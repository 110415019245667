import Vue, { VNode } from 'vue'
import FroalaEditor from 'froala-editor'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { i18n } from '@plugins/i18n'

const PLUGIN_NAME = 'openai'
const COMMAND_NAME = 'openaiCmd'

const dropdownItems = {
    rephrase: {
        icon: 'rotate',
        key: 'TERMS.REPHRASE',
    },
    shorten: {
        icon: 'arrows-to-line',
        key: 'TERMS.MAKE_SHORTER',
    },
    lengthen: {
        icon: 'arrows-from-line',
        key: 'TERMS.MAKE_LONGER',
    },
    professional: {
        icon: 'user-tie',
        key: 'TONE_OF_VOICE.PROFESSIONAL',
    },
    straightforward: {
        icon: 'hand-holding-heart',
        key: 'TONE_OF_VOICE.STRAIGHTFORWARD',
    },
    confident: {
        icon: 'person-snowboarding',
        key: 'TONE_OF_VOICE.CONFIDENT',
    },
    humorous: {
        icon: 'face-smile-upside-down',
        key: 'TONE_OF_VOICE.HUMOROUS',
    },
    friendly: {
        icon: 'handshake',
        key: 'TONE_OF_VOICE.FRIENDLY',
    },
    encouraging: {
        icon: 'heart',
        key: 'TONE_OF_VOICE.ENCOURAGING',
    },
} as Record<OpenAICommands, { icon: string; key: string }>

function renderDropdownItem(type: OpenAICategory, command: OpenAICommands): string {
    const item = dropdownItems[command]

    const tempComponent = new Vue({
        render: (h): VNode => h(FontAwesomeIcon, {
            props: { icon: ['fal', item.icon], fixedWidth: true },
            style: { width: '16px' },
            class: ['pr-2'],
        }),
    }).$mount()

    const html = tempComponent.$el.outerHTML
    const text = i18n.t(item.key)

    tempComponent.$destroy()

    /* eslint-disable vue/max-len */
    return `
        <li role="presentation">
            <a class="fr-command px-3" data-cmd="${COMMAND_NAME}" data-param1="${type}" data-param2="${command}" title="${text}">
                ${html} ${text}
            </a>
        </li>
    `
    /* eslint-enable vue/max-len */
}

function AskGeni(FroalaEditor: any): void {
    Object.assign(FroalaEditor.DEFAULTS, { openai: false })

    FroalaEditor.PLUGINS[PLUGIN_NAME] = function(editor: InstanceType<typeof FroalaEditor>): object {
        function apply(type: OpenAICategory, command: OpenAICommands): void {
            editor.opts.openai({
                type,
                message: editor.selection.text(),
                command,
            })
        }

        function _init(): void {
            if (!editor.opts.openai) {
                return editor.$tb.find(`.fr-command[data-cmd="${COMMAND_NAME}"]`)?.hide()
            }
        }

        return {
            _init,
            apply,
        }
    }

    FroalaEditor.DefineIcon(COMMAND_NAME, { NAME: 'info' })
    FroalaEditor.DefineIcon('froalaAIIcon', { NAME: 'sparkles' })

    FroalaEditor.RegisterCommand(COMMAND_NAME, {
        title: i18n.t('OPENAI.ASK_AI'),
        icon: 'froalaAIIcon',
        plugin: PLUGIN_NAME,
        type: 'dropdown',
        html: function() {
            return `
                <ul class="fr-dropdown-list" role="presentation">
                    <li role="presentation">
                        <span class="text-smaller text-muted px-3">
                            ${i18n.t('TERMS.EDIT')}
                        </span>
                    </li>
                    ${renderDropdownItem('modify', 'rephrase')}
                    ${renderDropdownItem('modify', 'shorten')}
                    ${renderDropdownItem('modify', 'lengthen')}

                    <li role="presentation" class="pt-2">
                        <span class="text-smaller text-muted px-3">
                            ${i18n.t('TONE_OF_VOICE.CHANGE')}
                        </span>
                    </li>
                    ${renderDropdownItem('tone-of-voice', 'professional')}
                    ${renderDropdownItem('tone-of-voice', 'straightforward')}
                    ${renderDropdownItem('tone-of-voice', 'confident')}
                    ${renderDropdownItem('tone-of-voice', 'humorous')}
                    ${renderDropdownItem('tone-of-voice', 'friendly')}
                    ${renderDropdownItem('tone-of-voice', 'encouraging')}
                </ul>`
        },
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback(_cmd: string, type: OpenAICategory, command: OpenAICommands) {
            this[PLUGIN_NAME].apply(type, command)
        },
    })
}

AskGeni(FroalaEditor)
