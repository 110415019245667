<template>
    <!-- Single action item -->
    <div
        v-if="!isDropdown"
        class="navigation-item btn-group"
        :class="value.options && value.options.class"
    >
        <btn
            class="navigation-item__btn text-nowrap"
            :class="buttonClass"
            :active="isMainActive"
            :variant="variant"
            @click="handleClick(false)"
        >
            <slot
                name="button-content"
                :active="isMainActive"
                :total-count="totalCount"
            >
                <fa
                    v-if="showIcons && value.icon"
                    fixed-width
                    :icon="Array.isArray(value.icon) ? value.icon : ['fal', value.icon]"
                />
                {{ value.label }}
                <fa-layers
                    v-if="totalCount > 0"
                    fixed-width
                >
                    <fa
                        class="text-danger"
                        size="lg"
                        :icon="['fas', 'circle']"
                    />
                    <fa-text
                        class="text-white font-weight-bold"
                        transform="shrink-6"
                        :value="totalCount"
                    />
                </fa-layers>
            </slot>
        </btn>
    </div>
    <!-- Multi action item -->
    <b-dropdown
        v-else
        ref="dropdown"
        class="navigation-item"
        :class="value.options && value.options.class"
        menu-class="navigation-item__menu"
        :toggle-class="['navigation-item__btn', { 'active': isMainActive }]"
        :variant="variant"
        v-bind="$attrs"
        boundary="viewport"
        :popper-opts="popperOpts"
        @mouseenter.native="onMouseOver"
        @mouseleave.native="onMouseLeave"
        @shown="setShown(true)"
        @hidden="setShown(false)"
        @toggle="handleClick(true)"
    >
        <template #button-content>
            <!--
                @slot Replace default content for main button
                @binding {boolean} active is current navigation item active in router
                -->
            <slot
                name="button-content"
                :active="isMainActive"
                :shown="shown"
                :total-count="totalCount"
            >
                <fa
                    v-if="showIcons && value.icon"
                    fixed-width
                    :icon="Array.isArray(value.icon) ? value.icon : ['fal', value.icon]"
                />
                {{ value.label }}
                <fa-layers
                    v-if="totalCount > 0"
                    fixed-width
                >
                    <fa
                        class="text-danger"
                        size="lg"
                        :icon="['fas', 'circle']"
                    />
                    <fa-text
                        class="text-white font-weight-bold"
                        transform="shrink-6"
                        :value="totalCount"
                    />
                </fa-layers>
            </slot>
        </template>

        <template #default="{ hide }">
            <!-- Current route label -->
            <slot
                name="header"
                :back="goBack"
                :hide="hide"
                :has-history="!!history.length"
            >
                <vnode-renderer
                    v-if="currentItem.scopedSlots && currentItem.scopedSlots.header"
                    :value="currentItem.scopedSlots.header"
                />
                <b-dropdown-header v-else>
                    <span class="text-muted">{{ currentItem.label }}</span>
                </b-dropdown-header>
            </slot>

            <!-- Back indicator if viewing nested item -->
            <div
                v-if="history.length"
                class="pointer position-absolute top-0 right-0 py-2 px-3"
            >
                <fa
                    size="lg"
                    :icon="['fal', 'times']"
                    @click.stop="goBack"
                />
            </div>

            <!--
                @slot Before dropdown items only when there's no child history
                @binding {Function} hide Hide the dropdown
             -->
            <slot
                v-if="!history.length"
                name="before"
                :hide="hide"
            />

            <vnode-renderer
                v-if="currentItem.scopedSlots && currentItem.scopedSlots.before"
                :value="currentItem.scopedSlots.before({ hide })"
            />
            <!-- Children routes -->
            <template v-if="currentItem.children">
                <b-dropdown-item-button
                    v-for="(item, key) in currentItem.children"
                    :key="key"
                    :active="isActive(item)"
                    :button-class="[
                        'navigation-item__menu__btn',
                        'd-flex align-items-center',
                        { 'navigation-item__menu__btn--has-children': item.children && item.children.length }
                    ]"
                    @click.native.capture.stop="handleChildClick(item, $event)"
                >
                    <!--
                        @slot Replace default content for each child item
                        @binding {NavigationItem} item Navigation item object
                        @binding {boolean} active is current navigation item active in router
                        @binding {Function} hide Hide the dropdown
                     -->
                    <slot
                        name="item-content"
                        :item="item"
                        :hide="hide"
                        :active="isActive(item)"
                    >
                        <fa
                            v-if="showIcons && item.icon"
                            fixed-width
                            :icon="Array.isArray(item.icon) ? item.icon : ['fal', item.icon]"
                        />
                        {{ item.label }}
                        <fa-layers
                            v-if="item.options && item.options.count"
                            class="ml-auto"
                            fixed-width
                        >
                            <fa
                                class="text-danger"
                                size="lg"
                                :icon="['fas', 'circle']"
                            />
                            <fa-text
                                class="text-white font-weight-bold"
                                transform="shrink-6"
                                :value="item.options.count"
                            />
                        </fa-layers>
                    </slot>
                    <span
                        v-if="item.children && item.children.length"
                        class="child-toggler d-flex align-items-center justify-content-center text-muted"
                    >
                        <fa :icon="['fal', 'angle-right']" />
                    </span>
                </b-dropdown-item-button>
            </template>
            <!--
                @slot After dropdown items only when there's no child history
                @binding {Function} hide Hide the dropdown
             -->
            <slot
                v-if="!history.length"
                name="after"
                :hide="hide"
            />

            <vnode-renderer
                v-if="currentItem.scopedSlots && currentItem.scopedSlots.after"
                :value="currentItem.scopedSlots.after({ hide })"
            />
        </template>
    </b-dropdown>
</template>

<script lang="ts">
    import Vue, { VueConstructor, PropType, FunctionalComponentOptions } from 'vue'
    import { BDropdown, BDropdownItemButton, BDropdownHeader } from 'bootstrap-vue'
    import { RawLocation } from 'vue-router'
    import { NavigationItem } from './@typings'

    interface Refs {
        $refs: {
            dropdown?: InstanceType<typeof BDropdown>;
        };
    }

    export default (Vue as VueConstructor<Vue & Refs>).extend({
        components: {
            BDropdown,
            BDropdownItemButton,
            BDropdownHeader,
            VnodeRenderer: {
                functional: true,
                render: (_h, { props }) =>
                    typeof props.value === 'function'
                        ? props.value(props)
                        : props.value,
            } as FunctionalComponentOptions,
        },

        props: {
            /**
             * Navigation item
             */
            value: {
                type: Object as PropType<NavigationItem>,
                required: true,
            },
            /**
             * Set variant value on main navigation button
             */
            variant: {
                type: String as PropType<string>,
                default: 'link',
            },
            /**
             * Forward popperOptions when item is dropdown
             */
            popperOpts: {
                type: Object as PropType<object>,
                default: null,
            },
            /**
             * If item is dropdown, then open on item hover
             */
            hover: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Display icons on every item by default if they have icons
             * Keep in mind, using `button-content` or `item-content` will
             * overwrite this behaviour.
             */
            showIcons: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            buttonClass: {
                type: String as PropType<string>,
                default: '',
            },
        },

        data() {
            return {
                history: [] as NavigationItem[],
                shown: false,
            }
        },

        computed: {
            isSingleAction(): boolean {
                return !this.value.children?.length
            },
            /**
             * Total sum of counts defined on item and children if any
             */
            totalCount(): any {
                const counts = this.value.children?.map((item) => item.options?.count ?? 0) ?? []
                counts.push(this.value.options?.count ?? 0)

                return counts.reduce((acc, val) => acc + val, 0)
            },

            isDropdown(): boolean {
                return (
                    !!this.value.children?.length ||
                    !!this.value.scopedSlots?.before ||
                    !!this.value.scopedSlots?.after ||
                    !!this.$slots.before?.length ||
                    !!this.$slots.after?.length
                )
            },

            currentItem(): NavigationItem {
                return this.history[this.history.length - 1] ?? this.value
            },

            isMainActive(): boolean {
                return this.isActive(this.value)
            },
        },

        watch: {
            // Toggle dropdown on children change
            // Fixes positioning issues when list length changes
            'history.length'(value, oldValue): void {
                if (value === oldValue) return

                this.$refs.dropdown?.hide()
                this.$refs.dropdown?.show()
            },
        },

        methods: {
            /**
             * Checks if current route is same as navigation item
             * or any of its children
             */
            isActive(item: NavigationItem): boolean {
                return this.flattenLocations([item]).some((item) =>
                    typeof item === 'string'
                        ? item === this.$route.path
                        : item.name === this.$route.name)
            },
            /**
             * Get a flat list of all RawLocation items recursively
             */
            flattenLocations(item: NavigationItem[]): RawLocation[] {
                return item.reduce((acc, item) => {
                    if (item.to) acc.push(item.to)
                    if (item.children) acc.push(...this.flattenLocations(item.children))

                    return acc
                }, [] as RawLocation[])
            },
            /**
             * Open children items with in parent
             */
            openChild(item: NavigationItem): void {
                this.history.push(item)
            },
            /**
             * Navigate back to parent
             */
            goBack(): void {
                this.history.splice(-1)
            },
            /**
             * Reset history
             */
            clearHistory(): void {
                this.history = []
            },
            /**
             * Handle clicking of the main navigation item
             */
            handleClick(isDropdown: boolean): void {
                if (typeof this.value.listeners?.click === 'function') {
                    this.value.listeners.click()

                    return this.$refs.dropdown?.hide()
                }

                const location = this.value?.to ?? this.value.children?.[0]?.to
                if (!location || (isDropdown && !this.hover)) return

                this.reRoute(location)
            },
            /**
             * Handle clicking on a child route,
             * weather to toggle nested or go to location
             */
            handleChildClick(item: NavigationItem, event: PointerEvent): void {
                /** Check if click came from toggler elm */
                const fromToggler = !!(event.target as HTMLElement).closest('.child-toggler')

                if (fromToggler)
                    return this.openChild(item)

                if (item.to) {
                    this.reRoute(item.to)

                    return this.$refs.dropdown?.hide()
                }

                if (typeof item.listeners?.click === 'function') {
                    item.listeners.click()

                    return this.$refs.dropdown?.hide()
                }

                if (item.children?.length)
                    return this.openChild(item)
            },

            reRoute(location: RawLocation): void {
                this.$router.push(location)
                    // Silence "NavigationDuplicated" error
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    .catch(() => {})
            },

            onMouseOver(): void {
                if (this.hover)
                    this.$refs.dropdown?.show()
            },

            onMouseLeave(): void {
                if (this.hover)
                    this.$refs.dropdown?.hide()
            },

            setShown(value: boolean): void {
                this.shown = value
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';
    $box-shadow-reverse-xs: 0 -.125rem .675rem rgba($black, .08);

    .navigation-item {
        // ::v-deep &__btn {}
        // ::v-deep &__menu {}

        ::v-deep &__menu {
            border: 0;
            // Overwrite nav dropdowns on mobile
            // - Fill screen
            @include media-breakpoint-down(sm) {
                margin: 0;
                width: 100%;
                border-radius: 0;
                box-shadow: $box-shadow-reverse-xs inset !important;
                height: vh(100, calc(env(safe-area-inset-bottom, 0) + #{$main-nav-width}));
                overflow-y: auto;

                .dropdown-header, .navigation-user-header__name {
                    font-size: $font-size-lg;
                }
            }
        }

        ::v-deep &__menu__btn {
            &--has-children {
                position: relative;
                padding-right: spacer(5);
            }

            .child-toggler {
                width: spacer(5);
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
            }
        }

        ::v-deep .dropdown-header {
            color: inherit;
            display: flex;
            align-items: center;
        }
    }
</style>
