import { Module } from 'vuex'
import { utilHelper } from '@learningbank/lb-utils'
import Http from '@utils/Http'

import { loadLanguageAsync } from '@plugins/i18n'
import { $localStorage } from '@plugins/storage'

const store: Module<{
    appConfig: AppConfig;
    lang: string | null;
}, any> = {
    namespaced: true,
    state: {
        appConfig: window.appConfig || {},
        lang: $localStorage.get('lang') ?? window.appConfig.language_id ?? null,
    },
    getters: {
        organization({ appConfig }): AppConfig | null {
            return appConfig ?? null
        },

        organizationConfig({ appConfig }): OrganizationConfig {
            return appConfig.organizationConfig
        },

        languages: ({ appConfig }): Language[] => appConfig.languages,

        languageMap: ({ appConfig }): Map<string, Language> => {
            return utilHelper.mapBy(appConfig.languages, 'lang') as Map<string, Language>
        },

        systemLanguages({ appConfig }): Language[] {
            return appConfig.languages.filter((item) => item.systemLanguage)
        },

        hasAuth({ appConfig }): (method: string, strict?: boolean) => any {
            return (method: string, strict = false): boolean => {
                const searchMethod = strict ? 'every' : 'some'

                return appConfig.authentications[searchMethod]((authentication: string) => authentication === method)
            }
        },

        orgUuid({ appConfig }): string {
            return appConfig.organization_uuid
        },

    },
    mutations: {
        SET_APP_CONFIG(state, payload: AppConfig): void {
            if (state.appConfig.id !== payload.id)
                return

            state.appConfig = payload
            window.appConfig = payload
        },

        SET_LANG(state, languageId): void {
            const langId = languageId ?? state.lang
            loadLanguageAsync(langId)
            state.lang = langId
        },

        UPDATE_MIN_PASSWORD_LENGTH(state, value): void {
            state.appConfig.organizationConfig.minPasswordLength = value
        },
    },

    actions: {
        /**
         * Load all fonts from appConfig or pass in fonts to be loaded
         */
        async loadFonts({ state }, fonts?: WhitelabelFontModel[]): Promise<void> {
            const fontsArray = fonts ?? state.appConfig.whitelabel_fonts ?? []

            for (const font of fontsArray) {
                const fontFace = new FontFace(font.fullName, `url(${font.url})`)

                try {
                    const loadedFontFace = await fontFace.load()
                    document.fonts.add(loadedFontFace)
                } catch (e) {
                    console.warn(`Failed to load font ${font.fullName}`)
                }
            }
        },

        async sync({ state, commit }): Promise<void> {
            const base64 = btoa(encodeURIComponent(state.appConfig.domain))
            const { data } = await Http.api().get(`/appConfig/${base64}`)

            commit('SET_APP_CONFIG', data)
        },
    },
}

export default store
