<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <multiselect
                ref="multiselect"
                v-model="internalValue"
                :class="[{
                    'multiselect--checkboxes': checkboxes,
                    'multiselect--default-option': defaultOption,
                    'multiselect--lg': size === 'lg',
                    'multiselect--multiple': multiple,
                }, inputClass]"
                :track-by="trackBy"
                :options="options"
                :multiple="multiple"
                :disabled="disabled"
                :label="labelField"
                :custom-label="customLabelField"
                :loading="loading"
                :placeholder="placeholder"
                :tag-placeholder="tagPlaceholder"
                :show-labels="false"
                :hide-selected="hideSelected"
                :max-height="maxHeight"
                :close-on-select="closeOnSelect"
                :limit="limit"
                :limit-text="limitText"
                :searchable="searchable"
                :internal-search="internalSearch"
                :taggable="taggable"
                :allow-empty="allowEmpty"
                :preselect-first="preselectFirst"
                :clear-on-select="clearOnSelect"
                @open="(instanceId) => $emit('open', instanceId)"
                @close="(value, instanceId) => $emit('close', value, instanceId)"
                @select="(selectedOption, instanceId) => $emit('select', selectedOption, instanceId)"
                @remove="(removedOption, instanceId) => $emit('deselect', removedOption, instanceId)"
                @search-change="(searchQuery, instanceId) => $emit('search-change', searchQuery, instanceId)"
                @tag="(searchQuery, instanceId) => $emit('tag', searchQuery, instanceId)"
            >
                <template #noResult>
                    <span class="text-wrap small line-height-sm d-block">
                        {{ $t('TERMS.FILTERS_NO_RESULTS') }}
                    </span>
                </template>
                <template
                    v-for="(_, slot) of $scopedSlots"
                    #[slot]="scope"
                >
                    <slot
                        :name="slot"
                        v-bind="scope"
                        :toggle="toggle"
                    />
                </template>
                <template #caret>
                    <fa
                        size="xs"
                        :icon="['fal', 'chevron-down']"
                        class="select-caret"
                    />
                </template>
            </multiselect>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'
    import Multiselect from 'vue-multiselect'
    import '~vue-multiselect/dist/vue-multiselect.min.css'

    interface Refs {
        $refs: {
            multiselect: InstanceType<typeof Multiselect>;
        };
    }

    /**
     * Advanced select input
     *
     * This is a wrapper component around [vue-multiselect](https://vue-multiselect.js.org)
     * that adds minor extra functionality. But mainly to comply to our sharedFields mixin.
     * This components also includes all of our css overwrites to the default styling of
     * this package.
     *
     * **Notable changes**
     *
     * - Prop `checkboxes` will add checkboxes on the left side of every option in the list.
     * - All slots available in vue-multiselect are inherited in this component.
     *   As well as all scopes with one addition, every slot has a `toggle` method available
     *   to them in addition to the defaults. Gives you the ability to toggle the dropdown
     *   in any slot context you are in.
     *
     * **Forced behaviors**
     *
     * - When `multiple` prop is enabled, we by default force the dropdown to stay open
     *   when you select an item.
     * - When `checkboxes` prop is enabled we by default force the prop `hideSelected` to
     *   be disabled since keeping this on, defeats the purpose of checkboxes.
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedField> & Refs
    >).extend({
        components: { Multiselect },
        mixins: [SharedField],
        props: {
            /** List options */
            options: {
                type: Array as PropType<object[]>,
                required: true,
            },
            /** List options display key */
            labelField: {
                type: String,
                default: 'name',
            },

            /** Function to display custom label */
            customLabelField: {
                type: Function,
                default: undefined,
            },

            /**
             * Property of option object used for selection internals
             * **Important:** This property needs to be unique
             */
            trackBy: {
                type: String,
                default: 'id',
            },
            /** Allow multiple selection */
            multiple: {
                type: Boolean,
                default: false,
            },
            /** Allow tag creation */
            taggable: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Label displayed to the first option when tagging is on
             */
            tagPlaceholder: {
                type: String as PropType<string>,
                default: undefined,
            },
            /** Show selected option in list, defaults to hiding selected */
            showSelected: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /** Clear search when option is selected */
            clearOnSelect: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /** Show list as checkboxes */
            checkboxes: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /** Allows to remove all selected values. Otherwise one must be left selected. */
            allowEmpty: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Limit the display of selected options.
             * The rest will be hidden within the `limitText` string.
             */
            limit: {
                type: Number as PropType<number>,
                default: 99999,
            },
            /**
             * Function that process the message shown when
             * selected elements pass the defined `limit`.
             */
            limitText: {
                type: Function as PropType<any>,
                default: function(count: number): string {
                    return this.$tc('FORMS.OPTIONS_SELECTED', count)
                },
            },
            /** Add / removes search input. */
            searchable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /** Selects the first option if initial value is empty */
            preselectFirst: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Decide whether to filter the results internally based on search query.
             * Useful for async filtering, where we search through more complex data.
             */
            internalSearch: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Show/Hide the loading spinner
             */
            loading: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /** Max height of dropdown options */
            maxHeight: {
                type: Number as PropType<number>,
                default: 300,
            },
            /** Show a default option */
            defaultOption: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            hideSelected(): boolean {
                if (this.checkboxes || !this.multiple) {
                    return false
                }

                return !this.showSelected
            },

            closeOnSelect(): boolean {
                if (this.multiple) {
                    return false
                }

                return true
            },
        },

        methods: {
            toggle(): void {
                (this.$refs.multiselect as any).toggle()
            },
        },
    })
</script>

<style lang="scss" scoped>
@import '@scss/vue.scss';

//*--------------------------------------------
//* Style overwrites to match our other inputs
//*--------------------------------------------
::v-deep .multiselect, ::v-deep .multiselect__input, ::v-deep .multiselect__single {
    font-size: $font-size-base;
}

::v-deep .multiselect {
    min-height: $custom-select-height;
    color: $body-color;

    .select-caret {
        @include centerer(false, true);
        right: spacer(3);
    }

    // Disabled state
    &--disabled {
        opacity: initial;
        background: none;

        .multiselect__tags {
            background-color: $input-disabled-bg;
        }
        .multiselect__tag {
            background: $gray-150;
        }

    }

    &__tags {
        min-height: $custom-select-height;
        padding: ($custom-select-padding-y / 2) $custom-select-padding-x;
        font-size: $font-size-base;
        border-color: $border-color;
        border-radius: $input-border-radius;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-right: $custom-select-height;

        .multiselect__single {
            font-size: 1em;
            color: $gray-700;
        }
    }

    &__placeholder {
        margin: 0;
        padding: 0;
        color: $input-placeholder-color;
    }

    &__input {
        padding: 0;
        margin: 0;

        &::placeholder {
            color: $input-placeholder-color;
        }
    }

    &__spinner {
        height: $custom-select-height - 1px; /* stylelint-disable-line unit-blacklist */
        border-radius: 1rem;
        width: $custom-select-height;

        &::before, &::after {
            border-top-color: whitelabel-color('primary');
        }
    }

    &__select {
        height: $custom-select-height;
        width: $custom-select-height;
        background: $custom-select-background;

        &::before { // remove original caret
            border: 0;
        }
    }

    &__tags-wrap {
        display: flex;
        flex-wrap: wrap;

        &:not(:empty) + .multiselect__strong {
            margin-left: spacer(2);
        }
    }

    &__single, &__strong {
        margin: 0;
        padding: 0;

        &:empty {
            display: none;
        }

    }

    &__tag {
        /* stylelint-disable unit-blacklist */
        padding: 3px $badge-padding-x;
        margin: 2px;
        padding-right: 23px;
        line-height: 1.5;
        font-size: $font-size-sm;
        color: $input-color;
        background: color('off-white');
        border-radius: $border-radius;
        /* stylelint-enable unit-blacklist */
    }

    &__tag-icon {
        margin: 0;
        border-radius: 0;
        line-height: 24px; /* stylelint-disable-line unit-blacklist */
        width: 20px; /* stylelint-disable-line unit-blacklist */

        &::after {
            color: $text-muted !important;
            font-weight: normal;
        }

        &:hover {
            background: inherit;
            color: $body-color;
        }
    }

    &__content-wrapper {
        box-shadow: $box-shadow;
        border: 0;
    }

    &__option {
        padding: $custom-select-padding-y $custom-select-padding-x;
        min-height: initial;
        line-height: $line-height-base;

        // We set the labels below each option since
        // we only use labels on tagging this won't
        // look too weird ;)
        &::after {
            background: transparent;
            position: static;
            display: block;
            padding: 0;
            line-height: 1;
            font-size: $font-size-sm;
            white-space: normal;
        }

        &--selected {
            background: color('off-white');
            color: $body-color;
            font-weight: normal;
        }

        &--highlight {
            background: color('off-white');
            color: $black;
        }
    }

    // Additional selected state on none checkbox options
    &:not(.multiselect--checkboxes) .multiselect__option--selected {
        box-shadow: inset $border-width * 2 0 0 0 whitelabel-color('primary');
    }

    &--active { // Disable spinning icon on select caret
        .multiselect__select {
            transform: none;
        }

        .multiselect__tags {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .multiselect__placeholder {
            display: inline-block;
        }
    }
}

//*--------------------------------------------
//* Styles for displaying Select field as Large
//*--------------------------------------------
::v-deep .multiselect--lg {
    min-height: $custom-select-height-lg;

    .multiselect__tags {
        min-height: $custom-select-height-lg;
        padding: $custom-select-padding-y-lg $custom-select-padding-x-lg;
        font-size: $input-font-size-lg;
        padding-right: $custom-select-height-lg;
        border-radius: $input-border-radius-lg;
    }

    .multiselect__input {
        top: -1px; /* stylelint-disable-line unit-blacklist */
        font-size: $input-font-size-lg;
    }

    .multiselect__spinner {
        top: 3px; /* stylelint-disable-line unit-blacklist */

        &::before, &::after {
            width: $font-size-lg;
            height: $font-size-lg;
        }
    }

    .multiselect__select {
        top: 3px; /* stylelint-disable-line unit-blacklist */
        height: $custom-select-height-lg;
        width: $custom-select-height-lg;
    }

    .multiselect__single, .multiselect__strong {
        font-size: $input-font-size-lg;
        top: 1px; /* stylelint-disable-line unit-blacklist */
    }

    .multiselect__tag {
        /* stylelint-disable unit-blacklist */
        padding: 5px $badge-padding-x;
        padding-right: 28px;
        font-size: $font-size-base;
        /* stylelint-enable unit-blacklist */
    }

    .multiselect__tag-icon {
        margin: 0;
        border-radius: 0;
        line-height: 24px; /* stylelint-disable-line unit-blacklist */
        width: 24px; /* stylelint-disable-line unit-blacklist */
    }

    &.multiselect--active .multiselect__tags {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .multiselect__option {
        padding: $custom-select-padding-y-lg $custom-select-padding-x-lg;
        line-height: $line-height-lg;
        // font-size: $input-font-size-lg; //? Seems a bit to excessive
    }
}

::v-deep .multiselect--default-option {
    .multiselect__element:first-child {
        padding: .5rem 0;
        border-bottom: $border-width solid $border-color;
    }
}

::v-deep .multiselect__option--highlight {
    &::after {
        color: $input-placeholder-color;
    }
}
</style>
