<template>
    <module-player-start-btn
        #default="{ start }"
        :module-id="moduleId"
        :container-id="containerId"
        display-text
    >
        <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
                <learning-content-icon :value="value" />

                <div class="ml-3">
                    <h6
                        class="mb-0 font-weight-normal"
                        v-text="value.data.name"
                    />
                    <small
                        v-if="learningStatus"
                        :class="learningStatus.class"
                        v-text="learningStatus.text"
                    />
                </div>
            </div>

            <btn
                class="ml-auto"
                variant="primary"
                :label="$t('TERMS.CONTINUE')"
                :disabled="!canStart"
                @click="onClick(start)"
            />
        </div>
    </module-player-start-btn>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { Route } from 'vue-router'
    import { canStartModule, canStartContainerModule } from '@modules/Academy/utils/user-content'
    import ModulePlayerStartBtn from '@modules/ModulePlayer/components/ModulePlayerStartBtn.vue'
    import LearningStatusMixin from '@modules/Learning/mixins/learning-status.mixin'
    import LearningContentIcon from '@modules/Learning/components/LearningContentlcon.vue'

    export default (Vue as VueConstructor<Vue & InstanceType<typeof LearningStatusMixin>>).extend({
        components: {
            ModulePlayerStartBtn,
            LearningContentIcon,
        },

        mixins: [LearningStatusMixin],

        props: {
            value: {
                type: Object as PropType<LearningModel<'module' | 'container' | 'development-plan'>>,
                required: true,
            },
        },

        computed: {
            canStart(): boolean {
                if (this.value?.type === 'container')
                    return canStartContainerModule(this.value.context.nextModule)

                if (this.value?.type === 'module')
                    return canStartModule(this.value)

                if (this.value?.type === 'development-plan')
                    return true

                return false
            },

            moduleId(): number | null {
                if (this.value?.type === 'development-plan')
                    return null

                if (this.value?.type === 'container')
                    return this.value?.context.nextModule?.moduleId || null

                return this.value?.data.id ?? null
            },

            containerId(): number | null {
                if (this.value?.type === 'development-plan')
                    return null

                if (this.value?.type === 'module')
                    return this.value.context.containerId ?? null

                return this.value?.data.id ?? null
            },

            learningStatus(): ModuleStatusData | null {
                if (!this.value) return null

                const deadline = this.value.type === 'container'
                    ? this.value.context.nextModule?.deadlineAt ?? null
                    : this.value?.context.deadlineAt ?? null

                return this.getModuleStatusData({
                    ...this.value.context,
                    deadlineAt: deadline,
                })
            },
        },

        methods: {
            onClick(start?: () => any): Promise<Route> | void {
                if (this.value?.type === 'development-plan') {
                    return this.$router.push({
                        name: 'learning.developmentPlans.details',
                        params: {
                            developmentPlanId: this.value.data.id.toString(),
                        },
                        query: {
                            containerId: String(this.value.context.containerId),
                        },
                    })
                }

                if (start)
                    start()
            },
        },

    })
</script>
