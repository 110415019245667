<template>
    <div
        class="table-responsive data-table-tree"
        :class="{ 'table-vcenter' : centerV, 'table-card': card }"
    >
        <!--
            @slot Secondary header displays below header
            @binding {() => void} clearSelection clears selection
         -->
        <slot
            name="header-secondary"
            :clear-selection="clearSelection"
        />
        <b-table-simple :hover="hover">
            <b-thead>
                <b-tr>
                    <b-th
                        v-for="(column, index) in fieldsLocal"
                        :key="index"
                        :class="column.thClass"
                        :style="column.thStyle"
                    >
                        <span class="d-flex align-items-center">
                            <b-form-checkbox
                                v-if="!disableSelectAll && selectable && index === 0 && itemsLocal.length"
                                v-model="selectAll"
                                class="mr-3"
                                @input="onSelectAll"
                            />
                            {{ column.label }}
                        </span>
                    </b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <!-- Empty States -->
                <b-tr v-if="!itemsLocal.length && !hideEmpty">
                    <b-td :colspan="fieldsLocal.length || '1'">
                        <!--
                            @slot Visible when no items to display
                            @binding {any[]} fields Visible fields
                        -->
                        <slot
                            name="empty"
                            :fields="fieldsLocal"
                        >
                            <table-empty-state-default
                                :title="$t('TERMS.EMPTY_STATE_DATA')"
                                :message="$t('TERMS.EMPTY_DATA_DETAILS')"
                            />
                        </slot>
                    </b-td>
                </b-tr>
                <b-tr
                    v-for="(row, index) in itemsLocal"
                    :key="index"
                >
                    <template v-for="(column, colIndex) in fieldsLocal">
                        <b-td
                            :key="colIndex"
                            :class="column.tdClass"
                        >
                            <span
                                class="d-flex align-items-center"
                                :style="colIndex === 0 ? setPadding(row) : ''"
                            >
                                <b-form-checkbox
                                    v-if="selectable && colIndex === 0"
                                    v-model="row._selected"
                                    :disabled="selectAll"
                                    class="mr-3"
                                    inline
                                    @change="checked => onRowSelect(checked, row)"
                                />
                                <btn
                                    v-if="!row._leaf && colIndex === 0"
                                    class="text-muted text-larger ml-n2"
                                    variant="link"
                                    pill
                                    :icon="['fal', row._collapsed ? 'angle-down' : 'angle-right']"
                                    @click="toggle(row)"
                                />
                                <table-cell
                                    v-if="column.template"
                                    :data="{ field: column, ...sanitizeRow(row, true) }"
                                />
                                <template v-else>
                                    <mark-query
                                        v-if="filter && filterIncludedFields.includes(column.key)"
                                        :query="filter"
                                        :value="row[column.key]"
                                    />
                                    <template v-else>
                                        {{ row[column.key] }}
                                    </template>
                                </template>
                            </span>
                        </b-td>
                    </template>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <span style="display: none;">
            <!--
                @slot Collection of `<table-column />` components that define table columns
            -->
            <slot />
        </span>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { BTableSimple, BThead, BTh, BTr, BTbody, BTd, BFormCheckbox } from 'bootstrap-vue'
    import { omit, cloneDeep } from 'lodash'
    import TableCell from './TableCell'
    import TableEmptyStateDefault from './TableEmptyStateDefault.vue'
    import TableLocalMixin from './mixins/TableLocal.mixin'
    import { filterTreeData } from '@utils'
    import MarkQuery from '@common/components/MarkQuery.vue'

    /**
     * This table supports data as associative array and will display rows in
     * a tree structure with toggleable behaviour.
     *
     * First column of the table will receive a toggle button and will be
     * Indented based on its nested level. You can configure how much indentation
     * you need with props as well as define a child key for each row object.
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof TableLocalMixin>
    >).extend({
        components: {
            BTableSimple,
            BThead,
            BTh,
            BTr,
            BTbody,
            BTd,
            TableCell,
            TableEmptyStateDefault,
            BFormCheckbox,
            MarkQuery,
        },

        mixins: [ TableLocalMixin ],

        props: {
            /** Child indentation in REMs */
            indent: {
                type: Number as PropType<number>,
                default: 2.75,
            },

            /** Object key that contains nested children */
            childKey: {
                type: String as PropType<string>,
                default: 'children',
            },

            /**
             * Initial open nodes by id's
             */
            initialCollapsedItems: {
                type: Array as PropType<number[]>,
                default: (): [] => [],
            },

            /**
             * Allow rows to be selected.
             * You can pass a string for a certain selection method
             * @values true, 'cascade-down'
             */
            selectable: {
                type: [Boolean, String] as PropType<boolean | string>,
                default: false,
            },

            disableSelectAll: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                itemId: null,
                collapsedItems: this.initialCollapsedItems,
            }
        },

        computed: {
            itemsLocal(): any[] {
                if (this.filter) {
                    const items = cloneDeep(this.items)
                    const filteredItems = filterTreeData(items, this.filter, {
                        fields: this.filterIncludedFields,
                        omitChildren: false,
                    })

                    return this.transformItems(filteredItems, 0)
                }

                return this.transformItems(this.items, 0)
            },

            selectedMap(): Map<number, number> {
                return this.selectedItems.reduce((map, item, index) => {
                    map.set(item.id, index)

                    return map
                }, new Map())
            },
        },

        methods: {
            transformItems(
                rows: any[],
                level: number,
            ): any[] {
                const results: any[] = []

                rows.forEach((row: any): void => {
                    const isLeaf = !row[this.childKey] || !row[this.childKey].length

                    row._leaf = isLeaf
                    row._level = level
                    row._selected = this.selectAll ? true : this.selectedMap.has(row.id)

                    results.push(row)

                    if (!isLeaf) {
                        const isCollapsed = this.collapsedItems.some((item: any) => item === row.id)
                        if (row.id === this.itemId || this.filter || isCollapsed) {
                            row._collapsed = true
                        }

                        if (row._collapsed === true) {
                            results.push(
                                ...this.transformItems(
                                    row[this.childKey],
                                    row._level + 1,
                                ),
                            )
                        }
                    }
                })

                return results
            },

            toggle(row: any): void {
                this.itemId = row.id
                if (!row._collapsed) {
                    this.collapsedItems.push(row.id)
                }

                if (row._collapsed === true && row[this.childKey] !== undefined) {
                    row[this.childKey].forEach((child: any) =>  {
                        child._collapsed = undefined
                        const index = this.collapsedItems.findIndex((item: number) => item === child.id)
                        this.collapsedItems.splice(index, 1)
                    })

                    this.$set(row, '_collapsed', undefined)
                    this.$set(row, '_leaf', false)
                    this.itemId = null

                    const index = this.collapsedItems.findIndex((item: number) => item === row.id)
                    this.collapsedItems.splice(index, 1)
                }
            },

            setPadding(item: any): object {
                return {
                    paddingLeft: `${item._level * this.indent}rem`,
                }
            },

            /**
             * Clean up row data for table column scope and selection list
             */
            sanitizeRow(row: any, withMeta = false): any {
                const omitKeys = ['_collapsed', '_leaf', '_level', this.childKey, '_selected']
                if (withMeta) {
                    return {
                        collapsed: row._collapsed ?? false,
                        isLeaf: row._leaf,
                        level: row._level,
                        selected: row._selected,
                        children: row[this.childKey],
                        item: omit(row, omitKeys),
                    }
                }

                return omit(row, omitKeys)
            },

            /**
             * Add and remove selected items based on checkboxes
             * Adds all sub items to the selection as well
             */
            onRowSelect(checked: boolean, row: any): any {
                if (!checked) {
                    const index = this.selectedMap.get(row.id) || -1

                    this.selectedItems.splice(index, 1)

                    if (typeof this.selectable === 'string' && this.selectable === 'cascade-down') {
                        const children = row[this.childKey] ?? []

                        children.forEach((child: any) => this.onRowSelect(false, child))
                    }

                    return
                }

                // Cascade selection down to all children
                if (typeof this.selectable === 'string' && this.selectable === 'cascade-down') {
                    const children = row[this.childKey] ?? []

                    children.forEach((child: any) => this.onRowSelect(true, child))
                }

                if (!this.selectedMap.has(row.id))
                    this.selectedItems.push(this.sanitizeRow(row))

                // Toggle row when selected
                if (!row._leaf && !row._collapsed)
                    this.$nextTick(() => this.toggle(row))
            },

            /**
             * Flatten the tree structure to a list of clean objects
             */
            flattenData(data: any[], result: any[] = []): any[] {
                (data ?? []).forEach((item: any) => {
                    result.push(this.sanitizeRow(item))
                    this.flattenData(item[this.childKey], result)
                })

                return result
            },

            /**
             * Handle when selecting all items
             */
            onSelectAll(checked: boolean): any {
                if (this.disableSelectAll)
                    return

                if (checked) {
                    /**
                     * Emits when user has selected a row in table
                     * @param {array} items a collection of selected rows
                     */
                    return this.$emit('selected', this.flattenData(this.items))
                }

                return this.$emit('selected', this.selectedItems)
            },
        },
    })
</script>

<docs>
```vue
<data-table-tree :items="$root.users">
    <table-column
        field="name"
        label="Name"
    />
    <table-column
        compact
        field="id"
        label="ID"
    />
    <table-column
        field="age"
        label="Age"
        #default="{item}"
    >
        <strong class="text-success">{{ item.age }}</strong>
    </table-column>
    <table-column
        field="isActive"
        label="Is active?"
    />
    <table-column
        field="company"
        label="Company name"
    />
</data-table-tree>
```
</docs>
