<template>
    <component
        :is="tag"
        class="fr-view"
        v-html="valueLocal"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    export default Vue.extend({
        props: {
            /**
             * Html content to display
             */
            value: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Wrapping element for content.
             */
            tag: {
                type: String as PropType<string>,
                default: 'div',
            },
        },

        computed: {
            valueLocal(): string {
                if (!this.value) return ''

                /**
                 * Parsing the html string and modify any video src's
                 * to combat iOS missing thumbnails
                 * @see https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
                 */
                const VIDEO_SRC = /(<video[^>]+src="([^"]+))/g

                return this.value.replace(VIDEO_SRC, '$1#t=0.001')
            },
        },
    })
</script>
