<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <!--
                Leaving this for reference
                There is a bug with bs timepicker when in modals
                once it has been resolveds we can go back to it

                <b-form-timepicker
                    v-if="false"
                    v-model="internalValue"
                    :class="inputClass"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :name="nameLocal"
                    :size="size"
                    minutes-step="5"
                    no-close-button
                    hide-header
                    v-bind="$attrs"
                />
            -->
            <template v-if="!plain">
                <!--
                The actual input that goes through validation.
             -->
                <input
                    v-model="internalValue"
                    type="hidden"
                >
                <b-dropdown
                    class="time-field w-100"
                    :class="{ 'time-field--lg': size === 'lg' }"
                    variant="default"
                    menu-class="p-0 mw-unset"
                    :toggle-class="['time-field__toggle', 'border', inputClass]"
                    :size="size"
                    :right="right"
                    @shown="onShown"
                >
                    <template #button-content>
                        <fa
                            v-if="!hideIcon"
                            class="mr-2 text-muted"
                            :icon="['fal', 'clock']"
                        />
                        <template v-if="displayValue">
                            {{ displayValue }}
                        </template>
                        <span
                            v-else
                            class="text-muted"
                            v-text="placeholder"
                        />
                    </template>
                    <b-dropdown-text text-class="p-0 font-weight-normal">
                        <div class="d-flex">
                            <!-- Hours list -->
                            <ul class="time-field__list border-right">
                                <li
                                    v-for="hour in hours"
                                    :key="hour"
                                    class="time-field-item"
                                    :class="{ 'time-field-item--selected': hour === model.hour }"
                                    @click="onSelect($event, hour, 'hour')"
                                    v-text="String(hour).padStart(2, '0')"
                                />
                            </ul>
                            <!-- Minutes list -->
                            <ul class="time-field__list">
                                <li
                                    v-for="minute in minutes"
                                    :key="minute"
                                    class="time-field-item"
                                    :class="{ 'time-field-item--selected': minute === model.minute }"
                                    @click="onSelect($event, minute, 'minute')"
                                    v-text="String(minute).padStart(2, '0')"
                                />
                            </ul>
                            <!-- Seconds list -->
                            <ul
                                v-if="showSeconds"
                                class="time-field__list border-left"
                            >
                                <li
                                    v-for="second in seconds"
                                    :key="second"
                                    class="time-field-item"
                                    :class="{ 'time-field-item--selected': second === model.second }"
                                    @click="onSelect($event, second, 'second')"
                                    v-text="String(second).padStart(2, '0')"
                                />
                            </ul>
                        </div>
                    </b-dropdown-text>
                </b-dropdown>
            </template>
            <template v-else>
                <input-field
                    v-model="internalValue"
                    :input-class="inputClass"
                    :icon-class="iconClass"
                    :placeholder="placeholder"
                    :prepend-icon="!hideIcon ? 'clock' : ''"
                    :disabled="disabled"
                    type="time"
                    class="mb-0"
                />
            </template>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'
    import { BDropdown, BDropdownText } from 'bootstrap-vue'
    import { range } from 'lodash'
    import { InputField } from '@common/Forms'

    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedField>
    >).extend({
        components: {
            BDropdown,
            BDropdownText,
            InputField,
        },
        mixins: [SharedField],
        inheritAttrs: false,
        props: {
            /**
             * Show Seconds for time
             */
            showSeconds: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Step value for the seconds list. Should be a value evenly divided into 60
             */
            secondsStep: {
                type: [Number] as PropType<number>,
                default: 1,
            },
            /**
             * Step value for the minutes list. Should be a value evenly divided into 60
             */
            minutesStep: {
                type: [Number] as PropType<number>,
                default: 1,
            },
            /**
             * Hide the input icon
             */
            hideIcon: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Align the right edge of the menu with the right of the button
             */
            right: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Switching from dropdown to plain input type
             */
            plain: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            defaultOpenValue: {
                type: Date as PropType<Date>,
                default: (): Date => new Date(),
            },
        },

        data() {
            return {
                model: {
                    hour: 0 as number,
                    minute: 0 as number,
                    second: 0 as number,
                },
            }
        },

        computed: {
            internalValue: {
                get(): string {
                    return this.value.slice(0, 5)
                },
                set(value: string): void {
                    this.$emit('input', value)
                },
            },

            hours(): number[] {
                return range(0, 24)
            },

            minutes(): number[] {
                return range(0, 60, this.minutesStep)
            },

            seconds(): number[] {
                return range(0, 60, this.secondsStep)
            },

            displayValue(): string | null {
                if (!this.internalValue) return null

                const paddedHour = String(this.model.hour).padStart(2, '0')
                const paddedMinute = String(this.model.minute).padStart(2, '0')
                const paddedSecond = String(this.model.second).padStart(2, '0')

                const display = `${paddedHour}:${paddedMinute}`

                if (this.showSeconds) {
                    return display + `:${paddedSecond}`
                }

                return display
            },

        },

        mounted() {
            this.parseValue()
        },

        methods: {
            parseValue(): void {
                if (this.internalValue) {
                    const [hour = 0, minute = 0, second = 0] = this.internalValue.split(':')
                    this.model.hour = parseInt(hour)
                    this.model.minute = parseInt(minute)
                    this.model.second = parseInt(second)
                }
            },

            setModelNow(): void {
                if (!this.defaultOpenValue)
                    return

                this.model.hour = this.defaultOpenValue.getHours()
                this.model.minute = this.defaultOpenValue.getMinutes()

                if (this.showSeconds)
                    this.model.second = this.defaultOpenValue.getSeconds()
            },

            setValue(): void {
                const padHour = String(this.model.hour || 0).padStart(2, '0')
                const padMinute = String(this.model.minute || 0).padStart(2, '0')
                const padSecond = String(this.model.second || 0).padStart(2, '0')

                this.internalValue = `${padHour}:${padMinute}:${padSecond}`
            },

            onShown(): void {
                if (!this.internalValue)
                    this.setModelNow()

                this.$nextTick(() => {
                    document.querySelectorAll('.time-field-item--selected')
                        .forEach((target: any) => {
                            target.parentNode.scrollTop = target.offsetTop
                        })
                })
            },

            onSelect(event: Event, value: number, context: 'hour' | 'minute' | 'second'): void {
                const target = event.target as HTMLElement
                const parent = target.parentNode as HTMLElement

                this.model[context] = value

                if (parent)
                    parent.scrollTop = target.offsetTop

                this.setValue()
            },
        },

    })
</script>

<style lang="scss" scoped>
@import '@scss/vue.scss';

.time-field {
    ::v-deep &__toggle {
        background: white;
        text-align: left;

        &:not(:disabled):hover, &:focus, &:active {
            /* stylelint-disable-next-line declaration-no-important */
            background: white !important;
        }

        &:disabled {
            background: $input-disabled-bg;
            color: $input-color;
        }
    }

    &__list {
        width: 3.5em;
        height: 12em;
        overflow: hidden;
        margin: 0;
        padding: 0 0 10em;

        .time-field-item {
            height: 2em;
            line-height: 2em;
            list-style: none;
            cursor: pointer;
            padding: 0 0 0 .75em;

            &:not(.time-field-item--selected):hover {
                background: whitelabel-color('primary', .25);
            }

            &--selected {
                background: whitelabel-color('primary');
                color: whitelabel-color('primary-txt');
            }

        }

        &:hover {
            overflow-y: auto;
            scroll-behavior: smooth;
        }
    }

    &--lg &__list {
        font-size: $font-size-lg;
    }
}

::v-deep input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
}

::v-deep input[type='time'] {
    text-align: center;
}
</style>
