<template>
    <div
        class="bg-white rounded shadow-sm d-flex p-3 m-2 line-height-sm pointer"
        @click="close"
    >
        <!-- Message icon -->
        <fa
            v-if="icon"
            :icon="icon"
            class="mr-2"
            :class="[`text-${item.type}`, item.data.iconClass]"
        />

        <!-- Message body -->
        <div class="flex-grow-1">
            <p
                class="mb-0"
                v-html="item.title"
            />
            <small
                v-if="item.text"
                class="d-block text-muted mt-1"
                v-html="item.text"
            />
            <!-- Flash actions -->
            <ul
                v-if="item.data.actions && item.data.actions.length"
                class="inline-list mt-2 mb-0"
            >
                <template v-for="(action, index) in item.data.actions">
                    <li :key="index">
                        <a
                            href="#"
                            @click.prevent="action.handler"
                            v-text="action.label"
                        />
                    </li>
                </template>
            </ul>
        </div>

        <!-- Close Icon -->
        <fa
            :icon="['fal', 'times']"
            class="text-muted pointer ml-2"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { FlashOptions, FlashVariant } from './typings'

    interface FlashItem {
        id: number;
        title: string;
        text?: string;
        type: FlashVariant;
        data: Pick<FlashOptions, 'icon' | 'noIcon' | 'iconClass' | 'actions'>;
    }

    const iconMap: { [key: string]: string[] } = {
        danger: ['fas', 'exclamation-circle'],
        success: ['fas', 'check-circle'],
        warning: ['fas', 'exclamation-circle'],
        info: ['fas', 'info-circle'],
    }

    export default Vue.extend({
        props: {
            item: {
                type: Object as PropType<FlashItem>,
                required: true,
            },

            close: {
                type: Function as PropType<() => void>,
                required: true,
            },
        },

        computed: {
            icon(): string[] | null {
                if (this.item.data.noIcon)
                    return null

                if (this.item.data.icon) {
                    return Array.isArray(this.item.data.icon)
                        ? this.item.data.icon
                        : ['fal', this.item.data.icon]
                }

                return iconMap[this.item.type]
            },
        },
    })
</script>