<template>
    <div class="border-top" />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    export default Vue.extend({
        props: {
            options: {
                type: Object as PropType<IntersectionObserverInit>,
                default: (): object => ({}),
            },
        },

        data() {
            return {
                observer: null as null | IntersectionObserver,
            }
        },

        mounted() {
            this.observer = new IntersectionObserver(([entry]) => {
                if (entry && entry.isIntersecting) {
                    this.$emit('intersect')
                }
            }, this.options)

            this.observer.observe(this.$el)
        },

        destroyed() {
            if (this.observer) {
                this.observer.disconnect()
            }
        },
    })
</script>
