<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        no-flip
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <date-range-picker
                ref="datePicker"
                v-model="internalValue"
                :class="fieldClasses"
                :disabled="disabled"
                control-container-class=""
                :append-to-body="appendToBody"
                :show-dropdowns="showDropdowns"
                :auto-apply="autoApply"
                :always-show-calendars="alwaysShowCalendars"
                :opens="opens"
                :min-date="min"
                :max-date="max"
                :ranges="rangesLocal"
                v-bind="$attrs"
                class="h-100"
                @update="onUpdate"
                @select="onSelect"
            >
                <template #input="{ startDate, endDate }">
                    <slot
                        :start-date="startDate"
                        :end-date="endDate"
                    >
                        <fa
                            v-if="icon"
                            class="mr-1"
                            :icon="Array.isArray(icon) ? icon : ['fal', icon]"
                        />
                        <span
                            v-if="placeholderFixed || !hasValue"
                            class="placeholder"
                            :class="{
                                'placeholder--fixed mr-3': hasValue && placeholderFixed
                            }"
                            v-text="placeholder"
                        />
                        <span
                            v-if="hasValue"
                            v-text="getValueDisplay(startDate, endDate)"
                        />
                        <fa
                            size="xs"
                            class="ml-3"
                            :icon="['fal', 'chevron-down']"
                        />
                    </slot>
                </template>
                <template #footer="{ clickApply, clickCancel, in_selection }">
                    <div class="d-flex align-items-center p-2 justify-content-between">
                        <btn
                            v-if="opens !== 'inline'"
                            variant="link"
                            :label="$t('TERMS.CANCEL')"
                            size="sm"
                            @click="clickCancel"
                        />
                        <btn
                            v-if="showClearBtn"
                            variant="primary"
                            :emphasis="autoApply ? 'high' : 'medium'"
                            class="ml-auto"
                            size="sm"
                            :label="$t('TERMS.CLEAR')"
                            @click="clear"
                        />
                        <btn
                            v-if="!autoApply"
                            variant="primary"
                            class="ml-2"
                            size="sm"
                            :label="$t('TERMS.APPLY')"
                            :disabled="!!in_selection"
                            @click="clickApply"
                        />
                    </div>
                </template>
            </date-range-picker>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import moment from 'moment'
    import SharedField from './mixin'
    import DateRangePicker from 'vue2-daterange-picker'

    /**
     * Date range picker
     *
     */

    interface DatePicker extends Vue {
        clickRange: (...args: any[]) => any;
    }

    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedField>
    >).extend({
        components: { DateRangePicker },
        mixins: [SharedField],
        inheritAttrs: false,
        props: {
            /**
             * Apply value when selection has been made.
             * This also closes the dropdown.
             */
            autoApply: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Opening direction of calendar dropdown.
             */
            opens: {
                type: String as PropType<string>,
                default: 'left',
                validator: (value: string): boolean =>
                    ['center', 'left', 'right', 'inline'].includes(value),
            },

            /**
             * Minimum date that picker can select
             */

            min: {
                type: [String, Date] as PropType<string | Date>,
                default: null,
            },

            /**
             * Maximum date that picker can select
             */
            max: {
                type: [String, Date] as PropType<string | Date>,
                default: null,
            },

            /**
             * Show month/year dropdowns in calendar header
             */
            showDropdowns: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            showClearBtn: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Preset date ranges to be displayed in dropdown.
             * The object must have a key, that is used as label,
             * and the value needs to be an array with exactly
             * 2 Date objects.
             * `{[label: string]: [startDate: Date, endDate: Date]}`
             *
             * You can also pass in `false` and ranges will be disabled
             */
            ranges: {
                type: [Object, Boolean] as PropType<{ [label: string]: [Date, Date] } | boolean>,
                default: null,
            },
            /**
             * Append the dropdown element to the end of the body
             * and size/position it dynamically. Use it if you have
             * overflow or z-index issues.
             */
            appendToBody: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            alwaysShowCalendars: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Show icon in input, you can pass `false` to disable icon
             */
            icon: {
                type: [Boolean, String, Array] as PropType<boolean | string | string[]>,
                default: (): string[] => ['fal', 'calendar'],
            },

            /**
             * Fixes the placeholder even when there's value in the input
             */
            placeholderFixed: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            internalValue: {
                get(): DateRange<string> {
                    return this.getTime(this.value)
                },

                set(value: DateRange<string>): void {
                    const dates = this.getTime(value)

                    this.$emit('input', dates)
                },
            },

            hasDefaultSlot(): boolean {
                return !!this.$scopedSlots.default || !!this.$slots.default
            },

            fieldClasses(): any[] {
                return [
                    'pointer',
                    {
                        // We remove all styling as input fields if there is
                        // content in the default slot, then slot takes
                        // over the presentation of the none collapsible
                        // part of the component.
                        ...(!this.hasDefaultSlot) && {
                            'form-control': this.opens !== 'inline',
                            'form-control-lg': this.opens !== 'inline' && this.size === 'lg',
                            'form-control-sm': this.opens !== 'inline' && this.size === 'sm',
                        },
                    },
                    this.inputClass,
                ]
            },

            hasValue(): boolean {
                return this.internalValue.startDate && this.internalValue.endDate
            },

            rangesLocal(): { [label: string]: [Date, Date] } | boolean {
                if (this.ranges !== null)
                    return this.ranges

                const today = moment()
                const yesterday = today.clone().subtract(1, 'days')
                const startOfMonth = today.clone().startOf('month')
                const endOfMonth = today.clone().endOf('month')
                const startOfLastMonth = startOfMonth.clone().subtract(1, 'months')
                const endOfLastMonth = endOfMonth.clone().subtract(1, 'months')
                const startOfLastWeek = today.clone().subtract(1, 'week')
                    .startOf('week')
                const endOfLastWeek = startOfLastWeek.clone().endOf('week')
                const startOfYear = today.clone().startOf('year')

                return {
                    [String(this.$t('TERMS.TODAY'))]: [today.toDate(), today.toDate()],
                    [String(this.$t('TERMS.YESTERDAY'))]: [yesterday.toDate(), yesterday.toDate()],
                    [String(this.$t('TERMS.LAST_WEEK'))]: [startOfLastWeek.toDate(), endOfLastWeek.toDate()],
                    [String(this.$t('TERMS.THIS_MONTH'))]: [startOfMonth.toDate(), endOfMonth.toDate()],
                    [String(this.$t('TERMS.LAST_MONTH'))]: [startOfLastMonth.toDate(), endOfLastMonth.toDate()],
                    [String(this.$t('TERMS.YEAR_TO_DATE'))]: [startOfYear.toDate(), today.toDate()],
                }
            },
        },

        methods: {
            getTime(value: DateRange<string>): DateRange<string> {
                return {
                    startDate: this.getStartDate(value.startDate),
                    endDate: this.getEndDate(value.endDate),
                }
            },

            setTime(value: DateRange<string>): void {
                const { startDate, endDate } = this.getTime(value)

                this.value.startDate = startDate
                this.value.endDate = endDate
            },

            onUpdate(value: DateRange<string>): void {
                this.emit('update', value)
            },

            onSelect(value: DateRange<string>): void {
                this.emit('select', value)
            },

            emit(type: 'update' | 'select', value: DateRange<string>): void {
                this.setTime(value)
                this.$emit(type, this.value)
            },

            clear(): void {
                (this.$refs.datePicker as DatePicker).clickRange(0)

                this.internalValue = {
                    startDate: null,
                    endDate: null,
                }

                this.$emit('clear')
            },

            getStartDate(date: string | null): string | null {
                if (!date)
                    return null

                return moment(date)
                    .startOf('day')
                    .format()
            },

            getEndDate(date: string | null): string | null {
                if (!date)
                    return null

                return moment(date)
                    .endOf('day')
                    .format()
            },

            getValueDisplay(startDate: string | null, endDate: string | null): string {
                return `${this.$filter('date')(startDate)} · ${this.$filter('date')(endDate)}`
            },
        },
    })
</script>
<style lang="scss">
    @import '@scss/vue.scss';

    .vue-daterange-picker {
        display: block;
        [tabindex] { outline: none; }
    }

    .vue-daterange-picker {
        .placeholder {
            color: $input-placeholder-color;

            &--fixed {
                color: $gray-400;
            }
        }
    }

    // Daterange picker in inline mode overwrites
    .vue-daterange-picker.inline .daterangepicker {
        border: 0;
        box-shadow: $box-shadow-sm !important;
    }


    .daterangepicker {
        z-index: $zindex-modal !important;
        font-family: $font-family-base;
        box-shadow: $box-shadow !important;

        .btn-primary {
            background-color: whitelabel-color('primary') !important;
            color: whitelabel-color('primary-txt') !important;
        }

        .calendar-table {
            .monthselect, .yearselect {
                border: $border-width solid $border-color;
                border-radius: $border-radius;
                padding: 0 1rem;
                padding: ($input-padding-y-sm / 2) $input-padding-x-sm;
                font-size: $font-size-sm;

                &:focus { outline: none; }
            }

            thead tr th {
                font-size: $font-size-base;
                font-weight: normal;
            }

            tbody tr th {
                font-weight: normal;
                border-bottom: $border-width solid $border-color;
                font-size: $font-size-sm;
            }

            tbody td {
                font-size: $font-size-sm;
                line-height: 1;
                color: color('gray');
                height: 2rem;
                width: 2rem;

                // Dates in current month in calendar
                &:not(.off) {
                    color: $body-color;
                }

                &.disabled {
                    background: color('gray-light');
                    border-radius: 0;
                }

                &.in-range {
                    background: whitelabel-color('primary', .3);
                    color: $body-color;
                }

                &.in-range.start-date,
                &.in-range.end-date {
                    background-color: whitelabel-color('primary');
                    color: whitelabel-color('primary-txt');
                }

                &.in-range.start-date {
                    border-top-left-radius: 2rem;
                    border-bottom-left-radius: 2rem;
                }

                &.in-range.end-date {
                    border-top-right-radius: 2rem;
                    border-bottom-right-radius: 2rem;
                }
            }
        }


        .ranges {
            li { font-size: $font-size-sm; }
            li:not(.active):hover { background: color('off-white'); }

            .active {
                background-color: whitelabel-color('primary');
                color: whitelabel-color('primary-txt');
            }
        }

        // Remove bubble thing on dropdown
        &:before, &:after { display: none; }
    }
</style>
