import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'development-plans',
    module: 'SkillsDeveloper',
    middleware: [
        auth(['admin', 'lb'], { name: '401' }),
        hasFeatures(['SkillsDeveloper', 'Checklists', 'ActivityPlans'], { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'developmentPlans.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/SkillsDeveloper" */
                './DevelopmentPlansIndex.vue'
            ),
    },
    {
        path: ':developmentPlanId/edit',
        name: 'developmentPlans.edit',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/SkillsDeveloper" */
                './DevelopmentPlansEdit.vue'
            ),
    },
    {
        path: ':developmentPlanId/settings',
        name: 'developmentPlans.settings',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/SkillsDeveloper" */
                './DevelopmentPlansSettings.vue'
            ),
        meta: {
            hideAside: true,
        },
    },
])
