import Vue from 'vue'
import moment from 'moment'
import { toHMS, formatDate, formatDateRange, formatTime, formatTimeRange } from '@utils'


/**
 * Format date for display
 *
 * @param value Incoming date to transform
 */
Vue.filter('date', formatDate)

/**
 * Format two dates as a range with shorted display within
 * same month or year
 *
 * @param value Incoming date(s) to transform
 */
Vue.filter('daterange', formatDateRange)

/**
 * Format two dates in hour, min, format as a range with shorted display within
 * same month or year
 *
 * @param value Incoming date(s) to transform
 */
Vue.filter('timerange', formatTimeRange)

/**
 * Format time for display
 *
 * @param value Incoming time to transform
 */
Vue.filter('time', formatTime)

/**
 * Display relative time
 *
 * @param value Incoming date to transform
 * @param threshold Threshold in days - whith in what timefrime from now
 * until past to display relative time
 * @param suffix Show suffix for fromNow value
 * @param format Date format to use when relative time is not in threshold
 */
Vue.filter('fromNow', (value: string, threshold: number, suffix = false, format = 'll'): string => {
    if (!value) return value

    const momentValue = moment(value)

    if (!threshold) {
        return momentValue.fromNow(suffix)
    }

    const momentThreshold = moment().subtract(threshold, 'days')

    if (momentValue.isBefore(momentThreshold)) {
        return momentValue.format(format)
    }

    return momentValue.fromNow(suffix)
})

/**
 * Format seconds for readable display
 *
 * @param value Seconds
 */
Vue.filter('seconds', (value: number): string | null => {
    if (!value) return null

    return moment.duration(value * 1000).humanize()
})

/**
 * Format seconds to HMS
 */
Vue.filter('hms', (value: number, options: Parameters<typeof toHMS>[1]): string | null => {
    if (!value) return null

    const { h, m, s } = toHMS(value, options)
    let str = ''
    if (h) str = str + `${h}h `
    if (m) str = str + `${m}m `
    if (s) str = str + `${s}s `

    return str.trim()
})
