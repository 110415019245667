<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <div
                class="linear-scale d-flex align-items-center"
                :class="{
                    'linear-scale--readonly': readonly,
                    'linear-scale--disabled': disabled,
                }"
            >
                <div
                    v-for="option in options"
                    :key="option"
                    class="linear-scale__option"
                    :class="[{
                        'bg-primary-type': !disabled && option === internalValue,
                        'pointer': !disabled && !readonly
                    }, inputClass]"
                    @click="onSelect(option)"
                    v-text="option"
                />
            </div>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'

    /**
     * This component is closely ralated to a range component but rather then
     * a slider to change the value we use buttons.
     *
     * - Adjustable range, max will always be 10, reach for something else
     *   if you need higher values.
     * - Can start from 0 or 1
     * - Read only mode
     *
     * ###### Todo features
     * - start end end description fields, should support slots too.
     *   for inline editing
     *
     * @example ./__docs__/LinearScaleField.examples.md
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedField>
    >).extend({
        mixins: [SharedField],
        props: {
            /**
             * Disables user interaction but shows the current value
             */
            readonly: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Where to start the scale, Zero or One
             */
            start: {
                type: Number as PropType<number>,
                default: 0,
                validator: (value: number): boolean => [0, 1].includes(value),
            },
            /**
             * Where to end the scale
             */
            end: {
                type: Number as PropType<number>,
                default: 10,
                validator: (value: number): boolean => value >= 3 && value <= 10,
            },
        },

        computed: {
            options(): number[] {
                return Array(this.end - this.start + 1)
                    .fill(null)
                    .map((_, idx) => this.start + idx)
            },
        },

        methods: {
            onSelect(option: number): void {
                if (this.disabled || this.readonly) return
                this.internalValue = option
            },
        },
    })
</script>
<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .linear-scale {
        $self: &;
        margin: 0 -#{spacer(1)};

        &__option {
            flex: 0 1 3rem;
            text-align: center;
            margin: 0 spacer(1);
            padding: spacer(3) spacer(1);
            border-radius: $border-radius;
            background: color('off-white');
            @include transition();
        }

        &:not(&--readonly):not(&--disabled) & {
            &__option:hover {
                transform: scale(1.2, 1.2);
            }
        }

        &--disabled, &--readonly {
            opacity: .4;
        }

        &--disabled {
            filter: grayscale(100%);
        }
    }

</style>
