<script lang="ts">
    import Vue, { PropType } from 'vue'

    const isMac = window.navigator.platform.toLowerCase().includes('mac')

    const getKeymap = (): Record<string, string> => {
        const keyMap = {
            up: '↑',
            down: '↓',
            left: '←',
            right: '→',
            ctrl: isMac ? '⌃' : 'Ctrl',
            shift: isMac ? '⇧' : 'Shift',
            enter: 'Enter',
            esc: 'Esc',
            command: '⌘',
            option: isMac ? '⌥' : 'Alt',
        } as Record<string, string>

        // Meta and alt are aliases
        keyMap.meta = keyMap.command
        keyMap.alt = keyMap.option

        // Mod is Command on Mac, and Ctrl on Windows/Linux
        keyMap.mod = isMac ? keyMap.command : keyMap.ctrl

        return keyMap
    }

    export default Vue.extend({
        functional: true,
        props: {
            tag: {
                type: String as PropType<string>,
                default: 'span',
            },
            code: {
                type: String as PropType<string>,
                default: null,
            },
            badge: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            dark: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        render(h, context) {
            const { staticClass } = context.data
            const keyMap = getKeymap()
            const keys = context.props.code.split(/([ +])/)

            const keyStrings = keys.map((key) => {
                if (key === '+') return isMac ? '' : '+'
                if (key === ' ') return ''

                return h('kbd', {}, [keyMap[key.toLowerCase()] ?? key])
            })


            return h(context.props.tag, {
                staticClass,
                class: [context.data.class, {
                    'shortcut': true,
                    'shortcut--badge': context.props.badge,
                    'shortcut--dark': context.props.dark,
                }],
            }, keyStrings)
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .shortcut {
        kbd {
            font-family: $font-family-sans-serif;
        }

        &--badge {
            background-color: $gray-150;
            color: color('gray-dark');
            border-radius: 3px;
            padding: 0 4px;
        }


        &--dark {
            background-color: rgba($white, .2);
            color: color('gray-light');
        }
    }
</style>