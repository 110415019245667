import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'

const routeConfig = {
    baseURI: 'sharing',
    module: 'Sharing',
    middleware: [
        auth('admin', { name: '401' }),
        hasFeatures('Sharing', { name: '401' }),
    ],
}

export default routesFactory(routeConfig, [
    {
        path: '',
        name: 'sharing.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Sharing" */
                '@modules/Sharing/SharingIndex.vue'
            ),
    },

    /**
     * Edit share routes
     */
    {
        path: ':id',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Sharing" */
                '@modules/Sharing/SharingManage.vue'
            ),
        children: [
            {
                path: 'content',
                name: 'sharing.content',
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/Sharing" */
                    '@modules/Sharing/SharingManageContent.vue'
                ),
            },
        ],
    },
    {
        path: ':id/settings',
        name: 'sharing.settings',
        props: true,
        meta: {
            hideAside: true,
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Sharing" */
                './SharingSettings.vue'
            ),
    },
])
