<template>
    <validation-provider
        #default="props"
        ref="provider"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <vue-tel-input
                v-model="internalValue"
                wrapper-classes="phone-field"
                :input-classes="[
                    'form-control',
                    size ? `form-control-${size}` : ''
                ]"
                mode="international"
                :name="nameLocal"
                :disabled="disabled"
                :placeholder="placeholder"
                :dynamic-placeholder="useDynamicPlaceholder"
                :preferred-countries="preferredCountriesLocal"
                :only-countries="onlyCountries"
                :input-id="inputId()"
                @blur="onBlur"
                @input="onInput"
                @validate="onValidate"
            />
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'
    import { VueTelInput } from 'vue-tel-input'

    /**
     * Telephone input field
     *
     * * Auto fetches users current country code
     *
     * @example ./__docs__/PhoneField.examples.md
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedField>
    >).extend({
        components: { VueTelInput },
        mixins: [SharedField],
        props: {
            /**
             * Preferred countries list, will be on top of the dropdown.
             * Use ISO Alpha-2 format ie ['DK', 'NO', 'ES']
             */
            preferredCountries: {
                type: Array as PropType<string[]>,
                default: (): never[] => [],
            },
            /**
             * List of countries will be shown on the dropdown.
             * Use ISO Alpha-2 format ie ['DK', 'NO', 'ES']
             */
            onlyCountries: {
                type: Array as PropType<string[]>,
                default: (): never[] => [],
            },
        },

        data() {
            return {
                isValidLocal: {} as any,
                initialized: false,
            }
        },

        computed: {
            preferredCountriesLocal(): string[] {
                if (this.preferredCountries.length) {
                    return this.preferredCountries
                }

                // This list should be fetched from a store or config constant
                // when we have it available.
                return ['DK', 'NO', 'SE', 'FI', 'DE', 'GB', 'US', 'FR', 'ES', 'PL']
            },
            /**
             * Set validation message based on component possibility string
             */
            errorMessage(): string {
                if (this.isValidLocal.isValid) {
                    return ''
                }

                switch (this.isValidLocal.possibility) {
                case 'invalid-country-code':
                    return 'VALIDATION.PHONE.INVALID_COUNTRY_CODE'
                case 'too-long':
                    return 'VALIDATION.PHONE.TOO_LONG'
                case 'too-short':
                    return 'VALIDATION.PHONE.TOO_SHORT'
                default:
                    return 'VALIDATION.PHONE.DEFAULT'
                }
            },

            useDynamicPlaceholder(): boolean {
                return !this.placeholder
            },
        },

        methods: {
            /**
             * Generate random ID since package doesn't omit
             * the id prop (stupid)
             * This avoids DOM warnings of repetitive IDs
             */
            inputId(): string {
                return 'vue-tel-' + Math.floor(1000 + Math.random() * 9000)
            },

            onValidate(isValid: any): void {
                if (!this.initialized) {
                    this.initialized = true

                    return
                }

                if (!this.availableRules.includes('required') && !isValid.number.input) {
                    return this.setValidation({ isValid: true })
                }

                return this.setValidation(isValid)
            },

            /**
             * Set validation when input blurs
             */
            onBlur(event: FocusEvent): void {
                this.setValidation(this.isValidLocal)

                /**
                 * Triggers when user blurs the input
                 *
                 * @property {FocusEvent} event focus event
                 */
                this.$emit('blur', event)
            },

            /**
             * Set validation manually when input has changes
             */
            onInput(number: any, isValid: any): any {
                if (!this.availableRules.includes('required') && !number) {
                    return this.setValidation({ isValid: true })
                }

                return this.setValidation(isValid)
            },

            /**
             * Set validation manually when validity changes on phone input
             */
            setValidation(isValid: any): void {
                const isInputValid =
                    this.availableRules.includes('required') || this.internalValue !== '' ? isValid.isValid : true
                this.isValidLocal = isValid
                this.isValidLocal.isValid = isInputValid

                setTimeout(() => {
                    (this.$refs.provider as any).applyResult({
                        errors: isInputValid ? [] : [this.$t(this.errorMessage)],
                        valid: isInputValid,
                        failedRules: {},
                    })
                }, 0)
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .phone-field.vue-tel-input {
        border-radius: $input-border-radius;
        border: $border-width solid $input-border-color;

        &:focus-within {
            box-shadow: none;

            &:not(.disabled) {
                box-shadow: $input-focus-box-shadow;
                border-color: $input-focus-border-color;
            }
        }

        ::v-deep .vti__input {
            border-radius: $input-border-radius;
        }

        ::v-deep .vti__dropdown {
            border-top-left-radius: $input-border-radius;
            border-bottom-left-radius: $input-border-radius;
        }

        &.disabled {
            ::v-deep .vti__dropdown {
                cursor: initial;
                background: $input-disabled-bg;
            }
            ::v-deep .vti__dropdown-arrow {
                display: none;
            }
        }
    }
</style>
