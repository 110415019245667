<template>
    <div class="data-table-remote">
        <table-header
            v-if="isSearchable || $slots.header"
            v-model="queryLocal"
            :searchable="isSearchable"
        >
            <!-- @slot Primary header slot -->
            <slot name="header" />
        </table-header>

        <!--
            @slot Secondary header slot
            - Useful to display custom selection component
            @binding {function} clearSelection method that clears selection
        -->
        <slot
            name="header-secondary"
            :clear-selection="clearSelection"
            :refresh="refresh"
        />

        <!-- Table component -->
        <div
            :class="{
                'table-card': card,
                'table-vcenter' : centerV
            }"
        >
            <b-table
                :id="id"
                ref="table"
                :class="tableClass"
                :style="{ minHeight: minHeight }"
                :fields="tableFields"
                :filter="query"
                :items="itemsProvider"
                :current-page="currentPage"
                :per-page="perPage"
                :busy="internalBusy"
                :responsive="true"
                :show-empty="showEmpty"
                :tbody-tr-class="rowClass"
                sort-icon-left
                :hover="hover"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                v-on="$listeners"
            >
                <!-- Default table headers overwrite when fields can be hidden -->
                <template
                    v-if="shouldSaveColumnState || $slots['table-config-dropdown']"
                    #head()="{ label, field }"
                >
                    <span
                        v-if="lastFieldKey === field.key"
                        class="d-flex"
                    >
                        {{ label }}
                        <table-config-dropdown class="ml-auto pl-2">
                            <slot name="table-config-dropdown" />
                            <table-column-visibility
                                v-if="shouldSaveColumnState"
                                v-model="fieldsLocal"
                                :table-id="id"
                            />
                        </table-config-dropdown>
                    </span>
                </template>
                <!-- Checkbox in column header to select all -->
                <template
                    v-if="!disableSelectAll && selectionLimit == null"
                    #head(select)
                >
                    <b-form-checkbox
                        v-model="selectAll"
                        @input="onSelectAll"
                    />
                </template>
                <!-- Checkboxes on each row when table is selectable -->
                <template #cell(select)="{ item }">
                    <b-form-checkbox
                        v-if="!item._selectable"
                        v-model="item._selected"
                        :disabled="(
                            item.$disabled ||
                            selectAll ||
                            !item._selected && selectionLimit != null && selectedItems.length >= selectionLimit)"
                        @change="checked => onRowSelect(checked, item)"
                    />
                </template>
                <!-- Render table-column templates -->
                <template
                    v-for="(field, index) in templateFields"
                    #[`cell(${field.key})`]="data"
                >
                    <table-cell
                        :key="index"
                        :data="data"
                    />
                </template>
                <!-- Render table-column's header templates -->
                <template
                    v-for="(field, index) in headerTemplateFields"
                    #[`head(${field.key})`]="data"
                >
                    <table-header-cell
                        :key="index"
                        :data="data"
                    />
                </template>
                <!-- Empty table slot -->
                <template #empty="scope">
                    <slot
                        name="empty"
                        v-bind="scope"
                    >
                        <table-empty-state-default
                            :title="$t('TERMS.EMPTY_STATE_DATA')"
                            :message="$t('TERMS.EMPTY_DATA_DETAILS')"
                        />
                    </slot>
                </template>
                <!-- Empty table after search -->
                <template #emptyfiltered="scope">
                    <slot
                        name="emptyquery"
                        v-bind="scope"
                    >
                        <table-empty-state-default
                            :title="$t('TERMS.FILTERS_NO_RESULTS')"
                            :message="$t('TERMS.FILTERS_NO_RESULTS_DETAILS')"
                        />
                    </slot>
                </template>
                <!-- show loading skeleton on first request -->
                <template
                    v-if="!initialized"
                    #table-busy
                >
                    <table-busy-empty-state :per-page="perPage" />
                </template>

                <!-- Table footer -->
                <template
                    v-if="initialized"
                    #custom-foot="scope"
                >
                    <table-footer
                        :scope="scope"
                        :per-page.sync="perPage"
                        :current-page.sync="currentPage"
                        :total-items="totalItems"
                        :selection-length="selectAll ? totalItems : selectedItems.length"
                    />
                </template>
            </b-table>
        </div>

        <!-- Required to get Component instances from column components -->
        <span style="display: none;">
            <slot />
        </span>
    </div>
</template>

<script lang="ts">
    import TableShared from './TableSharedMixin'
    import { PropType } from 'vue'
    import { BTable, BFormCheckbox, BvTableCtxObject } from 'bootstrap-vue'
    import { debounce, isEqual } from 'lodash'
    import TableCell from './TableCell'
    import TableHeaderCell from './TableHeaderCell'
    import TableHeader from './TableHeader.vue'
    import TableFooter from './TableFooter.vue'
    import TableEmptyStateDefault from './TableEmptyStateDefault.vue'
    import TableColumnVisibility from './TableColumnVisibility.vue'
    import TableBusyEmptyState from './TableBusyEmptyState.vue'
    import TableConfigDropdown from './TableConfigDropdown.vue'
    import {
        PaginationParams,
        PaginationSort,
        PaginationSearch,
        SortDirection,
        PaginationResponse,
    } from '@typings/pagination'
    import Http from '@utils/Http'

    export default TableShared.extend({
        components: {
            BTable,
            BFormCheckbox,
            TableCell,
            TableHeaderCell,
            TableHeader,
            TableFooter,
            TableEmptyStateDefault,
            TableBusyEmptyState,
            TableColumnVisibility,
            TableConfigDropdown,
        },
        props: {
            /**
             * Vuex store action to query for paginated data
             * @deprecated
             */
            storeAction: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Relative API uri to fetch paginated data from
             */
            uri: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Request query parameters to be sent with URI in addition to
             * `limit` and `offset` parameters.
             */
            params: {
                type: Object as PropType<object>,
                default: (): object => ({}),
            },
            /** Send extra parameters to vuex store */
            storeActionPayload: { type: Object as PropType<object>, default: (): object => ({}) },
            /** Filters to send with the pagination requeest */
            filters: { type: Object as PropType<object>, default: (): object => ({}) },
            /** Maximum number of selected rows allowed at the same time */
            selectionLimit: {
                type: Number as PropType<number>,
                default: null,
            },
            isBusy: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                showEmpty: false,
                initialized: false,
                allItems: [],
                internalBusy: this.isBusy,
                previousPage: null as number | null,
            }
        },

        computed: {
            selectedIds(): any[] {
                return this.selectedItems.map((item) => item.id)
            },

            /**
             * Generate new object when params change and watch this one.
             * This is due to limitations of vue that watcher will not watch
             * keep track of pre-mutated states.
             *
             * @see https://v2.vuejs.org/v2/api/#vm-watch
             */
            paramsToWatch(): any {
                return Object.assign({}, this.params)
            },
        },

        watch: {
            filters: {
                handler(): void {
                    // if filters are changed and select all is true, we need to update the items in the table
                    if (this.selectAll)
                        this.emitSelectAll()
                    this.refresh(true)
                },
                deep: true,
            },
            /**
             * Watch any changes to params and then refresh the table.
             */
            paramsToWatch: {
                handler(value, oldValue): void {
                    if (isEqual(value, oldValue))
                        return

                    this.refresh(true)
                },
                deep: true,
            },
            /**
             * Refresh table when URI is changed, as well as
             * clearing selected items, since the data context has changed.
             */
            uri(value, oldValue): void {
                if (value !== oldValue) {
                    this.clearSelection()
                }
            },
        },

        methods: {
            async itemsProvider(ctx: BvTableCtxObject): Promise<any[]> {
                this.internalBusy = true

                const search: PaginationSearch | null = typeof ctx.filter === 'string' && !!ctx.filter
                    ? { term: ctx.filter, fields: '*' }
                    : null
                const currentPage = search && this.previousPage === ctx.currentPage ? 1 : ctx.currentPage
                this.currentPage = currentPage
                this.previousPage = ctx.currentPage

                const limit = ctx.perPage
                const initialOffset = (currentPage - 1) * ctx.perPage
                const offset = this.totalItems >= initialOffset ? initialOffset : 0
                const sortDirection: SortDirection = ctx.sortDesc ? 'DESC' : 'ASC'
                const sort: PaginationSort | null = ctx.sortBy ? { [ctx.sortBy]: sortDirection } : null
                const filters = this.filters

                const paginationParams: PaginationParams = { limit, offset, sort, search, filters }

                try {
                    const { data, pagination } = this.uri
                        ? await this.handleUri({ limit, offset, sort, search })
                        : await this.handleStore(paginationParams)

                    this.totalItems = pagination?.total ? pagination.total : 0
                    this.showEmpty = !this.hideEmpty
                    this.initialized = true

                    return this.itemsTransformer(data)
                } catch (e) {
                    console.log(e)

                    return []
                } finally {
                    this.$nextTick(() => this.internalBusy = false)
                }
            },

            async handleUri(params: any): Promise<PaginationResponse> {
                const { data, headers } = await Http.api().get(this.uri, {
                    params: {
                        ...params,
                        ...this.params,
                    },
                })

                if (data.data && data.pagination) {
                    /**
                     * Emit request response data.
                     */
                    this.$emit('resolved', data)

                    return data
                }

                /**
                 * Convert old API pagination response to new structure
                 */
                return {
                    data: data,
                    pagination: {
                        total: headers['x-total-items']
                            ? parseInt(headers['x-total-items'])
                            : 0,
                    },
                }
            },

            async handleStore(params: PaginationParams): Promise<PaginationResponse> {
                const response = await this.$store.dispatch(this.storeAction, {
                    $route: this.$route,
                    paginationParams: params,
                    payload: this.storeActionPayload,
                })

                return response
            },

            /**
             * Manually trigger new request for data.
             */
            refresh: debounce(function(this: any, resetPagination?: boolean) {
                this.$refs?.table?.refresh()
                this.init(resetPagination)
            }, 100),

            /**
             * When selecting all we emit a custom object to indicate
             * that everything should be selected. Parent component shoukd
             * Take over that implementation.
             * Custom object contains the total count from pagination
             */
            onSelectAll(checked: boolean): any {
                this.refresh()

                if (checked) {
                    return this.emitSelectAll()
                }

                return this.$emit('selected', this.selectedItems)
            },

            emitSelectAll(): void {
                this.$emit('selected', [{
                    id: '*',
                    filters: this.filters,
                    totalItems: this.totalItems,
                    search: this.query ? { term: this.query, fields: '*' } : null,
                } as TableSelectAllConfig])
            },

            clearSelection(refresh = true): void {
                this.selectAll = false
                this.selectedItems = []
                if (refresh) this.refresh()
            },
        },
    })
</script>
