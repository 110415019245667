import { Module } from 'vuex'
import Http from '@utils/Http'

const store: Module<any, any> = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        /**
         * Get image by id
         * @param _ctx
         * @param id
         */
        async getImage(
            _ctx,
            id: number,
        ): Promise<any> {
            const { data } =  await Http.api().get(`api2/image`, { params: { id } })

            return data
        },
    },
}

export default store
