<template>
    <component
        :is="tag"
        v-html="output"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    /**
     * Mark query will convert a string and hightlight any substring that matches a query using `<mark />` html tags
     */
    export default Vue.extend({
        props: {
            /** String to parse */
            value: { type: String as PropType<string>, required: true },
            /** Query instance to be highlighted */
            query: { type: String as PropType<string>, default: null },
            /** Tag that results are marked in */
            tag: { type: String as PropType<string>, default: 'span' },
        },

        computed: {
            output(): any {
                const safeValue = this.sanitizeInput(this.value)

                if (this.query) {
                    return safeValue.replace(new RegExp(this.query, 'gi'), (match: string) => {
                        return '<mark>' + match + '</mark>'
                    })
                }

                return safeValue
            },
        },

        methods: {
            sanitizeInput(unsafe: string): string {
                const div = document.createElement('div')
                div.textContent = unsafe
                const escapedHtml = div.innerHTML

                div.remove()

                return escapedHtml
            },
        },
    })
</script>

<docs>
**Output:**
```vue
    <mark-query
        value="How much wood would a woodchuck chuck if a woodchuck could chuck wood?"
        query="wood"
    />
    <mark-query
        value="How much wood would a woodchuck chuck if a woodchuck could chuck wood?"
        query="wood"
        tag="h5"
    />
```
</docs>