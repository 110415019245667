import { Editor } from '@tiptap/vue-2'

export const EDITOR_MODES = ['field', 'inline'] as const

export class ExtensionManager {
    private editor: Editor

    constructor(editor: Editor) {
        this.editor = editor
    }

    /**
     * Check if editor has extensions
     *
     * @param name Name(s) of extensions to check for
     * @param strict Search strictly
     */
    has(name: string | string[], strict = false): boolean {
        const method = strict ? 'every' : 'some'
        name = Array.isArray(name) ? name : [name]

        return name[method]((extensionName) => !!this.editor.extensionManager.extensions.find((extension) => {
            return extension.name === extensionName
        }))
    }

    /**
     * Check if editor can run certain commands
     *
     * @param command Name(s) of commands to check for
     * @param strict Search strictly
     * @returns
     */
    can(command: string | string[], strict = false): boolean {
        const method = strict ? 'every' : 'some'
        command = Array.isArray(command) ? command : [command]

        return command[method]((commandName) =>
            (this.editor as unknown as any).can()[commandName]?.())
    }

    /**
     * Get options for extension by name
     *
     * @param name extension name
     */
    getOptions<T = Record<string, any>>(name: string): T {
        return this.editor.extensionManager.extensions
            .find((extension) => extension.name === name)
            ?.options ?? {}
    }
}