<template>
    <div>
        <div
            v-if="loading"
            class="spinner"
            :class="[variantClass, sizeClass, spinnerClass]"
        >
            <span class="ball ball1" />
            <span class="ball ball2" />
            <span class="ball ball3" />
        </div>
        <slot v-else />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    /**
     * Spinner will display a loading state animation based on a boolean.
     * When boolean is falsy then the default slot will be shown
     */
    export default Vue.extend({
        props: {
            /**
             * Loader size
             * @values xs, sm, md, lg, xl
             */
            size: {
                type: String as PropType<string>,
                default: 'md',
            },
            /**
             * Show loading animation if true
             */
            loading: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Loader color variant
             * @values primary, secondary, accent, mixed
             */
            variant: {
                type: String as PropType<string>,
                default: 'primary',
            },
            /**
             * Any class to attach to loader animation element
             */
            spinnerClass: {
                type: String as PropType<string>,
                default: '',
            },
        },

        computed: {
            variantClass(): string {
                return `spinner--${this.variant}`
            },

            sizeClass(): string {
                return `spinner--${this.size}`
            },
        },

    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .spinner {
        display: flex;
        justify-content: center;

        > .ball {
            width: 1.5rem;
            height: 1.5rem;
            background-color: color('gray-light');
            border-radius: 100%;
            animation: ball-scale 1.4s infinite ease-in-out both;
        }

        .ball1 {
            animation-delay: -320ms;
            margin-right: spacer(1);
        }

        .ball2 {
            animation-delay: -160ms;
            margin-right: spacer(1);
        }

        // Color Variants
        &--primary > .ball { background-color: whitelabel-color('primary'); }
        &--secondary > .ball { background-color: whitelabel-color('secondary'); }
        &--accent > .ball { background-color: whitelabel-color('accent'); }
        &--white > .ball { background-color: $white; }

        &--mixed > {
            .ball1 { background-color: whitelabel-color('primary'); }
            .ball2 { background-color: whitelabel-color('secondary'); }
            .ball3 { background-color: whitelabel-color('accent'); }
        }


        // Size variants
        &--sm > .ball { height: 1rem; width: 1rem; }
        &--xs > .ball { height: .75rem; width: .75rem; }
        &--lg > .ball { height: 1.75rem; width: 1.75rem; }
        &--xl > .ball { height: 2rem; width: 2rem; }
    }

    @keyframes ball-scale {
        0%, 80%, 100% { transform: scale(0); }
        40% { transform: scale(1); }
    }
</style>

<docs>
**Output:**
```vue
    <template>
        <div>
            <button
                :disabled="busy"
                v-text="'Do async'"
                @click="doAsync"
            />

            <spinner
                :loading="busy"
                variant="primary"
                size="md"
            >
                <h4 class="my-4">Results shown when loaded</h4>
            </spinner>
        </div>
    </template>

    <script>
    export default {
        data() {
            return { busy: true, };
        },

        mounted() {
            this.doAsync()
        },

        methods: {
            async doAsync() {
                this.busy = true
                await this.timeout(3000)
                this.busy = false
            },

            timeout(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        }
    }
    </script>
````
</docs>