import Vue from 'vue'
import { DirectiveBinding } from 'vue/types/options'

Vue.directive('click-outside', {
    bind(el: any, bindings: DirectiveBinding) {
        el.__handler = (event: PointerEvent): void  => {
            if (el !== event.target && !el.contains(event.target))
                bindings.value?.(event)
        }
        window.addEventListener('click', el.__handler)
    },
    unbind(el: any) {
        window.removeEventListener('click', el.__handler)
    },
})
