import { Extension } from '@tiptap/core'

type OpenAiCommand = {
    command: OpenAIModifyCommands | OpenAIToneCommands;
    type: OpenAICategory;
}

export interface OpenAiOptions {
    /**
     * Overwrite available text transformations
     */
    commands: OpenAiCommand[];
    /**
     * Handle AI call, this function needs to return a string
     */
    handler: ((cmd: OpenAiCommand & { message: string }) => Promise<string>) | null
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        openAi: {
            /**
             *
             * @param cmd Ai command instructions
             * @example editor.commands.execAiCommand(cmd)
             */
            execAiCommand: (cmd: OpenAiCommand) => ReturnType
        }
    }
}

export default Extension.create<OpenAiOptions>({
    name: 'openAi',

    addOptions() {
        return {
            commands: [
                {
                    command: 'rephrase',
                    type: 'modify',
                },
                {
                    command: 'shorten',
                    type: 'modify',
                },
                {
                    command: 'lengthen',
                    type: 'modify',
                },
                {
                    command: 'professional',
                    type: 'tone-of-voice',
                },
                {
                    command: 'straightforward',
                    type: 'tone-of-voice',
                },
                {
                    command: 'confident',
                    type: 'tone-of-voice',
                },
                {
                    command: 'humorous',
                    type: 'tone-of-voice',
                },
                {
                    command: 'friendly',
                    type: 'tone-of-voice',
                },
                {
                    command: 'encouraging',
                    type: 'tone-of-voice',
                },
            ],
            handler: null,
        }
    },

    addCommands() {
        return {
            execAiCommand: (cmd: OpenAiCommand) => ({ state, editor, dispatch }) => {
                const { from, to, empty } = state.selection

                if (empty || !this.options.handler)
                    return false

                if (dispatch) (async () => {
                    if (!this.options.handler)
                        return

                    editor.setEditable(false)
                    try {
                        const { command, type } = cmd
                        const message = state.doc.textBetween(from, to)
                        const replacement = await this.options.handler({ command, type, message })

                        editor.chain().focus()
                            .insertContentAt({ from, to }, replacement)
                            .run()
                    } finally {
                        editor.setEditable(true)
                    }
                })()

                return true
            },
        }
    },
})