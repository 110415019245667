import store from '@store'

/**
 * ### Check if logged in user has feature
 * - You can pass in feature names or feature Id's or mix them if in array
 *
 * @param featureName name or id of feature or features
 * @param role Optional role, defaults to current active role
 * @param strict Make sure that user has all features & roles asked for in array
 */
export function hasFeatures(
    featureNames: (string | number) | (string | number)[],
    role?: UserType | UserType[],
    strict = false,
): boolean {
    featureNames = Array.isArray(featureNames) ? featureNames : [featureNames]

    const searchMethod = strict ? 'every' : 'some'
    const activeUserType: UserType = store.get('Auth/activeUserType')
    const features = store.get('Auth/features')
    const rolesToCheck = !role
        ? [activeUserType]
        : Array.isArray(role) ? role : [role]

    return featureNames[searchMethod]((featureName: string | number) => {
        const feature = typeof featureName === 'string'
            ? features.find(({ feature }: any) => feature.module.toLowerCase() === featureName.toLowerCase())
            : features.find(({ feature }: any) => feature.id === featureName)

        if (!feature)
            return false

        return rolesToCheck[searchMethod]((role) => !!feature[role])
    })
}
