<template>
    <image-lazy
        :src="src"
        :ar="'16:9'"
        :class="imageClass"
        :no-lazy="noLazyImg"
        :fill-blur="fillBlur"
        :fill="fill"
    >
        <template #fallback>
            <div
                class="embed-responsive embed-responsive-16by9 bg-light text-gray-dark"
                :class="imageClass"
            >
                <inline-svg
                    class="centerer w-35"
                    :src="illustration"
                />
            </div>
        </template>
    </image-lazy>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import ImageLazy from '@common/Images/ImageLazy.vue'
    import { SKILL_DEVELOPER_TYPES_ILLUSTRATIONS } from '../../utils'

    export default Vue.extend({
        components: {
            ImageLazy,
        },

        props: {
            src: {
                type: String as PropType<string>,
                default: null,
            },

            type: {
                type: String as PropType<string>,
                default: null,
            },

            fill: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            fillBlur: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            noLazyImg: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            imageClass: {
                type: [String, Object, Array] as PropType<string | object | string[]>,
                default: null,
            },
        },

        computed: {
            illustration(): SVGElement | null {
                return SKILL_DEVELOPER_TYPES_ILLUSTRATIONS[this.type] || null
            },
        },
    })
</script>
