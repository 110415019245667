import Vue, { PropType, VueConstructor } from 'vue'
import { BDropdown } from 'bootstrap-vue'

interface Refs {
    $refs: {
        dropdown?: InstanceType<typeof BDropdown>;
    };
}

export default (Vue as VueConstructor<Vue & Refs>).extend({
    components: {
        BDropdown,
    },

    props: {
        /**
         * User id to get progress stats for
         */
        userId: {
            type: Number as PropType<number>,
            default: undefined,
        },
        /**
         * Show as togglable dropdown
         */
        togglable: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Apply any styles to the inner info card
         */
        mainStyle: {
            type: [String, Array, Object] as PropType<string | (string | object)[] | object>,
            default: null,
        },
        /**
         * Open dropdown to right when togglable
         */
        right: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Open on hover when togglable
         */
        hover: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },

    data() {
        return {
            busy: false,
        }
    },

    computed: {
        dropdownBindings(): object {
            if (!this.togglable)
                return {}

            return {
                toggleClass: 'p-0 border-0 rounded-0 bg-transparent text-reset',
                menuClass: 'p-4 mvh-75 overflow-y-auto',
                right: this.right,
            }
        },
    },

    mounted() {
        this.fetchData()
    },

    methods: {
        async fetchData(): Promise<void> {
            this.busy = true
            await Promise.resolve()
            this.busy = false
        },

        onMouseOver(): void {
            if (this.hover)
                this.$refs.dropdown?.show()
        },

        onMouseLeave(): void {
            if (this.hover)
                this.$refs.dropdown?.hide()
        },
    },
})