<template>
    <div
        v-if="options.show"
        class="tiptap-editor__counter"
    >
        <span
            v-if="count >= 0"
            v-text="count"
        />
        <span
            v-if="count >= 0 && limit"
            v-text="' / '"
        />
        <span
            v-if="limit"
            v-text="limit"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import type { CharacterCountOptions } from '../extensions/character-count'
    import { Inject } from './TiptapEditorProvider.vue'

    export default (Vue as VueConstructor<Vue & Inject>).extend({
        inject: ['editor', 'extensions'],

        computed: {
            options(): CharacterCountOptions {
                return this.extensions.getOptions('characterCount') as CharacterCountOptions
            },

            count(): number {
                return this.editor?.storage.characterCount?.characters?.() ?? 0
            },

            limit(): number | null {
                return this.extensions.getOptions('characterCount').limit
            },
        },
    })
</script>
