import checklistSvg from '@assets/icons/icon-checklist.svg'
import activityPlanSvg from '@assets/icons/icon-walking.svg'
import developmentPlanSvg from '@assets/icons/icon-stairs.svg'

export const SKILL_DEVELOPER_TYPES_ENUM = {
    DEVELOPMENT_PLAN: 'DEVELOPMENT_PLAN',
    ACTIVITY_PLAN: 'ACTIVITY_PLAN',
    CHECKLIST: 'CHECKLIST',
} as Readonly<{ [status: string]: string }>

export const SKILL_DEVELOPER_TYPES_ILLUSTRATIONS = {
    CHECKLIST: checklistSvg,
    ACTIVITY_PLAN: activityPlanSvg,
    DEVELOPMENT_PLAN: developmentPlanSvg,
} as Readonly<Record<string, SVGElement>>

export const SKILL_DEVELOPER_TYPES_ICONS = {
    CHECKLIST: 'list-check',
    ACTIVITY_PLAN: 'person-running',
    DEVELOPMENT_PLAN: 'stairs',
} as Readonly<Record<string, string>>

export const SKILL_DEVELOPER_TYPES_FEATURE_MAP = {
    CHECKLIST: 'Checklists',
    ACTIVITY_PLAN: 'ActivityPlans',
    DEVELOPMENT_PLAN: 'SkillsDeveloper',
} as Readonly<Record<string, string>>

/**
 * User session status
 * @readonly
 */
export const SKILL_DEVELOPER_TYPE_OPTIONS = [
    {
        value: SKILL_DEVELOPER_TYPES_ENUM.CHECKLIST,
        name: 'SKILL_DEVELOPER.CHECKLIST.TITLE',
        description: 'SKILL_DEVELOPER.CHECKLIST.INFO',
        illustration: SKILL_DEVELOPER_TYPES_ILLUSTRATIONS.CHECKLIST,
    },
    {
        value: SKILL_DEVELOPER_TYPES_ENUM.ACTIVITY_PLAN,
        name: 'SKILL_DEVELOPER.ACTIVITY_PLAN.TITLE',
        description: 'SKILL_DEVELOPER.ACTIVITY_PLAN.INFO',
        illustration: SKILL_DEVELOPER_TYPES_ILLUSTRATIONS.ACTIVITY_PLAN,
    },
    {
        value: SKILL_DEVELOPER_TYPES_ENUM.DEVELOPMENT_PLAN,
        name: 'SKILL_DEVELOPER.DEVELOPMENT_PLAN.TITLE',
        description: 'SKILL_DEVELOPER.DEVELOPMENT_PLAN.INFO',
        illustration: SKILL_DEVELOPER_TYPES_ILLUSTRATIONS.DEVELOPMENT_PLAN,
    },
] as ReadonlyArray<{ value: DevelopmentPlanType; name: string; description: string; illustration: SVGElement }>
