<template>
    <header
        class="topbar"
        :class="rootClasses"
    >
        <slot name="before" />
        <div class="topbar__container container-fluid-extended">
            <div class="topbar__left">
                <slot name="left" />
            </div>
            <div
                v-if="$slots.center"
                class="topbar__center"
            >
                <slot name="center" />
            </div>
            <div class="topbar__right">
                <slot name="right" />
            </div>
        </div>
        <slot name="after" />
    </header>
</template>
<script lang="ts">
    import Vue, { PropType } from 'vue'

    export default Vue.extend({
        props: {
            /**
             * Top bar bottom shadow
             * @values xs, sm, md, lg
             */
            shadow: {
                type: String as PropType<string>,
                default: null,
            },
        },

        computed: {
            rootClasses(): any {
                return {
                    'shadow-xs': this.shadow === 'xs',
                    'shadow-sm': this.shadow === 'sm',
                    'shadow': this.shadow === 'md',
                    'shadow-lg': this.shadow === 'lg',
                }
            },
        },
    })
</script>
<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .topbar {
        position: relative;
        height: $main-nav-width;
        background: $white;

        &__container {
            display: flex;
            height: 100%;
        }

        &__left, &__center, &__right {
            display: flex;
            align-items: center;
            flex: 1;
        }
        // fixes issues when using text-truncate with in left slot
        // @see https://css-tricks.com/flexbox-truncated-text/
        &__left {
            min-width: 0;
        }

        &__center {
            justify-content: center;
        }

        &__right {
            justify-content: flex-end;

            > * {
                margin-left: spacer(2);
            }
        }
    }
</style>
