<template>
    <main
        class="page-layout"
        :class="pageLayoutClasses"
    >
        <navigation
            v-if="isAuthenticated"
            :type="nav"
            :inner-class="mainClasses"
        />
        <section class="content d-flex flex-column">
            <!-- @slot place anything before main container to break out of it  -->
            <slot name="before" />
            <!-- @slot main content is placed here -->
            <slot />
            <!-- @slot place anything after main container to break out of it  -->
            <slot name="after" />
        </section>
    </main>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { get } from 'vuex-pathify'
    import { NAV_TYPES, CONTAINER_TYPES, CONTAINER_TYPE_CLASS_MAP } from './utils'
    import Navigation from '@common/Navigation/Navigation.vue'

    /**
     * Page layout is meant for every page components in the platform. With this component
     * you will be able to control what type of navigation you want to be displayed on the page
     * as well is it's layout type depending on your use case.
     */
    export default Vue.extend({
        components: {
            Navigation,
        },

        props: {
            /**
             * Navigation type, defaults to no navigation.
             */
            nav: {
                type: String as PropType<typeof NAV_TYPES[number]>,
                default: null,
                validator: (value: typeof NAV_TYPES[number]): boolean => NAV_TYPES.includes(value),
            },
            /**
             * Layout container type
             */
            container: {
                type: String as PropType<typeof CONTAINER_TYPES[number]>,
                default: null,
                validator: (value: typeof CONTAINER_TYPES[number]): boolean => CONTAINER_TYPES.includes(value),
            },
            /**
             * Background variant
             */
            bg: {
                type: String as PropType<string>,
                default: null,
            },
        },

        computed: {
            isAuthenticated: get('Auth/isAuthenticated'),
            mainClasses(): string | undefined {
                return CONTAINER_TYPE_CLASS_MAP[this.container]
            },
            pageLayoutClasses(): string[] {
                const classes: string[] = []

                if (this.bg) classes.push(`bg-${this.bg}`)


                return classes
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .page-layout, .content {
        min-height: 100vh;
        min-height: 100dvh;
    }
</style>