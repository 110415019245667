/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1): string {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }

    const units = si
        ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
}

/**
 * Generate a random number
 * @param {number[]} exclude Numbers the generated value cannot be
 * @param {number} min The minimum value for the random number
 * @param {number} max The maximum value for the random number
 * @returns {number}
 */
export function generateRandomNumber(exclude: number[] = [], min = 0, max = 100): number {
    const range = Array.from({ length: max - min + 1 }, (_, index) => index + min)
    const availableValues = range.filter((value) => !exclude.includes(value))

    if (availableValues.length === 0) {
        throw new Error('No available values in the specified range')
    }

    const randIndex = Math.floor(Math.random() * availableValues.length)

    return availableValues[randIndex]
}

/**
 * Break down total seconds into hours, minutes and seconds
 * @param options.roundSeconds - rounds seconds to a minute if totalSeconds is greater then a minute
 */

export function toHMS(
    totalSeconds: number,
    options: {
        roundSeconds?: boolean;
    } = {},
): { h: number; m: number; s: number } {
    const totalMinutes = Math.floor(totalSeconds / 60)

    const hours = Math.floor(totalMinutes / 60)
    let minutes = totalMinutes % 60
    let seconds = totalSeconds % 60

    if (options.roundSeconds === true && totalSeconds >= 60) {
        seconds = 0
        minutes += 1
    }

    return { h: hours, m: minutes, s: seconds }
}

/**
 * Abbreviate large numbers
 * This function will format any number above 1000 to a short abbreviated number e.g
 * 22468 => '22.5k'
 * 82000 => '82k'
 * 2636844 => '2.6m'
 *
 * You can pass in a decimal value, by default we show 1 decimal or none if decimal is 0
 *
 * @param value number to convert
 * @param decimals optional fixed decimal value
 */
export function abbreviateNumber(value: number, decimals?: number): string {
    const thresholds = [
        { threshold: 1e9, suffix: 'b' },
        { threshold: 1e6, suffix: 'm' },
        { threshold: 1e3, suffix: 'k' },
    ]

    for (const { threshold, suffix } of thresholds) {
        if (value >= threshold) {
            const dividedNumber = value / threshold
            const decimalValue = decimals ?? ((dividedNumber % 1) === 0 ? 0 : 1)
            const formattedNumber = dividedNumber.toFixed(decimalValue)

            return `${formattedNumber}${suffix}`
        }
    }

    return value.toString()
}
