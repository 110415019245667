<template>
    <div class="d-flex align-items-center">
        <!-- Edit mode -->
        <template v-if="editMode">
            <component
                :is="type"
                v-model="valueLocal"
                autofocus
                class="mb-0"
                v-bind="$attrs"
                @keyup.enter.native="confirm"
                @keyup.escape.native="cancel"
            />

            <fa
                class="text-success pointer ml-2"
                fixed-width
                :icon="['fal', 'check']"
                @click="confirm"
            />

            <fa
                class="text-danger pointer ml-2"
                fixed-width
                :icon="['fal', 'times']"
                @click="cancel"
            />
        </template>

        <!-- Display mode -->
        <template v-else>
            <component
                :is="tag"
                :class="{ 'text-gray': !value }"
                v-text="value || $attrs.placeholder"
            />

            <fa
                v-if="!disabled"
                class="text-link pointer ml-2"
                fixed-width
                :icon="['fal', 'pencil']"
                @click="toggleEdit"
            />
        </template>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { InputField, SelectField } from '.'

    /**
     * Wrapper component for inline editing.
     *
     * Has a normal text state with an edit button next to it.
     * Once you edit the component turns into a field component
     *
     * Any extra props you give this component will be inherited by the component
     * defined in the `type` prop
     *
     * If `v-model` is empty the display mode will use `placeholder` prop as display value
     * with a grayed out text.
     *
     * @example ./__docs__/EditInPlace.examples.md
     */
    export default Vue.extend({
        components: { InputField, SelectField },
        props: {
            /**
             * Value display tag, what is visible when not in edit mode
             */
            tag: {
                type: String as PropType<string>,
                default: 'span',
            },
            /**
             * @model
             */
            value: {
                type: [String, Number, Array, Object, Boolean, Date] as PropType<any>,
                default: null,
            },
            /**
             * Field type. Field to display when in edit mode
             */
            type: {
                type: String,
                default: 'InputField',
                validator: (value: string): boolean =>
                    ['InputField', 'SelectField'].includes(value),
            },
            /**
             * Disables the edit mode feature, only displaying value
             */
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                valueLocal: this.value,
                editMode: false,
            }
        },

        methods: {
            confirm(): void {
                /**
                 * Triggers when user confirms changes
                 *
                 * @property {any} value new value
                 */
                this.$emit('input', this.valueLocal)

                /**
                 * Triggers when user confirms changes
                 *
                 * @property {any} value new value
                 */
                this.$emit('update', this.valueLocal)
                this.toggleEdit()
            },

            cancel(): void {
                this.valueLocal = this.value
                this.toggleEdit()
            },

            toggleEdit(): void {
                this.editMode = !this.editMode
            },
        },
    })
</script>
