<template>
    <component
        :is="togglable ? 'b-dropdown' : 'div'"
        ref="dropdown"
        v-bind="dropdownBindings"
        @mouseenter.native="onMouseOver"
        @mouseleave.native="onMouseLeave"
    >
        <template #button-content>
            <fa
                class="text-muted"
                fixed-width
                :icon="['fal', 'fire']"
            />
            {{ userStreak }}
        </template>
        <div
            :style="mainStyle"
            class="d-flex"
        >
            <fa
                fixed-width
                class="mr-3"
                :class="userStreak ? 'text-danger' : 'text-muted'"
                size="2x"
                :icon="['fal', 'fire']"
            />
            <div>
                <p
                    class="text-nowrap text-larger"
                    v-text="$t('ACHIEVEMENT.STREAK.COUNT', { count: userStreak })"
                />
                <p
                    class="text-muted"
                    v-text="$t('ACHIEVEMENT.STREAK.INFO')"
                />
                <div class="d-flex flex-row-reverse justify-content-between">
                    <div
                        v-for="(value, key) in streakDays"
                        :key="key"
                        class="text-center px-1"
                    >
                        <small class="d-block text-gray">{{ key | date('dd') }}</small>
                        <fa
                            size="2x"
                            :class="{ 'text-gray': !value }"
                            :icon="['fal', value ? 'circle-check' : 'circle']"
                        />
                    </div>
                </div>
            </div>
        </div>
    </component>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import infoMixin from './info.mixin'

    export default (Vue as VueConstructor<Vue & InstanceType<typeof infoMixin>>).extend({
        mixins: [infoMixin],
        computed: {
            userStreak(): number {
                if (!this.busy)
                    return this.$store.getters['Achievements/streak'] ?? 0

                return 0
            },

            streakDays(): Record<string, LearningProgressDatesModel | null> {
                if (!this.busy)
                    return this.$store.getters['Achievements/pastDaysAttempts'] ?? 0

                return {}
            },
        },

        methods: {
            async fetchData(): Promise<void> {
                this.busy = true
                await this.$store.load('Achievements')
                await this.$store.cache.dispatch('Achievements/getAttemptsByDate', this.userId)
                this.busy = false
            },
        },
    })
</script>