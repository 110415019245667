import cssVars from 'css-vars-ponyfill'
import { lightenDarkenColor, contrastYiq } from '@learningbank/lb-whitelabel-utils'
import { IRgb } from '@learningbank/lb-whitelabel-utils/lib/types'
import { ANCHOR_COLORS, TEXT_COLORS, THEME_FALLBACKS } from '../utils'

class CssVariablesUtils {
    readonly textColors = TEXT_COLORS as { [key: string]: Partial<ColorModel> }
    readonly anchorColors = ANCHOR_COLORS as { [key: string]: Partial<ColorModel> }
    readonly fallbacks = THEME_FALLBACKS as { [key: string]: Partial<ColorModel> }

    hasBrandingColors(config: WhitelabelConfigModel): any {
        return {
            hasPrimary: config.colors.some((color) => color.type === 'primary'),
            hasSecondary: config.colors.some((color) => color.type === 'secondary'),
            hasAccent: config.colors.some((color) => color.type === 'tertiary'),
        }
    }

    getBrandingColors(config: WhitelabelConfigModel): any {
        return {
            primary: config.colors.find((color) => color.type === 'primary') || this.fallbacks.primary,
            secondary: config.colors.find((color) => color.type === 'secondary') || this.fallbacks.secondary,
            accent: config.colors.find((color) => color.type === 'tertiary') || this.fallbacks.tertiary,
        }
    }

    setConfig(config: WhitelabelConfigModel): void {
        const { primary, secondary, accent } = this.getBrandingColors(config)
        const { hasPrimary, hasSecondary, hasAccent } = this.hasBrandingColors(config)

        if (!hasPrimary)
            config.colors.push(Object.assign(primary, { type: 'primary', whitelabel_config_id: config.id }))

        if (!hasSecondary)
            config.colors.push(Object.assign(secondary, { type: 'secondary', whitelabel_config_id: config.id }))

        if (!hasAccent)
            config.colors.push(Object.assign(accent, { type: 'tertiary', whitelabel_config_id: config.id }))

        const variables = {
            ...this.generateThemeVariantVars('primary', primary, config.primaryText),
            ...this.generateThemeVariantVars('secondary', secondary, config.secondaryText),
            ...this.generateThemeVariantVars('accent', accent, config.tertiaryText),
            ...this.generateThemeVariantVars('anchor', this.anchorColors[config.anchorText]),

        }

        cssVars({
            variables,
        })
    }

    private generateThemeVariantVars(
        name: string,
        color: Partial<ColorModel>,
        variantText?: VariantText,
    ): StringMap {
        const vars = {
            [`--${name}`]: this.stringifyColor(color),
            [`--${name}-dark`]: this.stringifyColor(
                lightenDarkenColor(color as IRgb, -10) as ColorModel,
            ),
            [`--${name}-light`]: this.stringifyColor(
                lightenDarkenColor(color as IRgb, 10) as ColorModel,
            ),
        }

        // If no variant text given then we end exectution and return the above
        if (variantText === undefined)
            return vars

        // If variant text is set as null then we auto geneate text color based on color
        if (variantText === null) {
            vars[`--${name}-txt`] = this.stringifyColor(
                contrastYiq(color as IRgb, this.textColors.dark, this.textColors.light),
            )
        }

        // If string value of VariantText type then we output requested color
        if (typeof variantText === 'string') {
            vars[`--${name}-txt`] = this.stringifyColor(
                this.textColors[variantText],
            )
        }

        return vars
    }

    private stringifyColor(color: Partial<ColorModel>): string {
        return `${color.r}, ${color.g}, ${color.b}`
    }
}

export default new CssVariablesUtils()
