import Vue from 'vue'
import { abbreviateNumber, humanFileSize } from '@utils'

/**
 * Number format with thousands seperators
 *
 * @param value
 * @param thousandsSeperator
 * @param decimalSeperator
 */
Vue.filter('number', (value: number, thousandsSeperator: string, decimalSeperator: string): string => {
    thousandsSeperator = thousandsSeperator || '.'
    decimalSeperator = decimalSeperator || ','

    const numParts = value.toString().split('.')
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeperator)

    return numParts.join(decimalSeperator)
})

/**
 * Shortening numbers over 1000 with K, M, B etc
 *
 * @param value
 * @param decimals
 */
Vue.filter('numberShort', (value: number, decimals?: number) => {
    if (isNaN(value))
        return value

    return abbreviateNumber(value, decimals)
})

/**
 * Round number with optional decimal count
 *
 * @param value
 * @param decimals
 */
Vue.filter('round', (value: number, decimals: number) => {
    decimals = decimals || 0

    if (isNaN(Number(value))) {
        console.warn(`Value on [round] filter is not a number!`)

        return value
    }

    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)

    return value
})

/**
 * Display number as a percentage string value.
 *
 * @param value
 */
Vue.filter('pct', (value: string | number, decimals?: number) => {
    const number = Number(value)

    if (isNaN(number))
        return value

    return typeof decimals === 'number'
        ? `${+(number).toFixed(decimals)}%`
        : `${number}%`
})

/**
 * Round number with optional decimal count
 *
 * @param value
 * @param decimals
 */
Vue.filter('prettyBytes', (value: number, decimals: number) => {
    decimals = decimals || 0

    if (isNaN(Number(value))) {
        console.warn(`Value on [prettyBytes] filter is not a number!`)

        return value
    }

    return humanFileSize(value, true, decimals)
})
