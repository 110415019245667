import Vue, { PropType, VueConstructor } from 'vue'

/**
 * @mixin MaxLength Mixin
 *
 * This mixin add functionality to our inputs to handle max character length.
 *
 * This mixin should be used with our shared mixin for our field components.
 *
 * When setting a `maxlength` prop the `maxLength` computed props should be applied
 * where needed to stop the input from adding more characters when typing
 *
 * However when using the `max` rule in the rules prop of our shared mixin
 * Then you have 2 computed props availabel `maxChars` and `charsLeft`
 * Those should be used to display a string in a format such as `10/100`
 * Do indicated how many characters you have left while typing.
 *
 */
export default (Vue as VueConstructor<Vue &
{
    rulesNormalized: { [name: string]: any };
    internalValue: any;
}
>).extend({
    props: {
        /**
         * Maximum characters in field
         */
        maxlength: {
            type: [Number, String] as PropType<number>,
            default: undefined,
        },
    },
    computed: {
        maxChars(): number | null {
            return this.rulesNormalized.max?.length ?? null
        },

        charsLeft(): number | null {
            if (!this.maxChars)
                return null

            return (this.maxChars - this.internalValue?.length)
        },

        maxLength(): number | null {
            return this.maxChars || this.maxlength
        },
    },
})
