<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <b-form-datepicker
                v-model="internalValue"
                :class="[inputClass, { 'b-form-datepicker---no-icon': noIcon }]"
                :date-format-options="dateFormatOptions"
                :disabled="disabled"
                :placeholder="placeholder"
                :name="nameLocal"
                :size="size"
                :min="internalMin"
                :max="internalMax"
                :start-weekday="1"
                :reset-button="clear"
                :locale="languageId"
                hide-header
                nav-button-variant="default"
                v-bind="$attrs"
            >
                <template
                    #button-content
                >
                    <fa
                        v-if="!noIcon"
                        fixed-width
                        class="text-muted"
                        :icon="['fal', 'calendar']"
                    />
                </template>
            </b-form-datepicker>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { BFormDatepicker } from 'bootstrap-vue'
    import SharedField from './mixin'
    import { get } from 'vuex-pathify'

    /**
     * Date picker field
     *
     * Display date picker field with validation
     *
     * All additional attributes passed to this component
     * will be inherited by the internal `<b-form-datepicker />`
     *
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedField>
    >).extend({
        components: { BFormDatepicker },
        mixins: [SharedField],
        inheritAttrs: false,
        props: {
            /**
             * Format object for displayed text string in input
             * that is passed to `Intl.DateTimeFormat`
             */
            dateFormatOptions: {
                type: Object as PropType<object>,
                default: (): object => ({
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    weekday: undefined,
                }),
            },
            /**
             * Minimum date that picker can select
             */
            min: {
                type: [String, Date] as PropType<string | Date>,
                default: null,
            },

            /**
             * Maximum date that picker can select
             */
            max: {
                type: [String, Date] as PropType<string | Date>,
                default: null,
            },

            /**
             * Hide the input icon
             */
            noIcon: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            clear: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            profile: get<ProfileModel>('Auth/authUser@profile'),

            languageId(): string {
                return this.profile.language_id?.slice(0, 2) ?? 'en'
            },

            internalMin(): string | Date {
                if (typeof this.min === 'string')
                    return this.$filter('date')(this.min, 'YYYY-MM-DD')

                return this.min
            },

            internalMax(): string | Date {
                if (typeof this.max === 'string')
                    return this.$filter('date')(this.max, 'YYYY-MM-DD')

                return this.max
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    ::v-deep .b-calendar .b-calendar-grid {
        border: 0;

        footer {
            display: none;
        }
    }

    ::v-deep .b-form-datepicker---no-icon {
        > button.btn {
            padding-left: 0;
        }

        > label {
            padding-left: 0 !important;
        }
    }
</style>
