import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import store from '@store'

export interface RoutesFactoryOptions {
    baseURI: string;
    module: string;
    middleware?: any[] | undefined;
}

/**
 * Transform all routes recursively
 * - Apply middleware from createRoutes config to all routes and child routes by merging
 * - Apply stores to be loaded before entering any routes and child routes by merging
 *
 * Middlewares and stores are loaded via beforeEach method on base router.
 *
 * @param routes module routes
 * @param config route factory config
 */
function transformRoutes(routes: RouteConfig[], config: RoutesFactoryOptions): RouteConfig[] {
    return routes.map((route: RouteConfig) => {
        const perRouteMiddleware = Array.isArray(route.meta?.middleware)
            ? route.meta?.middleware
            : [route.meta?.middleware]

        const perRouteStore = Array.isArray(route.meta?.store)
            ? route.meta?.store
            : [route.meta?.store] as unknown as string[]

        route.meta = {
            middleware: [
                ...config.middleware ?? [],
                ...perRouteMiddleware ?? [],
            ].filter(Boolean),
            store: [
                config.module,
                ...perRouteStore ?? [],
            ].filter(Boolean),
        }

        const guards = [
            function(_to: any, _from: any, next: any): any {
                store.load(route.meta?.store ?? [])
                    .then(() => next())
                    .catch(() => next())
            },
        ]

        if (route.beforeEnter) {
            guards.push(route.beforeEnter)
        }

        route.beforeEnter = multiguard(guards)

        if (route.children) {
            route.children = transformRoutes(route.children, config)
        }

        return route
    })
}


/**
 * Route wrapper
 *
 * @param config
 * @param routes
 */
function factory(config: RoutesFactoryOptions, routes: RouteConfig[]): RouteConfig[] {
    return transformRoutes(routes, config).map((route) => {
        const base = config.baseURI ? `${config.baseURI}/` : ''
        route.path = `/${base}` + route.path

        return route
    })
}

export default factory