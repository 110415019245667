<template>
    <validation-observer
        #default="{ invalid, valid, reset }"
        slim
    >
        <tiptap-dropdown
            lazy
            v-bind="$attrs"
            @show="onShow"
            @hidden="reset"
        >
            <b-dropdown-form @submit.prevent="addLink(valid)">
                <input-field
                    v-model="href"
                    autofocus
                    style="width: 18rem;"
                    rules="required|url"
                    placeholder="https://"
                    class="mb-0"
                >
                    <template #append>
                        <b-input-group-append>
                            <btn
                                variant="light"
                                class="border"
                                :icon="['fal', 'link']"
                                :disabled="invalid"
                                @click="addLink(valid)"
                            />
                            <btn
                                v-if="canUnlink"
                                class="border"
                                variant="light"
                                :icon="['fal', 'unlink']"
                                @click="removeLink"
                            />
                        </b-input-group-append>
                    </template>
                </input-field>
            </b-dropdown-form>
        </tiptap-dropdown>
    </validation-observer>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { BDropdownForm, BInputGroupAppend } from 'bootstrap-vue'
    import { InputField } from '@common/Forms'
    import TiptapDropdown from './TiptapDropdown.vue'
    import { Inject } from './TiptapEditorProvider.vue'

    export default (Vue as VueConstructor<Vue & Inject>).extend({
        components: {
            BDropdownForm,
            BInputGroupAppend,
            InputField,
            TiptapDropdown,
        },
        inject: ['editor'],
        inheritAttrs: false,

        data() {
            return {
                href: '',
            }
        },

        computed: {
            canUnlink(): boolean {
                return this.editor.isActive('link')
            },
        },

        methods: {
            onShow(): void {
                this.href = this.editor.getAttributes('link').href ?? ''
            },

            removeLink(): void {
                this.editor
                    .chain()
                    .focus()
                    .unsetLink()
                    .run()
            },

            addLink(valid: boolean): void {
                if (!valid) return

                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: this.href })
                    .run()

                this.href = ''
            },
        },
    })
</script>