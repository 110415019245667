import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'
import learnerRoutes from './learners.routes'
import contentRoutes from './content.routes'

const routerConfig = {
    baseURI: 'insights',
    module: 'Insights',
    middleware: [
        hasFeatures(['InsightsLearners', 'InsightsContent'], { name: '404' }),
        auth('admin', { name: '401' }),
    ],
}

const routes =  routesFactory(routerConfig, [
    {
        path: 'filters',
        name: 'insights.filters.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Insights" */
                '../InsightsFiltersIndex.vue'
            ),
    },
    {
        path: 'content/modules/:moduleId/answers',
        name: 'insights.module.aggregated',
        props: true,
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Insights" */
                '../InsightsModuleAggregatedAnswers.vue'
            ),
    },
    /**
     * Answers insights routes
     */
    {
        path: 'learners/users/:userId/modules/:moduleId/answers',
        name: 'insights.module.answers',
        props: ({ params }): object => ({
            moduleId: Number(params.moduleId),
            userId: Number(params.userId),
        }),
        component: (): Promise<any> => {
            return import(
                /* webpackChunkName: "modules/Insights" */
                '../InsightsModuleAnswers.vue'
            )
        },
    },

])

export default [
    ...routes,
    ...learnerRoutes,
    ...contentRoutes,
]
