<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="!hasSlot ? getValidationError(props.errors) : ''"
            :state="getValidationState(props)"
        >
            <label
                v-if="hasSlot"
                class="m-0"
                :for="id"
            >
                <!--
                    @slot Overwrite file input with anything, what ever it inside is clickable
                    and will trigger file selection
                    @binding {ValidationFlags} provider internal validation providers scope passed on
                 -->
                <slot :provider="props" />
            </label>
            <b-form-file
                v-show="!hasSlot"
                :id="id"
                v-model="internalValue"
                :size="size"
                :autofocus="autofocus"
                :placeholder="placeholder"
                :disabled="disabled"
                :name="nameLocal"
                :multiple="multiple"
                :capture="capture"
                :accept="acceptLocal"
                :plain="hasSlot"
                no-drop
                @change="onChange($event, props.validate)"
            />
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'
    import { BFormFile } from 'bootstrap-vue'
    import { uniqueId } from 'lodash'
    import type { ValidationResult } from 'vee-validate/dist/types/types'

    /**
     * File select component, will display a the default file selector from bootstrap
     * If you use a default slot then that will be displayed instead wrapped in a
     * `<label>` which will trigge the file selection on click.
     *
     * @example ./__docs__/FileField.examples.md
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedField>
    >).extend({
        components: { BFormFile },
        mixins: [SharedField],
        props: {
            /** Allow multiple files to be selected */
            multiple: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Value to set on the file input's 'accept' attribute
             * If the `ext` rule is used, then its value will be
             * used instead
             */
            accept: {
                type: String as PropType<string>,
                default: '',
            },
            /**
             * When set, will instruction the browser to use
             * the devices camera (if supported)
             */
            capture: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

        },

        data() {
            return {
                id: uniqueId('fileField_'),
            }
        },

        computed: {
            hasSlot(): boolean {
                return !!this.$slots.default || !!this.$scopedSlots.default
            },

            acceptLocal(): string {
                return this.rulesNormalized.ext
                    ?.map((ext: string) => `.${ext}`)
                    .join(',')
                    ?? this.accept
            },
        },

        methods: {
            async onChange(event: Event, validate: any): Promise<void> {
                const validationResult: ValidationResult = await validate(event)

                if (validationResult.valid)
                    /**
                     * Triggers when value changes and validation succeeds
                     *
                     * @property {Event} event native change event object
                     */
                    this.$emit('change', event)
                else
                    /**
                     * Triggers when value changes and validation fails
                     *
                     * @property {ValidationResult} validationResult Validation result from vee-validate
                     */
                    this.$emit('error', validationResult)
            },
        },
    })
</script>
