<template>
    <div :class="{ 'd-flex align-items-center': hasLabel }">
        <b-form-checkbox
            v-model="internalValue"
            :disabled="disabled"
            :label="label"
            :class="{
                'order-2 ml-2': labelPlacement === 'right',
            }"
            switch
        />

        <span
            v-if="hasLabel"
            :class="labelClass"
        >
            <slot name="label">
                {{ label }}
            </slot>
        </span>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'
    import { BFormCheckbox } from 'bootstrap-vue'

    export default (Vue as VueConstructor<
        Vue
        & InstanceType<typeof SharedField>
    >).extend({
        components: {
            BFormCheckbox,
        },

        mixins: [SharedField],

        props: {
            labelPlacement: {
                type: String as PropType<string>,
                default: 'right',
                validator: (value: string): boolean => ['left', 'right'].includes(value),
            },
        },

        computed: {
            hasLabel(): boolean {
                return !!this.label || !!this.$slots['label'] || !!this.$scopedSlots['label']
            },
        },
    })
</script>
