interface ReadFileResults {
    filename: string;
    filetype: string;
    hex: string;
}

const hexMap = {
    xlsx: {
        hex: ['50 4B 03 04'],
        typeContains: 'sheet',
    },
    docx: {
        hex: ['50 4B 03 04'],
        typeContains: 'document',
    },
    pptx: {
        hex: ['50 4B 03 04'],
        typeContains: 'presentation',
    },
    png: {
        hex: ['89 50 4E 47'],
        typeContains: 'image/png',
    },
    gif: {
        hex: ['47 49 46 38'],
        typeContains: 'image/gif',
    },
    jpg: {
        hex: [
            'FF D8 FF E0',
            'FF D8 FF E1',
            'FF D8 FF E2',
            'FF D8 FF E3',
            'FF D8 FF E8',
        ],
        typeContains: 'image/jpeg',
    },
}

/**
 * Read file by file blob to get hex signature
 * and basic file information
 *
 * @param file
 */
export function readFile(file: File): Promise<ReadFileResults> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onloadend = (event: ProgressEvent): void => {
            const target = event.target as FileReader
            if (target.readyState !== FileReader.DONE) {
                return
            }

            const uint = new Uint8Array(target.result as ArrayBuffer)
            const bytes: any[] = []

            uint.forEach((byte) => bytes.push(byte.toString(16)))

            const hex = bytes
                .map((byte) => byte.padStart(2, 0))
                .join(' ')
                .toUpperCase()

            resolve({
                filename: file.name,
                filetype: file.type || 'Unknown/Extension missing',
                hex: hex,
            })
        }

        reader.onerror = reject
        const blob = file.slice(0, 4)

        reader.readAsArrayBuffer(blob)
    })
}

/**
 * Validate file blob by hex signature
 *
 * @param file
 * @param types
 */
export async function validateFile(
    file: File,
    types: string[],
): Promise<any> {
    const validFileExt = Object.keys(hexMap)

    types.forEach((type) => {
        if (!validFileExt.includes(type)) {
            throw new Error(`Extension is not supported\n Valid types: ${validFileExt.join(',')}`)
        }
    })

    const fileInfo = await readFile(file)

    return types.some((type) => {
        const typeInfo = (hexMap as any)[type]

        return (
            typeInfo.hex.includes(fileInfo.hex) &&
            fileInfo.filetype.includes(typeInfo.typeContains)
        )
    })
}

/**
 * Password strength checker algorithm
 * Based on: https://gist.github.com/adatoo/9098390
 *
 * Min score: 0
 * Max score: 45
 *
 * @param password
 */
export function passwordStrength(password: string): {
    score: number;
    verdict: string;
    log: string[];
} {
    let score = 0
    let verdict = 'weak'
    const log: string[] = []

    // PASSWORD LENGTH
    if (password.length < 5) { // length 4 or less
        score = (score + 3)
        log.push(`3 points for length (${password.length})`)
    }
    else if (password.length > 4 && password.length < 8) { // length between 5 and 7
        score = (score + 6)
        log.push(`6 points for length (${password.length})`)
    }
    else if (password.length > 7 && password.length < 16) { // length between 8 and 15
        score = (score + 12)
        log.push(`12 points for length (${password.length})`)
    }
    else if (password.length > 15) { // length 16 or more
        score = (score + 18)
        log.push(`18 points for length (${password.length})`)
    }


    // LETTERS
    if (/[a-z]/.exec(password)) { // [verified] at least one lower case letter
        score = (score + 1)
        log.push('1 point for at least one lower case char')
    }

    if (/[A-Z]/.exec(password)) { // [verified] at least one upper case letter
        score = (score + 5)
        log.push('5 points for at least one upper case char')
    }

    // NUMBERS
    if (/\d+/.exec(password)) { // [verified] at least one number
        score = (score + 5)
        log.push('5 points for at least one number')
    }

    if (/(.*[0-9].*[0-9].*[0-9])/.exec(password)) { // [verified] at least three numbers
        score = (score + 5)
        log.push('5 points for at least three numbers')
    }


    // SPECIAL CHAR
    if (/.[!,@,#,$,%,^,&,*,?,_,~]/.exec(password)) { // [verified] at least one special character
        score = (score + 5)
        log.push('5 points for at least one special char')
    }

    // [verified] at least two special characters
    if (/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/.exec(password)) {
        score = (score + 5)
        log.push('5 points for at least two special chars')
    }


    // COMBOS
    if (/([a-z].*[A-Z])|([A-Z].*[a-z])/.exec(password)) { // [verified] both upper and lower case
        score = (score + 2)
        log.push('2 combo points for upper and lower letters')
    }

    if (/([a-zA-Z])/.exec(password) && /([0-9])/.exec(password)) { // [verified] both letters and numbers
        score = (score + 2)
        log.push('2 combo points for letters and numbers')
    }

    // [verified] letters, numbers, and special characters
    if (/([a-zA-Z0-9].*[!,@,#,$,%,^,&,*,?,_,~])|([!,@,#,$,%,^,&,*,?,_,~].*[a-zA-Z0-9])/.exec(password)) {
        score = (score + 2)
        log.push('2 combo points for letters, numbers and special chars')
    }


    if (score < 16) {
        verdict = 'VERY_WEAK'
    }
    else if (score > 15 && score < 25) {
        verdict = 'WEAK'
    }
    else if (score > 24 && score < 35) {
        verdict = 'MEDIUM'
    }
    else if (score > 34 && score < 45) {
        verdict = 'STRONG'
    }
    else {
        verdict = 'VERY_STRONG'
    }

    return { score, verdict, log }
}

/**
 * Get true type of input
 * returns data type in lowercase string
 * e.g. 'object' | 'array' | 'string'...
 */
export function trueTypeOf(input: unknown): string {
    return Object.prototype.toString
        .call(input)
        .slice(8, -1)
        .toLowerCase()
}
