<template>
    <div class="swimlane">
        <div class="d-flex mb-3">
            <div class="flex-grow-1">
                <h5
                    v-if="title"
                    class="mb-0"
                    v-text="title"
                />
                <p
                    v-if="description"
                    class="mb-0 text-muted"
                    v-text="description"
                />
            </div>
            <slot name="header-after" />
        </div>

        <!-- Swiper DOM -->
        <div
            v-show="!(!value.length && $slots.empty)"
            ref="swiperContainer"
            class="swiper px-3 mx-n3 py-4 my-n4"
        >
            <!-- v-show="value.length" -->
            <div class="swiper-wrapper">
                <div
                    v-for="(item, index) in value"
                    :key="index"
                    class="swiper-slide h-auto"
                >
                    <slot
                        name="item"
                        :item="item"
                    >
                        {{ item }}
                    </slot>
                </div>
                <div
                    v-if="!value.length"
                    class="swiper-slide h-auto"
                >
                    <!--
                        @slot Empty state shown as a single item when no items are available
                     -->
                    <slot name="empty-item" />
                </div>
            </div>
        </div>
        <!--
            @slot Empty slot that fills the width of component
                  this will overwrite the `empty-item` slot if that is defined
         -->
        <slot
            v-if="!value.length"
            name="empty"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import Swiper, { FreeMode } from 'swiper'
    import '~swiper/swiper.scss'

    interface Refs {
        $refs: {
            swiperContainer: HTMLElement;
        };
    }

    export default (Vue as VueConstructor<Vue & Refs>).extend({
        props: {
            /**
             * List of items to render in slider
             */
            value: {
                type: Array as PropType<any[]>,
                default: (): never[] => [],
            },
            /**
             * Title of swimlane
             */
            title: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Description of swimlane
             */
            description: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Overwrite slidesPerView for each breakpoint
             */
            perView: {
                type: Object as PropType<Record<TShirtSizes, number>>,
                default: (): object => ({}),
            },

        },

        data() {
            return {
                swiper: null as Swiper | null,
            }
        },

        computed: {
            perViewLocal(): Record<TShirtSizes, number> {
                return {
                    xs: 1.4,
                    sm: 2.4,
                    md: 3.4,
                    lg: 4,
                    xl: 5,
                    ...this.perView,
                }
            },
        },

        watch: {
            value(): void {
                this.$nextTick(() => this.swiper?.update?.())
            },
        },

        mounted() {
            this.initSwiper()
        },

        beforeDestroy() {
            this.swiper?.destroy?.()
            this.swiper = null
        },

        methods: {
            initSwiper(): void {
                const breakpoints = this.$config.scss.breakpoints

                this.swiper = new Swiper(this.$refs.swiperContainer, {
                    modules: [FreeMode],
                    direction: 'horizontal',
                    loop: false,
                    slidesPerView: this.perViewLocal.xs,
                    spaceBetween: 28,
                    freeMode: {
                        enabled: true,
                        sticky: false,
                    },
                    breakpoints: {
                        [parseInt(breakpoints.sm)]: {
                            slidesPerView: this.perViewLocal.sm,
                        },
                        [parseInt(breakpoints.md)]: {
                            slidesPerView: this.perViewLocal.md,
                        },
                        [parseInt(breakpoints.lg)]: {
                            slidesPerView: this.perViewLocal.lg,
                        },
                        [parseInt(breakpoints.xl)]: {
                            slidesPerView: this.perViewLocal.xl,
                        },
                    },
                })
            },
        },
    })
</script>