import { Module } from 'vuex'
import Http from '@utils/Http'

const store: Module<any, any> = {
    namespaced: true,
    actions: {
        /**
         * Request a password reset
         */
        async requestConfirmEmail({ rootGetters }, email: string): Promise<void> {
            const organizationUuid = rootGetters['AppConfig/orgUuid']

            await Http.api().put(`user/request/activation`, {
                email,
                organizationUuid,
            })
        },

        /**
         * Request a password reset
         */
        async resetRequest({ rootGetters }, emailOrUsername: string): Promise<void> {
            const organizationUUID = rootGetters['AppConfig/orgUuid']

            await Http.api().put(`user/requestResetPassword`, {
                emailOrUsername,
                organizationUUID,
            })
        },

        /**
         * Reset password for user
         */
        async reset(
            { rootGetters, dispatch },
            payload: { nonce?: string; credentials?: AuthSigninPayload; newPassword: string },
        ): Promise<void> {
            const organizationUUID = rootGetters['AppConfig/orgUuid']
            const method = payload.nonce ? 'patch' : 'post'
            const uri = payload.nonce ? 'passwordReset' : 'login/passwordReset'
            let reqPayload: Record<string, any> = {}

            if (payload.nonce) {
                reqPayload = {
                    organizationUUID,
                    nonce: payload.nonce,
                    newPassword: payload.newPassword,
                }
            } else {
                reqPayload = {
                    organizationUUID,
                    username: payload.credentials?.username,
                    password: payload.credentials?.password,
                    newPassword: payload.newPassword,
                    remember: payload.credentials?.remember,
                }
            }

            const { data } = await Http.api()[method](uri, reqPayload)
            if (data.token && data.user)
                await dispatch('Auth/postLogin', {
                    response: data,
                    remember: payload.credentials?.remember,
                }, { root: true })
        },
    },
}

export default store
