/**
 * Transform custom fonts to options for font selectors
 */
function getCustomFonts() {
    return window.appConfig.whitelabel_fonts?.reduce((acc, item) => {
        if (!item.archived)
            acc.push({
                value: `'${item.fullName}',sans-serif`,
                text: item.fullName,
            })

        return acc
    }, [] as { value: string; text: string }[]) ?? []
}

/**
 * Web safe fonts for font selectors
 */
const WEBSAFE_FONTS = [
    {
        value: 'Arial,sans-serif',
        text: 'Arial',
    },
    {
        value: 'Georgia,serif',
        text: 'Georgia',
    },
    {
        value: 'Helvetica,sans-serif',
        text: 'Helvetica',
    },
    {
        value: 'Impact,Charcoal,sans-serif',
        text: 'Impact',
    },
    {
        value: 'Tahoma,Geneva,sans-serif',
        text: 'Tahoma',
    },
    {
        value: "'Times New Roman',Times,serif",
        text: 'Times New Roman',
    },
    {
        value: 'Verdana,Geneva,sans-serif',
        text: 'Verdana',
    },
]

/**
 * Get all available font families
 * @param omitCustom - omit custom fonts
 */
export const getFontFamilies = (omitCustom = false): { value: string | null; text: string }[] => {
    const CUSTOM_FONTS = !omitCustom ? getCustomFonts() : []

    return [
        ...CUSTOM_FONTS,
        ...WEBSAFE_FONTS,
    ]
}

/**
 * Font sizes options
 */
export const FONT_SIZES = [
    {
        text: 'Extra large',
        value: '1.71em',
    },
    {
        text: 'Large',
        value: '1.28em',
    },
    {
        text: 'Normal',
        value: null,
    },
    {
        text: 'Small',
        value: '0.85em',
    },
]

/**
 * Header options
 */
export const HEADING_SIZES = [
    {
        value: 1,
        text: 'Heading 1',
    },
    {
        value: 2,
        text: 'Heading 2',
    },
    {
        value: 3,
        text: 'Heading 3',
    },
    {
        value: 4,
        text: 'Heading 4',
    },
] as const

/**
 * Colors we include in our color pickers / wysiwyg editors
 */
export const COLORS = [
    '#f8f8fb',
    '#d8d8d8',
    '#a0a0a0',
    '#686868',
    '#141414',
    '#dd614d',
    '#f3bc60',
    '#6bac54',
    '#4481c3',
]

export const CHART_COLOR = [
    '#1A3054',
    '#79D9BE',
    '#FAD461',
    '#F06A75',
    '#686868',
    '#d3e3f7',
    '#d8d8d8',
    '#6bac54',
    '#4381c3',
    '#f0a22c',
    '#434348',
    '#4481c3',
    '#17a2b8',
    '#f7a35c',
    '#f15c80',
    '#e4d354',
    '#90ed7d',
    '#2b908f',
    '#f45b5b',
    '#7cb5ec',
    '#91e8e1',
    '#8085e9',
    '#5a5a5a',
]

/**
 * Neutral color set, useful when we need to color charts etc
 * when branding colors aren't applicable.
 */
export const NEUTRAL_COLORS = [
    '#3E444E',
    '#526D82',
    '#9DB2BF',
    '#DDE6ED',
    '#D9EEFF',
] as const