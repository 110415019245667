import { hasFeatures, auth } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'learning',
    module: 'Learning',
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'learning.index',
        meta: {
            middleware: [
                auth('client', { name: '401' }),
                hasFeatures('AcademyManagement', { name: '401' }),
            ],
        },
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Learning" */
            './LearningIndex.vue'
        ),
    },
    {
        path: 'library',
        name: 'learning.library.index',
        meta: {
            middleware: [
                auth('client', { name: '401' }),
            ],
        },
        props: true,
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Learning" */
            './LearningLibrary.vue'
        ),
        children: [
            {
                path: 'events',
                name: 'learning.library.events',
                meta: {
                    middleware: [
                        hasFeatures('Course', { name: '401' }),
                    ],
                },
                props: true,
                redirect: { name: 'learning.library.events.index' },
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/Learning" */
                    './components/Library/LibraryEvents.vue'
                ),
                children: [
                    {
                        path: 'all',
                        name: 'learning.library.events.index',
                        component: (): Promise<any> => import(
                            /* webpackChunkName: "modules/Learning" */
                            './components/Library/LibraryEventsList.vue'
                        ),
                    },
                    {
                        path: 'schedule',
                        name: 'learning.library.events.session',
                        component: (): Promise<any> => import(
                            /* webpackChunkName: "modules/Learning" */
                            './components/Library/LibraryEventsSessions.vue'
                        ),
                    },
                ],
            },
            {
                path: 'learning-paths',
                name: 'learning.library.learningpaths',
                props: true,
                meta: {
                    middleware: [
                        hasFeatures('PublicLearning', { name: '401' }),
                    ],
                },
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/Learning" */
                    './components/Library/LibraryLearningPaths.vue'
                ),
            },
        ],
    },
    {
        path: 'assigned',
        name: 'learning.assigned',
        props: {
            mode: 'assigned',
        },
        meta: {
            middleware: [
                auth('client', { name: '401' }),
            ],
        },
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Learning" */
            './LearningAssigned.vue'
        ),
    },
    {
        path: 'events',
        name: 'learning.events.index',
        meta: {
            store: ['Courses'],
            middleware: [
                auth('client', { name: '401' }),
                hasFeatures('Course', { name: '401' }),
            ],
        },
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Courses" */
            '@modules/Courses/CoursesClientIndex.vue'
        ),
    },
    {
        path: 'events/:courseId',
        name: 'learning.events.details',
        props: true,
        meta: {
            store: ['Courses'],
            middleware: [
                auth('client', { name: '401' }),
                hasFeatures('Course', { name: '401' }),
            ],
        },
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Courses" */
            '@modules/Courses/CourseClientDetails.vue'
        ),
    },
    {
        path: 'journeys',
        name: 'learning.journeys',
        meta: {
            middleware: [
                auth('client', { name: '401' }),
            ],
        },
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Learning" */
            './LearningJourneys.vue'
        ),
    },
    {
        path: 'bookmarks',
        name: 'learning.bookmarks',
        meta: {
            middleware: [
                auth('client', { name: '401' }),
            ],
        },
        props: {
            mode: 'bookmarks',
        },
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Learning" */
            './LearningAssigned.vue'
        ),
    },
    {
        path: 'recent',
        name: 'learning.recent',
        meta: {
            middleware: [
                auth('client', { name: '401' }),
            ],
        },
        props: {
            mode: 'recent',
        },
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Learning" */
            './LearningAssigned.vue'
        ),
    },
    {
        path: 'plans',
        name: 'learning.developmentPlans.index',
        meta: {
            store: ['SkillsDeveloper'],
            middleware: [
                hasFeatures(['SkillsDeveloper', 'Checklists', 'ActivityPlans'], { name: '401' }, 'client'),
            ],
        },
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Learning" */
            './LearningDevelopmentPlans.vue'
        ),
    },
    {
        path: 'plans/:developmentPlanId',
        name: 'learning.developmentPlans.details',
        meta: {
            store: ['SkillsDeveloper'],
            middleware: [
                auth(['client', 'admin'], { name: '401' }),
                hasFeatures(['SkillsDeveloper', 'Checklists', 'ActivityPlans'], { name: '401' }, ['client', 'admin']),
            ],
        },
        props: (route): object => ({
            userId: route.query.userId,
            containerId: route.query.containerId,
        }),
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Courses" */
            '@modules/SkillsDeveloper/DevelopmentPlanDetails.vue'
        ),
    },

    {
        path: ':academyId',
        name: 'learning.path.index',
        meta: {
            hideAside: true,
            middleware: [
                auth('client', { name: '401' }),
                hasFeatures('AcademyManagement', { name: '401' }, 'client'),
            ],
        },
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Learning" */
            './LearningPathIndex.vue'
        ),
    },
])
