<template>
    <b-dropdown-item
        link-class="navigation-user-header navigation-item__menu__btn d-flex align-items-center"
        :disabled="!(hasClientAccess || userType !== 'lb')"
        :to="{ name: 'userProfile.index' }"
    >
        <avatar
            :value="authUser.image_path"
            :user-name="authUser.profile.combined_name"
        />
        <div class="ml-3 mb-0 line-height-sm">
            <p
                class="navigation-user-header__name mb-0"
                v-text="authUser.profile.combined_name"
            />
            <small
                v-if="hasClientAccess && userType !== 'lb'"
                class="navigation-user-header__link text-muted"
            >
                {{ $t('PROFILE.VIEW') }}
            </small>
        </div>
    </b-dropdown-item>
</template>

<script lang="ts">
    import Vue from 'vue'
    import { get } from 'vuex-pathify'
    import { BDropdownItem } from 'bootstrap-vue'
    import Avatar from '@common/Images/Avatar.vue'

    export default Vue.extend({
        components: {
            BDropdownItem,
            Avatar,
        },

        computed: {
            userType: get<'lb' | 'admin' | 'client'>('Auth/userType'),
            authUser: get<UserModel>('Auth/authUser'),
            hasClientAccess: get<boolean>('Auth/hasClientAccess'),
        },
    })
</script>

<style lang="scss" scoped>
    @import "@scss/vue.scss";
    .navigation-user-header {
        font-size: $font-size-base;
    }
</style>