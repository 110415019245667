<template>
    <btn
        variant="primary"
        emphasis="low"
        type="text"
        :icon="['fal', 'plus']"
        class="mb-0"
        :label="$t('COURSE.SESSION.ATTACH_FILE')"
        :disabled="disabled"
        @click="add"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import MaterialUploadModal from '@modules/Courses/components/MaterialUploadModal.vue'

    export default Vue.extend({
        props: {
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        methods: {
            async add(): Promise<void> {
                const files = await this.$dialog.modal(MaterialUploadModal)
                this.$emit('added', files)
            },
        },
    })
</script>
