import { Location } from 'vue-router'
import router from '@router'
import { extractPlaceholders, replacePlaceholders } from '@utils'

export enum Scopes {
    // Learners insights
    USERS = 'USERS',
    USER_MODULES = 'USER_MODULES',
    USER_LEARNINGPATHS = 'USER_LEARNINGPATHS',
    DIVISIONS = 'DIVISIONS',
    DIVISION_MODULES = 'DIVISION_MODULES',
    DIVISION_LEARNINGPATHS = 'DIVISION_LEARNINGPATHS',
    TITLES = 'TITLES',
    TITLE_MODULES = 'TITLE_MODULES',
    TITLE_LEARNINGPATHS = 'TITLE_LEARNINGPATHS',
    // Content insights
    MODULES = 'MODULES',
    MODULE_USERS = 'MODULE_USERS',
    MODULE_DIVISIONS = 'MODULE_DIVISIONS',
    MODULE_TITLES = 'MODULE_TITLES',
    CONTAINERS = 'CONTAINERS',
    CONTAINER_USERS = 'CONTAINER_USERS',
    CONTAINER_DIVISIONS = 'CONTAINER_DIVISIONS',
    CONTAINER_TITLES = 'CONTAINER_TITLES',
}

/**
 * Instructions on where to find url params (keys) in filter object
 */
export const PARAM_MAPPER: Readonly<Record<string, string>> = {
    userId: 'users',
    divisionId: 'divisions',
    titleId: 'titles',
    containerId: 'containers',
    moduleId: 'modules',
}

interface InsightScope {
    name: InsightsFilterScope;
    routeName: string;
    apiList: string;
    apiStats?: string;
    getLocation: <T extends InsightScope>(
        this: T,
        filters: Partial<InsightsParams>,
    ) => Location;
    getExportData: <T extends InsightScope>(
        this: T,
        filters: Partial<InsightsParams>,
    ) => { uri: string; params: Partial<InsightsParams> };
    getUriList: <T extends InsightScope>(
        this: T,
        params?: Record<keyof typeof PARAM_MAPPER, any>,
    ) => string;
    getUriStats: <T extends InsightScope>(
        this: T,
        params?: Record<keyof typeof PARAM_MAPPER, any>,
    ) => string | null;
}

/**
 * Create url params object by transforming filters.
 * - Used due to us storing uri parameters with in filter params 🤷‍♂️
 * - Updates filter object by removing the params not needed
 */
function extractParamsFromFilters(
    targetParams: string[],
    originalFilters: any,
): { params: Record<string, any>; filters: Partial<InsightsParams> } {
    const filters = { ...originalFilters }
    const params = targetParams.reduce((acc, item) => {
        const paramToConvert = PARAM_MAPPER[item]

        if (!paramToConvert) return acc

        acc[item] = Array.isArray(filters[paramToConvert])
            ? filters[paramToConvert][0]
            : filters[paramToConvert]

        // delete param from filter object after conversion
        delete filters[paramToConvert]

        return acc
    }, {} as Record<string, any>)

    return {
        params,
        filters,
    }
}

/**
 * Get built api list url by passing params
 */
function getUriList<T extends InsightScope>(
    this: T,
    params?: Record<keyof typeof PARAM_MAPPER, any>,
): string {
    return replacePlaceholders(this.apiList, params ?? {})
}

/**
 * Get built api list url by passing params
 */
function getUriStats<T extends InsightScope>(
    this: T,
    params?: Record<keyof typeof PARAM_MAPPER, any>,
): string | null {
    return this.apiStats ? replacePlaceholders(this.apiStats, params ?? {}) : null
}

/**
 * Get vue location object for scope based on filters
 */
function getLocation<T extends InsightScope>(
    this: T,
    filters: Partial<InsightsParams>,
): Location {
    const route = router.getRoutes().find((route) => route.name === this.routeName)
    const routeParams = extractPlaceholders(route?.path || '')
    const processed = extractParamsFromFilters(routeParams, filters)

    return {
        name: this.routeName,
        params: processed.params,
        query: processed.filters as Record<string, string>,
    }
}

/**
 * Get export url and params
 */
function getExportData<T extends InsightScope>(
    this: T,
    filters: Partial<InsightsParams>,
): { uri: string; params: Partial<InsightsParams> } {
    const params = extractPlaceholders(this.apiList)
    const processed = extractParamsFromFilters(params, filters)

    return {
        uri: replacePlaceholders(this.apiList, processed.params),
        params: processed.filters,
    }
}

/**
 * Export Insights scopes map singleton
 */
export const INSIGHT_SCOPES = ((): ReadonlyMap<InsightsFilterScope, InsightScope> => {
    const map = new Map<InsightsFilterScope, Partial<InsightScope>>([
        [Scopes.USERS, {
            routeName: 'insights.learners.users',
            apiList: 'api2/insights/users',
        }],
        [Scopes.USER_MODULES, {
            routeName: 'insights.learners.user.modules',
            apiList: 'api2/insights/users/:userId/modules',
            apiStats: 'api2/insights/users/:userId/modules/stats',
        }],
        [Scopes.USER_LEARNINGPATHS, {
            routeName: 'insights.learners.user.containers',
            apiList: 'api2/insights/users/:userId/containers',
            apiStats: 'api2/insights/users/:userId/containers/stats',
        }],
        [Scopes.DIVISIONS, {
            routeName: 'insights.learners.divisions',
            apiList: 'api2/insights/divisions',
        }],
        [Scopes.DIVISION_MODULES, {
            routeName: 'insights.learners.division.modules',
            apiList: 'api2/insights/divisions/:divisionId/modules',
            apiStats: 'api2/insights/divisions/:divisionId/modules/stats',
        }],
        [Scopes.DIVISION_LEARNINGPATHS, {
            routeName: 'insights.learners.division.containers',
            apiList: 'api2/insights/divisions/:divisionId/containers',
            apiStats: 'api2/insights/divisions/:divisionId/containers/stats',
        }],
        [Scopes.TITLES, {
            routeName: 'insights.learners.titles',
            apiList: 'api2/insights/titles',
        }],
        [Scopes.TITLE_MODULES, {
            routeName: 'insights.learners.title.modules',
            apiList: 'api2/insights/titles/:titleId/modules',
            apiStats: 'api2/insights/titles/:titleId/modules/stats',
        }],
        [Scopes.TITLE_LEARNINGPATHS, {
            routeName: 'insights.learners.title.containers',
            apiList: 'api2/insights/titles/:titleId/containers',
            apiStats: 'api2/insights/titles/:titleId/containers/stats',
        }],
        [Scopes.MODULES, {
            routeName: 'insights.content.modules',
            apiList: 'api2/insights/modules',
        }],
        [Scopes.MODULE_USERS, {
            routeName: 'insights.content.module.users',
            apiList: 'api2/insights/modules/:moduleId/users',
            apiStats: 'api2/insights/modules/:moduleId/users/stats',
        }],
        [Scopes.MODULE_DIVISIONS, {
            routeName: 'insights.content.module.divisions',
            apiList: 'api2/insights/modules/:moduleId/divisions',
            apiStats: 'api2/insights/modules/:moduleId/divisions/stats',
        }],
        [Scopes.MODULE_TITLES, {
            routeName: 'insights.content.module.titles',
            apiList: 'api2/insights/modules/:moduleId/titles',
            apiStats: 'api2/insights/modules/:moduleId/titles/stats',
        }],
        [Scopes.CONTAINERS, {
            routeName: 'insights.content.containers',
            apiList: 'api2/insights/containers',
        }],
        [Scopes.CONTAINER_USERS, {
            routeName: 'insights.content.container.users',
            apiList: 'api2/insights/containers/:containerId/users',
            apiStats: 'api2/insights/containers/:containerId/users/stats',
        }],
        [Scopes.CONTAINER_DIVISIONS, {
            routeName: 'insights.content.container.divisions',
            apiList: 'api2/insights/containers/:containerId/divisions',
            apiStats: 'api2/insights/containers/:containerId/divisions/stats',
        }],
        [Scopes.CONTAINER_TITLES, {
            routeName: 'insights.content.container.titles',
            apiList: 'api2/insights/containers/:containerId/titles',
            apiStats: 'api2/insights/containers/:containerId/titles/stats',
        }],
    ])

    return new Map<InsightsFilterScope, InsightScope>([...map.entries()].map(([key, value]) => {
        return [key, {
            ...value,
            name: key,
            getLocation,
            getExportData,
            getUriList,
            getUriStats,
        } as InsightScope]
    }))
})()
