<template>
    <validation-provider
        #default="{ invalid, valid, errors }"
        :name="name"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="name"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(errors)"
            :state="invalid ? valid : null"
        >
            <v-select
                ref="input"
                v-model="internalValue"
                :reduce="reduce"
                :options="options"
                :taggable="taggable"
                :multiple="multiple"
                :label="textField"
                :placeholder="placeholder"
                :get-option-label="getOptionLabel"
                :clearable="!availableRules.includes('required')"
                @option:created="onOptionCreated"
                @search:blur="onSearchBlur"
            >
                <template
                    v-for="(_, slot) of $scopedSlots"
                    #[slot]="scope"
                >
                    <slot
                        :name="slot"
                        v-bind="scope"
                    />
                </template>
            </v-select>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import { CreateElement, VNode } from 'vue'
    import SharedField from './mixin'
    import vSelect from 'vue-select'
    vSelect.props.components.default = (): object => ({
        Deselect: {
            render: (h: CreateElement): VNode => h('i', { class: 'fas fa-times-circle text-gray fa-sm' }),
        },
        OpenIndicator: {
            render: (h: CreateElement): VNode => h('i', { class: 'fas fa-caret-down fa-sm' }),
        },
    })

    export default SharedField.extend({
        components: { vSelect },
        props: {
            options: { type: Array, required: true },
            valueField: { type: String, default: null },
            textField: { type: String, default: 'text' },
            taggable: { type: Boolean, default: false },
            multiple: { type: Boolean, default: false },
            taggableLabel: { type: Function, default: (label: string): any => `Create option "${label}"` },
        },

        computed: {
            reduce(): any {
                if (this.valueField) {
                    return (option: any): any => option[this.valueField]
                }

                return (option: any): any => option
            },
        },

        methods: {
            onOptionCreated(option: any): void {
                this.$emit('option:created', option)
            },

            onSearchBlur(): void {
                this.$emit('search:blur', this.internalValue)
            },

            clearSelection(): void {
                const { input }: any = this.$refs
                input.clearSelection()
            },

            getOptionLabel(option: any): any {
                if (typeof option === 'object') {
                    return option[this.textField]
                }

                return this.taggable ? this.taggableLabel(option) : option
            },
        },
    })
</script>
