import { RouteConfig } from 'vue-router'

/**
 * Get all module routes by auto-loading
 * all route files in the module namespace
 */
function getModuleRoutes(): RouteConfig[] {
    const routeModules = import.meta.glob<RouteConfig[]>([
        '@modules/**/routes.ts',
        '@modules/**/routes/index.ts',
    ], {
        eager: true,
        import: 'default',
    })

    const routes: RouteConfig[] = []

    for (const path in routeModules) {
        routes.push(...routeModules[path])
    }

    return routes
}

export default getModuleRoutes()