import io, { Socket } from 'socket.io-client'

const SOCKET_URL = LB_ENV.common.serviceEndpoints.socket.public.url

class SocketPlugin {
    private static readonly SOCKET_HOST: string = SOCKET_URL
    private static readonly IDLE_TIMEOUT_DELAY_MS = 60 * 1000

    private socketConnection: Socket
    /** Must be uniquely defined per instance of {@link SocketPlugin}, is used as key for event-listeners */
    private focusEventListener: (this: Window, event: FocusEvent) => void
    /** Must be uniquely defined per instance of {@link SocketPlugin}, is used as key for event-listeners */
    private blurEventListener: (this: Window, event: FocusEvent) => void

    private user: UserModel | null = null
    private idleTimeout: NodeJS.Timeout | null = null


    /** Constructor of {@link SocketPlugin} */
    constructor() {
        /** Prepare the websocket connection */
        this.socketConnection = io(SocketPlugin.SOCKET_HOST, {
            autoConnect: false,
            transports: ['websocket'],
        })

        /** Set websocket event callback for "connect" (is also fired for re-connection) */
        this.socketConnection.on('connect', () => {
            if (!this.user) return
            this.socketConnection.emit('client-connect', this.user.id, this.user.organization_id)
        })

        /** Define the focus-event which is fired when the window gain focus */
        this.focusEventListener = (): void => {
            if (this.idleTimeout) {
                clearTimeout(this.idleTimeout)
                this.idleTimeout = null
            }

            if (this.socketConnection.connected) return

            this.socketConnection.open()
        }

        /** Define the blur-event which is fired when the window loose focus */
        this.blurEventListener = (): void => {
            if (this.idleTimeout) return

            this.idleTimeout = setTimeout(() => this.socketConnection.disconnect(), SocketPlugin.IDLE_TIMEOUT_DELAY_MS)
        }
    }

    get instance(): Socket {
        return this.socketConnection
    }

    connect(user: UserModel): void {
        this.user = user
        if (document.hasFocus()) this.socketConnection.open()
        this.setEventListeners()
    }

    disconnect(): void {
        this.socketConnection.disconnect()
        this.removeEventListeners()

        if (this.idleTimeout) {
            clearTimeout(this.idleTimeout)
            this.idleTimeout = null
        }
    }

    private setEventListeners(): void {
        window.addEventListener('focus', this.focusEventListener)
        window.addEventListener('blur', this.blurEventListener)
    }

    private removeEventListeners(): void {
        window.removeEventListener('focus', this.focusEventListener)
        window.removeEventListener('blur', this.blurEventListener)
    }
}

const socketPlugin = new SocketPlugin()

export default socketPlugin