<template>
    <base-modal
        centered
        scrollable
        no-cancel
    >
        <template #header>
            <div>
                <h5 class="mb-1">
                    {{ $t('JOURNEY.CONTENT.LOCKED.TITLE') }}
                </h5>
                <p
                    class="text-muted mb-0"
                    v-text="$t('JOURNEY.CONTENT.LOCKED.INFO')"
                />
            </div>
        </template>

        <!-- Loading skeleton -->
        <div
            v-if="busy"
            class="skeleton-container card border-0"
        >
            <div class="row no-gutters">
                <div class="col-5 col-md-4">
                    <div class="skeleton-img embed-responsive embed-responsive-16by9 rounded-left" />
                </div>
                <div class="col pl-3 pt-3">
                    <small><span class="skeleton-text w-25" /></small>
                    <div><span class="skeleton-text w-50" /></div>
                </div>
            </div>
        </div>

        <!-- Dependency list -->
        <template v-else>
            <learning-card
                v-for="(item, key) in parents"
                :key="key"
                :class="{ 'mb-5': key !== parents.length - 1 }"
                :item="item"
                :clickable="item.context.status !== 'locked'"
                :horizontal="{
                    before: 'col-5 col-md-3',
                    row: 'align-items-center',
                }"
                @click.capture.native="onClick($event, item)"
            />
        </template>

        <template #ok-btn="{ resolve }">
            <btn
                variant="primary"
                :label="$t('TIPS.GOT_IT')"
                @click="resolve"
            />
        </template>
    </base-modal>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { DialogComponent } from 'vue-modal-dialogs'
    import { BaseModal } from '@common/Modals'
    import LearningStatusMixin from '@modules/Learning/mixins/learning-status.mixin'
    import LearningCard from '@modules/Learning/components/LearningCard.vue'

    export default (Vue as VueConstructor<
        Vue &
        DialogComponent<void> &
        InstanceType<typeof LearningStatusMixin>
    >).extend({
        components: {
            BaseModal,
            LearningCard,
        },

        mixins: [LearningStatusMixin],

        props: {
            value: {
                type: Object as PropType<LearningModel<'container' | 'module' | 'development-plan'>>,
                required: true,
            },
            groupId: {
                type: Number as PropType<number>,
                required: true,
            },
        },

        data() {
            return {
                busy: false,
                parents: [] as (LearningModel<'container' | 'module' | 'development-plan'> & { learningStatus: ModuleStatusData | null })[],
            }
        },

        created() {
            this.fetchData()
        },

        methods: {
            async fetchData(): Promise<void> {
                this.busy = true

                try {
                    await this.$store.load('Journeys')
                    const data = await this.$store.dispatch('Journeys/getContentParents', {
                        groupId: this.groupId,
                        contentType: this.value.type,
                        contentId: this.value.data.id,
                    })

                    this.setParents(data)
                } catch (e) {
                    this.$flash('danger', this.$t('ERROR.SOMETHING_WENT_WRONG'))
                }
                finally {
                    this.busy = false
                }
            },

            setParents(items: LearningModel<'container' | 'module' | 'development-plan'>[]): void {
                this.parents = items.map((item) => ({
                    ...item,
                    learningStatus: this.getModuleStatusData(item.context),
                }))
            },

            onClick(event: PointerEvent, learning: LearningModel<'module' | 'container' | 'development-plan'>): void {
                if (learning.context.status === 'locked')
                    return event.stopPropagation()

                this.$close()
            },
        },
    })
</script>

<style lang="scss" scoped>
    ::v-deep .modal-content {
        max-height: 50% !important;
    }
</style>
