import { Module } from 'vuex'
import { make } from 'vuex-pathify'
import Http from '@utils/Http'


const state: WhitelabelsFontsStoreState = {
    fonts: [],
}
const store: Module<WhitelabelsFontsStoreState, any> = {
    namespaced: true,
    state,
    getters: {
        fontsOrdered(state): WhitelabelFontModel[] {
            return [...state.fonts].sort((a, b) => a.fullName.localeCompare(b.fullName))
        },
    },

    mutations: {
        ...make.mutations(state),

        ADD_FONTS(state, payload: WhitelabelFontModel[]): void {
            state.fonts.push(...payload)
        },

        DESTROY_FONT(state, payload: WhitelabelFontModel): void {
            const index = state.fonts
                .findIndex(({ id }) => id === payload.id)

            state.fonts.splice(index, 1)
        },

        UPDATE_FONT(state, payload: WhitelabelFontModel): void {
            const index = state.fonts.findIndex(({ id }) => id === payload.id)

            state.fonts.splice(index, 1, payload)
        },
    },

    actions: {
        /**
         * Get all fonts
         */
        async list({ commit }, params: object): Promise<PaginationResponse<WhitelabelFontModel[]>> {
            const { data } = await Http.api().get(`api2/whitelabel/fonts`, {
                params,
            })

            commit('SET_FONTS', data.data)

            return data
        },

        /**
         * Create fonts from file
         */
        async create(
            { commit, dispatch },
            payload: { file: File; overwrite: boolean },
        ): Promise<WhitelabelFontsCreateResponse> {
            if (!payload.file)
                throw new Error(`[Whitelabels/Fonts/create] Missing file`)

            const formData = new FormData()
            formData.append('font', payload.file)

            const { data } = await Http.api().post<WhitelabelFontsCreateResponse>(`api2/whitelabel/fonts`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    overwrite: payload.overwrite ? '1' : null,
                },
            })

            dispatch('AppConfig/loadFonts', data.success, { root: true })
            dispatch('AppConfig/sync', null, { root: true })
            commit('ADD_FONTS', data.success)

            return data
        },

        /**
         * Archive font
         */
        async archive({ commit, dispatch }, payload: WhitelabelFontModel): Promise<WhitelabelFontModel> {
            if (!payload.id)
                throw new Error(`[Whitelabels/Fonts/archive] missing id`)

            const { data } = await Http.api().patch(`api2/whitelabel/fonts/${payload.id}`, {
                archived: !payload.archived,
            })
            commit('UPDATE_FONT', data)
            dispatch('AppConfig/sync', null, { root: true })

            return data
        },

        /**
         * Delete font
         */
        async destroy({ commit, dispatch }, payload: WhitelabelFontModel): Promise<void> {
            if (!payload.id)
                throw new Error(`[Whitelabels/Fonts/destroy] missing id`)

            await Http.api().delete(`api2/whitelabel/fonts/${payload.id}`)

            commit('DESTROY_FONT', payload)
            dispatch('AppConfig/sync', null, { root: true })
        },
    },
}

export default store