export const faFlipH = {
    prefix: 'fac',
    iconName: 'flip-h',
    icon: [
        16, 16,
        [],
        null,
        'M15.5 1.2954C15.5 0.800178 14.9998 0.461545 14.54 0.645468L0.778536 6.15006C0.0629183 6.43631 0.267774 7.5 1.03851 7.5H14.8C15.1866 7.5 15.5 7.18659 15.5 6.8V1.2954Z M14.5 14.2615L2.59629 9.5H14.5V14.2615ZM14.54 15.3545C14.9998 15.5384 15.5 15.1998 15.5 14.7046V9.2C15.5 8.8134 15.1866 8.5 14.8 8.5H1.03852C0.267778 8.5 0.0629269 9.56369 0.778543 9.84993L14.54 15.3545Z',
    ],
}

export const faFlipV = {
    prefix: 'fac',
    iconName: 'flip-v',
    icon: [
        16, 16,
        [],
        null,
        'M14.7046 15.5C15.1998 15.5 15.5384 14.9998 15.3545 14.54L9.84993 0.778536C9.56368 0.0629184 8.5 0.267774 8.5 1.03851V14.8C8.5 15.1866 8.8134 15.5 9.2 15.5H14.7046Z M1.73852 14.5L6.5 2.59629L6.5 14.5L1.73852 14.5ZM0.645472 14.54C0.46155 14.9998 0.800181 15.5 1.29541 15.5L6.8 15.5C7.1866 15.5 7.5 15.1866 7.5 14.8L7.5 1.03852C7.5 0.267779 6.43631 0.0629274 6.15007 0.778543L0.645472 14.54Z',
    ],
}

export const faRatio1by1 = {
    prefix: 'fac',
    iconName: '1:1',
    icon: [
        16, 16,
        [],
        null,
        'M13.5 2H2.5C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2ZM2.5 1H13.5C14.3284 1 15 1.67157 15 2.5V13.5C15 14.3284 14.3284 15 13.5 15H2.5C1.67157 15 1 14.3284 1 13.5V2.5C1 1.67157 1.67157 1 2.5 1Z',
    ],
}

export const faRatio4by3 = {
    prefix: 'fac',
    iconName: '4:3',
    icon: [
        16, 16,
        [],
        null,
        'M14.5 3H1.5C1.22386 3 1 3.22386 1 3.5V12.5C1 12.7761 1.22386 13 1.5 13H14.5C14.7761 13 15 12.7761 15 12.5V3.5C15 3.22386 14.7761 3 14.5 3ZM1.5 2H14.5C15.3284 2 16 2.67157 16 3.5V12.5C16 13.3284 15.3284 14 14.5 14H1.5C0.671573 14 0 13.3284 0 12.5V3.5C0 2.67157 0.671573 2 1.5 2Z',
    ],
}

export const faRatio3by4 = {
    prefix: 'fac',
    iconName: '3:4',
    icon: [
        16, 16,
        [],
        null,
        'M13 14.5L13 1.5C13 1.22386 12.7761 1 12.5 1L3.5 1C3.22386 1 3 1.22386 3 1.5L3 14.5C3 14.7761 3.22386 15 3.5 15L12.5 15C12.7761 15 13 14.7761 13 14.5ZM14 1.5L14 14.5C14 15.3284 13.3284 16 12.5 16L3.5 16C2.67157 16 2 15.3284 2 14.5L2 1.5C2 0.671572 2.67157 -4.95181e-07 3.5 -4.5897e-07L12.5 -6.55671e-08C13.3284 -2.93554e-08 14 0.671573 14 1.5Z',
    ],
}

export const faRatio3by2 = {
    prefix: 'fac',
    iconName: '3:2',
    icon: [
        16, 16,
        [],
        null,
        'M14.5 4H1.5C1.22386 4 1 4.22386 1 4.5V11.5C1 11.7761 1.22386 12 1.5 12H14.5C14.7761 12 15 11.7761 15 11.5V4.5C15 4.22386 14.7761 4 14.5 4ZM1.5 3H14.5C15.3284 3 16 3.67157 16 4.5V11.5C16 12.3284 15.3284 13 14.5 13H1.5C0.671573 13 0 12.3284 0 11.5V4.5C0 3.67157 0.671573 3 1.5 3Z',
    ],
}

export const faRatio2by3 = {
    prefix: 'fac',
    iconName: '2:3',
    icon: [
        16, 16,
        [],
        null,
        'M12 14.5L12 1.5C12 1.22386 11.7761 1 11.5 1L4.5 1C4.22386 1 4 1.22386 4 1.5L4 14.5C4 14.7761 4.22386 15 4.5 15L11.5 15C11.7761 15 12 14.7761 12 14.5ZM13 1.5L13 14.5C13 15.3284 12.3284 16 11.5 16L4.5 16C3.67157 16 3 15.3284 3 14.5L3 1.5C3 0.671573 3.67157 -4.07759e-07 4.5 -3.71547e-07L11.5 -6.55671e-08C12.3284 -2.93554e-08 13 0.671573 13 1.5Z',
    ],
}

export const faRatio16by9 = {
    prefix: 'fac',
    iconName: '16:9',
    icon: [
        16, 16,
        [],
        null,
        'M14.5 5H1.5C1.22386 5 1 5.22386 1 5.5V10.5C1 10.7761 1.22386 11 1.5 11H14.5C14.7761 11 15 10.7761 15 10.5V5.5C15 5.22386 14.7761 5 14.5 5ZM1.5 4H14.5C15.3284 4 16 4.67157 16 5.5V10.5C16 11.3284 15.3284 12 14.5 12H1.5C0.671573 12 0 11.3284 0 10.5V5.5C0 4.67157 0.671573 4 1.5 4Z',
    ],
}

export const faRatio9by16 = {
    prefix: 'fac',
    iconName: '9:16',
    icon: [
        16, 16,
        [],
        null,
        'M11 14.5L11 1.5C11 1.22386 10.7761 1 10.5 1L5.5 1C5.22386 1 5 1.22386 5 1.5L5 14.5C5 14.7761 5.22386 15 5.5 15L10.5 15C10.7761 15 11 14.7761 11 14.5ZM12 1.5L12 14.5C12 15.3284 11.3284 16 10.5 16L5.5 16C4.67157 16 4 15.3284 4 14.5L4 1.5C4 0.671573 4.67157 -3.20336e-07 5.5 -2.84124e-07L10.5 -6.55671e-08C11.3284 -2.93554e-08 12 0.671573 12 1.5Z',
    ],
}

export const faGripVertical = {
    prefix: 'fac',
    iconName: 'grip-vertical',
    icon: [
        16, 16,
        [],
        null,
        'M5 7.4C5 7.17909 5.17909 7 5.4 7H6.6C6.82091 7 7 7.17909 7 7.4V8.6C7 8.82091 6.82091 9 6.6 9H5.4C5.17909 9 5 8.82091 5 8.6V7.4Z M9 7.4C9 7.17909 9.17909 7 9.4 7H10.6C10.8209 7 11 7.17909 11 7.4V8.6C11 8.82091 10.8209 9 10.6 9H9.4C9.17909 9 9 8.82091 9 8.6V7.4Z M5 3.4C5 3.17909 5.17909 3 5.4 3H6.6C6.82091 3 7 3.17909 7 3.4V4.6C7 4.82091 6.82091 5 6.6 5H5.4C5.17909 5 5 4.82091 5 4.6V3.4Z M9 3.4C9 3.17909 9.17909 3 9.4 3H10.6C10.8209 3 11 3.17909 11 3.4V4.6C11 4.82091 10.8209 5 10.6 5H9.4C9.17909 5 9 4.82091 9 4.6V3.4Z M5 11.4C5 11.1791 5.17909 11 5.4 11H6.6C6.82091 11 7 11.1791 7 11.4V12.6C7 12.8209 6.82091 13 6.6 13H5.4C5.17909 13 5 12.8209 5 12.6V11.4Z M9 11.4C9 11.1791 9.17909 11 9.4 11H10.6C10.8209 11 11 11.1791 11 11.4V12.6C11 12.8209 10.8209 13 10.6 13H9.4C9.17909 13 9 12.8209 9 12.6V11.4Z',
    ],
}

