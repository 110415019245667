export * from './hasFeatures'
export * from './hasPermissions'

/**
 * Get user type string value by userTypeId
 */
export const USER_TYPE_MAP = new Map([
    [1, 'lb'],
    [2, 'admin'],
    [3, 'client'],
]) as ReadonlyMap<number, UserType>

/**
 * Available nonce types
 */
export const NONCE_TYPE = {
    ACTIVATION: 'activation',
    PASSWORD_RESET: 'passwordReset',
} as Readonly<Record<string, string>>

/**
 * Get userType from User model.
 */
export const getUserType = (user: UserModel | null): UserType | null => {
    const superTypeId = user?.user_type.super_type_id

    return USER_TYPE_MAP.get(superTypeId) ?? null
}

/**
 * Special userType getter to determine which active userType to set
 * after logging in.
 */
export function getLoginUserType(user: UserModel | null): UserType | null {
    const userType = getUserType(user)

    if (userType === 'admin' && !user?.user_type.super_user)
        return 'client'

    return userType
}