<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            class="input-field"
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <b-input-group :size="size">
                <!--
                    @slot Input group prepend content.
                    Please use `<b-input-group-prepend />` as imitate child of the slot
                    to get the correct styling when combining with buttons or other
                    compatable compinents
                 -->
                <slot name="prepend" />

                <!--
                    Special addon
                    Prepended Icon on certain input types
                -->
                <b-input-group-prepend
                    v-if="prependIconLocal"
                    class="input-group-prepend-special"
                    is-text
                >
                    <fa
                        fixed-width
                        :class="iconClass"
                        :icon="['fal', prependIconLocal]"
                    />
                </b-input-group-prepend>

                <!-- Input field -->
                <b-form-input
                    ref="input"
                    v-model="internalValue"
                    :class="inputClass"
                    :autofocus="autofocus"
                    :maxlength="maxLength"
                    :inputmode="getInputMode"
                    :placeholder="placeholder"
                    :debounce="debounce"
                    :type="type"
                    :number="number"
                    :name="nameLocal"
                    :disabled="disabled"
                    :size="size"
                    @blur="onBlur"
                    @keyup.enter.stop="onEnter"
                />

                <!--
                    Content from append prop rendered as special addon
                 -->
                <b-input-group-append
                    v-if="append"
                    class="input-group-append-special"
                    is-text
                >
                    {{ append }}
                </b-input-group-append>

                <!--
                    Character length display as special addon
                 -->
                <b-input-group-append
                    v-if="maxChars"
                    is-text
                    class="input-group-append-special"
                >
                    <small
                        class="text-smaller"
                        v-text="`${charsLeft}/${maxChars}`"
                    />
                </b-input-group-append>

                <!--
                    Special addon.
                    Clear button on clearable inputs
                -->
                <b-input-group-append
                    v-if="internalValue && clearable"
                    class="input-group-append-special pointer"
                    is-text
                    @click="clear"
                >
                    <fa
                        size="sm"
                        :icon="['fal', 'times']"
                    />
                </b-input-group-append>

                <b-input-group-append
                    v-if="hasAppendIconSlot"
                    class="input-group-append-special"
                    is-text
                >
                    <slot name="append-icon" />
                </b-input-group-append>

                <!--
                    @slot Input group append content.
                    Please use `<b-input-group-append />` as imitate child of the slot
                    to get the correct styling when combining with buttons or other
                    compatable compinents
                 -->
                <slot name="append" />
            </b-input-group>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'
    import MaxLengthMixin from './maxlength.mixin'
    import { BInputGroup, BFormInput } from 'bootstrap-vue'

    interface Refs {
        $refs: {
            input: InstanceType<typeof BFormInput>;
        };
    }

    const inputTypeIconMap = {
        email: 'envelope',
        number: null,
        password: 'key',
        search: 'search',
        tel: 'phone',
        text: null,
        url: null,
        time: null,
    } as any

    const inputModes: StringMap = {
        email: 'email',
        number: 'numeric',
        search: 'search',
        tel: 'tel',
        url: 'url',
    }

    /**
     * Multi purpose input field.
     *
     * * Supports different input types with icon support
     * * Validation baked in
     * * Input group prepend and append slots available
     * * When using the `max` rule the input will display a character counter
     * * When using the `maxlength` prop, the input will not display the counter
     *
     * @example ./__docs__/InputField.examples.md
     */
    export default (Vue as VueConstructor<Vue
        & InstanceType<typeof SharedField>
        & InstanceType<typeof MaxLengthMixin>
        & Refs
    >).extend({
        components: {
            BInputGroup,
            BFormInput,
        },

        mixins: [SharedField, MaxLengthMixin],

        props: {
            /**
             * Type of input
             *
             * @values email, number, password, search, tel, text, url, time
             */
            type: {
                type: String as PropType<string>,
                default: 'text',
                validator: (value: string): boolean => Object.keys(inputTypeIconMap).includes(value),
            },

            /**
             * Input mode property, if not set then it will select
             * an input type based on `type` prop
             */
            inputmode: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Debounce input event in ms
             */
            debounce: {
                type: Number as PropType<number>,
                default: 0,
            },

            /**
             * Display a font awesome icon with in input on the left side.
             * This will overwrite the default icon based on input type
             * as well as overwrite the `noIcon` prop.
             */
            prependIcon: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Disable any icons for input
             */
            noIcon: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Show a clear icon in input on click will clear the input
             */
            clearable: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Append text on input
             */
            append: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Focus the input field when the value changes to true
             */
            focus: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * When set attempts to convert the input value to a native number.
             * Emulates the Vue '.number' v-model modifier
             */
            number: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            hasPrependSlot(): boolean {
                return !!this.$slots['prepend'] || !!this.$scopedSlots['prepend']
            },

            hasAppendSlot(): boolean {
                return !!this.$slots['append'] || !!this.$scopedSlots['append']
            },

            hasAppendIconSlot(): boolean {
                return !!this.$slots['append-icon'] || !!this.$scopedSlots['append-icon']
            },

            getInputMode(): string {
                return this.inputmode || (inputModes[this.type] ?? 'text')
            },

            prependIconLocal(): string | null {
                if (this.prependIcon) {
                    return this.prependIcon
                }

                if (this.noIcon || !inputTypeIconMap[this.type]) {
                    return null
                }

                return inputTypeIconMap[this.type]
            },
        },

        watch: {
            focus: {
                handler(value): void {
                    if (value) this.$nextTick(this.$refs.input.focus)
                },
            },
        },

        methods: {
            clear(): void {
                this.internalValue = ''
                this.$emit('clear')
            },

            onBlur(event: FocusEvent): void {
                /**
                 * Triggers when user blurs the input
                 *
                 * @property {FocusEvent} event focus event
                 */
                this.$emit('blur', event)
            },

            onEnter(event: KeyboardEvent): void {
                if (this.$listeners.enter) {
                    /**
                     * Triggers when user hits enter when focused in input
                    *
                    * @property {FocusEvent} event focus event
                    */
                    this.$emit('enter', event);
                    (event.target as HTMLTextAreaElement).blur()
                }
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .input-group ::v-deep {
        // Set bg to white for special addons
        .input-group-prepend-special .input-group-text,
        .input-group-append-special .input-group-text {
            @include transition($input-transition);
            background-color: $input-bg;
            // Match the background color on special addons to inputs color when disabled
            [disabled='disabled'] & {
                background-color: $input-disabled-bg;
            }
        }

        // Style prepend icon as it was inside input
        .input-group-prepend-special .input-group-text {
            z-index: 5;
            padding-right: 0;
            border-right: none;
        }

        // Style clear icon as it was inside input
        .input-group-append-special .input-group-text {
            z-index: 5;
            padding-left: 0;
            border-left: none;
            color: color('gray');

            &:hover .svg-inline--fa {
                color: $body-color;
            }
        }

        .input-group-prepend .dropdown .dropdown-toggle {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        .input-group-append .dropdown .dropdown-toggle {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }

    // Add focus color on special addons
    .input-group:focus-within ::v-deep {
        .input-group-prepend-special .input-group-text,
        .input-group-append-special .input-group-text {
            border-color: $input-focus-border-color;
        }
    }
</style>
