import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
    methods: {
        getLanguage(value: Language, translate: boolean): TranslateResult {
            const translated = this.$t(`LANGUAGE.ISO.${value.lang.toUpperCase()}`)
            if (translate)
                return translated

            return value.langNative || translated
        },

        getCountry(value: Language, translate: boolean, format?: 'short' | 'long'): TranslateResult {
            if (format === 'short')
                return `${value.country.toUpperCase()}`

            const translated = this.$t(`LANGUAGE.ISO.${value.lang.toUpperCase()}`)
            if (translate)
                return translated

            return `(${value.countryNative || translated})`
        },

        getLangCountry(value: Language, translate: boolean, format: CountryFormatTypes = 'long'): string {
            const delimiter = this.getDelimiter(format)
            const translatedLanguage = this.getLanguage(value, translate)
            const translatedCountry = this.getCountry(value, translate, format)

            return `${translatedLanguage}${delimiter}${translatedCountry}`
        },

        getDelimiter(format?: CountryFormatTypes): string {
            if (format && format === 'short')
                return ', '

            return ' '
        },
    },
})
