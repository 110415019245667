<template>
    <div
        class="flex-grow-1"
        :class="mainClasses"
    >
        <slot />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { NAV_TYPES, CONTAINER_TYPES, CONTAINER_TYPE_CLASS_MAP } from './utils'

    type NavTypes = typeof NAV_TYPES[number]
    type ContainerTypes = typeof CONTAINER_TYPES[number]

    export default Vue.extend({
        props: {
            /**
             * Navigation type, defaults to no navigation.
             */
            nav: {
                type: String as PropType<NavTypes>,
                default: null,
                validator: (value: NavTypes): boolean => NAV_TYPES.includes(value),
            },
            /**
             * Layout container type
             */
            container: {
                type: String as PropType<ContainerTypes>,
                default: null,
                validator: (value: ContainerTypes): boolean => CONTAINER_TYPES.includes(value),
            },
            /**
             * Background variant
             */
            bg: {
                type: String as PropType<string>,
                default: null,
            },
        },

        computed: {
            mainClasses(): string[] | undefined {
                const classes: string[] = []
                const containerClass = CONTAINER_TYPE_CLASS_MAP[this.container]

                if (containerClass)
                    classes.push(containerClass, 'py-6')

                return classes
            },
        },

        watch: {
            nav: 'emitLayout',
            container: 'emitLayout',
            bg: 'emitLayout',
        },

        created() {
            this.emitLayout()
        },

        methods: {
            emitLayout(): void {
                this.$parent?.$emit('input', {
                    nav: this.nav,
                    container: this.container,
                    bg: this.bg,
                })
            },
        },
    })
</script>