import { cloneDeep } from 'lodash'
import { validator } from '@learningbank/lb-utils'

/**
 * Remove properties with falsy values in any object.
 *
 * @param object
 */
export function removeFalsy(object: object): object {
    return Object.entries(object)
        .reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {})
}

/**
 * Recursively prune empty values from object
 *
 * Removes
 *  - Null values
 *  - Undefined values
 *  - Empty string values
 *  - Empty Objects
 *  - Empty Arrays
 *
 * @todo Add some sort of configuration option to choose what you want to prune
 *       fx everything above can be optional, plus adding "falsy" values option etc.
 * @param obj
 */
export function pruneEmpty(data: Record<string, any> | any[]): Record<string, any> | any[] {
    const prune = (current: any, parentKey?: string, parent?: any): Record<string, any> => {
        Object.entries(current).forEach(([key, value]: any) => {
            if (
                value === undefined ||
                value === null ||
                (typeof value === 'string' && !value.length) ||
                (
                    typeof value === 'object' &&
                    value !== null &&
                    !Object.keys(prune(value, key, current)).length &&
                    !validator.isDate(value)
                )
            ) delete current[key]
        })

        // remove any leftover undefined values from the delete operation on an array
        if (Array.isArray(current)) {
            const currentClean: any[] = []
            current.forEach((v: any) => currentClean.push(v))

            if (parent && parentKey)
                parent[parentKey] = currentClean
            else
                return currentClean
        }

        return current
    }

    // Do not modify the original object, create a clone instead
    return prune(cloneDeep(data))
}

/**
 * Unflatten objects to a nested object based on delimiter in keys.
 *
 * @param obj Object to unflatten
 * @param delimiter Delimiter substring in object keys
 */
export function unflattenObject(obj: Record<string, any>, delimiter = '.'): object {
    const result: any = {}

    // For each object path (property key) in the object
    for (const objectPath in obj) {
        // Split path into component parts
        const parts = objectPath.split(delimiter)

        // Create sub-objects along path as needed
        let target = result
        while (parts.length > 1) {
            const part: any = parts.shift()
            target = target[part] = target[part] || {}
        }

        // Set value at end of path
        target[parts[0]] = obj[objectPath]
    }

    return result
}

/**
 * Create object based on path
 */
export function createObjectFromPath(path: string, value: any): Record<string, any> {
    const keys: string[] = path.split('.')
    const lastKey: string = keys.pop() || ''

    const obj: Record<string, any> = {}
    let currentObj: Record<string, any> = obj

    keys.forEach((key) => {
        currentObj[key] = {}
        currentObj = currentObj[key]
    })

    currentObj[lastKey] = value

    return obj
}