import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: '',
    module: 'Modules',
    middleware: [
        auth(['admin', 'lb'], { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: 'modules',
        name: 'modules.index',
        meta: {
            middleware: [
                hasFeatures('ModuleMaker', { name: '401' }),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Modules" */
                './ModulesIndex.vue'
            ),
    },
    {
        path: 'modules/overview',
        name: 'modules.overview.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Modules" */
                './ModulesIndex.vue'
            ),
    },
    {
        path: 'modules/:moduleId/overview/survey',
        name: 'modules.responses.overview.survey',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Modules" */
                './components/overview/ModulesOverview.vue'
            ),
    },

])
