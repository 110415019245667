import { CharacterCount, CharacterCountOptions as CharacterCountOptionsDefault } from '@tiptap/extension-character-count'

export interface CharacterCountOptions extends CharacterCountOptionsDefault {
    show: boolean;
}

export default CharacterCount.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            show: true,
        }
    },
})
