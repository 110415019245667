<template>
    <div class="data-table">
        <table-header
            v-if="isSearchable || $slots.header"
            v-model="queryLocal"
            :searchable="isSearchable"
            :custom-area-class="headerClass"
        >
            <!-- @slot Primary header slot -->
            <slot name="header" />
        </table-header>

        <!--
            @slot Secondary header slot
            - Useful to display custom selection component
            @binding {function} clearSelection method that clears selection
        -->
        <slot
            name="header-secondary"
            :clear-selection="clearSelection"
        />

        <!-- Table component -->
        <div
            :class="{
                'table-card': card,
                'table-vcenter' : centerV
            }"
        >
            <b-table
                :id="id"
                ref="table"
                :class="tableClass"
                :style="{ minHeight: minHeight }"
                :fields="tableFields"
                :items="itemsLocal"
                :filter="query"
                :per-page="perPage"
                :current-page="currentPage"
                :responsive="true"
                :show-empty="!hideEmpty"
                :tbody-tr-class="rowClass"
                :hover="hover"
                :busy="busy"
                sort-icon-left
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                v-on="$listeners"
            >
                <!-- Default table headers overwrite when fields can be hidden -->
                <template
                    v-if="shouldSaveColumnState || $slots['table-config-dropdown']"
                    #head()="{ label, field }"
                >
                    <span
                        v-if="lastFieldKey === field.key"
                        class="d-flex"
                    >
                        {{ label }}
                        <table-config-dropdown class="ml-auto pl-2">
                            <slot name="table-config-dropdown" />
                            <table-column-visibility
                                v-if="shouldSaveColumnState"
                                v-model="fieldsLocal"
                                :table-id="id"
                            />
                        </table-config-dropdown>
                    </span>
                </template>
                <!-- Checkbox in column header to select all -->
                <template
                    v-if="!disableSelectAll"
                    #head(select)
                >
                    <b-form-checkbox
                        v-model="selectAll"
                        @input="onSelectAll"
                    />
                </template>
                <!-- Checkboxes on each row when table is selectable -->
                <template #cell(select)="{ item }">
                    <b-form-checkbox
                        v-if="!item._selectable"
                        v-model="item._selected"
                        :disabled="selectAll"
                        @change="checked => onRowSelect(checked, item)"
                    />
                </template>
                <!-- Render table-column's header templates -->
                <template
                    v-for="(field, index) in headerTemplateFields"
                    #[`head(${field.key})`]="data"
                >
                    <table-header-cell
                        :key="index"
                        :data="data"
                    />
                </template>
                <!-- Render table-column templates -->
                <template
                    v-for="(field, index) in templateFields"
                    #[`cell(${field.key})`]="data"
                >
                    <table-cell
                        :key="index"
                        :data="data"
                    />
                </template>
                <template #empty="{ fields, items }">
                    <!--
                        @slot Display when table is empty
                        @binding {array} fields The normalized fields definition array (in the array of objects format)
                        @binding {array} items The items array. Exposed here to check null vs []
                    -->
                    <slot
                        name="empty"
                        :items="items"
                        :fields="fields"
                    >
                        <table-empty-state-default
                            :title="$t('TERMS.EMPTY_STATE_DATA')"
                            :message="$t('TERMS.EMPTY_DATA_DETAILS')"
                        />
                    </slot>
                </template>
                <template #emptyfiltered="{ fields, items }">
                    <!--
                        @slot Display when table is empty and table has been filtered
                        @binding {array} fields The normalized fields definition array (in the array of objects format)
                        @binding {array} items The items array. Exposed here to check null vs []
                    -->
                    <slot
                        name="emptyquery"
                        :items="items"
                        :fields="fields"
                    >
                        <table-empty-state-default
                            :title="$t('TERMS.FILTERS_NO_RESULTS')"
                            :message="$t('TERMS.FILTERS_NO_RESULTS_DETAILS')"
                        />
                    </slot>
                </template>
                <template
                    v-for="(_, slot) of $scopedSlots"
                    #[slot]="scope"
                >
                    <slot
                        :name="slot"
                        v-bind="scope"
                    />
                </template>
                <template
                    #table-busy
                >
                    <table-busy-empty-state :per-page="perPage" />
                </template>

                <!-- Table footer -->
                <template #custom-foot="scope">
                    <table-footer
                        :scope="scope"
                        :per-page.sync="perPage"
                        :current-page.sync="currentPage"
                        :total-items="filteredItems.length"
                        :selection-length="selectAll ? totalItems : selectedItems.length"
                    />
                </template>
            </b-table>
        </div>

        <!-- Required to get Component instances from column components -->
        <span style="display: none;">
            <!--
                @slot Collection of `<table-column />` components that define table columns
            -->
            <slot />
        </span>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import { BTable, BFormCheckbox } from 'bootstrap-vue'
    import { cloneDeep, get } from 'lodash'
    import TableShared from './TableSharedMixin'
    import TableCell from './TableCell'
    import TableHeaderCell from './TableHeaderCell'
    import TableHeader from './TableHeader.vue'
    import TableFooter from './TableFooter.vue'
    import TableColumnVisibility from './TableColumnVisibility.vue'
    import TableEmptyStateDefault from './TableEmptyStateDefault.vue'
    import TableConfigDropdown from './TableConfigDropdown.vue'
    import TableBusyEmptyState from './TableBusyEmptyState.vue'

    /**
     * **A static data table component**
     *
     * This component extends `TableSharedMixin`
     */
    export default TableShared.extend({
        components: {
            BTable,
            BFormCheckbox,
            TableCell,
            TableHeaderCell,
            TableFooter,
            TableHeader,
            TableEmptyStateDefault,
            TableColumnVisibility,
            TableConfigDropdown,
            TableBusyEmptyState,
        },

        props: {
            /** Table data items */
            items: { type: Array as PropType<any[]>, default: (): any[] => [] },

            headerClass: {
                type: String as PropType<string>,
                default: '',
            },
            /**
             * Set busy state on table
             */
            busy: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                filteredItems: [],
            }
        },

        computed: {
            itemsLocal(): any[] {
                const itemsCopy = cloneDeep(this.items)

                return this.itemsTransformer(itemsCopy)
            },
        },

        watch: {
            items: {
                handler(value: any): void {
                    this.filteredItems = value
                },
                immediate: true,
            },
            /**
             * When changing per page visibility
             * We reset the current page to first page
             */
            perPage(): void {
                this.currentPage = 1
            },
        },

        methods: {
            onSelectAll(checked: boolean): any {
                if (checked) {
                    /**
                     * Emits when user has selected a row in table
                     * @param {array} items a collection of selected rows
                     */
                    const data = this.filteredItems.filter((item: any ) => item._selectable ?? true)

                    return this.$emit('selected', data)
                }

                const data = this.selectedItems.filter((item: any ) => item._selectable ?? true)

                return this.$emit('selected', data)
            },

            onFiltered(filteredItems: any): void {
                this.$emit('filtered', filteredItems)
                this.filteredItems = filteredItems
                this.currentPage = 1
            },

            clearSelection(): void {
                this.selectAll = false
                this.selectedItems = []
            },

            /**
             * Gets an object representation of the columns and cells of all
             * data in table. Record<columnLabel, cellValue>
             *
             * - omits columns with no labels
             * - omits hidden columns
             * - executes transformers when they are present
             *
             * Usage:
             * Add a ref to the DataTable component and execute `this.$refs.table.toObject()`
             *
             * @public
             * @notes Use sparingly
             */
            toObject(): Record<string, any> {
                const items = this.filteredItems.length
                    ? this.filteredItems
                    : this.items

                return items.map((item) => {
                    const object: Record<string, any> = {}

                    this.fieldsLocal.forEach((field: any) => {
                        if (!field.label || field.hidden)
                            return

                        const itemCell = get(item, field.key)
                        object[field.label] = field.formatter?.(itemCell, field.key, item) ?? itemCell
                    })

                    return object
                })
            },
        },
    })
</script>
