import routesFactory from '@router/routes-factory'


const routerConfig = {
    baseURI: 'notifications',
    module: 'Notifications',
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'notifications.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Notifications" */
                './NotificationsIndex.vue'
            ),
    },
])
