<template>
    <nav
        class="navigation-sidebar"
        :class="`navigation-sidebar--${variant}`"
    >
        <div class="navigation-sidebar__inner">
            <!-- Organization logo -->
            <div
                v-if="currentWhitelabel.logoSquare && !$device.isMobile"
                class="navigation-sidebar__logo"
            >
                <image-lazy
                    class="img-fluid"
                    :src="currentWhitelabel.logoSquare"
                    public
                />
            </div>
            <navigation-item-renderer
                v-for="(item, index) in displayItems"
                :key="index"
                v-tooltip="getTooltipOpts(item)"
                :dropright="!$device.isMobile"
                :hover="$device.isDesktop"
                :popper-opts="popperOpts"
                :value="item"
                :variant="variant"
            >
                <template #button-content="{ totalCount }">
                    <fa-layers
                        fixed-width
                        class="fa-lg"
                    >
                        <fa :icon="['fal', item.icon]" />
                        <fa-text
                            v-if="totalCount"
                            counter
                            :value="totalCount"
                            class="bg-danger"
                            style="
                                --fa-counter-scale: .5;
                                --fa-top: -.4rem;
                                --fa-right: -.2rem;
                                --fa-counter-padding: .3em;
                            "
                        />
                    </fa-layers>
                </template>
            </navigation-item-renderer>

            <!-- Mobile overflow drawer item -->
            <navigation-item-renderer
                v-if="overflowItem && authUser"
                :dropright="!$device.isMobile"
                :hover="$device.isDesktop"
                :popper-opts="popperOpts"
                :value="overflowItem"
                :variant="variant"
            >
                <template
                    v-if="overflowItem"
                    #button-content="{ totalCount }"
                >
                    <fa-layers
                        fixed-width
                        class="fa-lg"
                    >
                        <fa :icon="['fal', overflowItem.icon]" />
                        <fa-text
                            v-if="totalCount"
                            counter
                            :value="totalCount"
                            class="bg-danger"
                            style="
                                    --fa-counter-scale: .5;
                                    --fa-top: -.5rem;
                                    --fa-right: -.8rem;
                                    --fa-counter-padding: .3em;
                                "
                        />
                    </fa-layers>
                </template>

                <template #header>
                    <navigation-user-header />
                </template>

                <template #after>
                    <navigation-user-actions show-icons />
                </template>
            </navigation-item-renderer>

            <!-- Custom items when navigation is not overflowing -->
            <template v-if="!overflowItem">
                <!-- Spacer -->
                <div class="mt-auto" />
                <navigation-item-renderer
                    v-for="(item, index) in offsetItems"
                    :key="index + item.label"
                    v-tooltip="getTooltipOpts(item)"
                    :dropright="!$device.isMobile"
                    :hover="$device.isDesktop"
                    :popper-opts="popperOpts"
                    :value="item"
                    :variant="variant"
                >
                    <template #button-content="{ totalCount }">
                        <fa-layers
                            fixed-width
                            class="fa-lg"
                        >
                            <fa :icon="['fal', item.icon]" />
                            <fa-text
                                v-if="totalCount"
                                counter
                                :value="totalCount"
                                class="bg-danger"
                                style="
                                --fa-counter-scale: .5;
                                --fa-top: -.4rem;
                                --fa-right: -.2rem;
                                --fa-counter-padding: .3em;
                            "
                            />
                        </fa-layers>
                    </template>
                </navigation-item-renderer>

                <!-- User Item -->
                <navigation-item-renderer
                    v-if="authUser"
                    :hover="$device.isDesktop"
                    :dropright="!$device.isMobile"
                    :popper-opts="popperOpts"
                    :value="{
                        label: authUser.profile.combined_name
                    }"
                    :variant="variant"
                >
                    <template #button-content>
                        <avatar
                            size="sm"
                            :value="authUser.image_path"
                            :user-name="authUser.profile.combined_name"
                        />
                    </template>
                    <template #header>
                        <navigation-user-header />
                    </template>
                    <template #before>
                        <navigation-user-actions show-icons />
                    </template>
                </navigation-item-renderer>
            </template>
        </div>
        <slot />
    </nav>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { get } from 'vuex-pathify'
    import ItemGetterMixin from './item-getter.mixin'
    import { NavigationItem } from './@typings'
    import NavigationItemRenderer from './NavigationItemRenderer.vue'
    import NavigationUserHeader from './NavigationUserHeader.vue'
    import NavigationUserActions from './NavigationUserActions.vue'
    import Avatar from '@common/Images/Avatar.vue'
    import ImageLazy from '@common/Images/ImageLazy.vue'

    export default (Vue as VueConstructor<Vue
        & InstanceType<typeof ItemGetterMixin>
    >).extend({
        components: {
            NavigationItemRenderer,
            NavigationUserHeader,
            NavigationUserActions,
            Avatar,
            ImageLazy,
        },

        mixins: [ItemGetterMixin],

        data() {
            return {
                popperOpts: {
                    modifiers: {
                        preventOverflow: {
                            padding: 0, // https://github.com/popperjs/popper-core/issues/704
                        },
                    },
                },
            }
        },

        computed: {
            currentWhitelabel: get<WhitelabelConfigModel>('Whitelabels/current'),
            authUser: get<UserModel>('Auth/authUser'),

            variant(): 'primary' | 'secondary' | 'link' {
                const current = this.currentWhitelabel.navType

                if (current === 'light')
                    return 'link'

                return current
            },

            displayItems(): NavigationItem[]  {
                if (this.$device.isMobile) {
                    return this.children.slice(0, 4)
                }

                return this.children.filter((item) => !item.offset)
            },

            offsetItems(): NavigationItem[] {
                return this.children.filter((item) => item.offset)
            },

            overflowItem(): NavigationItem | null {
                if (!this.$device.isMobile)
                    return null

                return {
                    icon: 'bars',
                    label: this.$t('HEADER.MENU.MAIN') as string,
                    children: this.children.slice(4),
                }
            },
        },

        methods: {
            getTooltipOpts(item: NavigationItem): object {
                return {
                    title: item.label,
                    placement: 'right',
                    customClass: `tooltip-${this.variant}`,
                    disabled: this.$device.isMobile,
                    trigger: 'hover',
                }
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import './styles.scss';

    $box-shadow-reverse: 0 -.5rem 1rem rgba($black, .25);
    $box-shadow-reverse-sm: 0 -.125rem .25rem rgba($black, .15);

    // Offset content by fixed navigation on desktop
    @include media-breakpoint-up(md) {
        .navigation-sidebar ::v-deep + .content {
            margin-left: $main-nav-width;
        }
    }

    // Offset content by fixed navigation on mobile
    @include media-breakpoint-down(sm) {
        .navigation-sidebar ::v-deep + .content {
            margin-bottom: $main-nav-width;
        }
    }

    .navigation-sidebar {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: $white;
        z-index: $zindex-fixed;
        padding-bottom: env(safe-area-inset-bottom, 0);

        @include media-breakpoint-up(md) {
            box-shadow: $box-shadow-sm;
            top: 0;
            width: auto;
        }

        &__logo {
            width: $main-nav-width;
            height: $main-nav-width;
            padding: spacer(3);
        }

        &__inner {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
                flex-direction: column;
                justify-content: flex-start;
                height: 100%;
            }
        }

        /** Variants */
        &--secondary {
            @include nav-variant('secondary');
        }

        &--primary {
            @include nav-variant('primary');
        }

        &--link {
            box-shadow: $box-shadow-reverse-sm;

            ::v-deep .dropdown-divider {
                border-color: $gray-150;
            }

            ::v-deep .navigation-item__btn {
                color: $body-color;

                &.active, &:hover {
                    color: whitelabel-color('primary');
                }
            }
        }
    }

    //----------------------------------------------------
    // Navigation item overwrites fitted for this nav type
    //----------------------------------------------------
    .navigation-sidebar ::v-deep .navigation-item {
        &__btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 0;
            width: $main-nav-width;
            height: $main-nav-width;
        }

        &__menu {
            margin: 0;
            border: 0;
            width: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: $box-shadow-reverse;

            @include media-breakpoint-up(md) {
                width: initial;
                border-top-left-radius: 0;
                border-bottom-right-radius: $dropdown-border-radius;
                box-shadow: $dropdown-box-shadow;
            }
        }
    }
</style>
