import { validator } from '@learningbank/lb-utils'

export function buildFullPath(src: string | null | undefined, isPublic = false): string | null {
    if (!src)
        return null

    if (validator.isUrl(src) || src.startsWith('blob'))
        return src

    const baseUrl = isPublic ? LB_ENV.common.s3.buckets.public.url : LB_ENV.resourceCloudFrontBaseUrl

    return `${baseUrl}/${src}`
}
