<template>
    <div class="modal-wrapper">
        <transition name="backdrop-fade">
            <div
                v-if="modalCount > 0"
                class="modal-backdrop"
            />
        </transition>

        <dialogs-wrapper
            ref="wrapper"
            name="default"
            transition-name="modal-fade"
        />
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'
    import { DialogsWrapper } from 'vue-modal-dialogs'
    export default Vue.extend({
        components: { DialogsWrapper },
        data() {
            return {
                modalCount: 0,
            }
        },

        mounted() {
            this.$watch('$refs.wrapper.dialogs', (val) => {
                this.modalCount = Object.keys(val).length
                this.$nextTick(() => this.handleBodyTag())
            })
        },

        methods: {
            handleBodyTag(): void {
                const body = document.body

                if (this.modalCount > 0) body.classList.add('vue-modal-open')
                else setTimeout(() => body.classList.remove('vue-modal-open'), 200)
            },
        },
    })
</script>

<style lang="scss" scoped>
    //--------------------------------------------
    // Backdrop transition
    //--------------------------------------------
    .backdrop-fade-enter-active, .backdrop-fade-leave-active {
        transition: opacity 250ms ease-out;
    }

    .backdrop-fade-enter, .backdrop-fade-leave-to {
        opacity: 0;
    }

    //--------------------------------------------
    // Modal dialog transition
    //--------------------------------------------
    ::v-deep .modal-fade-enter-active, .modal-fade-leave-active {
        transition: opacity 250ms ease-out;

        .modal-dialog {
            transition: transform 250ms ease-out;
        }
    }

    ::v-deep .modal-fade-enter, .modal-fade-leave-to {
        opacity: 0;

        .modal-dialog {
            transform: translate(0, -3.125rem);
        }
    }
</style>
