import Vue, { VueConstructor } from 'vue'

const GlobalFilters = {
    install(Vue: VueConstructor): any {
        Vue.mixin({
            methods: {
                $filter(filter: string): any {
                    if (this.$options && this.$options.filters && this.$options.filters[filter]) {
                        return (...args: any[]): any => (this.$options as any).filters[filter](...args)
                    }

                    console.warn(`Filter [${filter}] does not exist`)
                },
            },
        })
    },
}

Vue.use(GlobalFilters)