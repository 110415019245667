import Vue, { PropType } from 'vue'
import { buildFullPath } from '@common/Resources/utils'

/**
 * @mixin ResourceGetterMixin
 *
 * Mixin to fetch signed requests to s3 by their keys.
 *
 * - Adds `src` prop to your component that is observed for changes
 * - supports external links too
 * - `srcLocal` property will hold the correct path to a resource you're requestion
 * - You can overwrite the `setSrc` method in your component if you want to
 *   do any additional operations, such as preloading etc. on the actual src,
 *   the fucntion takes one argument containing the source or null.
 *   Remember to always set the srcLocal from in there.
 * - You can use the `public` prop to indicate that the key included in src
 *   is from our public s3 bucket
 */

export default Vue.extend({
    props: {
        /**
         * Media source, url or s3 key
         */
        src: {
            type: String as PropType<string>,
            default: null,
        },
        /**
         * Indicates that the src property contains as s3 key to our public bucket
         */
        public: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },

    data() {
        return {
            srcLocal: null as null | string,
        }
    },

    computed: {
        hasSrc(): boolean {
            return !!this.srcLocal
        },
    },

    watch: {
        src: {
            handler(value, oldValue): void {
                if (!oldValue || value !== oldValue) {
                    this.srcLocal =  buildFullPath(value, this.public)
                }
            },
            immediate: true,
        },
    },
})
