<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            class="input-field"
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <b-form-input
                v-model="internalValue"
                type="range"
                number
                :class="inputClass"
                :autofocus="autofocus"
                :placeholder="placeholder"
                :debounce="debounce"
                :disabled="disabled"
                :min="min"
                :max="max"
                :step="step"
                :name="nameLocal"
                :size="size"
            />
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'

    /**
     * Range slider field
     *
     * @example ./__docs__/RangeField.examples.md
     */
    export default (Vue as VueConstructor<Vue
        & InstanceType<typeof SharedField>
    >).extend({
        mixins: [SharedField],
        props: {
            /**
             * Debounce input event in ms
             */
            debounce: {
                type: Number as PropType<number>,
                default: 0,
            },
            /**
             * Minimum value
             */
            min: {
                type: Number as PropType<number>,
                default: 0,
            },
            /**
             * Maximum value
             */
            max: {
                type: Number as PropType<number>,
                default: 100,
            },
            /**
             * The gap between the values when dragging
             */
            step: {
                type: Number as PropType<number>,
                default: 1,
            },
        },

        computed: {
        },

        methods: {
        },
    })
</script>
