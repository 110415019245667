import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'premiumLearning',
    module: 'PremiumLearning',
    middleware: [
        auth('admin', { name: '401' }),
        hasFeatures('PremiumLearning', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'premiumLearning.index',
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/PremiumLearning" */
            './PremiumLearningManagement.vue'
        ),

    },
])
