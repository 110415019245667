<template>
    <component
        :is="togglable ? 'b-dropdown' : 'div'"
        ref="dropdown"
        v-bind="dropdownBindings"
        @mouseenter.native="onMouseOver"
        @mouseleave.native="onMouseLeave"
    >
        <template #button-content>
            <fa
                class="text-muted"
                fixed-width
                :icon="['fal', 'route']"
            />
            {{ journeyCount }}
        </template>
        <div :style="mainStyle">
            <p
                v-if="!hideTitle"
                class="text-larger"
                v-text="$tc('JOURNEY.MINE', 2)"
            />
            <journey-list-item
                v-for="(journey, key) in journeys"
                :key="key"
                :class="{ 'border-bottom mb-3': key !== journeys.length - 1 }"
                :value="journey"
            />
            <div class="text-center">
                <router-link
                    :to="{ name: 'learning.journeys' }"
                    v-text="$t('TERMS.SEE_ALL')"
                />
            </div>
        </div>
    </component>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import infoMixin from './info.mixin'
    import JourneyListItem from '@modules/Journeys/components/JourneyListItem.vue'

    export default (Vue as VueConstructor<Vue & InstanceType<typeof infoMixin>>).extend({
        components: {
            JourneyListItem,
        },

        mixins: [infoMixin],

        props: {
            hideTitle: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                journeyCount: 0,
            }
        },

        computed: {
            journeys(): JourneyModel[] {
                return this.$store.state.Journeys?.journeys ?? []
            },
        },

        methods: {
            async fetchData(): Promise<void> {
                this.busy = true

                try {
                    await this.$store.load('Journeys')
                    const { pagination } = await this.$store.dispatch('Journeys/list', {
                        limit: 3,
                    })
                    this.journeyCount = pagination.total
                } finally {
                    this.busy = false
                }
            },
        },
    })
</script>
