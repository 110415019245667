import Vue, { VNode } from 'vue'
import { DirectiveBinding } from 'vue/types/options'
import { commentNode } from './utils'
import { hasPermissions } from '@modules/Auth/utils'
/**
 * v-cannot directive, opposite of v-can
 * Default in strict mode but using the .any modifier
 * will check if user has not any of the permissions provided
 *
 * Usage:
 *  Single permission:
 *      <element v-cannot:permissionName1 />
 *  Multiple permissions:
 *      <element v-cannot:permissionName1:permissionName2 />
 *  Has not any of multiple permissions:
 *      <element v-cannot:permissionName1:permissionName2.any />
 */
Vue.directive('cannot', (
    el: HTMLElement | HTMLInputElement,
    bindings: DirectiveBinding,
    vnode: VNode,
): void => {
    if (!bindings.arg) {
        throw new Error(`
            Missing permission argument in [v-can]
            Usage: v-can:permissionName:permissionName2
        `)
    }

    const permissions = bindings.arg.split(':')
    const strict = !bindings.modifiers.any
    const ok = hasPermissions(permissions, strict)

    if (ok) {
        commentNode(el, vnode)
    }
})