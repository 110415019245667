import { utilHelper } from '@learningbank/lb-utils'

/**
 * Create a data tree from flattened array
 *
 * @param {Array} items Flattened data array
 * @param {String} childKey Key name for children to be placed in
 * @param {String} linkKey The link between parent and child
 * @param {String} idKey Unique identifier for items to keep track
 *
 * @returns {Array}
 */
export function createDataTree(items: any[], childKey = 'children', linkKey = 'parent_id', idKey = 'id'): any[] {
    const hashTable = Object.create(null)
    items.forEach((item: any) => hashTable[item[idKey]] = { ...item })

    const dataTree: any[] = []
    items.forEach((item: any) => {
        if (item[linkKey] && hashTable[item[linkKey]]) {
            if (!hashTable[item[linkKey]][childKey]) {
                hashTable[item[linkKey]][childKey] = []
            }
            hashTable[item[linkKey]][childKey].push(hashTable[item[idKey]])
        } else {
            dataTree.push(hashTable[item[idKey]])
        }
    })

    return dataTree
}

/**
 * Find all parents and grandparents of item(s)
 */
export function findParents(value: any | any[], allItems: any[], linkKey = 'parent_id', idKey = 'id'): Set<any> {
    const itemMap = utilHelper.mapBy(allItems, idKey) as Map<number, any>

    const items = Array.isArray(value) ? value : [value]

    return items.reduce((acc: Set<any>, item: any) => {
        let parent = itemMap.get(item[linkKey])
        while (parent) {
            acc.add(parent)
            parent = itemMap.get(parent[linkKey])
        }

        return acc
    }, new Set<any>())
}

/**
 * @param items List of items to search in
 * @param value Value to match in the list
 * @param prop Property to match the value against
 * @param childKey Nested property to search recursively in
 * @returns Item that matches the value
 */
export function findRecursive(items: any[], value: any, prop: string, childKey?: string): any {
    if (!items?.length)
        return

    const item = items.find((obj) => obj[prop] === value)
    if (item)
        return item

    if (!childKey)
        return

    return findRecursive(items.flatMap((el) => el[childKey]), value, prop, childKey)
}

/**
 * Fuzzy search in arrays of objects.
 *
 * @param items array of objects to search in
 * @param query search query
 * @param properties properties to search in each object, including nested properties (e.g., 'course.name').
 */
export function fuzzySearch(items: any[], query: string, properties?: string[]): any[] {
    const search = query.split(' ')
    const ret = items.reduce((found, i) => {
        let matches = 0
        search.forEach((s: any) => {
            let props = 0
            for (const prop of properties ?? Object.keys(i)) {
                const nestedProps = prop.split('.')
                let nestedObj = i
                for (const nestedProp of nestedProps) {
                    if (nestedObj && nestedObj.hasOwnProperty(nestedProp)) {
                        nestedObj = nestedObj[nestedProp]
                    } else {
                        nestedObj = null
                        break
                    }
                }
                if (
                    nestedObj &&
                    typeof nestedObj === 'string' &&
                    nestedObj.toLowerCase().includes(s.toLowerCase())
                ) {
                    props++
                }
            }
            if (props >= 1) {
                matches++
            }
        })
        if (matches === search.length) {
            found.push(i)
        }

        return found
    }, [])

    return ret
}

/**
 * Search object for any string values in selected properties
 *
 * @private
 * @param obj object to search in
 * @param query query string
 * @param searchFields string array of object properties to search in
 */
function searchObjectStringValues(
    obj: any,
    query: string,
    searchFields?: string[],
): boolean {
    const props = Object.keys(obj)
    const searchIn = props
        .filter((field) => (searchFields ?? props).includes(field))
        .filter((field) => typeof obj[field] === 'string')

    return searchIn.some((field) =>
        obj[field]
            .toLowerCase()
            .includes(query.toLowerCase()))
}
/**
 * Recursively search tree structure by data properties
 * - Will retain parent structure
 *
 * @param nodes node tree data structure
 * @param query query string
 * @param options.childKey Key name for children to be placed in (default: 'children')
 * @param options.fields string array of object properties to search in
 * @param options.omitChildren Omits children from search result
 */
export function filterTreeData(
    nodes: any[],
    query: string,
    options?: { childKey?: string; fields?: string[]; omitChildren?: boolean },
): any[] {
    const results = []
    const opts = {
        ...{ childKey: 'children' },
        ...options && { ...options },
    }

    for (const node of nodes) {
        if (node[opts.childKey]) {
            const nextNodes = filterTreeData(node[opts.childKey], query, opts)
            if (nextNodes.length > 0) {
                node[opts.childKey] = nextNodes
            } else if (opts.omitChildren && searchObjectStringValues(node, query, opts.fields)) {
                node[opts.childKey] = nextNodes.length > 0 ? nextNodes : []
            }
            if (nextNodes.length > 0 || searchObjectStringValues(node, query, opts.fields)) {
                results.push(node)
            }
        } else if (searchObjectStringValues(node, query, opts.fields)) {
            results.push(node)
        }
    }

    return results
}

/**
 * Remove duplicate items from array by key
 *
 * @param {Object[]} array List of items to remove duplicates from
 * @param {string} [key='id'] Property which to compare
 */
export function uniqueByKey(array: [], key = 'id'): any[] {
    return [...new Map(array.map((item) => [item[key], item])).values()]
}

/**
 * Picks the random item based on its weight.
 * The items with higher weight will be picked more often (with a higher probability).
 *
 * For example:
 * - items = ['banana', 'orange', 'apple']
 * - weights = [0, 0.2, 0.8]
 * - weightedRandom(items, weights) in 80% of cases will return 'apple', in 20% of cases will return
 * 'orange' and it will never return 'banana' (because probability of picking the banana is 0%)
 *
 * @param {any[]} items
 * @param {number[]} weights
 * @returns {{item: any, index: number}}
 */
export default function weightedRandom(
    items: unknown[],
    weights: number[],
    withIndex = false,
): any {
    if (items.length !== weights.length) {
        throw new Error('Items and weights must be of the same size')
    }

    if (!items.length) {
        throw new Error('Items must not be empty')
    }

    // Preparing the cumulative weights array.
    // For example:
    // - weights = [1, 4, 3]
    // - cumulativeWeights = [1, 5, 8]
    const cumulativeWeights: number[] = []
    for (let i = 0; i < weights.length; i += 1) {
        cumulativeWeights[i] = weights[i] + (cumulativeWeights[i - 1] || 0)
    }

    // Getting the random number in a range of [0...sum(weights)]
    // For example:
    // - weights = [1, 4, 3]
    // - maxCumulativeWeight = 8
    // - range for the random number is [0...8]
    const maxCumulativeWeight = cumulativeWeights[cumulativeWeights.length - 1]
    const randomNumber = maxCumulativeWeight * Math.random()

    // Picking the random item based on its weight.
    // The items with higher weight will be picked more often.
    for (let itemIndex = 0; itemIndex < items.length; itemIndex += 1) {
        if (cumulativeWeights[itemIndex] >= randomNumber) {
            if (withIndex)
                return {
                    item: items[itemIndex],
                    index: itemIndex,
                }
            else
                return items[itemIndex]
        }
    }
}

/**
 * Filtering undefined elements from an array in TypeScript
 * https://www.benmvp.com/blog/filtering-undefined-elements-from-array-typescript/
 */
export const exists = (item: any | undefined): item is TranslationItemModel => {
    return !!item
}
