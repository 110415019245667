<template>
    <div :class="aspectRatioClass">
        <div
            v-if="!fill && fillBlur"
            class="embed-responsive-item fill-blur"
            :style="imgStyle"
        />
        <div
            class="embed-responsive-item bg-norepeat"
            :style="elStyle"
        />
        <div
            v-if="$slots.default"
            class="embed-responsive-item"
            :class="innerClass"
        >
            <!--
                @slot Any content in this slot will be displayed
                as overlay with a backdrop by default.
                You can disable the backdrop with `noBackdrop` property
             -->
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { AspectRatioMixin } from '@common/mixins'

    /**
     * Display image in a certain aspect ratio. Image will fill the ratio box and bleed
     * horizontally or vertically depending on original images aspect ratio
     *
     * Notable features:
     * - Fill blur. If image doesn't match the aspect ratio we fill the background with the
     * same image and blur it
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof AspectRatioMixin>
    >).extend({
        mixins: [AspectRatioMixin],

        props: {
            /** Image source */
            src: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Fill image in its aspect ratio container, this will make image bleed
             */
            fill: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Sets the same image, but blurred as background if image doesn't
             * fit the aspect ratio. Setting `fill` prop to true, disables this.
             */
            fillBlur: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Works only if there is anything in the default slot
             */
            noBackdrop: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Set the position of the image, takes same values as
             * css declaration `background-position`
             */
            position: {
                type: String as PropType<string>,
                default: 'center',
            },
            /**
             * Extra classes to be applied on overlay element
             */
            overlayClass: {
                type: String as PropType<string>,
                default: '',
            },
        },

        computed: {
            imgStyle(): object {
                return {
                    backgroundImage: this.src ? `url('${this.src}')` : 'none',
                }
            },
            elStyle(): object {
                return {
                    ...this.imgStyle,
                    backgroundSize: this.fill ? 'cover' : 'contain',
                    backgroundPosition: this.position,
                }
            },

            innerClass(): any[] {
                return [
                    this.overlayClass,
                    {
                        'embed-responsive-item--backdrop': !this.noBackdrop,
                    },
                ]
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .fill-blur {
        filter: blur(1rem);
        background-position: center;
        background-size: cover;
    }

    .fade-in {
        opacity: 0;
        transition: $transition-base;

        &:hover {
            opacity: 1;
        }
    }
</style>

<docs>
```vue
<div class="row">
    <div class="col-4">
        <image-aspect-ratio
            class="bg-off-white"
            src="https://picsum.photos/id/398/600/300"
        />
        <pre>1:1 (default)</pre>
    </div>
    <div class="col-4">
        <image-aspect-ratio
            class="bg-off-white"
            src="https://picsum.photos/id/518/380/600"
            ar="16:9"
        />
        <pre>16:9</pre>
    </div>
    <div class="col-4">
        <image-aspect-ratio
            fill
            src="https://picsum.photos/id/687/700/300"
            ar="16:9"
        >
            <div class="p-2 text-white">
                Integer ac lorem leo. Interdum et malesuada fames ac ante ipsum.
            </div>
        </image-aspect-ratio>
        <pre>Filled + overlay</pre>
    </div>
    <div class="col-4">
        <image-aspect-ratio
            src="https://picsum.photos/id/518/600/600"
            fill-blur
            ar="16:9"
        />
        <pre>using fill-blur prop</pre>
    </div>
    <div class="col-4">
        <image-aspect-ratio
            class="skeleton-container skeleton-img"
            no-backdrop
        >
            <fa class="centerer" size="2x" :icon="['fal', 'frown']" />
        </image-aspect-ratio>
        <pre>as skeleton</pre>
    </div>
</div>
```
</docs>
