<template>
    <base-modal
        centered
        :size="$device.isMobile ? 'fill' : 'xl'"
        scrollable
        content-class=""
        :no-footer="!value.nextContent"
        :title="value.name"
    >
        <journey-view
            :value="value"
            hide-title
            :content-clickable="contentClickable"
            :swimlane-props="{
                perView: {
                    md: 2.4,
                    lg: 3.4,
                    xl: 4.4,
                }
            }"
        />

        <template #footer>
            <journey-next-content-bar
                v-if="value.nextContent"
                :value="value.nextContent"
                class="w-100"
            />
        </template>
    </base-modal>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { DialogComponent } from 'vue-modal-dialogs'
    import BaseModal from '@common/Modals/BaseModal.vue'
    import JourneyView from './JourneyView.vue'
    import JourneyNextContentBar from './JourneyNextContentBar.vue'

    export default (Vue as VueConstructor<Vue & DialogComponent<void>>).extend({
        components: {
            BaseModal,
            JourneyView,
            JourneyNextContentBar,
        },

        props: {
            value: {
                type: Object as PropType<JourneyModel>,
                required: true,
            },
            contentClickable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
        },

        watch: {
            /**
             * Dismiss modal if route changes
             */
            '$route'(value, oldValue): void {
                if (value.name !== oldValue.name)
                    this.$close()
            },
        },
    })
</script>