<template>
    <validation-provider
        #default="props"
        vid="password"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="true"
        :skip-if-empty="skipIfEmpty"
        :bails="false"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :state="getValidationState(props)"
        >
            <b-input-group
                id="popTarget"
                class="input-group-clean"
            >
                <b-form-input
                    v-model="internalValue"
                    :autofocus="autofocus"
                    :placeholder="placeholder"
                    :debounce="debounce"
                    :type="type"
                    :disabled="disabled"
                    :class="inputClass"
                    :size="size"
                    autocomplete="new-password"
                />
                <b-input-group-append class="input-group-append-special">
                    <btn
                        tabindex="-1"
                        class="border"
                        variant="default"
                        emphasis="low"
                        :icon="['fal', type === 'password' ? 'eye' : 'eye-slash']"
                        @click="toggleVisibility"
                    />
                </b-input-group-append>
            </b-input-group>
            <password-validation
                class="mt-2"
                :value="internalValue"
                :failed-rules="Object.keys(props.failedRules)"
                no-password-meter
                v-bind="$attrs"
            />
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import { BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
    import PasswordValidation from '@common/components/PasswordValidation.vue'
    import SharedField from './mixin'


    export default SharedField.extend({
        components: { BInputGroup, BInputGroupAppend, PasswordValidation },
        props: {
            debounce: { type: Number as PropType<number>, default: 0 },
        },

        data() {
            return {
                type: 'password',
            }
        },

        methods: {
            toggleVisibility(): any {
                if (this.type === 'password') {
                    return this.type = 'text'
                }

                return this.type = 'password'
            },
        },
    })
</script>
