<template>
    <component
        :is="togglable ? 'b-dropdown' : 'div'"
        ref="dropdown"
        v-bind="dropdownBindings"
        @mouseenter.native="onMouseOver"
        @mouseleave.native="onMouseLeave"
    >
        <template #button-content>
            <fa
                class="text-muted"
                fixed-width
                :icon="['fal', 'graduation-cap']"
            />
            {{ modulesCount }}
        </template>
        <div
            :style="mainStyle"
            class="d-flex"
        >
            <fa
                fixed-width
                class="text-muted mr-3"
                size="2x"
                :icon="['fal', 'graduation-cap']"
            />
            <div>
                <p
                    class="text-nowrap text-larger"
                    v-text="$t('ACHIEVEMENT.PROGRESS.COUNT', { count: modulesCount })"
                />
                <p
                    class="text-muted"
                    v-text="$t('ACHIEVEMENT.PROGRESS.INFO')"
                />
                <ul
                    v-if="progress"
                    class="list-unstyled mb-0"
                >
                    <i18n
                        tag="li"
                        path="ACHIEVEMENT.PROGRESS.MODULES_COMPLETED"
                    >
                        <template #count>
                            <strong
                                v-text="`${progress.modules.completed}/${progress.modules.total}`"
                            />
                        </template>
                    </i18n>
                    <i18n
                        tag="li"
                        path="ACHIEVEMENT.PROGRESS.LP_COMPLETED"
                    >
                        <template #count>
                            <strong
                                v-text="`${progress.containers.completed}/${progress.containers.total}`"
                            />
                        </template>
                    </i18n>
                </ul>
            </div>
        </div>
    </component>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import infoMixin from './info.mixin'

    export default (Vue as VueConstructor<Vue & InstanceType<typeof infoMixin>>).extend({
        mixins: [infoMixin],
        computed: {
            progress(): AchievementsState['progress'] {
                return this.$store.state.Achievements?.progress
            },

            modulesCount(): number {
                return this.progress?.modules.completed ?? 0
            },
        },

        methods: {
            async fetchData(): Promise<void> {
                this.busy = true
                await this.$store.load('Achievements')
                await this.$store.cache.dispatch('Achievements/getProgress', this.userId)
                this.busy = false
            },
        },
    })
</script>