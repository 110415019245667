import Vue, { CreateElement, PropType } from 'vue'
import { Location } from 'vue-router'

/**
 * Topbar actions is required to be placed inside `<topbar />` default slot
 *
 * Maximum 2 actions will be displayed and the rest will collapse into
 * a drop down. The order matters, first 2 components are primary and secondary
 *
 * **Action types**
 *
 * You can have different kind of action types, they are listed below.
 * Note that you cannot combine them, you have to use only one of them
 *
 * - Handler: requires a `@click` event listner on the component
 * - Route: requires a `to` prop on component
 * - Link: requires a `href` prop on component
 *
 * _Note on `icon` prop:_ Use icon name in lower case without fa-prefix.
 * Depending on device it will display either a solid icon or regular icon
 * Make sure you have the same icon in both weights included in project
 */
export default Vue.extend({
    props: {
        /** Label to display on the action */
        label: {
            type: String as PropType<string>,
            required: true,
        },
        /** Icon associated with action */
        icon: {
            type: String as PropType<string>,
            default: '',
        },
        /** Icon associated with action */
        variant: {
            type: String as PropType<string>,
            default: undefined,
        },
        /** Disable state of the action */
        disabled: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Replaces v-if if you need reactivity to
         * show or hide the action
         */
        hidden: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        // Action types
        /** Vue router location object for route change */
        to: {
            type: Object as PropType<Location>,
            default: null,
        },
        /** Make action a normal anchor tag */
        href: {
            type: String as PropType<string>,
            default: null,
        },
        /** Class for primary topbar action item */
        primaryClass: {
            type: String as PropType<string>,
            default: null,
        },
    },

    methods: {
        getType(): string | null {
            if (this.$listeners.click) return 'callback'
            if (this.to !== null) return 'route'
            if (this.href !== null) return 'link'

            return null
        },

        getAction(): any {
            const action = {
                type: this.getType(),
                label: this.label,
                disabled: this.disabled,
                hidden: this.hidden,
                variant: this.variant,
                icon: this.icon,
                handler: this.$listeners.click,
                to: this.to,
                href: this.href,
                primaryClass: this.primaryClass,
            }

            return action
        },
    },

    render(h: CreateElement) {
        return h()
    },
})
