<template>
    <div class="integration-select">
        <div class="integration-select__search-wrapper">
            <input-field
                ref="searchField"
                v-model="query"
                type="search"
                class="mb-0"
                :debounce="500"
                :placeholder="$t('INTEGRATIONS.GIPHY.SEARCH_PLACEHOLDER')"
                clearable
                @input="search(true)"
            />
        </div>

        <!-- Empty state -->
        <i18n
            v-if="query && !results.length"
            #query
            class="text-center py-3"
            tag="p"
            path="INTEGRATIONS.GIPHY.SEARCH_EMPTY"
        >
            <strong>{{ query }}</strong>
        </i18n>

        <!-- Grid -->
        <div class="masonry">
            <overlay
                v-for="(item, i) in results"
                :key="item.id + String(i)"
                variant="default"
                class="masonry-item pointer"
                :show="selectedResult && selectedResult.id === item.id"
                @click.native="onSelect(item)"
            >
                <image-lazy
                    class="masonry-brick"
                    :src="item.images.fixed_height.url"
                    fill
                >
                    <template #loading>
                        <div
                            class="masonry-brick-loading bg-off-white"
                        />
                    </template>
                </image-lazy>

                <template #content="{ hovering }">
                    <div
                        v-if="selectedResult && selectedResult.id === item.id"
                        class="centerer"
                    >
                        <fa
                            v-if="!selectedFile"
                            :icon="['fal', 'circle-notch']"
                            class="text-white"
                            size="lg"
                            spin
                        />
                        <fa
                            v-else
                            :icon="['fal', 'check']"
                            class="text-white"
                            size="lg"
                        />
                    </div>
                    <div
                        v-if="hovering"
                        class="position-absolute bottom-0 w-100 bg-900-dimmed p-1 line-height-1"
                    >
                        <span
                            class="text-smaller text-white"
                            v-text="item.title"
                        />
                    </div>
                </template>
            </overlay>
        </div>

        <!-- Attribution -->
        <div class="integration-select__attribution">
            <a
                href="https://support.giphy.com/hc/en-us/articles/360020027752-GIPHY-Terms-of-Service"
                target="_blank"
            >
                <!-- eslint-disable vue/no-bare-strings-in-template -->
                <img
                    src="@assets/misc/giphy_attribution.png"
                    alt="Powered by Giphy"
                >
                <!-- eslint-enable vue/no-bare-strings-in-template-->
            </a>
        </div>

        <!-- Infinite loader observer -->
        <observer
            v-if="!busy && !allFetched"
            @intersect="loadMore"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { GiphyFetch } from '@giphy/js-fetch-api'
    import type { IGif } from '@giphy/js-types'
    import ImageLazy from '@common/Images/ImageLazy.vue'
    import { InputField } from '@common/Forms'
    import Overlay from '@common/components/Overlay.vue'
    import Observer from '@common/components/Observer.vue'
    import IntegrationSelectMixin from './integration-select.mixin'

    export default (Vue as VueConstructor<Vue & InstanceType<typeof IntegrationSelectMixin> & {
        results: IGif[];
        selectedResult: IGif;
    }>).extend({
        components: {
            ImageLazy,
            InputField,
            Overlay,
            Observer,
        },
        mixins: [IntegrationSelectMixin],

        data() {
            return {
                instance: new GiphyFetch('M3GvsdXgdBiTITohgMBKuhZx2S8ii0V9'),
            }
        },

        methods: {
            list(reset = false): void {
                this.fetch(reset, async () => {
                    const { data, pagination } = await this.instance.trending({
                        limit: this.perPage,
                        offset: this.offset,
                    })
                    this.offset = pagination.offset + pagination.count
                    this.total = pagination.total_count

                    return data
                })
            },

            search(reset = false): void {
                if (!this.query)
                    return this.list(true)

                this.fetch(reset, async () => {
                    const { data, pagination } = await this.instance.search(this.query, {
                        limit: this.perPage,
                        offset: this.offset,
                    })
                    this.offset = pagination.offset + pagination.count
                    this.total = pagination.total_count

                    return data
                })
            },

            onSelect(item: IGif): void {
                this.selectedFile = null
                this.selectedResult = item

                const imageUrl = item.images.original.webp
                const filename = `giphy-${item.id}-${Date.now()}`

                this.downloadAndEmit(imageUrl, filename)
            },
        },
    })
</script>
