import { getFontFamilies, COLORS } from '@utils'
/**
 * Plugins individually imported
 * - Add the plugin name to `FR_PLUGINS` const if you want the plugin
 *   to be set by default on all editorsþ
 */
// Froala official plugins
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/char_counter.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/video.min.js'

import '../plugins/froala-openai'

/**
 * Default plugins
 */
export const FR_PLUGINS = [
    'align',
    'charCounter',
    'codeView',
    'colors',
    'fontFamily',
    'fullscreen',
    'image',
    'link',
    'lists',
    'paragraphFormat',
    'paragraphStyle',
    'url',
    'video',
    'openai',
]

/**
 * Get base config for froala editor
 */
export const getFroalaBaseConfig = (): any => ({
    key: 'fIE3A-9C2C2F2C4D5C4E4ud1BI1IMNBUMRWAi1AYMSTRBUZYB-16D4E3D2F3C3I2E1B10B2D2==',
    attribution: false,
    iconsTemplate: 'font_awesome_5l',
    pastePlain: true,
    htmlExecuteScripts: false,
    charCounterCount: false,
    toolbarInline: true,
    linkAlwaysBlank: true,
    linkList: false,
    linkInsertButtons: ['linkBack'],
    linkEditButtons: ['linkEdit', 'linkRemove'],
    colorsStep: 6,
    colorsBackground: COLORS.concat(['REMOVE']),
    colorsText: COLORS.concat(['REMOVE']),
    fontFamilyDefaultSelection: 'Default',
    fontFamily: getFontFamilies().reduce((acc, item) => {
        acc[item.value || ''] = item.text

        return acc
    }, {} as Record<string, string>),
    paragraphFormat: {
        H1: 'Heading 1',
        H2: 'Heading 2',
        H3: 'Heading 3',
        H4: 'Heading 4',
        N: 'Normal',
        SMALL: 'Small',
    },
    toolbarButtons: [
        ['openaiCmd'],
        ['bold', 'italic', 'underline', 'strikeThrough', 'textColor', 'backgroundColor'],
        ['fontFamily', 'paragraphFormat'],
        ['insertImage', 'insertLink', 'insertVideo'],
        ['align', 'formatOL', 'formatUL', 'outdent', 'indent'],
        ['undo', 'redo', 'clearFormatting', 'selectAll'],
    ],
})