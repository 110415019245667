<template>
    <div class="journey position-relative">
        <h3
            v-if="!hideTitle"
            class="font-weight-normal mb-4"
            v-text="value.name"
        />

        <!-- Description -->
        <div v-if="value.description">
            <h6
                class="mb-3"
                v-text="$t('TERMS.DESCRIPTION')"
            />
            <p
                class="mb-6 w-100 w-md-75 w-lg-50"
                v-text="value.description"
            />
        </div>

        <!-- Progress -->
        <h6
            class="mb-3"
            v-text="$t('TERMS.PROGRESS')"
        />
        <h6 class="d-flex align-items-center mb-6 w-100 w-md-75 w-lg-50">
            <b-progress
                class="flex-grow-1 rounded-pill"
                :value="value.completionRate"
                variant="primary"
                height=".75rem"
            />
            <span
                class="ml-2"
                v-text="$filter('pct')(Math.round(value.completionRate))"
            />
        </h6>

        <!-- Obtainable skills -->
        <div
            v-if="skills.length"
            class="mb-6"
        >
            <h6
                class="mb-3"
                v-text="$t('SKILLS.OBTAINABLE')"
            />
            <div class="d-flex flex-wrap g-3">
                <span
                    v-for="skill in skills"
                    :key="skill.id"
                    class="badge badge-white shadow-sm font-size-reset"
                    v-text="skill.name"
                />
            </div>
        </div>

        <!-- Journey levels -->
        <h6
            class="mb-3"
            v-text="$t('JOURNEY.STARTS_HERE')"
        />

        <div
            v-for="level in value.levels"
            :key="level.level"
            class="journey__level ml-3"
            :class="`journey__level--${level.status}`"
        >
            <!-- Level status icon -->
            <fa-layers class="journey__level__icon fa-2x">
                <fa
                    :icon="['fas', 'circle']"
                    class="text-white"
                    transform="grow-4"
                />
                <fa
                    :icon="[level.status === 'incomplete' ? 'fal' : 'fas', 'circle']"
                    :class="level.status === 'locked' ? 'text-gray-light' : 'text-success'"
                />
                <fa
                    :icon="['fas', level.status === 'locked' ? 'lock' : 'check']"
                    :class="level.status === 'incomplete' ? 'text-success' : 'text-white'"
                    transform="shrink-8"
                />
            </fa-layers>
            <!-- Content swimlane -->
            <swimlane
                :value="level.contents"
                v-bind="swimlaneProps"
            >
                <template #item="{ item }">
                    <learning-card
                        class="h-100"
                        :item="item"
                        :clickable="contentClickable"
                        @click.capture.native="onClick($event, item)"
                    />
                </template>
            </swimlane>
        </div>
        <!-- Final message -->
        <div class="journey__level ml-3 pt-1">
            <fa-layers class="journey__level__icon fa-2x">
                <fa
                    :icon="['fas', 'circle']"
                    class="text-white"
                    transform="grow-4"
                />
                <fa
                    :icon="['fas', 'circle']"
                    :class="['text-white', {
                        'text-success': isAllDone,
                    }]"
                />
                <fa
                    :icon="['fas', isAllDone ? 'check' : 'circle']"
                    :class="isAllDone ? 'text-white' : 'text-gray-light'"
                    transform="shrink-8"
                />
            </fa-layers>
            <p
                v-if="isAllDone"
                class="mb-0 font-weight-bold w-100 w-md-75 w-lg-50"
                v-text="$t('JOURNEY.FINISH_CONGRATS', { name: value.name })"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { BProgress } from 'bootstrap-vue'
    import Swimlane from '@common/Slider/Swimlane.vue'
    import LearningCard from '@modules/Learning/components/LearningCard.vue'
    import JourneyContentDependencyModal from './JourneyContentDependencyModal.vue'

    export default Vue.extend({
        components: {
            BProgress,
            Swimlane,
            LearningCard,
        },

        props: {
            value: {
                type: Object as PropType<JourneyModel>,
                required: true,
            },

            hideTitle: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            contentClickable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Pass any props to the internal swimlane component
             */
            swimlaneProps: {
                type: Object as PropType<object>,
                default: (): object => ({}),
            },
        },

        data() {
            return {
                skills: [] as SkillModel[],
            }
        },

        computed: {
            isAllDone(): boolean {
                return this.value.levels.every(({ status }) => status === 'completed' )
            },
        },

        mounted() {
            this.fetchSkills()
        },

        methods: {
            async fetchSkills(): Promise<void> {
                this.skills = []
                await this.$store.load('Journeys')
                this.skills = await this.$store.dispatch('Journeys/getSkills', this.value.id)
            },

            onClick(event: PointerEvent, learning: LearningModel<'module' | 'container' | 'development-plan'>): void {
                if (learning.context.status !== 'locked')
                    return

                event.stopPropagation()

                this.$dialog.modal(JourneyContentDependencyModal, {
                    value: learning,
                    groupId: this.value.id,
                })
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .journey {
        &__level {
            position: relative;
            padding: 0 0 spacer(6) spacer(5);
            border-left-width: $border-width * 2;

            &--completed {
                border-left-style: solid;
                border-left-color: $success;
            }

            &--incomplete {
                border-left-style: dashed;
                border-left-color: $success;
            }

            &--locked {
                border-left-style: solid;
                border-left-color: $border-color;
            }

            &__icon {
                position: absolute;
                top: 0;
                left:0;
                transform: translateX(-50%)
            }

            &__icon-last {
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translateX(-50%)
            }
        }
    }
</style>