<template>
    <component
        :is="navComponent"
        v-if="type"
        :key="activeUserType"
        v-bind="$attrs"
    >
        <!--
            Admin navigation items
        -->
        <template v-if="activeUserType === 'admin'">
            <navigation-item
                :label="$t('NAV.HOME')"
                icon="home-alt"
                :to="{ name: 'dashboard' }"
            />
            <navigation-item
                :label="$t('NAV.STRUCTURE')"
                icon="building"
                :visible="$hasFeatures(['Organization', 'SMS', 'WhitelabelConfiguration'])"
            >
                <navigation-item
                    :label="$t('STRUCTURE.DIVISIONS.TITLE')"
                    :to="{ name: 'departments.index' }"
                    :visible="$can('organization')"
                />
                <navigation-item
                    :label="$t('USER_TYPE.TITLE')"
                    :to="{ name: 'permissions.index' }"
                    :visible="$can('organization')"
                />
                <navigation-item
                    :label="$t('STRUCTURE.JOBPROFILES.TITLE')"
                    :to="{ name: 'jobProfiles.index' }"
                    :visible="$can('organization')"
                />
                <navigation-item
                    :label="$t('COMMUNICATION.TITLE')"
                    :to="{ name: 'communication.index' }"
                    :visible="$hasFeatures('Organization') && $can('mailNotification')"
                />
                <navigation-item
                    :label="$t('SECURITY.TITLE')"
                    :to="{ name: 'settings.security' }"
                    :visible="$hasFeatures('Organization')"
                />
                <navigation-item
                    :label="$t('BRANDING.TITLE')"
                    :to="{ name: 'whitelabels.index' }"
                    :visible="$hasFeatures('WhitelabelConfiguration')"
                />
                <navigation-item
                    :label="`${$t('INTEGRATION_HUB.TITLE')}`"
                    :to="{ name: 'settings.integration' }"
                    :visible="$hasFeatures('ApiAccess')"
                />
            </navigation-item>
            <navigation-item
                :label="$t('NAV.USERMANAGEMENT')"
                :to="{ name: 'users.index' }"
                icon="users"
                :visible="$hasFeatures('UserManagement')"
            />
            <navigation-item
                label="Knowbot"
                :to="{ name: 'knowbot.training.index' }"
                icon="magic-wand-sparkles"
                :visible="$hasFeatures('Knowbot')"
            />
            <navigation-item
                :label="$t('NAV.EDUCATION')"
                icon="graduation-cap"
                :visible="$hasFeatures(['Course', 'ModuleMaker', 'AcademyManagement'])"
            >
                <navigation-item
                    :label="$t('TERMS.MODULES')"
                    :to="{ name: 'modules.index' }"
                    :visible="$hasFeatures('ModuleMaker')"
                />
                <navigation-item
                    :label="$t('ACADEMY.TITLE')"
                    :to="{ name: 'academy.index' }"
                    :visible="$hasFeatures('AcademyManagement') && $can('learningPathRead')"
                />
                <navigation-item
                    :label="$tc('TERMS.PLANS', 2)"
                    :to="{ name: 'developmentPlans.index' }"
                    :visible="$hasFeatures(['SkillsDeveloper', 'Checklists', 'ActivityPlans'])"
                />
                <navigation-item
                    :label="$tc('SKILLS.TITLE', 2)"
                    :to="{ name: 'skills.index' }"
                    :visible="$hasFeatures('SkillsManagement') && $can('skillRead')"
                />
                <navigation-item
                    :label="$t('TAG.TITLE')"
                    :to="{ name: 'tags.index' }"
                />
                <navigation-item
                    :label="$t('TERMS.CATEGORIES')"
                    :to="{ name: 'categories.index' }"
                />
                <navigation-item
                    :label="$t('PREMIUM_LEARNING.TITLE')"
                    :to="{ name: 'premiumLearning.index' }"
                    :visible="$hasFeatures('PremiumLearning')"
                />
            </navigation-item>
            <navigation-item
                :label="$t('SHARING.TITLE')"
                :to="{ name: 'sharing.index' }"
                icon="share-alt"
                :visible="$hasFeatures('Sharing') && $can('sharingRead')"
            />
            <navigation-item
                :label="$t('NAV.COURSE')"
                :to="{ name: 'courses.index' }"
                icon="calendar-day"
                :visible="$hasFeatures('Course')"
            />
            <navigation-item
                :label="$t('NAV.ANALYTICS')"
                icon="analytics"
                :visible="$hasFeatures([
                    'AnalyticsDashboard',
                    'AnalyticsReports',
                    'InsightsLearners',
                    'InsightsContent'
                ])"
            >
                <navigation-item
                    :label="$t('ANALYTICS.OVERVIEW.TITLE')"
                    :to="{ name: 'analytics.index' }"
                    :visible="$hasFeatures('AnalyticsDashboard')"
                />
                <navigation-item
                    :label="$t('ANALYTICS.REPORTS')"
                    :to="{ name: 'analyticsReports.index' }"
                    :visible="$hasFeatures('AnalyticsReports')"
                />
                <navigation-item
                    :label="$t('NAV.SURVEY')"
                    :to="{ name: 'modules.overview.index' }"
                    :visible="$hasFeatures('AnalyticsReports')"
                />
                <navigation-item
                    :label="$t('INSIGHTS.LEARNERS.TITLE')"
                    :to="{ name: 'insights.learners.index' }"
                    :visible="$hasFeatures('InsightsLearners')"
                />
                <navigation-item
                    :label="$t('INSIGHTS.CONTENT.TITLE')"
                    :to="{ name: 'insights.content.index' }"
                    :visible="$hasFeatures('InsightsContent')"
                />
                <navigation-item
                    :label="$t('INSIGHTS.SAVED_FILTERS.TITLE')"
                    :to="{ name: 'insights.filters.index' }"
                    :visible="$hasFeatures(['InsightsLearners', 'InsightsContent'])"
                />
            </navigation-item>
        </template>

        <!--
            Client navigation items
         -->
        <template v-if="activeUserType === 'client'">
            <navigation-item
                :label="$t('NAV.HOME')"
                icon="home-alt"
                :to="{ name: 'dashboard' }"
            />
            <navigation-item
                :label="$t('LEARNING.MY_LEARNING')"
                icon="graduation-cap"
                :visible="$hasFeatures('AcademyManagement')"
                :to="{ name: 'learning.index' }"
            />
            <navigation-item
                label="Knowbot"
                icon="magic-wand-sparkles"
                :visible="$hasFeatures('Knowbot') && $device.isMobile && canUseKnowbot"
                @click="openKnowbotModal"
            />
            <navigation-item
                :label="$t('SOCIAL_WALL.TITLE')"
                icon="messages"
                :visible="$hasFeatures('SocialWall')"
                :to="{ name: 'socialWall.index' }"
            />
            <navigation-item
                :label="$t('LEARNING.LIBRARY.TITLE')"
                icon="compass"
                :visible="$hasFeatures('PublicLearning')"
                :to="{ name: 'learning.library.index' }"
            />
            <navigation-item
                :label="$t('MANAGER.MY_TEAM')"
                icon="users"
                :visible="$hasFeatures('MiddleManagement')"
                :to="{ name: 'manager.team.index' }"
                :options="{ count: managerActionCount }"
            />
        </template>

        <!--
            Core navigation items
        -->
        <template v-if="activeUserType === 'lb'">
            <navigation-item
                :label="$t('NAV.PLATFORMS')"
                icon="globe"
                :to="{ name: 'organizations.index' }"
            />
            <navigation-item
                :label="$t('NAV.USERMANAGEMENT')"
                icon="users"
                :visible="$hasFeatures('UserManagement')"
                :to="{ name: 'users.index' }"
            />
            <navigation-item
                :label="$t('NAV.STATISTICS')"
                icon="analytics"
                :to="{ name: 'metrics.export' }"
            />
            <navigation-item
                :label="$t('NAV.DEFAULT_DASHBOARD')"
                icon="tachometer"
                :to="{ name: 'dashboard.default' }"
            />
            <navigation-item
                :label="$t('NAV.DEFAULT_EDUCATION')"
                icon="graduation-cap"
                :visible="$hasFeatures(['Course', 'ModuleMaker', 'Academy'])"
            >
                <navigation-item
                    :label="$t('TERMS.MODULES')"
                    :visible="$hasFeatures('ModuleMaker')"
                    :to="{ name: 'modules.index' }"
                />
                <navigation-item
                    :label="$t('ACADEMY.TITLE')"
                    :visible="$hasFeatures('AcademyManagement')"
                    :to="{ name: 'academy.index' }"
                />
                <navigation-item
                    :label="$t('TAG.TITLE')"
                    :to="{ name: 'tags.index' }"
                />
            </navigation-item>
            <navigation-item
                icon="envelope"
                :label="$t('COMMUNICATION.TITLE')"
                :to="{ name: 'communication.index' }"
                :visible="$can('mailNotification')"
            />
        </template>

        <!--
            Fixed navigation items with offset enabled so they
            can be placed elsewhere in any navigation component
        -->
        <navigation-item
            v-if="activeUserType === 'admin' && $device.isDesktop"
            :label="$t('TERMS.HELP')"
            icon="square-question"
            :options="{ class: 'intercom-trigger' }"
            offset
        />
        <navigation-item
            :label="$t('NOTIFICATION.TITLE')"
            icon="bell"
            :options="{ count: notificationUnreadCount }"
            offset
            @click="onClickNotifications"
        />
        <!-- Notifications sidebar -->
        <notifications-sidebar
            v-model="notificationsOpen"
            :right="type === 'top'"
        />
    </component>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { get } from 'vuex-pathify'
    import NavigationSidebar from './NavigationSidebar.vue'
    import NavigationTopbar from './NavigationTopbar.vue'
    import NavigationItem from './NavigationItem.vue'
    import NotificationsSidebar from '@modules/Notifications/components/NotificationsSidebar.vue'

    import KnowbotMixin from '@modules/Knowbot/mixins/knowbot.mixin'

    export default (Vue as VueConstructor<
        Vue
        & InstanceType<typeof KnowbotMixin>
    >).extend({
        components: {
            NavigationSidebar,
            NavigationTopbar,
            NavigationItem,
            NotificationsSidebar,

        },

        mixins: [KnowbotMixin],

        props: {
            type: {
                type: String as PropType<string>,
                default: null,
                validator: (value: string): boolean => ['top', 'left'].includes(value),
            },
        },

        data() {
            return {
                notificationsOpen: false,
            }
        },

        computed: {
            activeUserType: get('Auth/activeUserType'),
            notificationUnreadCount(): number {
                return this.$store.getters['Notifications/unread']?.length ?? 0
            },
            managerActionCount(): number {
                return this.$store.state.Manager
                    ? this.$store.get('Manager/totalActionCount')
                    : 0
            },

            navComponent(): string {
                if (this.type === 'top') {
                    return 'navigation-topbar'
                }

                return 'navigation-sidebar'
            },
        },

        watch: {
            activeUserType(value, oldValue): void {
                if (value !== oldValue)
                    this.fetchData()
            },
        },

        created() {
            this.fetchData()
        },

        methods: {
            async fetchData(): Promise<void> {
                await this.$store.load('Notifications')
                await this.$store.cache.dispatch('Notifications/list')
                if (this.$hasFeatures('MiddleManagement')) {
                    await this.$store.load('Manager')
                    await this.$store.cache.dispatch('Manager/actionCount')
                }
            },

            onClickNotifications(): void {
                this.$device.isMobile
                    ? this.$router.push({ name: 'notifications.index' })
                    : this.notificationsOpen = !this.notificationsOpen
            },
        },
    })
</script>
