import { Node } from '@tiptap/core'

export interface VariableOptions {
    /** Custom html element of editor input/output */
    tag: string;
    /** Allowed variables */
    variables: string[];
    /** Variable editor display transformer */
    variableTransformer: ((variable: string) => string) | null;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        variable: {
            setVariable: (attributes: { name: string }) => ReturnType;
        };
    }
}

const Variable = Node.create<VariableOptions>({
    name: 'variable',
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,

    addOptions() {
        return {
            tag: 'variable',
            variables: [],
            variableTransformer: null,

        }
    },

    addAttributes() {
        return {
            id: {
                default: null,
                isRequired: true,
                parseHTML: (element): string | null => element.getAttribute('data-id'),
            },
        }
    },

    parseHTML() {
        return this.options.variables.map((variable) => ({
            tag: `${this.options.tag}[data-id="${variable}"]`,
        }))
    },

    renderHTML({ node }) {
        return [
            this.options.tag,
            { 'data-id': node.attrs.id },
        ]
    },

    renderText({ node }) {
        return `:${node.attrs.id}:`
    },

    addNodeView() {
        return ({ node }) => {
            const dom = document.createElement('span')
            dom.innerHTML = this.options.variableTransformer?.(node.attrs.id) || node.attrs.id
            dom.classList.add('variable')

            return {
                dom,
            }
        }
    },

    addCommands() {
        return {
            setVariable: ({ name }) => (props): boolean => {
                if (!name || !this.options.variables.includes(name))
                    return false

                return props.commands.insertContent([
                    {
                        type: this.name,
                        attrs: {
                            id: name,
                        },
                    },
                    {
                        type: 'text',
                        text: ' ',
                    },
                ])
            },
        }
    },
})

export default Variable