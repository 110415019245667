<template>
    <flat-modal
        #default="{ resolve }"
        centered
        size="lg"
    >
        <div
            ref="gallery"
            class="media-gallery no-outline"
            tabindex="0"
            @keyup.left="prev"
            @keyup.right="next"
            @keyup.esc="resolve('close')"
            @mouseenter="hovering = true"
            @mouseleave="hovering = false"
        >
            <component
                :is="componentType"
                ar="4:3"
                class="bg-900"
                :delay="0"
                :src="src"
            >
                <template #loading>
                    <image-aspect-ratio
                        ar="4:3"
                        class="bg-900"
                    >
                        <fa
                            spin
                            size="3x"
                            class="text-white centerer"
                            :icon="['fal', 'circle-notch']"
                        />
                    </image-aspect-ratio>
                </template>
            </component>
            <div
                v-if="showNav"
                class="media-gallery__prev text-white px-3 d-flex flex-column justify-content-center pointer"
                @click="prev"
            >
                <fa
                    class="media-gallery__icon"
                    size="3x"
                    :icon="['fal', 'angle-left']"
                />
            </div>

            <div
                v-if="showNav"
                class="media-gallery__next text-white px-3 d-flex flex-column justify-content-center pointer"
                @click="next"
            >
                <fa
                    class="media-gallery__icon"
                    size="3x"
                    :icon="['fal', 'angle-right']"
                />
            </div>

            <div
                class="media-gallery__close text-white p-3 pointer"
                @click="resolve('close')"
            >
                <fa
                    size="2x"
                    :icon="['fal', 'times']"
                />
            </div>
        </div>
    </flat-modal>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { FlatModal } from '@common/Modals'
    import ImageLazy from '@common/Images/ImageLazy.vue'
    import VideoLazy from '@common/Video/VideoLazy.vue'
    import ImageAspectRatio from '@common/Images/ImageAspectRatio.vue'

    /**
     * Image gallery, you can pass a list of urls to images and
     * it will display them in a modal with navigation and keyboard support.
     *
     * <kbd>←</kbd> Previous image
     *
     * <kbd>→</kbd> Next image
     *
     * <kbd>Esc</kbd> Close dialog
     */
    export default Vue.extend({
        components: { FlatModal, ImageLazy, VideoLazy, ImageAspectRatio },

        props: {
            /** List of media and types */
            media: {
                type: Array as PropType<{ src: string; type: string }[]>,
                required: true,
            },
            /** Start on a specific index in the list array */
            index: { type: Number as PropType<number>, default: 0 },
        },

        data() {
            return {
                currentIndex: this.index,
                hovering: false,
            }
        },

        computed: {
            isMultiple(): boolean {
                return this.media.length > 1
            },

            showNav(): boolean {
                if (!this.isMultiple) {
                    return false
                }

                return this.hovering
            },

            src(): string {
                return this.media[this.currentIndex].src
            },

            type(): string {
                return this.media[this.currentIndex].type
            },

            componentType(): string {
                return this.type === 'image' ? 'image-lazy' : 'video-lazy'
            },
        },

        mounted() {
            (this.$refs.gallery as HTMLElement).focus()
        },

        methods: {
            prev(): any {
                if (!this.isMultiple) return

                if (this.currentIndex === 0) {
                    return this.currentIndex = (this.media.length - 1)
                }

                this.currentIndex--
            },

            next(): any {
                if (!this.isMultiple) return

                if (this.currentIndex === (this.media.length - 1)) {
                    return this.currentIndex = 0
                }

                this.currentIndex++
            },
        },

    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .media-gallery {
        position: relative;

        &__prev,
        &__next {
            pointer-events: none;
            position: absolute;
            top: 0;
            bottom: 0;
        }

        &__icon {
            pointer-events: initial;
        }

        &__prev {
            left: 0;
            background: linear-gradient(to right, rgba($gray-900, .75) 0%, rgba($gray-900, 0) 100%);
        }

        &__next {
            right: 0;
            background: linear-gradient(to left, rgba($gray-900, .65) 0%, rgba($gray-900, 0) 100%);
        }

        &__close {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
</style>
