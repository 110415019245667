function hasAttended(session?: SessionAttentionModel): boolean {
    return session?.attended === true
}

function isAttending(session?: SessionAttentionModel): boolean {
    return session?.userStatus === 'attending' && typeof session?.userStatus !== 'boolean'
}

function isWaiting(session?: SessionAttentionModel): boolean {
    return session?.userStatus === 'waitingList'
}

function isDeclining(session?: SessionAttentionModel): boolean {
    return session?.userStatus === 'notAttending'
}

function isInvited(session?: SessionAttentionModel): boolean {
    return session?.userStatus === 'invited'
}

function isRequested(session?: SessionAttentionModel): boolean {
    return session?.userStatus === 'requested'
}

function isPlanned(session?: SessionAttentionModel): boolean {
    return session?.status === 'PLANNED'
}

export function canRegisterSession(
    sessionStatus?: SessionStatus,
    markAttendance?: attendanceType,
    userStatus?: UserHasCourseSession['status'],
    attended?: UserHasCourseSession['attended'],
): boolean {
    return (
        sessionStatus === 'HELD'
        && markAttendance === 'user'
        && (userStatus === 'attending' || userStatus === 'requested')
        && attended === null
    )
}

export function getAttendingSession(sessions?: SessionModel[]): SessionModel | null {
    return sessions?.find((session) => {
        return session.user_has_course_session?.status === 'attending'
            && typeof session.user_has_course_session?.attended !== 'boolean'
    }) ?? null
}

export function getWaitingSession(sessions?: SessionModel[]): SessionModel | null {
    return sessions?.find((session) => {
        return session.user_has_course_session?.status === 'waitingList'
            && typeof session.user_has_course_session?.attended !== 'boolean'
    }) ?? null
}

export function getUserStates(session?: SessionAttentionModel): SessionUserStatesModel {
    return {
        hasAttended: hasAttended(session),
        isAttending: isAttending(session),
        isPlanned: isPlanned(session),
        isDeclining: isDeclining(session),
        isInvited: isInvited(session),
        isWaiting: isWaiting(session),
        isRequested: isRequested(session),
    }
}


export function getUserStatus(
    isExplorable: boolean,
    session?: SessionAttentionModel,
): { variant: string; label: string; style?: 'outline' | null } | null {
    const result = getUserStates(session)

    if (!session?.userStatus) {
        if (isExplorable || !result.isPlanned)
            return null

        return {
            variant: 'gray',
            label: 'LEARNING.COURSE_SESSION_INVITE',
        }
    }

    if (typeof session.attended === 'boolean')
        return {
            variant: 'gray',
            label: session.attended
                ? 'SESSION.USER.STATUS.ATTENDED'
                : 'SESSION.USER.STATUS.NOT_ATTENDED',
        }

    if (result.isAttending)
        return {
            variant: result.isPlanned ? 'success' : 'gray',
            label: 'SESSION.USER.STATUS.ENROLLED',
        }

    if (session.userStatus === 'invited' && result.isPlanned)
        return {
            variant: 'danger',
            label: session.signupDeadline
                ? 'COURSE.SESSION_REGISTER_BEFORE'
                : 'COURSE.SESSION_RESPOND_INVITATION',
        }

    if (result.isRequested)
        return {
            variant: result.isPlanned ?  'warning' : 'gray',
            label: 'SESSION.USER.STATUS.REQUESTED',
        }

    if (result.isDeclining)
        return {
            variant: result.isPlanned ?  'danger' : 'gray',
            label: 'SESSION.USER.STATUS.DECLINED',
            style: result.isPlanned ? 'outline' : null,
        }

    if (result.isWaiting)
        return {
            variant: result.isPlanned ?  'warning' : 'gray',
            label: 'SESSION.USER.STATUS.WAITINGLIST',
            style: result.isPlanned ? 'outline' : null,
        }

    return null
}
