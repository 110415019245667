import store from '@store'

/**
 * ### Check if logged in user has permission
 *
 * @param permissionNames name permissions
 * @param strict Make sure that user has all permissions asked for in array
 */
export function hasPermissions(permissionNames: string | string[], strict = false): boolean {
    permissionNames = Array.isArray(permissionNames)
        ? permissionNames
        : [permissionNames]

    const searchMethod = strict ? 'every' : 'some'
    const permissions: string[] = store.get('Auth/permissions')

    // If user has root permission then allow access to everything
    if (permissions.includes('root')) {
        return true
    }

    return permissionNames[searchMethod]((permissionName) => {
        return permissions.includes(permissionName)
    })
}