<template>
    <component
        :is="cardTemplateMap[item.type]"
        :value="item.data"
        :context="item.context"
        :clickable="clickable"
        :no-footer="noFooter"
        :no-lazy-img="noLazyImg"
        :horizontal="horizontal"
        :learning-info="learningInfo"
        :is-explorable="isExplorable"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import ModuleCard from '@modules/Modules/components/ModuleCard.vue'
    import AcademyCard from '@modules/Academy/components/AcademyCard.vue'
    import CourseCard from '@modules/Courses/components/CourseCard.vue'
    import DevelopmentPlanCard from '@modules/SkillsDeveloper/components/DevelopmentPlans/DevelopmentPlanCard.vue'

    export default Vue.extend({
        components: {
            ModuleCard,
            AcademyCard,
            CourseCard,
            DevelopmentPlanCard,
        },

        props: {
            /**
            * Learning type object
            * Can be of type learning path, module or course
            */
            item: {
                type: Object as PropType<LearningModel>,
                required: true,
            },
            /**
             * Fully disable any clickability with in component, useful when using
             * slots to take over navigation.
             * - Hides play button on hover
             * - Disables clicking prebuilt container and navigate to learning path
             */
            clickable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Completely disable footer of the card
             */
            noFooter: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Disable lazyloading on card image
             */
            noLazyImg: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
            * Used for dispalying the cards on horizontal view
            */
            horizontal: {
                type: [Boolean, Object] as PropType<boolean | Record<string, any>>,
                default: false,
            },
            /**
             * Learning info to display learning duration, content count
             */
            learningInfo: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Learning content explorable check
             */
            isExplorable: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                cardTemplateMap: {
                    'module': 'ModuleCard',
                    'academy': 'AcademyCard',
                    'container': 'AcademyCard',
                    'course': 'CourseCard',
                    'development-plan': 'DevelopmentPlanCard',
                } as Record<string, string>,
            }
        },
    })
</script>
