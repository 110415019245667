import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
    {
        name: '401',
        path: '/401',
        props: true,
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "errorPages" */
                './components/401.vue'
            ),
    },
    {
        name: '403',
        path: '/403',
        props: true,
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "errorPages" */
                './components/403.vue'
            ),
    },
    {
        name: '404',
        path: '/404',
        props: true,
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "errorPages" */
                './components/404.vue'
            ),
    },
    {
        name: '500',
        path: '/500',
        component: { template: '<div>500: Whoops, something went wrong!</div>' },
    },
]

export default routes
