<template>
    <b-tr>
        <b-td :colspan="scope.columns">
            <div class="d-flex align-items-center flex-wrap">
                <!-- Pagination -->
                <b-pagination
                    v-if="totalItems > perPage"
                    :value="currentPage"
                    :per-page="perPage"
                    :total-rows="totalItems"
                    :limit="$device.isDesktop ? 5 : 3"
                    class="mb-0"
                    first-number
                    last-number
                    @input="value => $emit('update:currentPage', value)"
                >
                    <template #next-text>
                        <fa :icon="['fal', 'arrow-right']" />
                    </template>
                    <template #prev-text>
                        <fa :icon="['fal', 'arrow-left']" />
                    </template>
                </b-pagination>

                <!-- Pagination text -->
                <small
                    v-if="totalItems > 0"
                    class="text-muted ml-auto d-none d-md-block mr-3"
                >
                    <i18n
                        v-if="selectionLength"
                        path="TERMS.SELECTED_OF_TOTAL"
                    >
                        <template #count>
                            <strong>{{ selectionLength }}</strong>
                        </template>
                        <template #total>
                            {{ (totalItems || 0) | number }}
                        </template>
                    </i18n>
                    <span
                        v-else
                        v-text="pagePosition"
                    />
                </small>

                <!-- Page size selector -->
                <b-form-select
                    v-if="showPageSizeSelector"
                    class="ml-auto ml-md-0"
                    :value="perPage"
                    :style="{ width: '4.5rem' }"
                    :options="pageLengths"
                    @input="value => $emit('update:perPage', value)"
                />
            </div>
        </b-td>
    </b-tr>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { BPagination, BFormSelect, BTr, BTd } from 'bootstrap-vue'
    import { TranslateResult } from 'vue-i18n'

    export default Vue.extend({
        components: {
            BPagination,
            BFormSelect,
            BTr,
            BTd,
        },
        props: {
            scope: {
                type: Object as PropType<{ columns: number; fields: any[]; items: any[] }>,
                required: true,
            },
            perPage: {
                type: Number as PropType<number>,
                default: 10,
            },
            hidePageSize: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            currentPage: {
                type: Number as PropType<number>,
                default: 1,
            },
            totalItems: {
                type: Number as PropType<number>,
                default: 1,
            },
            pageLengths: {
                type: Array as PropType<number[]>,
                default: (): number[] => ([10, 25, 50]),
            },
            selectionLength: {
                type: Number as PropType<number>,
                default: 0,
            },
        },
        computed: {
            showPageSizeSelector(): boolean {
                return (this.totalItems > Math.min(...this.pageLengths)) && !this.hidePageSize
            },

            pagePosition(): string {
                const start = (this.currentPage - 1) * this.perPage + 1
                const end = Math.min((this.currentPage - 1) * this.perPage + this.perPage, this.totalItems)

                return `${start}-${end} ${this.$t('TERMS.OF')} ${this.$filter('number')(this.totalItems)}`
            },

            footerText(): TranslateResult | string {
                if (this.selectionLength > 0)
                    return this.$t('TERMS.SELECTED_OF_TOTAL', {
                        count: this.selectionLength,
                        total: this.totalItems,
                    })

                return this.pagePosition
            },
        },
    })
</script>
<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .pagination {
        gap: spacer(2);

        ::v-deep .page-item {
            &:not(.active) .page-link {
                border: 0;
                background: transparent;
                color: $body-color;
            }

            .page-link {
                border-radius: $border-radius;
            }

            &.disabled .page-link {
                color: color('gray');
            }
        }
    }
</style>