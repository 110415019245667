<template>
    <div class="card-file-mini shadow-sm rounded p-2 d-flex align-items-center">
        <fa
            fixed-width
            size="lg"
            :icon="['fal', fileIcon]"
        />

        <div class="flex-grow-1 d-flex flex-column flex-item-truncated line-height-1 ml-2 mr-3">
            <small v-text="value.name" />
            <small class="text-muted text-smaller">{{ value.size | prettyBytes(1) }}</small>
        </div>
        <fa
            v-if="$listeners.delete"
            class="text-danger-hover"
            :icon="['fal', 'times']"
            @click.stop="destroy"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { getFaForMime } from '@utils'

    /**
     * Card File Mini Component
     *
     * Component that represents a file as a very small card.
     * Useful to display file objects inside dropzones fx.
     *
     * - Delete action (optional if listener is passed)
     */
    export default Vue.extend({
        props: {
            /**
             * File object required to display card
             */
            value: {
                type: File as PropType<File>,
                required: true,
            },
        },

        computed: {
            fileIcon(): string {
                return getFaForMime(this.value.type)
            },
        },

        methods: {
            destroy(): void {
                /**
                 * Triggers delete button is pressed, button is only visible
                 * when a delete listener is attached.
                 *
                 * @property {Object} value The passed in file object via `value` prop
                 * @property {Object} key VNode key if using `:key=""` on this component
                 */
                this.$emit('delete', this.value, this.$vnode.key)
            },
        },
    })
</script>

<style lang="scss" scoped>
    // @see https://css-tricks.com/flexbox-truncated-text/
    .flex-item-truncated {
        &, & > * {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
</style>
