<template functional>
    <div class="busy-table skeleton-container">
        <div
            v-for="row in props.perPage"
            :key="row"
            class="busy-table__row"
        >
            <div
                v-for="(col, index) in props.columns"
                :key="index"
                class="busy-table__col"
            >
                <div class="skeleton-text w-75" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    export default Vue.extend({
        props: {
            perPage: { type: Number as PropType<number>, default: 10 },
            columns: { type: Number as PropType<number>, default: 4 },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .busy-table {
        margin: - $table-cell-padding;

        &__row {
            padding: $table-cell-padding;
            border-bottom: $table-border-width solid $table-border-color;
            display: flex;
            justify-content: space-evenly;
        }

        &__col {
            margin-right: spacer(3);
            flex-grow: 1;
        }
    }
</style>
