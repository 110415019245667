<template>
    <component
        :is="nextModuleId ? 'module-player-start-btn' : 'fallback'"
        #default="{ start, btnProps }"
        :module-id="nextModuleId"
        :container-id="value.id"
    >
        <card
            class="academy-card"
            :class="{
                'card-hover pointer': clickable,
            }"
            border="none"
            :body-class="{ 'pt-1 pb-0': !!horizontalLocal }"
            :footer-class="{ 'pb-1': !!horizontalLocal }"
            :horizontal="horizontalLocal"
            v-bind="$attrs"
            @click="onClick"
        >
            <!-- Header image -->
            <template #before>
                <div class="position-relative">
                    <!-- Header -->
                    <image-lazy
                        :src="value.thumbnailPath"
                        :ar="'16:9'"
                        :class="[
                            horizontalLocal ? 'rounded-left' : 'rounded-top',
                            { 'grayscale': context && context.status === 'locked' }
                        ]"
                        :no-lazy="noLazyImg"
                        fill-blur
                    >
                        <template #fallback>
                            <div
                                class="embed-responsive embed-responsive-16by9 bg-light text-gray-dark"
                                :class="[ horizontalLocal ? 'rounded-left' : 'rounded-top' ]"
                            >
                                <inline-svg
                                    class="centerer w-35"
                                    :src="require('@assets/icons/icon-signs-post.svg')"
                                />
                            </div>
                        </template>
                    </image-lazy>
                    <!-- Start content button -->
                    <btn
                        v-if="!$device.isMobile && clickable && canStartModule"
                        class="start-btn position-absolute"
                        :class="!!horizontalLocal ? 'top-1 left-1' : 'bottom-n2 left-3'"
                        v-bind="btnProps"
                        pill
                        @click.stop="start"
                    />
                    <div
                        v-if="cardInfo"
                        class="position-absolute right-0 bottom-0 badge badge-pill rounded-0 rounded-top-left mw-100 text-truncate"
                        :class="`badge-${cardInfo.variant}`"
                        v-text="cardInfo.text"
                    />
                </div>
            </template>
            <template #default>
                <small
                    class="text-muted"
                    v-text="$tc('ACADEMY_MANAGEMENT.TITLE_PLURAL', 1)"
                />
                <!-- Academy name -->
                <h6
                    v-line-clamp:[lineClampArg]="2"
                    class="mb-0"
                    :class="{ 'text-gray': context && context.status === 'locked' }"
                    v-text="value.name"
                />
            </template>

            <template #footer>
                <!-- Search: found with in message -->
                <small
                    v-if="matchesCount"
                    class="text-info mb-3 d-block"
                    v-text="$tc('LEARNING.SEARCH.MODULE_MATCH', matchesCount)"
                />
                <!-- Learning attributes -->
                <ul
                    v-if="!horizontalLocal || learningInfo"
                    class="inline-list small text-muted mb-0"
                >
                    <li
                        v-if="modulesCount > 0"
                        v-text="$tc('MODULE.COUNT', modulesCount)"
                    />
                    <li
                        v-if="coursesCount > 0"
                        v-text="$tc('EVENT.COUNT', coursesCount)"
                    />
                </ul>
                <!-- Progress bar -->
                <div
                    v-if="context && context.stats && context.stats.completionRate > 0
                        && context.stats.completionRate !== 100"
                    class="d-flex align-items-center"
                >
                    <b-progress
                        class="flex-grow-1"
                        :value="context.stats.completionRate"
                        variant="primary"
                        height="6px"
                    />
                    <small
                        class="font-weight-bold ml-2"
                        v-text="$filter('pct')(Math.round(context.stats.completionRate))"
                    />
                </div>
            </template>
        </card>
    </component>
</template>

<script lang="ts">
    import Vue, { CreateElement, PropType, RenderContext, VNode, VueConstructor } from 'vue'
    import { Route } from 'vue-router'
    import Card, { HorizontalOptions } from '@common/Card/Card.vue'
    import ImageLazy from '@common/Images/ImageLazy.vue'
    import { canStartContainerModule } from '../utils/user-content'
    import LearningStatusMixin from '@modules/Learning/mixins/learning-status.mixin'
    import ModulePlayerStartBtn from '@modules/ModulePlayer/components/ModulePlayerStartBtn.vue'

    export default (Vue as VueConstructor<
        Vue
        & InstanceType<typeof LearningStatusMixin>
    >).extend({
        components: {
            fallback: {
                functional: true,
                render: (_h: CreateElement, { scopedSlots }: RenderContext): VNode | VNode[] =>
                    scopedSlots.default?.({}) ?? [],
            },
            Card,
            ImageLazy,
            ModulePlayerStartBtn,
        },

        mixins: [LearningStatusMixin],

        props: {
            value: {
                type: Object as PropType<ContainerDataModel>,
                required: true,
            },

            context: {
                type: Object as PropType<ContainerContextModel | null>,
                default: null,
            },

            clickable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Disable lazyloading on card image
             */
            noLazyImg: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            horizontal: {
                type: [Boolean, Object] as PropType<boolean | HorizontalOptions>,
                default: false,
            },

            /**
             * Learning info to display learning duration, content count
             */
            learningInfo: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            lineClampArg(): string | null {
                return this.$device.isDesktop ? 'fixed' : null
            },

            nextModule(): NextModule | null {
                return this.context?.nextModule ?? null
            },

            nextModuleId(): number | null {
                return this.nextModule?.moduleId ?? null
            },

            canStartModule(): boolean {
                return this.context?.status !== 'locked' && canStartContainerModule(this.nextModule)
            },

            horizontalLocal(): boolean | HorizontalOptions {
                if (typeof this.horizontal === 'object')
                    return this.horizontal

                return this.horizontal
                    ? {
                        before: 'col-5',
                        row: 'align-items-center',
                    }
                    : false
            },

            cardInfo(): ModuleStatusData | null  {
                const deadline = this.context?.nextModule?.deadlineAt ?? this.context?.deadlineAt

                return this.getModuleStatusData({
                    ...this.context,
                    deadlineAt: deadline,
                })
            },

            modulesCount(): number {
                return this.context?.stats.moduleCount ?? this.value.counts?.modules ?? 0
            },

            coursesCount(): number {
                return this.context?.stats.courseCount ?? this.value.counts?.courses ?? 0
            },

            matchesCount(): number {
                return this.context?.matches?.moduleIds?.length || 0
            },
        },

        methods: {
            async onClick(): Promise<Route | void> {
                if (!this.clickable) return

                return this.$router.push({
                    name: 'learning.path.index',
                    params: {
                        academyId: this.value.id.toString(),
                        ...(this.context?.matches?.moduleIds) && {
                            searchedModules: JSON.stringify(this.context.matches.moduleIds),
                        },
                    },
                })
            },
        },
    })
</script>

<style lang="scss" scoped>
    .academy-card {
        .start-btn {
            transition: all 200ms ease-in-out;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-.5rem);
        }

        &:hover {
            .start-btn {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

</style>
