<template>
    <tiptap-dropdown
        #default="{ textColor, hide }"
        extension="textColor"
        icon="palette"
        label="Color"
    >
        <b-tabs
            class="pr-3 pl-3 pb-2"
            small
            lazy
            fill
        >
            <b-tab
                v-if="textColor.text"
                :title="$t('TIPTAP.TEXT_COLOR')"
            >
                <div class="d-flex flex-wrap m-n2">
                    <div class="w-25 p-1">
                        <tiptap-button
                            variant="white"
                            class="btn-icon border diagonal-line"
                            extension="color"
                            command="unsetColor"
                            pill
                        />
                    </div>
                    <div
                        v-for="(item, key) in textColor.colors"
                        :key="key"
                        class="w-25 p-1"
                    >
                        <tiptap-button
                            class="btn-icon"
                            :style="{
                                background: item,
                            }"
                            :label="''"
                            extension="color"
                            command="setColor"
                            :command-params="item"
                            pill
                        />
                    </div>
                    <div class="w-25 p-1">
                        <input
                            v-show="false"
                            ref="customColor"
                            type="color"
                            @change="onCustomColorSelected($event, hide)"
                        >
                        <btn
                            :icon="['fal', 'plus']"
                            class="border"
                            emphasis="medium"
                            pill
                            @click="$refs.customColor.click()"
                        />
                    </div>
                </div>
            </b-tab>
            <b-tab
                v-if="textColor.highlight"
                :title="$t('TIPTAP.HIGHLIGHT')"
            >
                <div class="d-flex flex-wrap m-n2">
                    <div class="w-25 p-1">
                        <tiptap-button
                            variant="white"
                            class="btn-icon border diagonal-line"
                            extension="highlight"
                            command="unsetHighlight"
                            pill
                        />
                    </div>
                    <div
                        v-for="(item, key) in textColor.colors"
                        :key="key"
                        class="w-25 p-1"
                    >
                        <tiptap-button
                            class="btn-icon"
                            :style="{
                                background: item,
                            }"
                            :label="''"
                            extension="highlight"
                            command="setHighlight"
                            :command-params="{ color: item }"
                            pill
                        />
                    </div>
                    <div class="w-25 p-1">
                        <input
                            v-show="false"
                            ref="customBackgroundColor"
                            type="color"
                            @change="onCustomBackgroundColorSelected($event, hide)"
                        >
                        <btn
                            :icon="['fal', 'plus']"
                            class="border"
                            emphasis="medium"
                            pill
                            @click="$refs.customBackgroundColor.click()"
                        />
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </tiptap-dropdown>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { BTabs, BTab } from 'bootstrap-vue'
    import { Inject } from './TiptapEditorProvider.vue'
    import TiptapDropdown from './TiptapDropdown.vue'
    import TiptapButton from './TiptapButton.vue'

    interface Refs {
        $refs: {
            customColor: HTMLInputElement
            customBackgroundColor: HTMLInputElement
        }
    }

    export default (Vue as VueConstructor<Vue & Refs & Inject>).extend({
        components: {
            BTabs,
            BTab,
            TiptapDropdown,
            TiptapButton,
        },

        inject: ['editor', 'extensions'],

        methods: {
            onCustomColorSelected(event: HTMLInputEvent, hide: () => void) {
                this.editor.chain()
                    .setColor(event.target.value)
                    .focus()
                    .run()

                hide()
            },

            onCustomBackgroundColorSelected(event: HTMLInputEvent, hide: () => void) {
                this.editor.chain()
                    .setHighlight({ color: event.target.value })
                    .focus()
                    .run()

                hide()
            },
        },
    })
</script>