interface MedalModel {
    id: number;
    class: 'medal-gold' | 'medal-silver' | 'medal-bronze' | 'text-gray-light';
    type: 'MEDALS.GOLD' | 'MEDALS.SILVER' | 'MEDALS.BRONZE' | 'MEDALS.FAILED';
}

const gold = {
    id: 1,
    class: 'medal-gold',
    type: 'MEDALS.GOLD',
} as MedalModel

const silver = {
    id: 2,
    class: 'medal-silver',
    type: 'MEDALS.SILVER',
} as MedalModel

const bronze = {
    id: 3,
    class: 'medal-bronze',
    type: 'MEDALS.BRONZE',
}

const failed = {
    id: 5,
    class: 'text-gray-light',
    type: 'MEDALS.FAILED',
} as MedalModel

export const medals = [
    gold,
    silver,
    bronze,
    failed,
] as MedalModel[]

export const medalMap = {
    1: gold,
    2: silver,
    3: bronze,
    5: failed,
} as { [key: number]: MedalModel }