<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            class="input-field"
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <froala-editor
                v-if="editor === 'froala'"
                v-model="internalValue"
                :placeholder="placeholder"
                :config="configLocal"
                :disabled="disabled"
                :upload-options="uploadOptions"
                :class="inputClass"
                :initialized="onInitialized"
                v-bind="$attrs"
            />
            <tiptap-editor
                v-else-if="editor === 'tiptap'"
                v-model="internalValue"
                :content-style="{ minHeight, maxHeight }"
                :class="inputClass"
                :disabled="disabled"
                :options="options"
                v-bind="$attrs"
            >
                <template
                    v-for="(_, slot) of $scopedSlots"
                    #[slot]="scope"
                >
                    <slot
                        :name="slot"
                        v-bind="scope"
                    />
                </template>
            </tiptap-editor>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'
    import MaxLengthMixin from './maxlength.mixin'
    import { FroalaEditor } from '@common/RichText'
    import { TiptapEditor, TiptapOptions } from '@common/RichText/Tiptap'
    /**
     * Rich text field component.
     *
     * - Validation baked in
     * - When using the `max` rule the input will display a character counter
     * - When using the `maxlength` prop, the input will not display the counter
     * - Supports s3 uploads on images and videos using `uploadOptions` prop
     * - Keeps track of image/video removals from our s3 bucket. Once you fire
     *   save contnet from any parent you can use the public method `cleanupMedia`
     *   from parent via $refs to remove any s3 assets that were marked for deletion.
     */
    export default (Vue as VueConstructor<Vue
        & InstanceType<typeof SharedField>
        & InstanceType<typeof MaxLengthMixin>
    >).extend({
        components: { FroalaEditor, TiptapEditor },
        mixins: [SharedField, MaxLengthMixin],
        props: {
            /**
             * Editor type, what features are available
             * Ported from ng implementation (`options.type`)
             *
             * @deprecated
             */
            type: {
                type: String as PropType<string>,
                default: 'simple',
                validator: (value: string): boolean => [
                    'inline',
                    'email',
                    'simple',
                    'advance',
                ].includes(value),
            },
            /**
             * Editor implementation to use
             * - Froala will be removed soon so refrain from using that one.
             */
            editor: {
                type: String as PropType<'froala' | 'tiptap'>,
                default: 'froala',
                validator: (value: string): boolean => ['froala', 'tiptap'].includes(value),
            },
            /**
             * Minimum height of text field
             */
            minHeight: {
                type: String as PropType<string>,
                default: '300px',
            },
            /**
             * Minimum height of text field
             */
            maxHeight: {
                type: String as PropType<string>,
                default: '600px',
            },
            /**
             * Froala config object overwrites can be passed
             * in here.
             */
            froalaConfig: {
                type: Object as PropType<object>,
                default: (): object => ({}),
            },
            /**
             * Editor component configuration object
             */
            options: {
                type: Object as PropType<TiptapOptions>,
                default: (): object => ({}),
            },

            /**
             * Upload options for s3, this will activate image & video uploaing
             * capabilities on the text editor.
             */
            uploadOptions: {
                type: Object as PropType<ResourceFroalaS3Params>,
                default: null,
                validator: ({ location, location_id }): boolean =>
                    !!location && !!location_id,
            },
        },

        data() {
            return {
                /**
                 * Current editor instance
                 *
                 * @public
                 */
                editorInstance: null as any | null,
                s3DeletionQueue: [] as string[],
            }
        },

        computed: {
            configLocal(): object {
                const configLocal = {
                    toolbarInline: false,
                    heightMin: parseInt(this.minHeight) || undefined,
                    heightMax: parseInt(this.maxHeight) || undefined,
                    videoMaxSize: 1024 * 1024 * 1000,
                    charCounterMax: this.maxLength ?? undefined,
                    type: this.type,
                    events: {
                        'froalaEditor.image.removed': this.onS3MediaRemove.bind(this),
                        'froalaEditor.video.removed': this.onS3MediaRemove.bind(this),
                    },
                }

                return {
                    ...configLocal,
                    ...this.froalaConfig,
                }
            },
        },

        methods: {
            onInitialized(editor: any): void {
                this.editorInstance = editor
            },
            /**
             * Add s3 key to deletion queue
             */
            onS3MediaRemove(_event: any, _editor: any, $el: any): void {
                const src: string = $el.context.currentSrc
                if (!src.startsWith(LB_ENV.common.s3.buckets.public.url)) return

                let [, key] = src.split(LB_ENV.common.s3.buckets.public.url)
                key = key.replace(/^\//g, '')

                if (key && !this.s3DeletionQueue.includes(key))
                    this.s3DeletionQueue.push(key)
            },

            /**
             * Clean up s3 keys that have been marked for deletion
             * This should be fired **AFTER** the content of this editor
             * has been saved in parent.
             *
             * @public
             */
            async cleanupMedia(): Promise<void> {
                // Make sure editors's text doesn't contain any of the keys in the queue
                // They could reappear when user undo's a image removal.
                const filteredKeys = this.s3DeletionQueue.filter((key) => {
                    return !this.internalValue.includes(key)
                })

                for (const key of filteredKeys) {
                    try {
                        await this.$store.dispatch('Resource/deletePublicKey', key)
                    } catch (e) {
                        console.log(e)
                    }
                }
            },
        },
    })
</script>
