import { Module } from 'vuex'
import { make } from 'vuex-pathify'
import Http from '@utils/Http'

const state: WhitelabelsThemesStoreState = {
    themes: [],
}

const store: Module<WhitelabelsThemesStoreState, any> = {
    namespaced: true,
    state,
    mutations: {
        ...make.mutations(state),

        ADD_THEME(state, payload: WhitelabelThemeModel): void {
            if (payload.isDefault === true)
                state.themes.forEach((item) => item.isDefault = false)

            state.themes.push(payload)
        },

        UPDATE_THEME(state, payload: WhitelabelThemeModel): void {
            if (payload.isDefault === true)
                state.themes.forEach((item) => item.isDefault = false)

            const index = state.themes.findIndex(({ id }) => id === payload.id)

            state.themes.splice(index, 1, payload)
        },

        DESTROY_THEME(state, themeId: number): void {
            const index = state.themes
                .findIndex(({ id }) => id === themeId)

            state.themes.splice(index, 1)
        },
    },
    actions: {
        /**
         * Get all themes for organization
         */
        async list({ commit }, params: object): Promise<PaginationResponse<WhitelabelThemeModel>> {
            const { data } = await Http.api().get('api2/whitelabel/themes', {
                params: params,
            })

            commit('SET_THEMES', data.data)

            return data
        },

        /**
         * Create theme
         */
        async create({ commit }, payload: Partial<WhitelabelThemeModel>): Promise<WhitelabelThemeModel> {
            const { data } = await Http.api().post(`api2/whitelabel/themes`, payload)

            commit('ADD_THEME', data)

            return data
        },

        /**
         * Update theme
         */
        async update(
            { commit },
            payload: PartialExcept<WhitelabelThemeModel, 'id'>,
        ): Promise<WhitelabelConfigModel> {
            const { id, ...payloadToSend } = payload

            if (!id) throw new Error('[Whitelabels/Themes/update] Missing id')

            const { data } = await Http.api().patch(`api2/whitelabel/themes/${id}`, payloadToSend)

            commit('UPDATE_THEME', data)

            return data
        },

        /**
         * Delete theme
         */
        async destroy({ commit }, payload: number | WhitelabelThemeModel): Promise<void> {
            const id = typeof payload === 'number'
                ? payload
                : payload.id

            if (!id) throw new Error('[Whitelabels/Themes/destroy] Missing id')

            await Http.api().delete(`api2/whitelabel/themes/${id}`)

            commit('DESTROY_THEME', id)
        },

        /**
         * Get theme usage
         */
        async usage(
            _ctx,
            payload: number | WhitelabelThemeModel,
        ): Promise<WhitelabelThemeUsageModel> {
            const id = typeof payload === 'number'
                ? payload
                : payload.id

            if (!id) throw new Error('[Whitelabels/Themes/destroy] Missing id')

            const { data } = await Http.api().get(`api2/whitelabel/themes/${id}/usage`)

            return data
        },
    },
}

export default store