<template>
    <div class="integration-select">
        <div class="integration-select__search-wrapper">
            <input-field
                ref="searchField"
                v-model="query"
                class="mb-0"
                type="search"
                :debounce="500"
                :placeholder="$t('INTEGRATIONS.UNSPLASH.SEARCH_PLACEHOLDER')"
                clearable
                @input="search(true)"
            />
        </div>

        <!-- Empty state -->
        <i18n
            v-if="query && !results.length"
            #query
            class="text-center py-3"
            tag="p"
            path="INTEGRATIONS.UNSPLASH.SEARCH_EMPTY"
        >
            <strong>{{ query }}</strong>
        </i18n>

        <!-- Grid -->
        <div class="masonry">
            <overlay
                v-for="(item, i) in results"
                :key="item.id + String(i)"
                variant="default"
                class="masonry-item pointer"
                :show="selectedResult && selectedResult.id === item.id"
                @click.native="onSelect(item)"
            >
                <image-lazy
                    class="masonry-brick"
                    fill
                    :src="item.urls.small"
                >
                    <template #loading>
                        <div
                            class="masonry-brick-loading"
                            :style="{ backgroundColor: item.color }"
                        />
                    </template>
                </image-lazy>
                <template #content="{ hovering }">
                    <div
                        v-if="selectedResult && selectedResult.id === item.id"
                        class="centerer"
                    >
                        <fa
                            v-if="!selectedFile"
                            :icon="['fal', 'circle-notch']"
                            class="text-white"
                            size="lg"
                            spin
                        />
                        <fa
                            v-else
                            :icon="['fal', 'check']"
                            class="text-white"
                            size="lg"
                        />
                    </div>
                    <div
                        v-if="hovering"
                        class="position-absolute bottom-0 w-100 bg-900-dimmed p-1 line-height-1"
                    >
                        <span class="text-smaller text-white">
                            {{ $t('INTEGRATIONS.UNSPLASH.PHOTO_BY') }}
                            <a
                                class="inherit-color text-underline"
                                :href="appendRef(item.user.links.html)"
                                target="_blank"
                                @click.stop
                            >{{ item.user.first_name }}</a>
                        </span>
                    </div>
                </template>
            </overlay>
        </div>

        <!-- Attribution -->
        <div class="integration-select__attribution">
            <!-- eslint-disable vue/no-bare-strings-in-template -->
            Powered by
            <a
                class="inherit-color text-underline"
                :href="appendRef('https://unsplash.com/')"
                target="_blank"
                v-text="'Unsplash'"
            />
            <a
                class="mx-1"
                target="_blank"
                href="https://unsplash.com/terms"
                v-text="'See terms'"
            />
            <!-- eslint-enable vue/no-bare-strings-in-template-->
        </div>

        <!-- Infinite loader observer -->
        <observer
            v-if="!busy && !allFetched"
            @intersect="loadMore"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { createApi } from 'unsplash-js'
    import type { Basic } from 'unsplash-js/dist/methods/photos/types'
    import ImageLazy from '@common/Images/ImageLazy.vue'
    import { InputField } from '@common/Forms'
    import Observer from '@common/components/Observer.vue'
    import Overlay from '@common/components/Overlay.vue'
    import IntegrationSelectMixin from './integration-select.mixin'

    export default (Vue as VueConstructor<Vue & InstanceType<typeof IntegrationSelectMixin> & {
        results: Basic[];
        selectedResult: Basic;
    }>).extend({
        components: {
            ImageLazy,
            InputField,
            Observer,
            Overlay,
        },

        mixins: [IntegrationSelectMixin],

        data() {
            return {
                instance: createApi({
                    accessKey: 'aYfLevV4hqU6wcJzGDPPZ8zJLRWboMY193QFeiAkr00',
                }),
            }
        },

        methods: {
            list(reset = false): void {
                this.fetch(reset, async () => {
                    const { response } = await this.instance.photos.list({
                        perPage: this.perPage,
                        page: this.page,
                    })

                    this.page = this.page + 1
                    this.total = response?.total ?? 0

                    return response?.results ?? []
                })
            },

            search(reset = false): void {
                if (!this.query)
                    return this.list(true)

                this.fetch(reset, async () => {
                    const { response } = await this.instance.search.getPhotos({
                        query: this.query,
                        perPage: this.perPage,
                        page: this.page,
                    })

                    this.page = this.page + 1
                    this.total = response?.total ?? 0

                    return response?.results ?? []
                })
            },

            onSelect(item: Basic): void {
                this.selectedFile = null
                this.selectedResult = item

                const filename = `unsplash-${item.id}-${Date.now()}`
                const imageUrl = `${item.urls.raw}&w=2000&q=80`

                this.downloadAndEmit(imageUrl, filename)

                // Comply to Unsplash API Guidelines
                // @see https://help.unsplash.com/en/articles/2511258-guideline-triggering-a-download
                this.instance.photos.trackDownload({
                    downloadLocation: item.links.download_location,
                })
            },

            /**
             * Append Unsplash required query params to links used in anchor tags.
             *
             * @see https://help.unsplash.com/en/articles/2511315-guideline-attribution
             */
            appendRef(url: string): string {
                try {
                    const urlObj = new URL(url)
                    urlObj.searchParams.set('utm_source', 'learningbank')
                    urlObj.searchParams.set('utm_medium', 'referral')

                    return urlObj.toString()
                } catch (e) {
                    return url
                }
            },
        },
    })
</script>

<style lang="scss">
@import '@scss/vue.scss';

.integration-select {
    &__search-wrapper {
        position: sticky;
        top: 0;
        z-index: $zindex-tooltip;
        padding-bottom: spacer(3);
        background-color: $white;
    }

    &__attribution {
        position: sticky;
        bottom: 0;
        background-color: $white;
        text-align: right;
        font-size: 75%;
        padding-top: spacer(1);

        img {
            height: 1rem;
        }
    }
}


.masonry {
    $height: 20vh;
    display: flex;
    flex-wrap: wrap;

    .masonry-item {
        height: $height;
        flex-grow: 1;
        margin: 0 spacer(1) spacer(1) 0;
    }

    .masonry-brick, .masonry-brick-loading {
        max-height: 100%;
        min-width: 100%;
        object-fit: cover;
        vertical-align: bottom;
    }

    .masonry-brick-loading {
        height: $height;
        width: $height;
    }
}

</style>