import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'departments',
    module: 'Departments',
    middleware: [
        auth('admin', { name: '401' }),
        hasFeatures('Organization', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'departments.index',
        component: (): Promise<any> =>
            import (
                /* webpackChunkName: "modules/Departments" */
                './DepartmentsIndex.vue'
            ),
    },
])
