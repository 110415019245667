<template>
    <div class="row">
        <div
            v-for="language in languages"
            :key="language.id"
            class="col-6 col-md-4 col-lg-3 border rounded mb-3 px-3 py-2 pointer"
            :class="{
                'border-transparent': language.id !== selectedLanguageId
            }"
            @click="$emit('click', language.id)"
        >
            <language-iso :value="language" />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import LanguageIso from './LanguageIso.vue'

    export default Vue.extend({
        components: {
            LanguageIso,
        },

        props: {
            languages: {
                type: Array as PropType<Language[]>,
                required: true,
            },

            selectedLanguageId: {
                type: String as PropType<string | null>,
                required: true,
            },
        },
    })
</script>
