import Vue, { PropType } from 'vue'

/**
 * @mixin
 */
export default Vue.extend({
    props: {
        /**
         * Disables clicking on the backdrop to dismiss the modal
         */
        blocking: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Center modal on the page
         */
        centered: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Modal will not succeed the body height and its content becomes scrollable
         */
        scrollable: {
            type: Boolean as PropType<boolean>,
            default: true,
        },
        /**
         * Classes for main dialog element
         */
        dialogModalClass: {
            type: [String, Array, Object] as PropType<string | any[] | object>,
            default: null,
        },
        /**
         * Modal sizes
         */
        size: {
            type: String as PropType<string>,
            default: null,
            validator: (value: string): boolean => ['sm', 'lg', 'md', 'xl', 'fill'].includes(value),
        },
    },

    computed: {
        dialogClass(): any[] {
            return [{
                'modal-dialog-scrollable': this.scrollable,
                'modal-dialog-centered': this.centered,
                'modal-sm': this.size === 'sm',
                'modal-lg': this.size === 'lg',
                'modal-xl': this.size === 'xl',
                'modal-fill': this.size === 'fill',
            }, this.dialogModalClass]
        },
    },

    mounted() {
        (this.$el as HTMLElement).focus?.()
    },

    methods: {
        outsideClick(): void {
            if (!this.blocking) this.reject('cancel')
        },

        resolve(payload: any): void {
            this.getPromise(this, '$close', payload)
        },

        reject(payload: any): void {
            this.getPromise(this, '$error', payload)
        },

        /**
         * Find the main component that is passed to the .modal()
         * function that inherits the promise methods
         */
        getPromise(ctx: any, method: string, payload: any): any {
            if (typeof ctx[method] === 'function') {
                return ctx[method](payload)
            }

            return this.getPromise(ctx.$parent, method, payload)
        },

    },
})
