<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <tree-select
                v-model="internalValue"
                :class="inputClassLocal"
                :options="options"
                :multiple="multiple"
                :disabled="disabled"
                :placeholder="placeholder"
                :normalizer="normalizer"
                :clearable="clearable"
                :limit="limit"
                :limit-text="limitText"
                :flat="flat"
                :searchable="searchable"
                :no-results-text="$t('TERMS.FILTERS_NO_RESULTS')"
                :auto-select-descendants="flat && autoSelectDescendent"
                :auto-deselect-descendants="flat && autoSelectDescendent"
                v-bind="$attrs"
                @open="(instanceId) => $emit('open', instanceId)"
                @close="(value, instanceId) => $emit('close', value, instanceId)"
                @select="(node, instanceId) => $emit('select', node, instanceId)"
                @deselect="(node, instanceId) => $emit('deselect', node, instanceId)"
                @search-change="(searchQuery, instanceId) => $emit('search-change', searchQuery, instanceId)"
            >
                <template
                    v-for="(_, slot) of $scopedSlots"
                    #[slot]="scope"
                >
                    <slot
                        :name="slot"
                        v-bind="scope"
                    />
                </template>
            </tree-select>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import { isEqual } from 'lodash'
    import TreeSelect from '@riophae/vue-treeselect'
    import '~@riophae/vue-treeselect/dist/vue-treeselect.css'
    import SharedField from './mixin'

    export default SharedField.extend({
        components: { TreeSelect },
        props: {
            options: {
                type: Array,
                required: true,
            },

            labelField: {
                type: String,
                default: 'name',
            },

            multiple: {
                type: Boolean,
                default: false,
            },

            showSelected: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            limit: {
                type: Number as PropType<number>,
                default: 99999,
            },

            limitText: {
                type: Function as PropType<any>,
                default: function(count: number): string {
                    return this.$tc('FORMS.OPTIONS_SELECTED', count)
                },
            },

            flat: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            searchable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            defaultOption: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            clearable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * This determines whether selecting a parent element to also select all it's child elements or not.
             * It will also determine whether child elements should be deselected when unselecting a parent element
             * It only works with 'flat: true'
             */
            autoSelectDescendent: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
        },

        data() {
            return {
                initialSelection: null as any,
                normalizer: (node: any): object => {
                    let hasSelectedChild = false

                    if (node.children) {
                        const childIds = node.children.map((child: any) => child.id)
                        hasSelectedChild = this.value && this.value.some((value: any) => childIds.includes(value))
                    }

                    return {
                        id: node.id,
                        label: node[this.labelField],
                        isDefaultExpanded: node.parent_id === 0 || hasSelectedChild,
                    }
                },
            }
        },

        computed: {
            hasInitialSelection(): boolean {
                const hasInitialSelection = Array.isArray(this.initialSelection)
                    ? !!this.initialSelection.length
                    : this.initialSelection !== null

                return hasInitialSelection && isEqual(this.initialSelection, this.internalValue) || false
            },

            inputClassLocal(): any[] {
                return [{
                    'vue-treeselect--default-option': this.defaultOption,
                    'has-selected': this.internalValue && (this.internalValue.length > 0 || this.internalValue.value),
                    'has-initial-selection': this.hasInitialSelection,
                }, this.inputClass]
            },
        },

        created() {
            this.initialSelection = this.value
        },
    })
</script>

<style lang="scss" scoped>
@import '@scss/vue.scss';


// make sure border-collapse is set to its default value
// due to issues when component is inside tables.
// https://github.com/riophae/vue-treeselect/issues/337
::v-deep .vue-treeselect {
    border-collapse: separate;
    outline: none !important;

    &.has-selected:not(.vue-treeselect--disabled):not(.has-initial-selection) {
        .vue-treeselect__control {
            border-color: $body-color;
        }
    }
}

// Overwrite input styling, match ours
::v-deep .vue-treeselect__control {
    border-color: $input-border-color;
    border-radius: $input-border-radius;
    padding-left: $input-padding-y * 2;
    padding-right: $input-padding-y * 2;
    width: auto;
}

// Overwrite limit text
::v-deep .vue-treeselect__limit-tip-text {
    color: $body-color !important;
    font-size: $font-size-base !important;
    font-weight: normal !important;
    padding: 0 .375rem !important;

    .vue-treeselect--disabled & {
        color: $custom-select-disabled-color !important;
    }
}

// Overwrite placeholder
::v-deep .vue-treeselect__placeholder {
    color: $input-placeholder-color;
    position: static;
    overflow: inherit;
    &:not(.vue-treeselect-helper-hide) + .vue-treeselect__input-container {
        position: absolute;
        left: 0;
        top: .375rem;
    }
}

// Ovewrite clear button
::v-deep .vue-treeselect__x-container {
    &:hover {
        color: $body-color;
    }
    + .vue-treeselect__control-arrow-container {
        padding-left: .375rem;
    }
}

::v-deep .vue-treeselect__menu {
    border-color: $dropdown-border-color;
    .vue-treeselect--open-below & {
        box-shadow: $box-shadow;
    }
}

// Allow menu items to overflow
::v-deep .vue-treeselect {
    .vue-treeselect__menu {
        overflow: auto;
    }
    .vue-treeselect__label {
        overflow: inherit !important;
    }
}

// Overwrite option highlight state
::v-deep .vue-treeselect__option {
    &--highlight {
        background: color('off-white');
    }
}

// Overwrite single select
::v-deep .vue-treeselect--single .vue-treeselect__option--selected {
    &, &:hover {
        background: color('off-white');
    }
    color: whitelabel-color('primary')
}

// Overwrite multiselect
::v-deep .vue-treeselect--multi .vue-treeselect__option {

    .vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked {
        border-color: whitelabel-color('primary');
    }
    .vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked {
        background: whitelabel-color('primary');
        border-color: whitelabel-color('primary');
    }

    .vue-treeselect__checkbox {
        width: $custom-control-indicator-size;
        height: $custom-control-indicator-size;
        border-radius: $custom-checkbox-indicator-border-radius;
        &--checked {
            background: whitelabel-color('primary');
            border-color: whitelabel-color('primary');
        }
        .vue-treeselect__check-mark, .vue-treeselect__minus-mark {
            @include centerer();
        }
    }
}

// Overwrite multiselect selection without limit (badges)
::v-deep .vue-treeselect__multi-value {
    .vue-treeselect__multi-value-item {
        color: $input-color;
        background: color('off-white');
        border-radius: $border-radius;

        .vue-treeselect__value-remove {
            color: $text-muted;
            border: 0;
        }
    }
}

// Overwrite default caret with css hack
::v-deep .vue-treeselect__control-arrow-container {
    svg { display: none; }
    &::after {
        width: 0.75em;
        display: inline-block;
        color: red;
        content: url('data:image/svg+xml;charset=UTF-8, <svg data-v-608aa12c="" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path data-v-608aa12c="" fill="rgb(104, 104, 104)" d="M4.251 181.1C7.392 177.7 11.69 175.1 16 175.1c3.891 0 7.781 1.406 10.86 4.25l197.1 181.1l197.1-181.1c6.5-6 16.64-5.625 22.61 .9062c6 6.5 5.594 16.59-.8906 22.59l-208 192c-6.156 5.688-15.56 5.688-21.72 0l-208-192C-1.343 197.7-1.749 187.6 4.251 181.1z" class=""></path></svg>');
    }
}

// Disabled state
::v-deep .vue-treeselect--disabled {
    .vue-treeselect__placeholder {
        color: $input-placeholder-color;
    }
    .vue-treeselect__control {
        background-color: $input-disabled-bg !important;
    }
    .vue-treeselect__multi-value-item {
        background: $gray-150;
        border-color: transparent;
    }
    .vue-treeselect__control-arrow-container {
        display: none;
    }
}

</style>
