<template>
    <component
        :is="togglable ? 'b-dropdown' : 'div'"
        ref="dropdown"
        v-bind="dropdownBindings"
        @mouseenter.native="onMouseOver"
        @mouseleave.native="onMouseLeave"
    >
        <template #button-content>
            <fa
                class="text-muted"
                fixed-width
                :icon="['fal', 'medal']"
            />
            {{ totalMedals }}
        </template>
        <div
            :style="mainStyle"
            class="d-flex"
        >
            <fa
                fixed-width
                class="mr-3"
                :class="totalMedals ? 'text-gold' : 'text-muted'"
                size="2x"
                :icon="['fal', 'medal']"
            />
            <div>
                <p
                    class="text-nowrap text-larger"
                    v-text="$tc('ACHIEVEMENT.MEDAL.COUNT', totalMedals, { count: totalMedals })"
                />
                <p
                    class="text-muted"
                    v-text="$t('ACHIEVEMENT.MEDAL.INFO')"
                />
                <div class="d-flex justify-content-between">
                    <div
                        v-for="item in medalsMerged"
                        :key="item.medalId"
                        class="d-flex align-items-center"
                    >
                        <fa
                            :class="item.class"
                            class="mr-2"
                            size="2x"
                            :icon="['fal', 'medal']"
                        />
                        <span
                            class="text-larger"
                            :class="{ 'text-gray': !item.count }"
                            v-text="item.count"
                        />
                    </div>
                </div>
            </div>
        </div>
    </component>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { medalMap } from '@utils'
    import infoMixin from './info.mixin'

    export default (Vue as VueConstructor<Vue & InstanceType<typeof infoMixin>>).extend({
        mixins: [infoMixin],
        computed: {
            medalStats(): AchievementsState['medalStats'] {
                return this.$store.state.Achievements?.medalStats ?? []
            },

            medalsMerged(): any[] {
                return this.medalStats.map((item) => ({
                    ...medalMap[item.medalId],
                    count: item.count,
                }))
            },

            totalMedals(): number {
                return this.medalStats.reduce((acc, item) => acc + item.count, 0)
            },
        },

        methods: {
            async fetchData(): Promise<void> {
                this.busy = true
                await this.$store.load('Achievements')
                await this.$store.cache.dispatch('Achievements/getMedalStats', this.userId)
                this.busy = false
            },
        },
    })
</script>