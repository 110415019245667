import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import { $localStorage } from './storage'
import DEFAULT_LANG from '@l18n/en.json'

Vue.use(VueI18n)

const LOADED_LANGUAGES = ['en']

const momentLanguageMap: StringMap = {
    zh: 'zh_cn',
    no: 'nb',
}

export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: DEFAULT_LANG,
    },
})

export function setI18nLanguage(lang: string): string {
    i18n.locale = lang.slice(0, 2);

    // Handle 3rd party languages
    (document.querySelector('html') as HTMLElement).setAttribute('lang', lang)
    moment.locale(momentLanguageMap[lang] || lang)

    // Sync local storage with langId
    $localStorage.set('lang', lang)

    return lang
}

export async function loadLanguageAsync(lang: string): Promise<any> {
    const tempLang = lang.slice(0, 2)

    if (i18n.locale === tempLang)
        return setI18nLanguage(lang)

    // If the language was already loaded
    if (LOADED_LANGUAGES.includes(tempLang))
        return setI18nLanguage(lang)

    try {
        const { default: messages } = await import(`@l18n/${tempLang}.json`)

        i18n.setLocaleMessage(tempLang, messages)
        LOADED_LANGUAGES.push(tempLang)

        return setI18nLanguage(lang)
    } catch (e) {
        /**
         * If language file doesn't exist we create an empty message object and assign
         * to the unavailable language, this will make all keys fallback to fallback lang
         */
        i18n.setLocaleMessage(lang, {})
        LOADED_LANGUAGES.push(lang)

        return setI18nLanguage(lang)
    }
}
