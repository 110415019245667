export function canStartContainerModule(nextModule: ContainerContextModel['nextModule']): boolean {
    if (!nextModule)
        return false

    if (nextModule.concluder === 'manager')
        return false

    return nextModule.status === 'completed' || nextModule.status.includes('incomplete')
}

export function canStartModule(learning: LearningModel<'module'>): boolean {
    if (!learning.context.containerId)
        return false

    if (learning.data.concluder === 'manager')
        return false

    return !['pending', 'locked'].includes(learning.context?.status)
}
