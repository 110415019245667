import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'events',
    module: 'Courses',
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'courses.index',
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Courses" */
            './CoursesIndex.vue'
        ),
        meta: {
            middleware: [
                auth('admin', { name: '401' }),
                hasFeatures('Course', { name: '401' }),
            ],
        },
    },
    // ? Temporary redirect route since we changed events route to belong to Learning module
    {
        path: 'available',
        name: 'courses.client.index',
        redirect: { name: 'learning.events.index' },
    },
    // ? Temporary redirect route since we changed events route to belong to Learning module
    {
        path: ':courseId/details',
        name: 'courses.client.details',
        redirect: { name: 'learning.events.details' },
    },
    {
        path: ':courseId',
        name: 'course.edit',
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Courses" */
            './CoursesEdit.vue'
        ),
        meta: {
            middleware: [
                auth('admin', { name: '401' }),
                hasFeatures('Course', { name: '401' }),
            ],
        },
    },
    {
        path: ':courseId/settings',
        name: 'course.settings',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Courses" */
                './CourseSettings.vue'
            ),
        meta: {
            middleware: [
                auth('admin', { name: '401' }),
                hasFeatures('Course', { name: '401' }),
            ],
        },
    },
])
