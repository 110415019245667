export const NAV_TYPES = [
    'top',
    'left',
] as const

export const CONTAINER_TYPES = [
    'fluid',
    'sm',
    'md',
    'lg',
    'xl',
] as const

export const CONTAINER_TYPE_CLASS_MAP = {
    fluid: 'container-fluid-extended',
    sm: 'container-sm',
    md: 'container-md',
    lg: 'container-lg',
    xl: 'container-xl',

} as Record<typeof CONTAINER_TYPES[number], string>