import { auth, hasFeatures, hasPermissions } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: '',
    module: 'Academy',
}

export default routesFactory(routerConfig, [
    {
        path: 'learning-paths/:view?',
        name: 'academy.index',
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Academy" */
            './AcademyIndex.vue'
        ),
        meta: {
            middleware: [
                auth(['admin', 'lb'], { name: '401' }),
                hasFeatures('AcademyManagement', { name: '401' }, 'admin'),
            ],
        },
    },
    {
        path: 'academy/deadline',
        name: 'academy.deadline',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Academy" */
                './ModulesNearingDeadline.vue'
            ),
        meta: {
            middleware: [
                auth('admin', { name: '401' }),
            ],
        },
    },
    {
        path: 'academy/:academyId/edit',
        name: 'academy.edit',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Academy" */
                './AcademyManage.vue'
            ),
        meta: {
            middleware: [
                auth(['admin', 'lb'], { name: '401' }),
                hasFeatures('AcademyManagement', { name: '401' }, 'admin'),
                hasPermissions('learningPathRead', { name: '401' }),
            ],
            hideAside: true,
        },
    },
    {
        path: 'academy/:academyId/settings',
        name: 'academy.settings',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Academy" */
                './AcademySettings.vue'
            ),
        meta: {
            middleware: [
                auth(['admin', 'lb'], { name: '401' }),
                hasFeatures('AcademyManagement', { name: '401' }, 'admin'),
                hasPermissions('learningPathRead', { name: '401' }),
            ],
            hideAside: true,
        },
    },
])
