<template>
    <overlay
        variant="default"
        overlay-class="rounded"
        :show="['completed', 'locked'].includes(value.context.status)"
        :hover="false"
    >
        <!-- Content image -->
        <image-lazy
            :class="['rounded', {
                'grayscale': value.context.status === 'locked'
            }]"
            :src="imagePath"
            ar="1:1"
            fill
        >
            <!-- Content image fallback -->
            <template #fallback>
                <div class="embed-responsive embed-responsive-1by1 bg-light text-gray-dark rounded">
                    <learning-content-illustration
                        :value="value"
                        class="centerer w-50"
                    />
                </div>
            </template>
        </image-lazy>
        <!-- Overlay contents (statuses) -->
        <template #content>
            <fa
                v-if="value.context.status === 'completed'"
                class="text-white centerer"
                :icon="['fas', 'check']"
            />
            <fa
                v-if="value.context.status === 'locked'"
                class="text-white centerer"
                :icon="['fas', 'lock']"
            />
        </template>
    </overlay>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import ImageLazy from '@common/Images/ImageLazy.vue'
    import Overlay from '@common/components/Overlay.vue'
    import LearningContentIllustration from '@modules/Learning/components/LearningContentIllustration.vue'

    export default Vue.extend({
        components: {
            ImageLazy,
            Overlay,
            LearningContentIllustration,
        },

        props: {
            value: {
                type: Object as PropType<LearningModel<'module' | 'container' | 'development-plan'>>,
                required: true,
            },
        },

        computed: {
            imagePath(): string | null {
                if (this.value.type === 'module')
                    return null

                return this.value.data.thumbnailPath
            },
        },
    })
</script>
