import Vue, { VueConstructor } from 'vue'
import { InputField } from '@common/Forms'
import { urlToFile } from '@utils'

interface Refs {
    $refs: {
        searchField: InstanceType<typeof InputField>;
    };
}

export default (Vue as VueConstructor<Vue & Refs>).extend({
    data() {
        return {
            mounted: false,
            busy: false,
            perPage: 25,
            page: 1,
            offset: 0,
            query: '',
            total: 0,

            results: [] as unknown[],
            selectedResult: null as unknown,
            selectedFile: null as File | null,
        }
    },
    computed: {
        allFetched(): boolean {
            return this.results.length >= this.total
        },
    },

    mounted() {
        this.list(true)
        this.mounted = true
    },

    methods: {
        loadMore(): void {
            this.$nextTick(() => {
                if (this.query) this.search()
                else this.list()
            })
        },

        async list(reset = false): Promise<void> {
            await Promise.reject(`Missing implementation [list] ${reset}`)
        },

        async search(reset = false): Promise<void> {
            await Promise.reject(`Missing implementation [search] ${reset}`)
        },

        async fetch(reset = false, dataCallback: () => Promise<unknown[]>): Promise<void> {
            this.busy = true
            if (reset) {
                this.offset = 0
                this.page = 1
            }

            const data = await dataCallback()

            this.setResults(data, !reset)
            this.busy = false
        },

        setResults(data: unknown[], append = false): void {
            this.results = append
                ? this.results.concat(data)
                : data
        },

        async downloadAndEmit(url: string, filename: string): Promise<void> {
            this.selectedFile = await urlToFile(url, filename)

            /**
             * Triggers when a image is selected
             *
             * @property {File} selectedFile File object of selected image
             * @property {unknown} selectedResult Integration response item.
             */
            this.$emit('selected', this.selectedFile, this.selectedResult)
        },
    },
})
