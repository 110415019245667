<script lang="ts">
    import Vue, { PropType, VNode } from 'vue'

    /**
     * Simple functional component that conditionally renders inner DOM's root container
     * Keep in mind that you *have to* have a single root element with in this component.
     *
     * This is useful to conditionally render a container around any elements.
     *
     * @example
     * ```
     * <conditional-root :show="false">
     *   <div>
     *      <span>Child 1</span>
     *      <span>Child 2</span>
     *   </div>
     * </conditional-root>
     * ```
     * Will output
     * ```
     *   <span>Child 1</span>
     *   <span>Child 2</span>
     * ```
     */
    export default Vue.extend({
        functional: true,

        props: {
            show: {
                type: Boolean as PropType<boolean>,
                required: true,
            },
        },

        render(_h, ctx) {
            const children = ctx.children.filter((vnode) => vnode.tag)

            if (children.length !== 1)
                console.warn('this component accepts only one root node in its slot')


            if (ctx.props.show)
                return children[0]
            else
                return children[0].children as VNode[]
        },

    })
</script>