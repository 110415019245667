<template>
    <tiptap-dropdown
        extension="openAi"
        :icon="['fas', 'sparkles']"
        icon-class="svg-gradient"
        :disabled="editor.state.selection.empty"
        :label="$t('OPENAI.ASK_AI')"
    >
        <template v-for="(commands, key) in groupedCommands">
            <b-dropdown-header :key="key">
                {{ translateGroup(key) }}
            </b-dropdown-header>
            <tiptap-button
                v-for="command in commands"
                :key="command.command"
                :icon="command.icon"
                :label="$t(command.key)"
                extension="openAi"
                command="execAiCommand"
                :command-params="command"
                child
            />
        </template>
    </tiptap-dropdown>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { BDropdownHeader } from 'bootstrap-vue'
    import TiptapDropdown from './TiptapDropdown.vue'
    import TiptapButton from './TiptapButton.vue'
    import { Inject } from './TiptapEditorProvider.vue'
    import { OpenAiOptions } from '../extensions/openai'

    const COMMAND_MAP: Record<OpenAiOptions['commands'][number]['command'], {
        key: string;
        icon: string;
    }> = {
        rephrase: {
            icon: 'rotate',
            key: 'TERMS.REPHRASE',
        },
        shorten: {
            icon: 'arrows-to-line',
            key: 'TERMS.MAKE_SHORTER',
        },
        lengthen: {
            icon: 'arrows-from-line',
            key: 'TERMS.MAKE_LONGER',
        },
        professional: {
            icon: 'user-tie',
            key: 'TONE_OF_VOICE.PROFESSIONAL',
        },
        straightforward: {
            icon: 'hand-holding-heart',
            key: 'TONE_OF_VOICE.STRAIGHTFORWARD',
        },
        confident: {
            icon: 'person-snowboarding',
            key: 'TONE_OF_VOICE.CONFIDENT',
        },
        humorous: {
            icon: 'face-smile-upside-down',
            key: 'TONE_OF_VOICE.HUMOROUS',
        },
        friendly: {
            icon: 'handshake',
            key: 'TONE_OF_VOICE.FRIENDLY',
        },
        encouraging: {
            icon: 'heart',
            key: 'TONE_OF_VOICE.ENCOURAGING',
        },
    }

    type ExtendedAiCommand = OpenAiOptions['commands'][number] & {
        key: string;
        icon: string;
    }

    export default (Vue as VueConstructor<Vue & Inject>).extend({
        components: {
            BDropdownHeader,
            TiptapDropdown,
            TiptapButton,
        },

        inject: ['editor', 'extensions'],

        computed: {
            groupedCommands(): Record<string, ExtendedAiCommand[]> {
                const options = this.extensions?.getOptions<OpenAiOptions>('openAi')
                const transformedOptions = options.commands?.reduce((acc, item) => {
                    if (!acc[item.type])
                        acc[item.type] = []

                    acc[item.type].push({
                        ...item,
                        ...COMMAND_MAP[item.command],
                    })

                    return acc
                }, {} as any) ?? []

                return transformedOptions
            },
        },
        methods: {
            translateGroup(name: string) {
                const map: Record<string, string> = {
                    'modify': 'TERMS.EDIT',
                    'tone-of-voice': 'TONE_OF_VOICE.CHANGE',
                }

                return this.$t(map[name])
            },
        },
    })
</script>