<template>
    <base-modal
        size="sm"
        centered
        :no-footer="true"
    >
        <div class="text-center">
            <fa
                class="text-gray mb-4"
                :icon="['fal', 'user-lock']"
                size="3x"
            />
            <h5 v-text="modalName" />
            <p
                class="mb-0"
                v-text="modalDescription"
            />
        </div>
    </base-modal>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { TranslateResult } from 'vue-i18n'
    import { BaseModal } from '@common/Modals'

    export default Vue.extend({
        components: {
            BaseModal,
        },
        props: {
            data: { type: Object as PropType<AcademyModuleModel>, required: true },
        },

        computed: {
            modalName(): TranslateResult {
                if (this.data.concluder === 'manager') return this.$t('MODULE.ATTEMPT_MANAGER_DRIVEN')

                return this.$t('MODULE.ATTEMPT_AWAIT_REVIEW')
            },

            modalDescription(): TranslateResult {
                if (this.data.concluder === 'manager') return this.$t('MODULE.ATTEMPT_MANAGER_DRIVEN_INFO')

                return this.$t('MODULE.ATTEMPT_AWAIT_REVIEW_INFO')
            },
        },
    })
</script>