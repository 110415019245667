<template>
    <b-sidebar
        v-model="isOpen"
        lazy
        bg-variant="white"
        header-class="font-size-reset d-flex align-items-center border-bottom p-3"
        backdrop
        width="420px"
        v-bind="$attrs"
    >
        <template #header="{ hide }">
            <h4
                class="mb-0 font-weight-normal"
                v-text="$t('NOTIFICATION.TITLE')"
            />
            <btn
                class="ml-auto"
                variant="white"
                :icon="['fal','times']"
                pill
                @click="hide"
            />
        </template>
        <notifications-index
            v-if="isOpen"
            is-sidebar
        />

        <template #footer>
            <div class="text-right">
                <btn
                    v-bind="$attrs"
                    variant="link"
                    :to="{ name: 'notifications.index' }"
                    :label="$t('NOTIFICATION.SEE_ALL')"
                />
            </div>
        </template>
    </b-sidebar>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { BSidebar } from 'bootstrap-vue'

    export default Vue.extend({
        components: {
            BSidebar,
            NotificationsIndex: (): Promise<any> => import(
                '@modules/Notifications/NotificationsIndex.vue'
            ),
        },

        props: {
            value: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        computed: {
            isOpen: {
                get(): boolean {
                    return this.value
                },
                set(value: boolean): void {
                    this.$emit('input', value)
                },
            },
        },
    })
</script>
