import Vue from 'vue'
import { daysBetweenDates } from '@utils/date-helpers'

/**
 * Get learning status info
 */
export default Vue.extend({
    methods: {
        /**
         * Get module status data
         */
        getModuleStatusData(
            context: Partial<LearningModel<'container' | 'module' | 'development-plan'>['context']> | null,
        ): ModuleStatusData | null {
            if (!context)
                return null

            if (context.status === 'locked') {
                // Check if container is locked or unlocked
                if ('unlocksAt' in context) {
                    const doesUnlock = context.unlocksAt && new Date(context.unlocksAt) > new Date()

                    return {
                        text: doesUnlock
                            ? `${this.$t('TERMS.WAIT')} ${this.$filter('fromNow')(context.unlocksAt, false, true)}`
                            : `${this.$t('TERMS.LOCKED')}`,
                        class: 'text-gray-dark',
                        variant: 'gray',
                    }
                }

                return {
                    text: `${this.$t('TERMS.LOCKED')}`,
                    class: 'text-gray-dark',
                    variant: 'gray',
                }
            }

            if (context.status === 'requested')
                return {
                    text: this.$t('ACADEMY.LIBRARY.APPROVAL.PENDING') as string,
                    class: 'text-warning',
                    variant: 'warning',
                }

            if (context.status === 'pending')
                return {
                    text: this.$t('MODULE.ATTEMPT_AWAIT_REVIEW') as string,
                    class: 'text-warning',
                    variant: 'warning',
                }

            if (context.status === 'completed')
                return {
                    text: this.$t('TERMS.COMPLETED') as string,
                    class: 'text-success d-inline-block',
                    variant: 'success',
                }

            if (context.containerRequired === false)
                return {
                    text: this.$t('TERMS.OPTIONAL') as string,
                    class: 'badge badge-gray d-inline-block',
                    variant: 'gray',
                }
    

            if (context.deadlineAt) {
                const deadlineDays = daysBetweenDates(new Date(), new Date(context.deadlineAt))

                if (deadlineDays < 0)
                    return {
                        text: `${this.$t('TERMS.DEADLINE')} ${this.$filter('fromNow')(context.deadlineAt)}`,
                        class: 'text-danger deadline',
                        variant: 'danger',
                    }

                else if (deadlineDays < 8)
                    return {
                        text: this.$t('DEADLINE.EXPIRES_IN_DAYS', { days: deadlineDays }) as string,
                        class: deadlineDays < 4 ? 'text-danger' : 'text-warning',
                        variant: deadlineDays < 4 ? 'danger' : 'warning',
                    }

                return {
                    text: this.$t('DEADLINE.EXPIRES_IN_DAYS', { days: deadlineDays }) as string,
                    class: 'text-gray-dark',
                    variant: 'gray',
                }
            }

            return null
        },
    },
})
