import Vue, { PropType, VNode } from 'vue'
import { TopbarAction } from './typings'
import {
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BDropdownDivider,
} from 'bootstrap-vue'

export default Vue.extend({
    name: 'TopbarActionDropdown',
    functional: true,
    props: {
        actions: {
            type: Array as PropType<TopbarAction[]>,
            required: true,
        },
        isMobile: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },

    render(h, { props, scopedSlots }) {
        const { isMobile, actions } = props
        const fixedItems: VNode[] = []

        if (scopedSlots.default) {
            // Add a diveder between fixed dd items and overflowing actions
            if (actions.length)
                fixedItems.push(h(BDropdownDivider))

            // Push fixed items to the end of dropdown
            fixedItems.push(...scopedSlots.default({}) as VNode[])
        }

        return h(BDropdown, {
            props: {
                right: true,
                toggleClass: [ 'btn-icon', 'text-reset', 'rounded-pill'],
                variant: isMobile ? 'simple-default' : 'default',
            },
            scopedSlots: {
                'button-content': (): VNode => h('fa', {
                    props: {
                        icon: ['fal', 'ellipsis-v'],
                        fixedWidth: true,
                    },
                }),
                'default': (): VNode[] => actions.map((action) => {
                    const icon = h('fa', {
                        props: {
                            icon: ['fal', action.icon],
                            fixedWidth: true,
                        },
                    })
                    const label = h('span', { class: 'ml-2' }, action.label as string)

                    if (action.type === 'callback') {
                        return h(BDropdownItemButton, {
                            props: {
                                variant: action.variant,
                                disabled: action.disabled,
                            },
                            on: {
                                click: action.handler,
                            },
                        }, [icon, label])
                    }

                    if (action.type === 'link') {
                        return h(BDropdownItem, {
                            props: {
                                variant: action.variant,
                                disabled: action.disabled,
                                href: action.href,
                            },
                        }, [icon, label])
                    }

                    if (action.type === 'route') {
                        return h(BDropdownItem, {
                            props: {
                                variant: action.variant,
                                disabled: action.disabled,
                                to: action.to,
                            },
                        }, [icon, label])
                    }

                    return h()
                }).concat(fixedItems),
            },
        })
    },
})
