import Vue from 'vue'
import { get } from 'vuex-pathify'

import KnowbotChatModal from '../components/KnowbotChatModal.vue'


export default Vue.extend({
    computed: {
        knowbot: get<KnowbotModel | null>('Knowbot/knowbot'),

        canUseKnowbot(): boolean {
            if (!this.knowbot)
                return false

            return this.knowbot.trainedAt !== null
                && this.knowbot.externalId !== null
        },
    },

    methods: {
        openKnowbotModal(): void {
            if (!this.canUseKnowbot || !this.knowbot)
                return

            this.$dialog.modal(KnowbotChatModal, {
                path: this.knowbot.externalId,
            })
        },
    },
})
