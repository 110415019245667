import Vue from 'vue'
import {
    TabsPlugin,
    FormInputPlugin,
    FormSelectPlugin,
    FormGroupPlugin,
    FormFilePlugin,
    FormCheckboxPlugin,
    InputGroupPlugin,
    NavPlugin,
    ModalPlugin,
    ProgressPlugin,
    VBTooltip,
    VBScrollspy,
} from 'bootstrap-vue'


Vue.use(TabsPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(InputGroupPlugin)
Vue.use(NavPlugin)
Vue.use(ModalPlugin)
Vue.use(ProgressPlugin)
Vue.directive('tooltip', VBTooltip)
Vue.directive('scrollspy', VBScrollspy)

