<template>
    <inline-svg
        v-if="illustration"
        :src="illustration"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import AcademySvg from '@assets/icons/icon-signs-post.svg'
    import { MODULE_TYPE_ICONS } from '@utils'
    import { SKILL_DEVELOPER_TYPES_ILLUSTRATIONS } from '@modules/SkillsDeveloper/utils'

    export default Vue.extend({
        props: {
            value: {
                type: Object as PropType<LearningModel<'module' | 'container' | 'development-plan'>>,
                required: true,
            },
        },

        computed: {
            illustration(): SVGElement | string | null {
                if (this.value.type === 'container')
                    return AcademySvg

                if (this.value.type === 'development-plan')
                    return SKILL_DEVELOPER_TYPES_ILLUSTRATIONS[this.value.data.type] || null

                if (this.value.type === 'module')
                    return MODULE_TYPE_ICONS[this.value.data.moduleTypeId]

                return null
            },
        },
    })
</script>
