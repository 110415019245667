<template>
    <div
        :class="!clean ? ['file-dropzone', {
            'file-dropzone--dragover': dragOver,
            'file-dropzone--disabled': disabled,
        }] : null"
        @dragover.prevent="onDragover"
        @dragleave="onDragleave"
        @drop.prevent="onDrop"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
    >
        <!--
            @slot Overwrite contents of dropzone
                @binding {File[]} files All files in the dropzone
                @binding {() => void)} clear Clear out all files from dropzone
                @binding {boolean} dragOver Is dropzone being dragged over?
                @binding {boolean} hover Is dropzone being hovered?
                @binding {boolean} hasErrors Set to true if there are any error messages
                @binding {string[]} errors Error messages for all files that failed validation
                @binding {() => void} openDialog Artificial click event to file input
        -->
        <slot
            :files="files"
            :clear="clear"
            :drag-over="dragOver"
            :hover="hover"
            :has-errors="hasErrors"
            :errors="errors"
            :open-dialog="openDialog"
        >
            <div
                class="file-dropzone__default p-6 text-center"
                :class="{ 'pointer': !disabled }"
                @click="openDialog"
            >
                <fa
                    class="mr-2"
                    :class="hasErrors ? 'text-danger' : 'text-primary'"
                    :icon="['fal', hasErrors ? 'exclamation-triangle' : 'cloud-arrow-up']"
                    fixed-width
                />
                <span class="text-muted">
                    {{ $t('UPLOADER.DROP_HERE') }} {{ $t('UPLOADER.OR_CLICK_SELECT') }}
                </span>

                <!--
                    @slot File list display files that are dropped/selected
                        @binding {File[]} files All files in the dropzone
                        @binding {() => void} clear Clear out all files from dropzone
                        @binding {(file: File) => void} remove Remove file by passing it as arg from file list
                -->
                <slot
                    v-if="!hideFileList"
                    name="fileList"
                    :files="files"
                    :remove="remove"
                    :clear="clear"
                >
                    <div
                        v-if="files.length"
                        class="d-flex flex-row flex-wrap g-3 mt-3"
                    >
                        <card-file-mini
                            v-for="(file, index) in files"
                            :key="index"
                            :value="file"
                            :style="{ maxWidth: '12.5rem' }"
                            @delete="remove"
                        />
                    </div>
                </slot>
            </div>
        </slot>
        <!-- Hidden file input -->
        <input
            v-show="false"
            ref="fileInput"
            type="file"
            :multiple="multiple"
            :accept="inputAccept"
            @change="onFileChange"
        >
        <!-- Validation errors display -->
        <b-popover
            v-if="$el && !hideErrors"
            :target="$el"
            placement="bottom"
            triggers="manual"
            :show="hasErrors"
        >
            <ul class="m-0 pl-3">
                <li
                    v-for="(error, key) in errors"
                    :key="key"
                >
                    {{ error }}
                </li>
            </ul>
        </b-popover>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { normalizeRules, validate } from 'vee-validate'
    import { BPopover } from 'bootstrap-vue'
    import CardFileMini from '@common/Card/CardFileMini.vue'

    interface Refs {
        $refs: {
            fileInput: HTMLInputElement;
        };
    }

    export default (Vue as VueConstructor<Vue & Refs>).extend({
        components: {
            BPopover,
            CardFileMini,
        },

        props: {
            /**
             * Clear file selection when dropping/selecting more files.
             * If `multiple` is set to false then dropzone will always
             * clear on drop.
             */
            clearOnDrop: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Allow multiple files to be dropped/selected
             * */
            multiple: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Disables dropping and clicking on the dropzone
             */
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Vee Validation rules for dropped files.
             * You can use `ext`, `mimes` and `image` to determine file types
             * but it's not recommended to combine them.
             * You can additionally combine other validation rules that apply to files such
             * as `size` etc.
             */
            rules: {
                type: [String, Object] as PropType<string | object>,
                default: undefined,
            },
            /**
             * Disable default error pop over. Useful when you want to handle
             * error display via scoped slots
             */
            hideErrors: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Hide the internal dropzone file list
             */
            hideFileList: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Remove base styling of dropzone (dotted lines) and dragover effects
             */
            clean: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                files: [] as File[],
                errors: [] as string[],
                dragOver: false,
                hover: false,
            }
        },

        computed: {
            rulesLocal(): Record<string, any> {
                return normalizeRules(this.rules)
            },

            /**
             * Input field accept value synced with validation rules
             */
            inputAccept(): string {
                const accept: string[] = []

                if (this.rulesLocal.image)
                    accept.push('image/*')

                this.rulesLocal.mimes?.forEach((mime: string) =>
                    accept.push(mime.trim()))
                this.rulesLocal.ext?.forEach((ext: string) =>
                    accept.push(`.${ext.trim()}`))

                return accept.join(',')
            },

            hasErrors(): boolean {
                return !!this.errors.length
            },
        },

        watch: {
            files(value): void {
                /**
                 * Emitted when internal files object is changed
                 *
                 * @property {File[]} files Files in dropzone
                 */
                this.$emit('input', value)
            },
        },


        methods: {
            /**
             * On dragging over dropzone we set drag state
             * and reset errors.
             */
            onDragover(): void {
                if (this.disabled) return

                this.errors = []
                this.dragOver = true
            },
            /**
             * On dragging off dropzone we set drag state
             */
            onDragleave(): void {
                if (this.disabled) return

                this.dragOver = false
            },
            /**
             * On mouse hover root elem
             */
            onMouseover(): void {
                this.hover = true
            },
            /**
             * On mouse off root elem
             */
            onMouseleave(): void {
                this.hover = false
            },
            /**
             * On dropping file to cropper we assign the files
             * from the event to the file input and trigger change
             */
            onDrop(event: DragEvent): void {
                // Do nothing if dropzone is disabled
                if (this.disabled) return

                // Reset drag over state
                this.dragOver = false

                // Set files to file input
                this.$refs.fileInput.files = event.dataTransfer?.files ?? null

                // Trigger file change
                this.onFileChange()
            },
            /**
             * Handle click on whole component
             */
            openDialog(): void {
                // Do nothing if dropzone is disabled
                if (this.disabled) return

                this.errors = []
                this.dragOver = false
                // Do a artificial click on hidden file input
                this.$refs.fileInput.click()
            },
            /**
             * Handle file changes on drop and click
             */
            async onFileChange(): Promise<void> {
                let files = Array.from(this.$refs.fileInput.files ?? [])

                if (!files.length) return

                // Reset the file input
                this.$refs.fileInput.value = ''

                // If dropzone allows only single files, we only take the first one
                if (!this.multiple)
                    files = files.slice(0, 1)

                // Exit if validation fails
                if (!(await this.validate(files)))
                    return

                // Assign selected files
                if (this.clearOnDrop || !this.multiple)
                    this.files = files
                else
                    this.files.push(...files)
            },
            /**
             * Handle file validation
             */
            async validate(files: File[]): Promise<boolean> {
                const promises = files.map((file) =>
                    validate(file, this.rulesLocal, {
                        name: file.name,
                    }))

                const results = await Promise.all(promises)

                results.forEach((result) => {
                    this.errors.push(...result.errors)
                })

                const isValid = this.errors.length <= 0

                if (!isValid) {
                    /**
                     * Emitted when any of the files no not pass validation
                     *
                     * @property {string[]} errors Error messages
                     */
                    this.$emit('error', this.errors)
                }

                return isValid
            },
            /**
             * Clears file selection
             */
            clear(): void {
                this.files = []
            },
            /**
             * Remove file from file list
             */
            remove(file: File): void {
                const index = this.files.indexOf(file)

                this.files.splice(index, 1)
            },
        },
    })
</script>

<style lang="scss">
    @import '@scss/vue.scss';

    .file-dropzone {
        background: $white;
        border: $border-width * 2 dashed $border-color;
        transition: $transition-base;

        &--dragover:not(&--disabled),
        &:hover:not(&--disabled) {
            border-color: whitelabel-color('primary');
            background: color('off-white');
        }

        &--disabled {
            background: $input-disabled-bg;
            & > * {
                opacity: .5;
            }
        }
    }
</style>

<docs>
### Playground
```vue
<template>
    <div>
        <p>Basic Dropzone</p>
        <dropzone />
        <br />

        <p>Dropzone with validation (maxSize: 400kb, extensions: jpg,png,gif)</p>
        <dropzone
            :max-size="400"
            accept-ext="jpg,png,gif"
        />
    </div>
</template>
```
</docs>
