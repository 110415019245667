import Vue from 'vue'

import { capitalize, lowercase, truncate, uppercase } from '@utils'


/**
 * Lowercase string
 *
 * @param value
 */
Vue.filter('lowercase', lowercase)

/**
 * Uppercase string
 *
 * @param value
 */
Vue.filter('uppercase', uppercase)

/**
 * Capitalize string
 *
 * @param value
 */
Vue.filter('capitalize', capitalize)

/**
 * Truncate string
 *
 * @param value
 */
Vue.filter('truncate', truncate)
