<template>
    <span v-if="isSingleDay">
        <small v-text="startDay" />
        <small
            class="mx-1"
            v-text="'·'"
        />
        <small v-text="startTime" />
    </span>

    <small
        v-else
        v-text="$t('COURSE.SESSION.STARTING_AT', { day: startDay, time: startTime })"
    />
</template>

<script setup lang="ts">
    import { computed } from 'vue'

    import { getStartDate, getTimeRange } from '../utils'

    interface Props {
        value: SessionModel | SessionAttentionModel
        formatDay?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        formatDay: (): string => 'dddd',
    })


    const isSingleDay = computed((): boolean => props.value?.dates.length === 1)
    const startDay = computed((): string => getStartDate(props.value, props.formatDay))
    const startTime = computed((): string => getTimeRange(props.value))
</script>
