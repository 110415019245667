<template>
    <b-button-group>
        <slot />
    </b-button-group>
</template>

<script lang="ts">
    import Vue from 'vue'
    import { BButtonGroup } from 'bootstrap-vue'

    export default Vue.extend({
        components: {
            BButtonGroup,
        },
    })
</script>