import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'skills',
    module: 'Skills',
    middleware: [
        auth(['admin', 'lb'], { name: '401' }),
        hasFeatures('SkillsManagement', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'skills.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Skills" */
                './SkillsIndex.vue'
            ),
    },
])
