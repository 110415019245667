import Vue, { PropType, VNode } from 'vue'
import { cloneDeep } from 'lodash'

export default Vue.extend({
    props: {
        /**
         * Optional if you don't want to use `<table-column />`
         */
        fields: { type: Array as PropType<any[]>, default: (): never[] => [] },
        /**
         * Table data
         */
        items: { type: Array as PropType<any[]>, default: (): never[] => [] },
        /**
         * Set preselected items
         */
        preSelect: { type: Array as PropType<any[]>, default: (): never[] => [] },
        /**
         * Are rows selectable Note, you get a flattened array
         */
        selectable: { type: Boolean as PropType<boolean>, default: false },
        /**
         * Criteria for filtering
         */
        filter: { type: String as PropType<string>, default: undefined },
        /**
         * Array of fields to include when filtering
         */
        filterIncludedFields: { type: Array as PropType<any[]>, default: (): string[] => ['name'] },
        /**
         * Disable empty states on table
         */
        hideEmpty: { type: Boolean, default: false },
        /**
         * Cell content is vertically centered
         */
        centerV: { type: Boolean, default: true },
        /**
         * Display table as a card
         */
        card: { type: Boolean, default: false },
        /**
        *  Hover effect on selected row in the table
        */
        hover: {
            type: Boolean as PropType<boolean>,
            default: true,
        },
    },

    data() {
        return {
            selectAll: false,
            selectedItems: [] as any[],
            fieldsLocal: this.fields,
        }
    },

    watch: {
        selectedItems: {
            handler(value): void {
                this.$emit('selected', value)
            },
            deep: true,
        },
    },

    mounted() {
        if (this.preSelect?.length ?? false) {
            this.selectedItems = cloneDeep(this.preSelect)
        }

        if (!this.$slots.default) return

        const columnComponents = this.$slots.default
            .filter((column: VNode) => column.componentInstance)
            .map((column: any) => column.componentInstance)
        const fields = columnComponents.map((column: any) => column.getColumn())

        if (!fields.length) return

        this.fieldsLocal = fields
    },

    methods: {
        clearSelection(): void {
            this.selectAll = false
            this.selectedItems = []
        },
    },
})
