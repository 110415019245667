import Vue, { VueConstructor } from 'vue'
import config, { ConfigPluginInterface } from './config'

/**
 * Add typed $config to vue prototype
 */
declare module 'vue/types/vue' {
    interface Vue {
        $config: Readonly<ConfigPluginInterface>;
    }
}


/**
 * Installation
 */
const ConfigPlugin = {
    install(Vue: VueConstructor): void {
        Vue.prototype.$config = config
    },
}

Vue.use(ConfigPlugin)

