import Vue from 'vue'
import { DirectiveBinding } from 'vue/types/options'

/**
 * Handle modifiers on inserted and update hooks
 */
const implementation = (el: HTMLElement, bindings: DirectiveBinding): void => {
    // Make element height fixed to the amount of lines provided
    if (bindings.arg === 'fixed') {
        const lineHeight = getComputedStyle(el).lineHeight
        el.style.height = `calc(${bindings.value} * ${lineHeight})`
    }
}

/**
 * v-line-clamp directive
 *
 * Limits the number of lines a html element can be.
 *
 * Argument:
 *      'fixed' - forces the element height to be exactly `value` line heights
 *
 * Usage:
 *      <p v-line-clamp="2">...</p>
 *      <p v-line-clamp:fixed="2">...</p>
 *
 */
Vue.directive('line-clamp', {
    inserted: (el, bindings) => implementation(el, bindings),
    update: (el, bindings) => implementation(el, bindings),
    bind(el: HTMLElement, bindings: DirectiveBinding) {
        const lines = parseInt(bindings.value)

        if (isNaN(lines))
            throw new Error(`[v-${bindings.name}]: invalid expression. Must be valid integer from 1`)

        const styles = `
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: ${lines};
            overflow: hidden;
            text-overflow: ellipsis;
        `
        el.style.cssText = styles
    },
})