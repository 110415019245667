import lessonSvg from '@assets/icons/icon-book.svg'
import quizSvg from '@assets/icons/icon-question.svg'
import surveySvg from '@assets/icons/icon-list.svg'

export const MODULE_TYPES = [
    { id: 1, value: 'content', name: 'MODULE.TYPES.1', illustration: lessonSvg },
    { id: 4, value: 'test', name: 'MODULE.TYPES.4', illustration: quizSvg },
    { id: 2, value: 'survey', name: 'MODULE.TYPES.2', illustration: surveySvg },
] as ReadonlyArray<ModuleTypeSelectOption>

/**
 * Module type icons map
 */
export const MODULE_TYPE_ICONS = {
    1: lessonSvg,
    2: surveySvg,
    4: quizSvg,
} as Readonly<Record<string, SVGElement>>

/**
 * Module type id map
 */
export const MODULE_TYPE_MAP = {
    content: 1,
    survey: 2,
    test: 4,
} as Readonly<Record<string, number>>

/**
 * Module type select options
 */
export const MODULE_TYPE_SELECT_OPTIONS = [
    { id: null, value: '', name: 'TERMS.ALL' },
    ...MODULE_TYPES,
] as ReadonlyArray<ModuleTypeSelectOption>

/**
 * Visibility select options
 */
export const VISIBILITY_OPTIONS = [
    { id: null, name: 'TERMS.ALL' },
    { id: 1, name: 'VISIBILITY.HIDDEN' },
    { id: 0, name: 'VISIBILITY.UNHIDDEN' },
] as ReadonlyArray<{ id: number | null; name: string }>
