import { formatDate, formatDateRange, formatTimeRange } from '@utils'

export function getStartDate(
    session: { start: string },
    format?: string,
): string {
    return formatDate(session.start, format)
}

export function getDateRange(
    session: { start: string; end: string },
    format?: string,
): string {
    return formatDateRange([session.start, session.end], format)
}

export function getTimeRange(
    session: { start: string; end: string },
    format?: string,
): string {
    return formatTimeRange([session.start, session.end], format)
}

export function getSignupDeadline(
    session: { signup_deadline?: string | null; signupDeadline?: string | null },
    format?: string,
): string | null {
    const deadline = 'signup_deadline' in session
        ? session.signup_deadline
        : session.signupDeadline

    if (!deadline)
        return null

    return formatDate(deadline, format)
}
