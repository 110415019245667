<template>
    <div class="embed-responsive">
        <div :style="aspectRatioPadding" />
        <iframe
            v-if="isEmbeded"
            class="embed-responsive-item"
            :src="src"
            allowfullscreen
        />
        <video
            v-else
            controls
        >
            <source
                :src="src"
                :type="type"
            >
        </video>
        <div
            v-if="$slots.default"
            class="embed-responsive-item"
            :class="innerClass"
        >
            <!--
                @slot Any content in this slot will be displayed
                as overlay with a backdrop by default.
                You can disable the backdrop with `noBackdrop` property
             -->
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { AspectRatioMixin } from '@common/mixins'

    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof AspectRatioMixin>
    >).extend({
        mixins: [AspectRatioMixin],

        props: {
            /** Video source */
            src: { type: String as PropType<string>, default: null },
            /**
             * Video type. (mime type or external service)
             *
             * Special strings `youtube` & `vimeo` will display
             * an embeded video player based on src
             */
            type: { type: String as PropType<string>, default: null },
            /**
             * Works only if there is anything in the default slot
             */
            noBackdrop: { type: Boolean as PropType<boolean>, default: false },
        },

        computed: {
            isEmbeded(): boolean {
                return ['youtube', 'vimeo', 'dreambroker'].includes(this.type)
            },

            innerClass(): object {
                return {
                    'embed-responsive-item--backdrop': !this.noBackdrop,
                }
            },
        },
    })
</script>
