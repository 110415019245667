import { i18n } from '@plugins/i18n'
import moment from 'moment'
import { extend, configure } from 'vee-validate'
import {
    required,
    min,
    max,
    numeric,
    email,
    ext,
    size,
    mimes,
    min_value,
    max_value,
    image,
} from 'vee-validate/dist/rules'
import Http from '@utils/Http'
import store from '@store'
import { humanFileSize, readImageAsync } from '@utils'
import { validator } from '@learningbank/lb-utils'

/**
 * Configuration
 *
 * ? Error messages are synced with our i18n implementation
 * ? When adding a new rule make sure that you have a i18n key
 * ? in our loco platform in this pattern
 * ? "VALIDATION.RULE.<Rule name in uppercase>"
 * ? Take a look at the following link for values that might be available
 * ? for the built in rules.
 * ? https://github.com/logaretm/vee-validate/blob/v3/docs/guide/rules.md
 */
configure({
    defaultMessage(_field, values): any{
        if (values) {
            const ruleName = values._rule_.toUpperCase()
            if (values.size) {
                values.size = humanFileSize(values.size * 1000, true, 0)
            }
            // If no field name is defined then we default to "Field"
            values._field_ = values._field_ === '{field}'
                ? i18n.t('VALIDATION.FIELD.DEFAULT')
                : values._field_

            return i18n.t(`VALIDATION.RULE.${ruleName}`, values)
        }
    },
})

/**
 * Validation rules
 */

// Built in rules
extend('required', required)
extend('email', email)
extend('image', image)
extend('min', min)
extend('max', max)
extend('numeric', numeric)
extend('ext', ext)
extend('size', size)
extend('mimes', mimes)
extend('min_value', min_value)
extend('max_value', max_value)

/**
* Checks if repeat password field matches with the first password field
*/
extend('confirmed', {
    params: ['target'],
    validate(value, { target }: any) {
        return value === target
    },
    message: i18n.t('SETTINGS.PASSWORD.VALIDATION_ERROR') as string,
})

/**
* Add decimal number validation rule
*/
extend('decimal', {
    validate(value) {
        return /^\d*\.?\d*$/.test(value)
    },
    message: i18n.t('VALIDATION.RULE.DECIMAL') as string,
})

/**
 * Validate object keys
 * Running the required rule on each key defined as args
 */
extend('required_keys', {
    validate(value, args) {
        const validation = args.every((field: string) => {
            const { valid } = required.validate(Object(value)[field])

            return valid
        })

        return validation
    },
})

// Reverse ext rule to disallow certain extensions
extend('not_ext', {
    validate: (values, args) => !ext.validate(values, args),
})

/**
 * Hex Color validator
 *
 * Validates if input is a correct hex color.
 * You can pass in a boolean argument to add the pound symbol in front of value
 */
extend('hex_color', {
    params: ['usePound'],
    validate: (value, { usePound }: any) => {
        if (usePound)
            return /^#[0-9A-F]{6}$/i.test(value)
        else
            return /^[0-9A-F]{6}$/i.test(value)
    },
})

/**
 * Remote validator using the validateField api
 *
 * Usage:
 *  <component v-model="data" rules="remote:checkerName:exception" />
 *
 * Exception is useful to add in update views so its not
 * validating a value that has no change on the model.
 * exception argument is optional
 *
 */
extend('remote', {
    params: ['remoteChecker', 'exception'],
    async validate(value, { remoteChecker, exception }: any) {
        if (exception && value === exception) {
            return true
        }

        const { organization_id } = store.getters['Auth/authUser']
        const user = { organization_id }

        const params = { value, user, checker: remoteChecker }
        const { data: { matchTest, isValid } } = await Http.api().get(`api2/validateField`, { params })

        if (!matchTest) {
            console.warn('[remote] validation rule does not match test. check your config')
        }

        return isValid
    },
    message(_field, params) {
        if (params) {
            const remoteType = params.remoteChecker.toUpperCase()

            return i18n.t(`VALIDATION.RULE.REMOTE_${remoteType}`, params) as string
        }

        return i18n.t(`VALIDATION.RULE.DEFAULT`, params) as string
    },
})

/**
 * Validate if input has at least one uppercase
 * and one lowercase letter.
 */
extend('upper_and_lower_case', {
    validate: (value) => /(?=.*[a-z])(?=.*[A-Z])/.test(value),
    message: i18n.t('SETTINGS.PASSWORD.REQUIREMENT_GROUPS') as string,
})

extend('number_or_special', {
    validate: (value) => /(?=.*\W)|(?=.*\d)/.test(value),
    message: i18n.t('SETTINGS.PASSWORD.REQUIREMENT_SPECIALS') as string,
})

/**
 * Validate image minimum width
 * Rule should be preceded with `mimes:image/*` rule
 */
extend('image_min_width', {
    params: ['min'],
    async validate(file, { min }: any) {
        const image = await readImageAsync(URL.createObjectURL(file))

        return image.width >= min
    },
})


/**
 * Validate image maximum width
 * Rule should be preceded with `mimes:image/*` rule
 */
extend('image_max_width', {
    params: ['max'],
    async validate(file, { max }: any) {
        const image = await readImageAsync(URL.createObjectURL(file))

        return image.width <= max
    },
})

/**
 * Validate if time is after a target time that is marked
 * with Vid or name property, both need to be in the same
 * observer.
 *
 * @see https://vee-validate.logaretm.com/v3/advanced/cross-field-validation.html#targeting-other-fields
 *
 * Usage `time_after:@{vid}`
 */
extend('time_after', {
    params: ['target'],
    validate(value, { target }: any) {
        const format = 'hh:mm:ss'
        // fix for when target does not have the hours set or the minutes set
        if (typeof target === 'function')
            return i18n.t('VALIDATION.RULE.TIME_AFTER_INVALID') as string

        return moment(value, format)
            .isAfter(moment(target, format))
    },
})

/**
 * Validate if date is before a target date that is marked
 * with Vid or name property, both need to be in the same
 * observer.
 *
 * @see https://vee-validate.logaretm.com/v3/advanced/cross-field-validation.html#targeting-other-fields
 *
 * Usage `date_before:@{vid}`
 */
extend('date_before', {
    params: ['target'],
    validate(value, { target }: any) {
        return new Date(value) <= new Date(target)
    },
})

/**
 * Validates if input is a valid URL
 */
extend('url', {
    validate(value): boolean {
        return validator.isUrl(value)
    },
})

/**
 * Url or iframe
 * - First checks if input is a valid url
 * - Otherwise checks if input is a valid iframe html tag with valid `src` attribute as url
 */
extend('url_or_iframe', {
    validate(value): boolean {
        const regex = /^<iframe.* src=(['"`]?)(.+?)\1[> ].*<\/iframe>$/
        if (validator.isUrl(value)) {
            return true
        } else if (regex.test(value)) {
            const [,, src] = regex.exec(value) ?? []

            return validator.isUrl(src)
        } else {
            return false
        }
    },
})
