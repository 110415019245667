import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'modules',
    module: 'ModuleMaker',
    middleware: [
        auth(['admin', 'lb'], { name: '401' }),
        hasFeatures('ModuleMaker', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        redirect: { name: 'modules.index' },
    },
    {
        path: ':moduleId/edit',
        name: 'composer.index',
        props: true,
        meta: {
            hideAside: true,
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/ModuleMaker" */
                './ModuleMakerEdit.vue'
            ),
    },

    {
        path: ':moduleId/settings',
        name: 'composer.settings',
        props: true,
        meta: {
            hideAside: true,
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/ModuleMaker" */
                './ModuleMakerSettings.vue'
            ),
    },
])
