import Vuex, { Store } from 'vuex'

/**
 * Vuex plugin that handles async loading of modules
 * from our domain modules. e.g. @modules/{moduleName}/store
 *
 * This will add a async `load` method to the store prototype where
 * you can pass in a module name or an array of module names.
 * You will receive the store instance with new module loaded
 *
 ** Usage 1:
 *  await this.$store.load('MyModule').then((store) => store.dispatch('MyModule/someAction'))
 *
 ** Usage 2:
 *  await this.$store.load('MyModule')
 *  const data = await this.$store.dispatch('MyModule/someAction')
 *
 ** Usage 3: (not so pretty)
 *  const data = await (await this.$store.load('MyModule')).dispatch('MyModule/someAction')
 */
const plugin = (store: Store<any>): void => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    Vuex.Store.prototype.load = async function(moduleName): Promise<any> {
        const modules = Array.isArray(moduleName) ? moduleName : [moduleName]

        const promises = modules.map(async (moduleName) => {
            try {
                let module
                try {
                    module = await import(`@modules/${moduleName}/store/index.ts`)
                } catch {
                    module = await import(`@modules/${moduleName}/store.ts`)
                }

                if (this.hasModule(moduleName)) {
                    return
                }

                this.registerModule(moduleName, module.default)
            } catch (error) {
                console.warn(`Failed loading store of module [${moduleName}]`)
                throw error
            }
        })

        await Promise.all(promises)

        return store
    }
}

export default plugin