
<template>
    <!-- Case 1: Has image and aspect ratio -->
    <image-aspect-ratio
        v-if="showImage && $attrs.ar"
        v-bind="$attrs"
        :src="srcLocal"
        v-on="$listeners"
    >
        <!--
            @slot passes on the default slot to aspect ratio
            image component if `ar` prop is defined.
         -->
        <slot :src="srcLocal" />
    </image-aspect-ratio>

    <!-- Case 2: Has image, no aspect ratio and colored overlay -->
    <image-preview
        v-else-if="showImage && !$attrs.ar && $attrs.color"
        :src="srcLocal"
        v-bind="$attrs"
        v-on="$listeners"
    />

    <!-- Case 3: Has image and no aspect ratio -->
    <img
        v-else-if="showImage && !$attrs.ar"
        :src="srcLocal"
        v-bind="$attrs"
        v-on="$listeners"
    >

    <!-- Case 4: Show fallback when there is fallback slot -->
    <span v-else-if="fallback && $slots.fallback">
        <slot name="fallback" />
    </span>

    <!-- Case 5: Show fallback when there is aspect ratio -->
    <image-aspect-ratio
        v-else-if="fallback && $attrs.ar"
        v-bind="$attrs"
        no-backdrop
        class="skeleton-img"
    >
        <slot>
            <slot name="fallback">
                <fa
                    class="centerer"
                    size="2x"
                    :icon="fallbackIcon"
                />
            </slot>
        </slot>
    </image-aspect-ratio>

    <!-- Case 6: Show fallback when there is no aspect ratio -->
    <img
        v-else-if="fallback && !$attrs.ar"
        :src="srcLocal"
        v-bind="$attrs"
    >

    <!-- Case 7: If loading state and loading slot -->
    <span v-else-if="!loaded && $slots.loading">
        <slot name="loading" />
    </span>

    <!-- Case 8: If loading state and aspect ratio -->
    <image-aspect-ratio
        v-else-if="!loaded && $attrs.ar"
        v-bind="$attrs"
        class="skeleton-container skeleton-img"
    />
    <span v-else />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { buildFullPath } from '@common/Resources/utils'

    import ImageAspectRatio from './ImageAspectRatio.vue'
    import ImagePreview from './components/ImagePreview.vue'

    /**
     * Lazy Image, this component will only fetch image when component
     * is in view. It will also preload the image so you will never see
     * an image in the process of loading.
     *
     * This can work as a wrapper component around `ImageAspectRatio`.
     * if you have the `ar` prop defined then you can use any of
     * the other props from ImageAspectRatio.
     *
     * Todos:
     *      - Since the template its very complex we might want to convert
     *      it over to render functions since we are covering so many cases.
     */
    export default Vue.extend({
        components: {
            ImageAspectRatio,
            ImagePreview,
        },

        inheritAttrs: false,

        props: {
            /**
             * Image source
             *
             * Can be an url string or s3 key
             */
            src: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Delay the displaying of the image by milliseconds
             */
            delay: {
                type: Number as PropType<number>,
                default: 200,
            },

            /**
             * Get src key from public bucket of s3
             */
            public: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Icon to display on a fallback image. This prop os discarded
             * when using the `fallback` slot
             */
            fallbackIcon: {
                type: [String, Array] as PropType<string | string[]>,
                default: (): string[] => ['fal', 'image'],
            },

            /**
             * Disable Intersection observer and try to render the image rigth away
             * Only use in edge cases.
             */
            noLazy: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                observer: null as null | IntersectionObserver,
                srcLocal: null as null | string,
                fallback: false,
                loaded: false,
            }
        },

        computed: {
            showImage(): boolean {
                return this.loaded && !this.fallback
            },
        },

        watch: {
            src: {
                handler(value, oldValue): void {
                    if (!oldValue || value !== oldValue)
                        this.initIntersection()
                },
                immediate: true,
            },
        },

        beforeDestroy() {
            this.observer?.disconnect()
        },

        methods: {
            async initIntersection(): Promise<void> {
                this.observer?.disconnect()
                this.loaded = false
                this.fallback = false
                await this.$nextTick()

                if (this.noLazy) {
                    this.setImage()

                    return
                }


                this.observer = new IntersectionObserver(([entry]) => {
                    if (entry?.isIntersecting)
                        this.setImage()
                })

                this.observer.observe(this.$el)
            },

            setImage(): boolean | void {
                this.srcLocal = buildFullPath(this.src, this.public)

                if (!this.srcLocal)
                    return this.fallback = true

                const preloadImage = new Image()
                preloadImage.src = this.srcLocal
                preloadImage.onload = (): any => setTimeout(() => this.loaded = true, this.delay)
                preloadImage.onerror = (): any => this.fallback = true
            },
        },
    })
</script>

<docs>
**NOTE** Click on the example below to generate another image
At some point you might get a broken image path to demonstrate
the error slots

```vue
    <template>
        <div>
            <div class="row pointer" @click="getImage">
                <div class="col-4">
                    <p>Basic image, no loaders</p>
                    <image-lazy
                        class="img-fluid"
                        :src="src"
                    />
                </div>
                <div class="col-4">
                    <p>Aspect ratio, with default loading + 2s delay</p>
                    <image-lazy
                        :delay="2000"
                        ar="3:1"
                        fill
                        :src="src"
                    />
                </div>
                <div class="col-4">
                    <p>Custom loading and errors</p>
                    <image-lazy
                        class="img-fluid"
                        :src="src"
                    >
                        <template #fallback>
                            Bummer the image broke!
                        </template>
                        <template #loading>
                            <img src="https://resultsfinder.unl.edu/assets/images/loading3.gif">
                        </template>
                    </image-lazy>
                </div>
            </div>
        </div>
    </template>

    <script>
    export default {
        data() {
            return {src: null}
        },
        mounted() {
            this.getImage()
        },
        methods: {
            getImage() {
                this.src = `https://picsum.photos/id/${this.rand()}/${this.rand(400, 900)}/${this.rand(200, 450)}`
            },
            rand(min = 1, max = 1000) {
                let randomNum = Math.random() * (max - min) + min;
                return Math.round(randomNum);
            }
        }
    }
    </script>
```
</docs>
