import { auth } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'communication',
    module: 'Communication',
    middleware: [
        auth(['admin', 'lb'], { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'communication.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Communication" */
                './CommunicationIndex.vue'
            ),
    },
    {
        path: ':messageTypeId/templates',
        name: 'communication.templates',
        props: true,
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Communication" */
                './CommunicationMessageTemplates.vue'
            ),
    },
])
