<template>
    <div v-if="internalErrors.length">
        <div
            v-for="(error, index) in internalErrors"
            :key="index"
        >
            <div
                class="d-block invalid-feedback"
                v-text="error"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    export default Vue.extend({
        props: {
            value: {
                type: Object as PropType<Record<string, string>>,
                default: (): Record<string, string> => ({}),
            },
        },

        computed: {
            internalErrors(): string[] {
                return Object.values(this.value)
                    .map((errors) => errors)
                    .flat()
            },
        },
    })
</script>
