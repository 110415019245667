<template functional>
    <div class="text-center py-3">
        <div class="row justify-content-center no-gutters">
            <div class="col-sm-6 col-md-4 col-xl-3">
                <div
                    class="mx-auto"
                    style="max-width: 9rem"
                >
                    <inline-svg
                        class="text-gray"
                        :src="require('@assets/icons/icon-face-thinking.svg')"
                    />
                </div>
            </div>
        </div>
        <h5
            v-if="props.title"
            class="mb-1 mt-3"
        >
            {{ props.title }}
        </h5>
        <span
            v-if="props.message"
            class="text-gray-dark"
        >
            {{ props.message }}
        </span>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    export default Vue.extend({
        props: {
            title: { type: String as PropType<string>, default: null },
            message: { type: String as PropType<string>, default: null },
        },
    })
</script>

<style scoped>

</style>