<template>
    <base-modal
        :title="$t('LANGUAGE.CHOOSE')"
        :blocking="blocking"
        size="lg"
        centered
        no-cancel
    >
        <h4 v-t="'LANGUAGE.SUGGESTED'" />
        <language-row
            class="mb-3"
            :languages="suggestedLanguages"
            :selected-language-id="selectedLanguageId"
            @click="onSelectLanguage"
        />

        <h4 v-t="'LANGUAGE.AVAILABLE'" />
        <language-row
            :languages="availableLanguages"
            :selected-language-id="selectedLanguageId"
            @click="onSelectLanguage"
        />

        <template #ok-btn>
            <btn
                variant="primary"
                :label="$t('TERMS.SAVE')"
                @click="submit"
            />
        </template>
    </base-modal>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { get } from 'vuex-pathify'
    import { DialogComponent } from 'vue-modal-dialogs'
    import { utilHelper } from '@learningbank/lb-utils'
    import BaseModal from '@common/Modals/BaseModal.vue'
    import LanguageRow from './LanguageRow.vue'

    export default (Vue as VueConstructor<Vue & DialogComponent<string | null>>).extend({
        components: {
            BaseModal,
            LanguageRow,
        },

        props: {
            value: {
                type: String as PropType<string>,
                required: true,
            },

            /**
             * Disables clicking on the backdrop to dismiss the modal
             */
            blocking: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                selectedLanguageId: this.value,
            }
        },

        computed: {
            languages: get<Language[]>('AppConfig/systemLanguages'),
            companyLanguageId: get<string>('AppConfig/appConfig@language_id'),

            languageMap(): Map<string, Language> {
                return utilHelper.mapBy(this.languages, 'id') as Map<string, Language>
            },

            // Dictates the order of the suggested languages
            suggestedLanguageIds(): Set<string> {
                return new Set([
                    this.value,
                    this.companyLanguageId,
                    navigator.language,
                ])
            },

            availableLanguages(): Language[] {
                return this.languages.filter((language) => !this.suggestedLanguageIds.has(language.id))
            },

            suggestedLanguages(): Language[] {
                return Array.from(this.suggestedLanguageIds)
                    .map((languageId) => this.languageMap.get(languageId))
                    .filter(Boolean) as Language[]
            },

            companyLanguage(): Language | null {
                return this.languageMap.get(this.companyLanguageId) || null
            },

            browserLanguage(): Language | null {
                return this.languageMap.get(navigator.language) || null
            },

            userLanguage(): Language | null {
                return this.languageMap.get(this.value) || null
            },
        },

        methods: {
            onSelectLanguage(languageId: Language['id']): void {
                this.selectedLanguageId = languageId
            },

            submit(): void {
                this.$close(this.selectedLanguageId)
            },
        },
    })
</script>
