type StorageContract = Storage

/**
 * Web storage interface
 * Improve the interaction with web storages by auto parsing data sets
 * and auto stringify, with added typescript support.
 */
export class WebStorage {
    private storage: StorageContract

    constructor(storage: StorageContract) {
        this.storage = storage
    }

    /**
     * Check if web storage has key
     */
    has(key: string): boolean {
        return this.storage.getItem(key) !== null
    }

    /**
     * Get from web storage by key
     */
    get<T = unknown>(key: string): T {
        const value = this.storage.getItem(key)

        if (!value)
            return null as unknown as T

        try {
            return JSON.parse(value)
        } catch {
            return value as unknown as T
        }
    }

    /**
     * Get all items from web storage
     */
    getAll<T = unknown>(): Record<string, T> {
        return Object.keys(this.storage).reduce((acc, item) => {
            acc[item] = this.get(item)

            return acc
        }, {} as Record<string, T>)
    }

    /**
     * Set key in web storage
     */
    set(key: string, value: any): void {
        if (typeof value === 'string')
            return this.storage.setItem(key, value)

        return this.storage.setItem(key, JSON.stringify(value))
    }

    /**
     * Remove item from web storage
     */
    remove(key: string): void {
        return this.storage.removeItem(key)
    }

    /**
     * Clear web storage
     */
    clear(): void {
        return this.storage.clear()
    }
}

export const $localStorage = new WebStorage(window.localStorage)
export const $sessionStorage = new WebStorage(window.sessionStorage)
