<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <component
                :is="dropdown ? 'b-dropdown' : 'div'"
                ref="dropdown"
                class="color-select-field"
                variant="white"
                :toggle-class="['btn-icon border', inputClass]"
                :size="size"
                right
            >
                <template
                    v-if="dropdown"
                    #button-content
                >
                    <slot name="button-content">
                        <div
                            :style="currentValueStyle"
                            class="h-100 rounded"
                            :class="{ 'diagonal-line': !value }"
                        />
                    </slot>
                </template>

                <!-- Before options -->
                <slot name="before" />

                <!-- Color options -->
                <div
                    v-if="options && options.length"
                    class="d-flex flex-wrap g-2 mb-3"
                    :class="{ 'px-3 pt-2': dropdown }"
                    :style="dropdown ? 'min-width: 15rem' : ''"
                >
                    <btn
                        v-for="(item, key) in options"
                        :key="key"
                        :size="size"
                        variant="white"
                        :style="getColorStyle(item)"
                        :title="type === 'hex' ? item : null"
                        class="btn-icon border"
                        @click="optionClick(item)"
                    />
                    <!-- Clearable option -->
                    <btn
                        v-if="clearable"
                        :size="size"
                        variant="white"
                        class="btn-icon border diagonal-line"
                        :title="$t('TERMS.CLEAR')"
                        @click="clear"
                    />
                </div>

                <!-- Hex input form -->
                <div
                    v-if="!noCustom"
                    :class="{ 'mx-3 my-2': dropdown }"
                >
                    <validation-observer
                        #default="{ invalid, valid }"
                        class="d-flex custom-color-selector"
                    >
                        <div
                            v-if="!dropdown"
                            class="text-nowrap mr-3 pt-2"
                            v-text="$t('TERMS.HEX_COLOR')"
                        />
                        <input-field
                            v-model="hexLocal"
                            rules="hex_color"
                            class="mb-0"
                            input-class="rounded-right"
                            prepend-icon="hashtag"
                            :placeholder="dropdown ? $t('TERMS.HEX_COLOR') : null"
                            :description="invalid || !hexLocal ? null : $t('TERMS.ENTER_SAVE')"
                            @enter.stop.prevent="onCustomSelect(valid)"
                            @paste.native.prevent="onCustomHexPaste"
                        >
                            <template #append>
                                <div
                                    :style="valid && hexLocalStyle"
                                    class="btn btn-icon ml-2 border"
                                    :class="{
                                        'diagonal-line': !hexLocal || invalid
                                    }"
                                />
                            </template>
                        </input-field>
                    </validation-observer>
                </div>
            </component>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import {
        BDropdown,
        BFormInput,
        BInputGroupPrepend,
    } from 'bootstrap-vue'
    import { InputField } from '@common/Forms'
    import { hexToRgb, rgbToHex } from '@learningbank/lb-whitelabel-utils'
    import SharedField from './mixin'

    interface Refs {
        $refs: {
            dropdown: InstanceType<typeof BDropdown>;
        };
    }

    /**
     * Color select dropdown
     *
     * Color dropdown selector that supports both hex colors and RGB objects
     * as values and options, by default it will emit a rgb object but
     * you can set it up so it will emit hex values too
     *
     * **Notable Features**
     *
     * - Custom color via hex (will be transformed to rgb in rgb mode)
     * - Input can be set to clear selection
     * - Custom hex color support
     * - Can be displayed as dropdown or not
     *
     * **Tip**
     *
     * When in rgb mode (default) you can pass custom objects as options as
     * long as they contain valid `r`,`g` and `b` properties. These objects will be
     * set as values unchanged. Good when dealing with custom RGB objects
     *
     * @example ./__docs__/ColorSelectField.examples.md
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedField> & Refs
    >).extend({
        components: {
            BDropdown,
            BFormInput,
            InputField,
            BInputGroupPrepend,
        },
        mixins: [SharedField],

        props: {
            value: {
                type: [String, Object] as PropType<string | { r: number; b: number; g: number }>,
                default: null,
                validator: (value: string | object): boolean => {
                    if (typeof value === 'string')
                        return /^#[0-9A-F]{6}$/i.test(value)

                    return ['r', 'g', 'b'].every((key) => key in value)
                },
            },
            /**
             * Array of colors values to select from
             */
            options: {
                type: Array as PropType<string[] | { r: number; b: number; g: number }[]>,
                default: (): never[] => ([]),
            },
            /**
             * Type of colors we are emmitting
             * @values 'rgb', 'hex'
             */
            type: {
                type: String as PropType<string>,
                default: 'rgb',
                validator: (value: string): boolean => ['rgb', 'hex'].includes(value),
            },
            /**
             * Allow clearing the input (set NULL).
             * Displays a extra bubble
             */
            clearable: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Disable the custom hex input for custom colors
             */
            noCustom: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Display color picker as a dropdown
             */
            dropdown: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Styles to be applied on the editor content
             */
            opacity: {
                type: Number as PropType<Alpha>,
                default: null,
            },
        },

        data() {
            return {
                hexLocal: '',
            }
        },

        computed: {
            currentValueStyle(): Partial<CSSStyleDeclaration> {
                return {
                    ...(typeof this.opacity === 'number') && { opacity: `${this.opacity}` },
                    ...(this.value) && this.getColorStyle(this.value),
                }
            },

            hexLocalStyle(): Partial<CSSStyleDeclaration> {
                return {
                    ...(this.hexLocal) && this.getColorStyle(this.hexLocal),
                }
            },
        },

        watch: {
            value: {
                handler(value): string {
                    if (!value)
                        return this.hexLocal = ''

                    if (typeof value === 'object'){
                        const { r, g, b } = value

                        return this.hexLocal = rgbToHex(r, g, b, false)
                    }

                    return this.hexLocal = value.startsWith('#')
                        ? value.substring(1)
                        : value
                },
                immediate: true,
            },
        },

        methods: {
            getColorStyle(value: string | { r: number; b: number; g: number }): Partial<CSSStyleDeclaration> {
                return {
                    backgroundColor: typeof value === 'string'
                        ? value.startsWith('#') ? value : `#${value}`
                        : `rgb(${value.r}, ${value.g}, ${value.b})`,
                }
            },

            clear(): void {
                this.internalValue = null
                this.hexLocal = ''
                this.$refs.dropdown.hide?.()
            },

            optionClick(value: string | { r: number; b: number; g: number }): void {
                if (typeof value === 'string' && this.type === 'rgb')
                    this.internalValue = hexToRgb(value)
                else if (typeof value === 'object' && this.type === 'hex')
                    this.internalValue = rgbToHex(value.r, value.g, value.b, true)
                else
                    this.internalValue = value

                this.$refs.dropdown.hide?.()
            },

            onCustomSelect(isValid: boolean): void {
                if (!isValid || !this.hexLocal) return

                if (this.type === 'rgb')
                    this.internalValue = hexToRgb(this.hexLocal)
                else
                    this.internalValue = '#' + this.hexLocal

                this.$refs.dropdown.hide?.()
                this.hexLocal = ''
            },
            onCustomHexPaste(event: ClipboardEvent): void {
                this.hexLocal = event.clipboardData
                    ?.getData('text')
                    .replace('#', '') ?? ''
            },
        },
    })
</script>

<style lang="scss" scoped>
    ::v-deep .custom-color-selector {
        min-width: 15rem;
    }
</style>