import { Extension, Extensions } from '@tiptap/vue-2'
import { ListItem } from '@tiptap/extension-list-item'
import { BulletList } from '@tiptap/extension-bullet-list'
import { OrderedList } from '@tiptap/extension-ordered-list'

export interface ListOptions {
    bulletList: boolean;
    orderedList: boolean;
}

const List = Extension.create<ListOptions>({
    name: 'list',

    addOptions() {
        return {
            bulletList: true,
            orderedList: true,
        }
    },

    addExtensions() {
        const extensions: Extensions = [ ListItem ]

        if (this.options.bulletList !== false)
            extensions.push(BulletList)

        if (this.options.orderedList !== false)
            extensions.push(OrderedList)

        return extensions
    },
})

export default List