import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'


const routerConfig = {
    baseURI: 'social-wall',
    module: 'SocialWall',
    middleware: [
        auth(['client', 'admin'], { name: '401' }),
        hasFeatures('SocialWall', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'socialWall.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/SocialWall" */
                './SocialWallIndex.vue'
            ),
    },
    {
        path: ':postId',
        name: 'socialWall.view',
        props: true,
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/SocialWall" */
                './SocialWallView.vue'
            ),
    },
])
