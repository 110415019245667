import { Module } from 'vuex'
import Http from '@utils/Http'
import jwtDecode from 'jwt-decode'
import { $localStorage } from '@plugins/storage'

const store: Module<any, any> = {
    namespaced: true,
    state: {
        timeout: null,
    },
    mutations: {
        SET_TIMEOUT(state, payload: number): void {
            clearTimeout(state.timeout)
            state.timeout = payload
        },

        CLEAR_TIMEOUT(state): void {
            if (state.timeout) {
                clearTimeout(state.timeout)
                state.timeout = null
            }
        },
    },
    actions: {
        /**
         * Issue a new token for user by login credentials
         */
        async issue({ rootGetters, rootState }, credentials: AuthSigninPayload): Promise<AuthSigninResponse> {
            const organizationUUID = rootGetters['AppConfig/orgUuid']

            // Only send languageId if user manually changes the language on the login page
            const languageId = $localStorage.has('login-language-dirty') ? rootState.AppConfig?.lang : null

            try {
                const { data } = await Http.api().post(`login`, {
                    organizationUUID,
                    languageId,
                    ...credentials,
                })

                return data
            } finally {
                $localStorage.remove('login-language-dirty')
            }
        },

        /**
         * Renew user token
         */
        async renew({ dispatch }): Promise<void> {
            try {
                const [{ data }] = await Promise.all([
                    Http.api().get(`login/renew`),
                    dispatch('Auth/Cookie/issue', null, { root: true }),
                ])

                await dispatch('Auth/postLogin', {
                    response: data,
                }, { root: true })
            } catch (e) {
                dispatch('Auth/logout', {
                    reroute: true,
                }, { root: true })
            }
        },

        /**
         * Set token renew timer
         */
        setTimer({ commit, dispatch }, token): Promise<void> | void  {
            const tokenDecoded: any = jwtDecode(token)
            const expiresIn = (tokenDecoded.exp * 1000) - +new Date()

            commit('CLEAR_TIMEOUT')

            // If token is already expired
            if (expiresIn <= 0)
                return dispatch('Auth/logout', {
                    reroute: true,
                }, { root: true })

            // If token is about to expire
            if (expiresIn < 30 * 1000)
                return dispatch('renew')

            // Renew timer, extract 30 sec
            const renewIn = expiresIn - 30 * 1000

            return commit('SET_TIMEOUT', setTimeout(() => {
                dispatch('renew')
            }, renewIn))
        },
    },
}

export default store
