import { hasPermissions } from '@modules/Auth/utils'
import { RawLocation } from 'vue-router'

/**
 * Checks if user has necessary permission to enter route
 *
 * @param {string|string[]} permission
 * @param {Boolean|Object} nextParam
 * @returns {MiddlewareCallback}
 */
export default function(
    permission: string,
    nextParam: RawLocation | false = false,
): MiddlewareCallback {
    return ({ proceed, next }): void => {
        if (!hasPermissions(permission)) {
            return next(nextParam)
        }

        return proceed()
    }
}
