import ImageIcon from '@assets/icons/icon-image-plus.svg'
import FileIcon from '@assets/icons/icon-file-image.svg'

export const availableRatios = [
    '1:1',
    '2:1',
    '2:3',
    '3:1',
    '3:2',
    '4:1',
    '4:3',
    '4:5',
    '5:1',
    '7:9',
    '16:9',
    '21:9',
    '51:14',
    '70:90',
]

export const ICON_MAP = {
    'image': ImageIcon,
    '*': FileIcon,
} as Readonly<{ [key: string]: SVGElement }>
