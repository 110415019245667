<template>
    <component
        :is="isPlayable ? 'module-player-start-btn' : 'fallback'"
        #default="{ start, btnProps }"
        :module-id="value.id"
        :container-id="context && context.containerId"
    >
        <card
            class="module-card"
            :class="{
                'card-hover pointer': clickable && isPlayable,
            }"
            border="none"
            :body-class="{ 'pt-1 pb-0': !!horizontalLocal }"
            :footer-class="{ 'pb-1': !!horizontalLocal }"
            :horizontal="horizontalLocal"
            v-bind="$attrs"
            @click="onClick(start)"
        >
            <!-- Header image -->
            <template #before>
                <div class="position-relative">
                    <div
                        class="embed-responsive embed-responsive-16by9 bg-light text-gray-dark"
                        :class="[ horizontalLocal ? 'rounded-left' : 'rounded-top' ]"
                    >
                        <inline-svg
                            class="centerer w-35"
                            :src="moduletypeIcons[value.moduleTypeId]"
                        />
                    </div>

                    <!-- Start content button -->
                    <btn
                        v-if="!$device.isMobile && isPlayable && clickable"
                        class="start-btn position-absolute"
                        :class="!!horizontalLocal ? 'top-1 left-1' : 'bottom-n2 left-3'"
                        v-bind="btnProps"
                        pill
                        @click.stop="clickable && start"
                    />
                    <div
                        v-if="cardInfo"
                        class="position-absolute right-0 bottom-0 badge badge-pill rounded-0 rounded-top-left mw-100 text-truncate"
                        :class="`badge-${cardInfo.variant}`"
                        v-text="cardInfo.text"
                    />
                </div>
            </template>
            <template #default>
                <small
                    class="text-muted"
                    v-text="$t('TERMS.MODULE')"
                />
                <!-- Module name -->
                <h6
                    v-line-clamp:[lineClampArg]="2"
                    class="module-card__title mb-0"
                    :class="{ 'text-gray': context && context.status === 'locked' }"
                    v-text="value.name"
                />
            </template>

            <template #footer>
                <!-- Search: found with in message -->
                <small
                    v-if="context && context.matches && context.matches.slideIds.length"
                    class="text-info mb-3 d-block"
                    v-text="$tc('LEARNING.SEARCH.SLIDE_MATCHES', context.matches.slideIds.length)"
                />

                <!-- Learning attributes -->
                <ul
                    v-if="!horizontalLocal || learningInfo"
                    class="inline-list small text-muted mb-0"
                >
                    <!-- Module type -->
                    <li v-text="$t(`MODULE.TYPES.${value.moduleTypeId}`)" />
                    <!-- Module duration -->
                    <li
                        v-if="value.duration"
                        v-text="$tc('ACADEMY.MODULES_ESTIMATED_TIME', value.duration)"
                    />
                </ul>
            </template>
        </card>
    </component>
</template>

<script lang="ts">
    import Card, { HorizontalOptions } from '@common/Card/Card.vue'
    import LearningStatusMixin from '@modules/Learning/mixins/learning-status.mixin'
    import ModulePlayerStartBtn from '@modules/ModulePlayer/components/ModulePlayerStartBtn.vue'
    import { MODULE_TYPE_ICONS } from '@utils'
    import Vue, { CreateElement, PropType, RenderContext, VNode, VueConstructor } from 'vue'
    import ModuleInfo from './ModuleInfo.vue'

    export default (Vue as VueConstructor<Vue & InstanceType<typeof LearningStatusMixin>>).extend({
        components: {
            fallback: {
                functional: true,
                render: (_h: CreateElement, { scopedSlots }: RenderContext): VNode | VNode[] =>
                    scopedSlots.default?.({}) ?? [],
            },
            Card,
            ModulePlayerStartBtn,
            ModuleInfo,
        },

        mixins: [LearningStatusMixin],

        props: {
            value: {
                type: Object as PropType<ModuleDataModel>,
                required: true,
            },

            context: {
                type: Object as PropType<ModuleContextModel | null>,
                default: null,
            },

            horizontal: {
                type: [Boolean, Object] as PropType<boolean | HorizontalOptions>,
                default: false,
            },

            clickable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Learning info to display learning duration, content count
             */
            learningInfo: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                moduletypeIcons: MODULE_TYPE_ICONS,
            }
        },

        computed: {
            lineClampArg(): string | null {
                return this.$device.isDesktop ? 'fixed' : null
            },

            horizontalLocal(): boolean | HorizontalOptions {
                if (typeof this.horizontal === 'object')
                    return this.horizontal

                return this.horizontal
                    ? {
                        before: 'col-5',
                        row: 'align-items-center',
                    }
                    : false
            },

            cardInfo(): ModuleStatusData | null {
                return this.context
                    ? this.getModuleStatusData(this.context)
                    : null
            },

            isPlayable(): boolean {
                return (
                    !!this.context
                    && !['pending', 'locked'].includes(this.context?.status)
                    && this.context?.containerId > 0
                    && this.value.concluder !== 'manager'
                )
            },
        },

        methods: {
            onClick(start: () => any): void {
                if (!this.clickable)
                    return

                if (this.isPlayable)
                    return start()

                this.$dialog.modal(ModuleInfo, { data: this.value })
            },
        },
    })
</script>

<style lang="scss" scoped>
    .module-card {
        .start-btn {
            transition: all 200ms ease-in-out;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-.5rem);
        }

        &:hover {
            .start-btn {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

</style>
