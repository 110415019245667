import Vue, { VNode } from 'vue'

/**
 * Set up global error handler via NewRelic
 */
if (NODE_ENV === 'production' && window.NREUM) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    Vue.config.errorHandler = (error): void => {
        try {
            window.NREUM.noticeError?.(error, {
                url: window.location.hash,
            })
        } catch (e) {
            console.error("Couldn't report to new relic")
        }
    }
}

/**
 * Plugins
 * - import & bootstrap plugins
 */
import '@plugins/acl'
import '@plugins/portal-vue'
import '@plugins/bootstrap-vue'
import '@plugins/flash'
import '@plugins/validation'
import '@plugins/dialog'
import '@plugins/global-components'
import '@plugins/global-filters'
import '@plugins/sockets'
import '@plugins/device'
import '@plugins/config'
import '@plugins/storage'
import '@plugins/scrolling'
import '@plugins/chartjs'
import '@plugins/fontawesome'
import './filters'
import './directives'

/**
 * Styles
 */
import '@scss/main.scss'

/**
 * Bootstrap application
 */
import App from './App.vue'
import router from '@router'
import store from '@store'
import { i18n } from '@plugins/i18n'

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    created(): void {
        this.$store.dispatch('initApp')
    },
    render: (h): VNode => h(App),
})
