import mime from 'mime-types'

/**
 * Santitize filename with extension.
 * Support to append and prepend custom string
 *
 * @param {String} filename
 * @param {String} append added just before extension
 * @param {String} prepend added before new file name
 * @returns {String}
 */
export function sanitizeFilename(
    filename: string,
    append = '',
    prepend = '',
): string {
    const extension = filename
        .split('.')
        .slice(0)
        .pop()

    const newName = filename.replace(extension ?? '', '').replace(/[^a-zA-Z0-9_-]/g, '')

    return prepend + newName + append + `.${extension}`
}

/**
 * Read image file async
 *
 * @param {String} src ObjectURL
 * @return {Image}
 */
export function readImageAsync(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = (): any => resolve(img)
        img.onerror = reject
        img.src = src
    })
}

/**
 * Create a new File object out of base64 string
 *
 * @param {String} url base64 string
 * @param {String} filename Name of file
 * @param {String} mimeType file type
 *
 * @return {File}
 */
export async function base64ToFile(
    url: string,
    filename: string,
    mimeType: string,
): Promise<File> {
    return fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }))
}

/**
 * Get a File object from file on the web
 * - Will detect content type and append correct
 *   extension automatically
 *
 * @param url External url of file to download
 * @param filename File name for image object
 *
 * @returns {File}
 */
export async function urlToFile(
    url: string,
    filename: string,
): Promise<File> {
    const response = await fetch(url)
    const contentType = response.headers.get('content-type')
    const blob = await response.blob()
    const ext = mime.extension(contentType)

    const file = new File([blob], `${filename}.${ext}`, {
        type: contentType as string,
    })

    return file
}

/**
 * This helper creates an artificial file select input
 *
 * This is useful to call on a click event,
 * this will create an hidden file input on the page
 * and propt the file select dialog.
 *
 * This function will return a promise, resolving the
 * files selected.
 *
 *
 * @param options.multiple - Allow multiple file selection
 * @param options.accept - Accept attribute of file input
 * @param options.onCancel - Callback to fire when user cancels file selection.
 */
export function createFileSelect(
    options?: { multiple?: boolean; accept?: string; onCancel?: () => void },
): Promise<FileList | null> {
    let lock = false

    return new Promise((resolve) => {
        // Create hidden file input
        const el = document.createElement('input')
        el.style.display = 'none'
        el.setAttribute('type', 'file')

        // Configure input options
        if (options?.multiple) {
            el.setAttribute('multiple', '')
        }

        if (options?.accept) {
            el.setAttribute('accept', options.accept)
        }

        // Add input to body
        document.body.appendChild(el)

        // Handle when files are selected
        el.addEventListener('change', () => {
            lock = true
            // Resolve promise with selected files
            resolve(el.files)
            // Remove input from dom
            el.remove()
        }, { once: true })

        // Add cancel listener to window focus
        window.addEventListener('focus', () => {
            setTimeout(() => {
                if (!lock && el) {
                    resolve(null)
                    options?.onCancel?.()
                    el.remove()
                }
            }, 500)
        }, { once: true })

        // Open file selection
        el.click()
    })
}

/**
 * @description Downloads provided string as a text file
 * @param {Object} options
 * @param {String} options.filename
 * @param {string} options.mime
 * @param {string} options.data
 * @return {void}
 */


/**
 * Downloads provided object as a file
 *
 * @param data Any object
 * @param filename name for the file
 * @param mimeType mime type to export
 */
export function downloadObjectToFile(data: object, filename: string, mimeType = 'text'): void {
    const dataStr = `data:${mimeType};charset=utf-8,` + encodeURIComponent(JSON.stringify(data))
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href',     dataStr)
    downloadAnchorNode.setAttribute('download', filename)
    document.body.appendChild(downloadAnchorNode)
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
}

export const MIME_TYPES = {
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

/**
 * Downloads provided data as a file
 *
 * @param data string or blob
 * @param filename name for the file
 * @param mimeType mime type to export
 */
export function downloadFile(data: Blob | string, filename: string): void {
    const url = typeof data === 'string' ? data : URL.createObjectURL(data)

    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', url)
    downloadAnchorNode.setAttribute('download', filename)

    document.body.appendChild(downloadAnchorNode)

    downloadAnchorNode.click()
    downloadAnchorNode.remove()
}