import { RawLocation } from 'vue-router'

/**
 * Checks if user is logged in, fires nextParam if user is
 * - Useful to guard public facing pages when logged in
 *
 * @param {Boolean|Object} nextParam
 * @returns {MiddlewareCallback}
 */
export default function(
    nextParam: RawLocation | false = false,
): MiddlewareCallback {
    return ({ proceed, next, store }): void => {
        if (store.get('Auth/isAuthenticated'))
            return next(nextParam)

        return proceed()
    }
}