import routesFactory from '@router/routes-factory'
import { guest } from '@middleware'

const routerConfig = {
    baseURI: 'auth',
    module: 'Auth',
    middleware: [],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        redirect: { name: 'auth.signin' },
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Auth" */
            './AuthWrapper.vue'
        ),
        children: [
            {
                path: 'signin',
                name: 'auth.signin',
                meta: {
                    middleware: [
                        guest('/'),
                    ],
                },
                component: (): Promise<any> => import(
                /* webpackChunkName: "modules/Auth" */
                    './AuthSignin.vue'
                ),
            },
            {
                path: 'signout',
                name: 'auth.signout',
                component: (): Promise<any> => import(
                /* webpackChunkName: "modules/Auth" */
                    './AuthSignout.vue'
                ),
            },
            {
                path: 'signup',
                name: 'auth.signup',
                meta: {
                    middleware: [
                        guest('/'),
                    ],
                },
                component: (): Promise<any> => import(
                /* webpackChunkName: "modules/Auth" */
                    './AuthSignup.vue'
                ),
            },
            {
                path: 'forgot-password',
                name: 'auth.passwordForgot',
                meta: {
                    middleware: [
                        guest('/'),
                    ],
                },
                component: (): Promise<any> => import(
                /* webpackChunkName: "modules/Auth" */
                    './AuthPasswordForgot.vue'
                ),
            },
            {
                path: 'reset-password',
                name: 'auth.passwordReset',
                props: (route): object => ({
                    nonce: route.query.nonce,
                    credentials: route.params.credentials && JSON.parse(route.params.credentials),
                }),
                meta: {
                    middleware: [
                        guest('/'),
                    ],
                },
                component: (): Promise<any> => import(
                /* webpackChunkName: "modules/Auth" */
                    './AuthPasswordReset.vue'
                ),
            },
            {
                path: 'mfa',
                name: 'auth.validationMfa',
                props: (route): object => ({
                    credentials: route.params.credentials && JSON.parse(route.params.credentials),
                }),
                meta: {
                    middleware: [
                        guest('/'),
                    ],
                },
                component: (): Promise<any> => import(
                /* webpackChunkName: "modules/Auth" */
                    './AuthValidationMfa.vue'
                ),
            },
            {
                path: 'account-activation',
                name: 'auth.activationAccount',
                props: (route): object => ({
                    nonce: route.query.nonce,
                }),
                meta: {
                    middleware: [
                        guest('/'),
                    ],
                },
                component: (): Promise<any> => import(
                /* webpackChunkName: "modules/Auth" */
                    './AuthActivationAccount.vue'
                ),
            },
            {
                path: 'first-time-login',
                name: 'auth.firstTimeLogin',
                meta: {
                    middleware: [
                        guest('/'),
                    ],
                },
                component: (): Promise<any> => import(
                /* webpackChunkName: "modules/Auth" */
                    './AuthFirstTimeLogin.vue'
                ),
            },
            {
                path: 'app-activation',
                name: 'auth.activationApp',
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/Auth" */
                    './AuthActivationApp.vue'
                ),
            },
            {
                path: 'error',
                name: 'auth.error',
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/Auth" */
                    './AuthError.vue'
                ),
            },
        ],
    },
])
