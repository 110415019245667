<template>
    <span v-show="false">
        <slot />
    </span>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { RawLocation } from 'vue-router'
    import { NavigationItem } from './@typings'
    import ItemGetterMixin from './item-getter.mixin'

    export default (Vue as VueConstructor<Vue
        & InstanceType<typeof ItemGetterMixin>
    >).extend({
        mixins: [ItemGetterMixin],
        props: {
            /**
             * Label to display on the item
             */
            label: {
                type: String as PropType<string>,
                required: true,
            },
            /**
             * Icon associated with item
             */
            icon: {
                type: [String, Array] as PropType<string | string[]>,
                default: null,
            },
            /**
             * Visibility
             */
            visible: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Location to route to via vue-router
             */
            to: {
                type: [String, Object] as PropType<RawLocation>,
                default: null,
            },
            /**
             * Any additional props you can give to the renderer component
             */
            options: {
                type: Object as PropType<NavigationItem['options']>,
                default: (): object => ({}),
            },
            /**
             * Offset navigation item, to be filtered out and placed elsewhere
             */
            offset: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        methods: {
            getItem(): any {
                const item: NavigationItem = {
                    label: this.label,
                    icon: this.icon,
                    visible: this.visible,
                    to: this.to,
                    children: this.children,
                    listeners: this.$listeners as Record<
                        string,
                        ((...args: any[]) => any) | ((...args: any[]) => any)[]>,
                    scopedSlots: this.$scopedSlots,
                    options: this.options,
                    offset: this.offset,
                }

                return item
            },
        },
    })
</script>