<template>
    <spinner
        v-if="busy"
        class="centerer"
    />

    <component
        :is="tag"
        v-else
        class="position-relative"
        @mouseover="setHover(true)"
        @mouseleave="setHover(false)"
    >
        <slot
            :hovering="hover && hovering"
            :show="show"
        />
        <transition name="fade">
            <div
                v-if="showOverlay"
                class="absolute-fill"
                :class="overlayClassLocal"
            >
                <slot
                    name="content"
                    :hovering="hover && hovering"
                    :show="show"
                />
            </div>
        </transition>
    </component>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import Spinner from '@common/components/Spinner.vue'

    export default Vue.extend({
        components: {
            Spinner,
        },

        props: {
            /**
             * Wrapper tag for relative position tag.
             */
            tag: {
                type: String as PropType<string>,
                default: 'div',
            },

            /**
             * Overlay color variant - will use the dimmed version of the color
             */
            variant: {
                type: String as PropType<string>,
                default: null,
                validator: (value: string): boolean => [
                    'default',
                    'primary',
                    'secondary',
                    'accent',
                ].includes(value),
            },

            busy: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Trigger the overlay programatically
             */
            show: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Trigger overlay on hover
             */
            hover: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Any classes to add on the overlay element
             */
            overlayClass: {
                type: [Array, Object, String] as PropType<any[] | object | string>,
                default: null,
            },
        },

        data() {
            return {
                hovering: false,
            }
        },

        computed: {
            showOverlay(): boolean {
                return (
                    this.hover && this.hovering ||
                    this.show
                )
            },

            overlayClassLocal(): any[] {
                return [
                    ...this.variant ? [`overlay--${this.variant}`] : [],
                    this.overlayClass,
                ]
            },
        },

        methods: {
            setHover(state: boolean): void {
                this.hovering = state
            },
        },

    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .overlay--primary {
        background-color: whitelabel-color('primary', $modal-backdrop-opacity);
    }

    .overlay--secondary {
        background-color: whitelabel-color('secondary', $modal-backdrop-opacity);
    }

    .overlay--accent {
        background-color: whitelabel-color('accent', $modal-backdrop-opacity);
    }

    .overlay--default {
        background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    }
</style>
