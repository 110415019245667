<template>
    <router-link
        :to="to"
        class="topbar-nav-item d-flex align-items-center"
        :class="!$device.isMobile ? 'mx-3' : 'mx-2'"
    >
        <fa
            v-if="icon"
            fixed-width
            :class="{ 'mr-2': !$device.isMobile }"
            :icon="icon"
        />
        <slot
            v-if="!$device.isMobile"
            name="default"
        >
            <span v-text="label" />
        </slot>
    </router-link>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { Location } from 'vue-router'

    export default Vue.extend({
        props: {
            /* Navigation label */
            label: {
                type: String as PropType<string>,
                default: null,
            },
            /* Navigation icon */
            icon: {
                type: [String, Array] as PropType<string | string[]>,
                default: null,
            },
            /* Navigation vue-router location */
            to: {
                type: Object as PropType<Location>,
                required: true,
            },
        },

    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .topbar-nav-item {
        border-bottom: $border-width * 3 solid transparent;
        color: color('gray-dark');
        height: 100%;

        &.active {
            color: whitelabel-color('primary');
            border-color: whitelabel-color('primary');
        }
    }
</style>
