type IconTypeCombination = { icon: string; type: string; variant: string }

enum FileIcons {
    image = 'file-image',
    pdf = 'file-pdf',
    word = 'file-word',
    powerpoint = 'file-powerpoint',
    excel = 'file-excel',
    csv = 'file-csv',
    audio = 'file-audio',
    video = 'file-video',
    archive = 'file-archive',
    code = 'file-code',
    text = 'file-alt',
    file = 'file',
}

type FileIconsMap = { [key: string]: keyof typeof FileIcons }

const fileExtMap: FileIconsMap = {
    gif: 'image',
    jpeg: 'image',
    jpg: 'image',
    png: 'image',
    svg: 'image',
    pdf: 'pdf',
    doc: 'word',
    docx: 'word',
    ppt: 'powerpoint',
    pptx: 'powerpoint',
    xls: 'excel',
    xlsx: 'excel',
    csv: 'csv',
    aac: 'audio',
    mp3: 'audio',
    wav: 'audio',
    ogg: 'audio',
    avi: 'video',
    flv: 'video',
    mkv: 'video',
    mp4: 'video',
    gz: 'archive',
    zip: 'archive',
    css: 'code',
    html: 'code',
    js: 'code',
    txt: 'text',
} as const

const fileMimeMap: FileIconsMap = {
    'image/gif': 'image',
    'image/jpeg': 'image',
    'image/png': 'image',
    'image/svg+xml': 'image',
    'application/pdf': 'pdf',
    'application/msword': 'word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
    'application/mspowerpoint': 'powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint',
    'application/msexcel': 'excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
    'text/csv': 'csv',
    'audio/aac': 'audio',
    'audio/wav': 'audio',
    'audio/mpeg': 'audio',
    'audio/mp4': 'audio',
    'audio/ogg': 'audio',
    'video/x-msvideo': 'video',
    'video/mpeg': 'video',
    'video/mp4': 'video',
    'video/ogg': 'video',
    'video/quicktime': 'video',
    'video/webm': 'video',
    'application/gzip': 'archive',
    'application/zip': 'archive',
    'text/css': 'code',
    'text/html': 'code',
    'text/javascript': 'code',
    'application/javascript': 'code',
    'text/plain': 'text',
    'text/richtext': 'text',
    'text/rtf': 'text',
} as const

const fileVariantMap: { [key: string]: string } = {
    image: 'info',
    pdf: 'danger',
    word: 'info',
    powerpoint: 'warning',
    excel: 'success',
    csv: 'success',
    audio: 'gray',
    video: 'gray',
    archive: 'gray',
    code: 'gray',
    text: 'gray',
    file: 'gray',
}

/**
 * Get font awesome icon based on a MIME type
 *
 * @param mimeType
 * @param combination return an object with icon and file type
 */
function getFaForMime(mimeType: string, combination: boolean): IconTypeCombination
function getFaForMime(mimeType: string, combination?: boolean): string
function getFaForMime(mimeType: string, combination?: boolean): string | IconTypeCombination {
    const type = fileMimeMap[mimeType.toLowerCase()]
    const icon = FileIcons[type || 'file']
    const variant = fileVariantMap[type || 'file']

    return !combination ? icon : {
        icon,
        type: type || 'other',
        variant,
    }
}

/**
 * Get font awesome icon based on a file extension
 *
 * @param extension
 */
function getFaForExt(extension: string, combination: boolean): IconTypeCombination
function getFaForExt(extension: string, combination?: boolean): string
function getFaForExt(extension: string, combination?: boolean): string | IconTypeCombination {
    const type = fileExtMap[extension.toLowerCase()]
    const icon = FileIcons[type || 'file']
    const variant = fileVariantMap[type || 'file']

    return !combination ? icon : {
        icon,
        type: type || 'other',
        variant,
    }
}

/**
 * Get font awesome icon based on a full filename that includes ext
 *
 * @param filename
 */
function getFaForFilename(filename: string, combination: boolean): IconTypeCombination
function getFaForFilename(filename: string, combination?: boolean): string
function getFaForFilename(filename: string, combination?: boolean): string | IconTypeCombination {
    const extension = filename
        .slice((filename.lastIndexOf('.') - 1 >>> 0) + 2)

    return getFaForExt(extension, combination)
}

export {
    getFaForMime,
    getFaForExt,
    getFaForFilename,
}
