<template>
    <span>
        <b-dropdown-header>{{ $t('TERMS.COLUMNS') }}</b-dropdown-header>
        <b-dropdown-text>
            <b-form-checkbox
                v-for="(field, index) in valueLocal"
                :key="index"
                v-model="field.hidden"
                class="mb-2 text-nowrap"
                :value="false"
                :unchecked-value="true"
                @input="updateSelectedColumns"
            >
                {{ field.label }}
            </b-form-checkbox>
        </b-dropdown-text>
    </span>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { get } from 'vuex-pathify'
    import { BDropdownHeader, BFormCheckbox, BDropdownText } from 'bootstrap-vue'

    export default Vue.extend({
        components: { BFormCheckbox, BDropdownHeader, BDropdownText },

        props: {
            tableId: { type: String as PropType<string>, default: null },
            value: { type: Array as PropType<any[]>, default: (): any[] => ([]) },
        },

        computed: {
            userId: get<number>('Auth/user@id'),

            storageKey(): string {
                return `${this.userId}-${this.tableId}-columns`
            },

            valueLocal: {
                get(): any[] {
                    return this.value.filter((field) => field.label)
                },
                set(value: any[]): void {
                    this.$emit('input', value)
                },
            },
        },

        watch: {
            value: {
                handler(): void {
                    this.syncFromStorage()
                },
                immediate: true,
                deep: true,
            },
        },

        methods: {
            syncFromStorage(): void {
                const config = this.$localStorage.get<Record<string, boolean> | null>(this.storageKey)

                if (!config)
                    return

                if (Object.keys(config).length !== this.value.length)
                    return this.$localStorage.remove(this.storageKey)

                this.valueLocal.forEach((item: any) => {
                    item.hidden = !config[item.key]
                })
            },

            updateSelectedColumns(): void {
                const config = this.value.reduce((acc: any, item: any) => {
                    acc[item.key] = !item.hidden

                    return acc
                }, {})

                this.$localStorage.set(this.storageKey, config)
            },
        },
    })
</script>