import Vue, { PropType } from 'vue'
import { isEqual } from 'lodash'
import { ValidationFlags } from 'vee-validate/dist/types/types'
import { normalizeRules } from 'vee-validate'

export default Vue.extend({
    props: {
        /**
         * Vee-validate prop.
         * Identifier used for target/cross-field based rules.
         */
        vid: {
            type: String,
            default: undefined,
        },
        /**
         * The current value of the field.
         * @model
         */
        value: {
            type: [String, Number, Array, Object, Boolean, Date] as PropType<any>,
            default: null,
        },
        /**
         * Sets the value of the `name` attribute on the form control
         */
        name: {
            type: String as PropType<string>,
            default: undefined,
        },
        /**
         * Text to place in the help text area of the form group
         */
        description: {
            type: String as PropType<string>,
            default: undefined,
        },
        /**
         * Sets the `placeholder` attribute value on the form control
         */
        placeholder: {
            type: String as PropType<string>,
            default: '',
        },
        /**
         * When set to `true`, disables the component's
         * functionality and places it in a disabled state
         */
        disabled: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Pass on classes to the input itself.
         */
        inputClass: {
            type: [String, Array, Object] as PropType<string | any[] | object>,
            default: null,
        },
        /**
         * Text to place in the label/legend of the form group
         */
        label: {
            type: String as PropType<string>,
            default: undefined,
        },
        /**
         * Additional classes to add to label element if label is present
         */
        labelClass: {
            type: [String, Object] as PropType<string | object>,
            default: undefined,
        },

        /**
         * Vee-validate rules.
         * You can use piped string syntax or rules object expression
         */
        rules: {
            type: [String, Object] as PropType<string | object>,
            default: undefined,
        },
        /**
         * Custom error messages, keyed by rule name.
         * These will override any default and configured messages.
         */
        customMessages: {
            type: Object as PropType<{ [k: string]: string }>,
            default: (): object => ({}),
        },
        /**
         * Set the size of the component's appearance.
         */
        size: {
            type: String as PropType<string>,
            default: 'md',
            validator: (value: string): boolean =>
                ['lg', 'md', 'sm'].includes(value),
        },
        /**
         * When set to `true`, attempts to auto-focus the control when
         * it is mounted, or re-activated when in a keep-alive.
         * Does not set the `autofocus` attribute on the control
         */
        autofocus: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Vee-validate prop.
         * If the field should be validated immediately after render (initially).
         */
        immediate: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Vee-validate prop. If true, the validation will be skipped if the value is empty).
         */
        skipIfEmpty: {
            type: Boolean as PropType<boolean>,
            default: true,
        },
        /**
        * Temporary prop until we set on a specific color for our icons inside inputs/dropdown
        */
        iconClass: {
            type: String as PropType<string>,
            default: 'text-gray',
        },

        /**
         * If the form error message should be displayed or not
         */
        noInvalidFeedback: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },

    computed: {
        rulesNormalized(): { [name: string]: any } {
            return normalizeRules(this.rules)
        },

        availableRules(): string[] {
            return Object.keys(this.rulesNormalized)
        },

        /**
         * Local name prop
         * Reuse label for name prop if name is not present
         */
        nameLocal(): string | undefined {
            return this.name || this.label
        },

        labelClasses(): any[] {
            return [
                { 'is-required': this.availableRules.includes('required') },
                this.labelClass,
            ]
        },

        internalValue: {
            get(): any {
                return this.value
            },
            set(value): void {
                if (!isEqual(this.value, value)) {
                    /**
                     * Triggers when value (v-model) is updated only if the value
                     * has changed compared to previous set value.
                     *
                     * @property {any} value Current value of field
                    */
                    this.$emit('change', value)
                }

                /**
                 * Triggers when value (v-model) changes
                 *
                 * @property {any} value Current value of field
                */
                this.$emit('input', value)
            },
        },
    },

    methods: {
        /**
         * Determine the form group state based validation flags
         *
         * @param validationFlags
         */
        getValidationState({ validated, dirty, valid }: ValidationFlags): boolean | null {
            return dirty || validated ? valid : null
        },

        getValidationError(errors: string[]): string | null {
            if (this.noInvalidFeedback)
                return null
            else
                return errors.length ? errors[0] : null
        },
    },
})
