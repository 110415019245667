import { auth } from '@middleware'
import routesFactory from '@router/routes-factory'


const routerConfig = {
    baseURI: 'organizations',
    module: 'Organizations',
    middleware: [
        auth('lb', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [

    {
        path: '',
        name: 'organizations.index',
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Organizations" */
            './OrganizationsIndex.vue'
        ),
    },
    {
        path: ':organizationUuId',
        component: (): Promise<any> => import(
            /* webpackChunkName: "modules/Organizations" */
            './OrganizationsEdit.vue'
        ),
        children: [
            {
                path: 'edit',
                name: 'organization.edit',
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/Organizations" */
                    './OrganizationEditInformation.vue'
                ),
            },
            {
                path: 'features',
                name: 'organization.features',
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/Organizations" */
                    './OrganizationFeatures.vue'
                ),
            },
            {
                path: 'uploadUser',
                name: 'organization.uploadUser',
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/Organizations" */
                    './OrganizationsUploadUser.vue'
                ),
            },
            {
                path: 'sso',
                name: 'organization.sso',
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/Organizations" */
                    './OrganizationsSso.vue'
                ),
            },
            {
                path: 'knowbot',
                name: 'organization.knowbot',
                component: (): Promise<any> => import(
                    /* webpackChunkName: "modules/Organizations" */
                    './OrganizationsKnowbot.vue'
                ),
            },
        ],
    },
])
