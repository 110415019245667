/**
 * Handle pipeline
 * @param {IContext} context
 * @param {Array} middleware
 * @param {Number} index
 * @returns {Function}
 */
function pipeline(context: PipelineContext, middleware: { (arg: object): MiddlewareContext }[], index: number): any {
    const nextMiddleware = middleware[index]

    if (!nextMiddleware) {
        return context.next
    }

    return (): any => {
        const proceed = pipeline(context, middleware, index + 1)

        nextMiddleware({
            ...context,
            proceed,
            next: context.next,
        })
    }
}

/**
 *
 * @param {Route} to
 * @param {Route} from
 * @param {*} next
 * @param {Store<any>} store
 * @returns {Function}
 */
export default function middlewarePipeline(to: any, from: any, next: () => void, store: any): any {
    if (to.meta.middleware?.length) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
        const context = { to, from, next, store }

        return middleware[0]({
            ...context,
            proceed: pipeline(context, middleware, 1),
            next: context.next,
        })
    }

    return next()
}
