import routesFactory from '@router/routes-factory'
import { auth, hasFeatures } from '@middleware'

const routerConfig = {
    baseURI: '',
    module: 'Knowbot',
    middleware: [
        hasFeatures('Knowbot', '401'),
    ],

}

export default routesFactory(routerConfig, [
    {
        path: 'knowbot',
        name: 'knowbot.training.index',
        meta: {
            middleware: [
                auth('admin'),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Knowbot" */
                './KnowbotTrainingIndex.vue'
            ),
    },
])
