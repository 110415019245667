<template>
    <b-dropdown
        variant="link"
        toggle-class="p-0 text-muted text-primary-hover line-height-1"
        right
        boundary="window"
    >
        <template #button-content>
            <i class="fal fa-cog" />
        </template>
        <slot />
    </b-dropdown>
</template>

<script lang="ts">
    import Vue from 'vue'
    import { BDropdown } from 'bootstrap-vue'
    export default Vue.extend({
        components: { BDropdown },
    })
</script>