<template>
    <div class="achievements-bar d-inline-flex align-items-center">
        <info-journeys
            v-if="journeys"
            :user-id="userId"
            togglable
            right
            hover
            :main-style="{ minWidth: '280px' }"
        />
        <info-progress
            v-if="progress"
            :user-id="userId"
            togglable
            right
            hover
            :main-style="{ minWidth: '280px' }"
        />
        <info-streak
            v-if="streak"
            :user-id="userId"
            togglable
            right
            hover
            :main-style="{ minWidth: '280px' }"
        />
        <info-medals
            v-if="medals"
            :user-id="userId"
            togglable
            right
            hover
            :main-style="{ minWidth: '280px' }"
        />
        <info-score
            v-if="medals"
            :user-id="userId"
            togglable
            right
            hover
            :main-style="{ minWidth: '280px' }"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { get } from 'vuex-pathify'
    import InfoProgress from './Info/InfoProgress.vue'
    import InfoStreak from './Info/InfoStreak.vue'
    import InfoMedals from './Info/InfoMedals.vue'
    import InfoScore from './Info/InfoScore.vue'
    import InfoJourneys from './Info/InfoJourneys.vue'

    export default Vue.extend({
        components: {
            InfoProgress,
            InfoStreak,
            InfoMedals,
            InfoScore,
            InfoJourneys,
        },

        props: {
            /**
             * Show users progress
             */
            progress: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Show users streak
             */
            streak: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Show users medals
             */
            medals: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Show users progress
             */
            score: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Show Journeys
             */
            journeys: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
        },

        computed: {
            userId: get<number>('Auth/authUser@id'),
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .achievements-bar {
        > div:not(:first-child) {
            margin-left: spacer(4);
        }
    }
</style>