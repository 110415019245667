<template>
    <component
        :is="tag"
        class="iso text-nowrap"
        v-on="$listeners"
    >
        <!--
            The second span is on the same line as the previous element
            to prevent an unwanted space to be placed between the two texts
        -->
        <component
            :is="inline ? 'span' : 'div'"
            v-if="language"
            :class="{
                'font-weight-bold': !inline,
            }"
            v-text="language"
        /><span
            v-if="country"
            v-text="country"
        />

        <slot name="append" />
    </component>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { get } from 'vuex-pathify'
    import { TranslateResult } from 'vue-i18n'
    import i18nMixin from '../mixins'

    export default (Vue as VueConstructor<
        Vue
        & InstanceType<typeof i18nMixin>
    >).extend({
        mixins: [i18nMixin],

        props: {
            value: {
                type: [String, Object] as PropType<string | Language>,
                required: true,
            },

            /**
             * Show language text
             */
            showLanguage: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Show country text
             */
            showCountry: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Translate language and country, otherwise show native translation (from database)
             */
            translate: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            tag: {
                type: String as PropType<string>,
                default: 'span',
                validator: (value: string): boolean =>
                    ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'div'].includes(value),
            },

            format: {
                type: Object as PropType<{ country: CountryFormatTypes }>,
                default: (): { country: CountryFormatTypes } => ({ country: 'long' }),
            },

            inline: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
        },

        computed: {
            languageMap: get<Map<string, Language>>('AppConfig/languageMap'),

            internalValue(): Language {
                if (typeof this.value === 'string')
                    return this.languageMap.get(this.value) as Language

                return this.value
            },

            language(): TranslateResult | null {
                if (!this.showLanguage)
                    return null

                return this.getLanguage(this.internalValue, this.translate)
            },

            country(): TranslateResult | null {
                if (!this.showCountry)
                    return null

                const delimiter = this.getDelimiter(this.format.country)
                const country = this.getCountry(this.internalValue, this.translate, this.format.country)

                return `${delimiter}${country}`
            },
        },
    })
</script>

<docs>
**Output:**
```vue
    <h1>Playground</h1>
    <h4>Default</h4>
    <ul>
        <li><language-iso :value="{ lang: 'en' }" /></li>
        <li><language-iso :value="{ lang: 'en', country: 'gb' }" /></li>
        <li><language-iso :value="{ lang: 'en', country: 'gb' }" /></li>
    </ul>
    <hr />
    <h4>Tags</h4>
    <language-iso :value="{ lang: 'en', country: 'gb' }" tag="h1" />
    <language-iso :value="{ lang: 'en', country: 'gb' }" tag="h2" />
    <language-iso :value="{ lang: 'en', country: 'gb' }" tag="h3" />
    <language-iso :value="{ lang: 'en', country: 'gb' }" tag="h4" />
    <language-iso :value="{ lang: 'en', country: 'gb' }" tag="h5" />
    <language-iso :value="{ lang: 'en', country: 'gb' }" tag="h6" />
    <language-iso :value="{ lang: 'en', country: 'gb' }" tag="p" />
    <language-iso :value="{ lang: 'en', country: 'gb' }" tag="span" />
```
</docs>
