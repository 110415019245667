import { RawLocation } from 'vue-router'
import { Store } from 'vuex'
import { $localStorage } from '@plugins/storage'

const AuthMap = {
    client: ['client'],
    admin: ['client', 'admin'],
    lb: ['client', 'admin', 'lb'],
} as StringMap<ActiveUserType[]>

/**
 * This function handles changing the active user type before testing
 * the auth on the coming route.
 * Admin users can be both client and admin user types so we need to ensure
 * that they can switch easily between
 *
 * @param {ActiveUserType[]} type
 * @param store Vuex store
 */
export function handleActiveUserTypeChange(type: ActiveUserType[], store: Store<any>): void {
    const userType = store.get('Auth/userType')
    const activeUserType = store.get('Auth/activeUserType')

    const authClientOnly = type.every((elem) => elem.includes('client'))
    const authAdminOnly = type.every((elem) => elem.includes('admin'))

    /**
     * If admin is trying to access client auth page, we change
     * the current active user type to client
     */
    if (authClientOnly && userType === 'admin' && activeUserType === 'admin' )
        store.commit('Auth/SET_ACTIVE_USER_TYPE', 'client')

    /**
     * If admin user that is currently active as client tries to access an
     * auth admin page we change his active user type back to admin
     */
    if (authAdminOnly && userType === 'admin' && activeUserType === 'client')
        store.commit('Auth/SET_ACTIVE_USER_TYPE', 'admin')
}

/**
 * Checks for auth and active user type
 *
 * @param {string|Array<string>} userTypes User type to check for
 * @param {boolean|Object} nextParam
 * @returns {MiddlewareCallback}
 */
export default function(
    userTypes: ActiveUserType | ActiveUserType[],
    nextParam: RawLocation | false = false,
): MiddlewareCallback {
    return ({ proceed, next, store, to }): any => {
        if (!store.get('Auth/isAuthenticated')) {
            $localStorage.set('deep-link', to)

            return next({ name: 'auth.signin' })
        }

        if (typeof userTypes === 'string')
            userTypes = [userTypes]

        handleActiveUserTypeChange(userTypes, store)

        const activeUserType = store.get('Auth/activeUserType')
        if (!userTypes.some((userType) => AuthMap[activeUserType].includes(userType))) {
            return next(nextParam)
        }

        return proceed()
    }
}
