import { Module } from 'vuex'
import { RawLocation, Route } from 'vue-router'
import { merge } from 'lodash'
import Http from '@utils/Http'
import router from '@router'
import SocketPlugin from '@plugins/sockets/sockets'
import { $localStorage, $sessionStorage } from '@plugins/storage'
import { hasFeatures, getUserType, getLoginUserType } from '../utils'

import LanguageSelectModal from '@common/i18n/components/LanguageSelectModal.vue'

// Store modules
import Token from './token.store'
import Password from './password.store'
import Nonce from './nonce.store'
import Cookie from './cookie.store'


import { initIntercom } from '../utils/intercom'


function hydrateState(): AuthStoreState {
    const storageAuth: AuthStoreState = $sessionStorage.get('auth') || $localStorage.get('remember')

    if (storageAuth)
        return storageAuth

    if (window.token) {
        $localStorage.set('login', 'sso')

        return {
            token: window.token?.token,
            user: window.token?.user,
            activeUserType: getLoginUserType(window.token.user),
        }
    }

    return {
        token: null,
        user: null,
        activeUserType: null,
    }
}

/**
 * Set Session/Local storage persistance
 */
function setPersistence(
    state: AuthStoreState,
    remember?: boolean,
): void {
    const activeUser = {
        token: state.token,
        user: state.user,
        activeUserType: state.activeUserType,
    }

    $sessionStorage.set('auth', activeUser)

    if (remember || $localStorage.has('remember'))
        $localStorage.set('remember', activeUser)
}

const store: Module<AuthStoreState, any> = {
    modules: {
        Token,
        Password,
        Nonce,
        Cookie,
    },

    namespaced: true,

    state: hydrateState(),

    getters: {
        isAuthenticated(state): boolean {
            return !!state.token
        },

        token(state): string | null {
            return state.token
        },

        authUser(state): UserModel | null {
            return state.user
        },

        userType(state): ActiveUserType | null {
            return getUserType(state.user)
        },

        activeUserType(state): string | null {
            return state.activeUserType
        },

        features(state): string[] {
            return state.user?.user_type.user_type_has_features ?? []
        },

        permissions(state): string[] {
            return state.user?.permissions ?? []
        },

        isCore(state): boolean {
            return state.activeUserType === 'lb'
        },

        hasClientAccess(_state, getters): boolean {
            return getters.features.some(({ client }: any) => client)
        },
    },

    mutations: {
        SET_LOGIN(state, { response, remember }: { response: AuthSigninResponse; remember: boolean }): void {
            state.token = response.token
            state.user = response.user
            state.activeUserType = state.activeUserType ?? getLoginUserType(response.user)

            setPersistence(state, remember)
        },

        /**
         * Update auth user by merging it with a partial user and update persistance
         */
        MERGE_USER(state, payload: Partial<UserModel>): void {
            if (!state.user) return

            state.user = merge(state.user, payload)

            setPersistence(state)
        },

        SET_LOGOUT(state): void {
            state.token = null
            state.user = null
            state.activeUserType = null
        },

        SET_ACTIVE_USER_TYPE(state, payload: ActiveUserType): void {
            state.activeUserType = payload

            setPersistence(state)
        },
    },

    actions: {
        /**
         * Switch active user type
         */
        switchUserType({ commit }, type: ActiveUserType): void {
            commit('SET_ACTIVE_USER_TYPE', type)

            if (router.currentRoute.name !== 'dashboard')
                router.push({ name: 'dashboard' })
        },

        /**
         * Login a user
         */
        async login({ dispatch }, payload: {
            credentials: AuthSigninPayload;
        }): Promise<Route | any> {
            const data: AuthSigninResponse = await dispatch('Token/issue', payload.credentials)

            // Check if user needs to reset password
            if (data.forcePasswordReset) return router.push({
                name: 'auth.passwordReset',
                params: {
                    credentials: JSON.stringify(payload.credentials),
                },
            })

            // Check if user needs MFA
            if (data.requireMFA) return router.push({
                name: 'auth.validationMfa',
                params: {
                    credentials: JSON.stringify(payload.credentials),
                },
            })

            await dispatch('postLogin', {
                response: data,
                remember: payload.credentials.remember,
                reroute: true,
            })

            await dispatch('Cookie/issue')
        },


        /**
         * Login a user via SSO
         */
        loginSso(): void {
            $localStorage.remove('login')
            window.location.assign(`${window.location.origin}/saml/login`)
        },

        /**
         * Check for user auth and sync with store
         */
        async check({ dispatch, getters, state }): Promise<void>{
            if (!getters.isAuthenticated)
                return

            return dispatch('postLogin', {
                response: state,
                reroute: $localStorage.has('deep-link'),
                remember: !!window.token && $localStorage.has('webview'), // set remember if logged in via sso in mobile-app
            })
        },

        /**
         * Handle Post login
         */
        postLogin(
            { commit, dispatch },
            payload: {
                response: AuthSigninResponse;
                remember?: boolean;
                reroute?: boolean;
            },
        ): void {
            const { response, remember } = payload

            commit('SET_LOGIN', {
                response,
                remember: remember ?? false,
            })

            dispatch('Token/setTimer', response.token)

            // Set user's whitelabel
            const userWhitelabel = $sessionStorage.get('userWhitelabel')
            dispatch('Whitelabels/setCurrent', userWhitelabel, { root: true })
                .then((whitelabel) => $sessionStorage.set('userWhitelabel', whitelabel))

            // Connect to the socket service
            response.user && SocketPlugin.connect(response.user)


            // Set users language
            commit('AppConfig/SET_LANG', response.user?.profile.language_id, { root: true })

            // Reroute
            if (payload.reroute === true) {
                const deepLink = $localStorage.get<RawLocation | null>('deep-link')

                router.push(deepLink ?? { name: 'dashboard' })
                    .then(() => $localStorage.remove('deep-link'))
            }

            // Identify user in 3rd party vendor systems
            initIntercom(this.get('Auth/user'), this.get('Auth/userType'), this.get('AppConfig/organization'))

            dispatch('firstLoginSetup')
            dispatch('knowbot')
        },

        /**
         * Log user out from application
         */
        logout({ commit }, options?: { reroute: boolean }): void {
            // TODO: LP-2544 SSO logout is broken on production and test. Disabling sso logout for now
            // If user was logged in with SSO, send a sso logout request to FE service
            // if (this.get('AppConfig/hasAuth', 'saml') && $localStorage.get('login') === 'sso')
            //     await Http.api({ baseUrl: window.location.origin }).post(`saml/logout`, {
            //         access_token: state.token,
            //     })

            commit('Token/CLEAR_TIMEOUT')
            commit('SET_LOGOUT')
            commit('Whitelabels/SYNC_CURRENT', window.appConfig.whitelabel_config, { root: true })

            SocketPlugin.disconnect()

            $sessionStorage.remove('auth')
            $sessionStorage.remove('userWhitelabel')
            $localStorage.remove('remember')
            $localStorage.remove('login')

            window.Intercom?.('shutdown')

            if (options?.reroute === true)
                router.replace('/')
        },

        /**
         * Sign up a user
         */
        async signup({ rootState, rootGetters, dispatch }, payload: AuthSignupPayload): Promise<void> {
            const organizationUUID = rootGetters['AppConfig/orgUuid']
            payload.profile.language_id = rootState.AppConfig?.lang

            await Http.api().post(`organization/${organizationUUID}/user`, {
                user: payload,
            })

            dispatch('login', {
                credentials: {
                    username: payload.email || payload.username,
                    password: payload.plainTextPassword,
                },
            })
        },

        /**
         * Validate MFA Code & Log user in
         */
        async validateMFA({ rootGetters, dispatch }, payload: AuthValidateMfaPayload): Promise<void> {
            const organizationUUID = rootGetters['AppConfig/orgUuid']

            const { data } = await Http.api().post(`login/validateMFA`, {
                organizationUUID,
                ...payload,
            })

            await dispatch('postLogin', {
                response: data,
            })
            router.replace('/')
        },

        /**
         * Submit users activation info on new accounts
         */
        async activateAccount(
            { rootState, rootGetters, dispatch },
            payload: {
                nonce: string;
                user: any;
            },
        ): Promise<void> {
            const organizationUUID = rootGetters['AppConfig/orgUuid']
            const languageId = rootState.AppConfig?.lang

            const { data } = await Http.api().patch(`userSetup`, {
                organizationUUID,
                nonce: payload.nonce,
                user: {
                    ...payload.user,
                    profile: { language_id: languageId },
                },
            })

            dispatch('login', {
                credentials: {
                    username: data.email,
                    password: payload.user.password,
                },
            })
        },

        firstLoginSetup({ commit, dispatch, state }): void {
            if (!state.user?.isFirstLogin)
                return

            (this as any)._vm.$nextTick(async () => {
                const language = await (this as any)._vm.$dialog.modal(LanguageSelectModal, {
                    value: state.user?.profile.language_id,
                    blocking: true,
                })

                await this.load('Settings')
                await dispatch('Settings/Profile/update', { field: 'language_id', value: language }, { root: true })

                // Set first login to false, otherwise the language selector will show up on refresh
                commit('MERGE_USER', { isFirstLogin: false })
            })
        },

        async knowbot({ dispatch }): Promise<void> {
            if (hasFeatures('Knowbot')) {
                await this.load('Knowbot')
                await dispatch('Knowbot/get', null, { root: true })
            }
        },
    },
}

export default store
