import routesFactory from '@router/routes-factory'
import { auth } from '@middleware'

const routerConfig = {
    baseURI: '',
    module: 'Categories',
    middleware: [
        auth('admin', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: 'categories',
        name: 'categories.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Categories" */
                './CategoriesIndex.vue'
            ),
    },
])