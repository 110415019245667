<template>
    <div class="table-header row align-items-center">
        <div
            v-if="searchable"
            class="mb-3 col-md-6 col-lg-5"
        >
            <input-field
                v-model="query"
                type="search"
                class="mb-0"
                :debounce="350"
                :placeholder="$t('TERMS.SEARCH')"
            />
        </div>
        <div
            class="mb-3"
            :class="[searchable ? 'col-md-6 col-lg-7' : 'col', customAreaClass]"
        >
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { InputField } from '@common/Forms'

    export default Vue.extend({
        components: { InputField },

        props: {
            value: { type: String as PropType<string>, default: '' },
            searchable: { type: Boolean as PropType<boolean>, default: false },
            customAreaClass: {
                type: String as PropType<string>,
                default: 'mb-3 text-md-right',
            },
        },

        computed: {
            // @INFO - We need to use computed props in order to preserve the debounce on input 🤷‍♂️
            query: {
                get(): string {
                    return this.value
                },

                set(value: string): void {
                    this.$emit('input', value)
                },
            },
        },
    })
</script>
