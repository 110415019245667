<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <div class="position-relative">
                <textarea
                    ref="textarea"
                    v-model="internalValue"
                    class="form-control"
                    :class="inputClass"
                    :style="styles"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :rows="rows"
                    :name="nameLocal"
                    :maxlength="maxLength"
                    @input="resize"
                    @keydown.enter.exact="onEnter"
                    @focus="onFocus"
                    @blur="onBlur"
                />
                <small
                    v-if="maxChars"
                    class="max-length-display text-smaller text-gray"
                    v-text="`${charsLeft}/${maxChars}`"
                />
            </div>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { VueConstructor, PropType } from 'vue'
    import SharedField from './mixin'
    import MaxLengthMixin from './maxlength.mixin'

    interface Refs {
        $refs: {
            textarea: HTMLTextAreaElement;
        };
    }

    /**
     * Basic text area field
     *
     * * Supports auto resizing
     * * You can hook into enter button to blur the input and catch its value
     *
     * @example ./__docs__/TextareaField.examples.md
     */
    export default (Vue as VueConstructor<Vue
        & Refs
        & InstanceType<typeof SharedField>
        & InstanceType<typeof MaxLengthMixin>
    >).extend({
        mixins: [SharedField, MaxLengthMixin],
        props: {
            /** Total rows of textarea */
            rows: {
                type: [Number, String] as PropType<number | string>,
                default: 2,
            },
            /**
             * Auto resize the height og textarea based on its content.
             */
            autoResize: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Disable users ability to resize textarea
             */
            noResize: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            styles(): Partial<CSSStyleDeclaration> {
                return {
                    resize: this.noResize ? 'none' : undefined,
                }
            },
        },

        mounted() {
            if (this.autoResize) {
                this.$nextTick(() => {
                    const textarea = this.$refs.textarea
                    textarea.style.height = `${textarea.scrollHeight + 2}px`
                })
            }

            if (this.autofocus) {
                this.$nextTick(() => this.$refs.textarea?.focus())
            }
        },

        methods: {
            resize($event: InputEvent): void {
                if (!this.autoResize) return

                const target = $event.target as  HTMLTextAreaElement

                target.style.height = 'auto'
                target.style.height = `${target.scrollHeight + 2}px`
            },

            onEnter($event: KeyboardEvent): void {
                if (this.$listeners.enter) {
                    $event.preventDefault()
                    /**
                     * Triggers when user hits enter when focused on component
                     * Only if the `@enter` listener is present
                     *
                     * @param {KeyboardEvent} event keyboard event object
                     */
                    this.$emit('enter', $event);
                    ($event.target as HTMLTextAreaElement).blur()
                }
            },

            onFocus(event: FocusEvent): void {
                /**
                 * Triggers when input gains focus
                 *
                 * @property {FocusEvent} event focus event object
                 */
                this.$emit('focus', event)
            },

            onBlur(event: FocusEvent): void {
                /**
                 * Triggers when input loses focus
                 *
                 * @property {FocusEvent} event focus event object
                 */
                this.$emit('blur', event)
            },
        },
    })
</script>
<style lang="scss" scoped>
    .max-length-display {
        pointer-events: none;
        position: absolute;
        bottom: .5rem;
        right: .75rem;
    }
</style>
