/**
 * Chart.js integration
 * We use chart.js tree shakeable way of importing things in combination
 * with vue-chartjs for chart components. Here we import the components from
 * vue-chartjs and export them again to be used in our interfaced chart components
 *
 * When you need support for more things in our charting components,
 * you will need to import that support from chart.js and add that to the registry
 */
import 'chartjs-adapter-moment'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Line, Bar, Doughnut } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Defaults,
    Tooltip,
    LinearScale,
    TimeScale,
    CategoryScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
} from 'chart.js'

/**
 * Chart.js registry
 */
ChartJS.register(
    Tooltip,
    LinearScale,
    TimeScale,
    CategoryScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    ChartDataLabels,
)

/**
 * Chart.js defaults
 */
const defaults: RecursivePartial<Defaults> = {
    borderColor: '#F0F0F0',
    color: '#686868',
    font: {
        family: `"Noto Sans", -apple-system, "Helvetica Neue", Arial, sans-serif`,
    },
    plugins: {
        datalabels: {
            display: false,
        },
        legend: {
            display: true,
            position: 'top',
            align: 'center',
        },
    },
    datasets: {
        line: {
            borderColor: '#686868',
            tension: 0.4,
        },
        bar: {
            backgroundColor: '#686868',
        },
    },
}
ChartJS.defaults.set('', defaults)

/**
 * Named exports, vue-chartjs components
 */
export {
    Line as LineChart,
    Bar as BarChart,
    Doughnut as DoughnutChart,
}