<template>
    <nav
        class="navigation-topbar"
        :class="[
            `navigation-topbar--${variant}`,
            $device.isMobile ? 'fixed-bottom' : 'fixed-top'
        ]"
    >
        <div
            class="navigation-topbar__inner d-flex align-items-center"
            :class="[innerClass, { 'p-0': $device.isMobile }]"
        >
            <!-- Organization logo -->
            <router-link
                v-if="currentWhitelabel.logoSquare && !$device.isMobile"
                class="navigation-topbar__logo mr-3"
                :to="{ name: 'dashboard' }"
            >
                <image-lazy
                    class="img-fluid"
                    :src="currentWhitelabel.logoSquare"
                    public
                />
            </router-link>
            <!--  -->
            <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                <navigation-item-renderer
                    v-for="(item, index) in displayItems"
                    :key="index"
                    class="main-item"
                    :popper-opts="popperOpts"
                    :value="item"
                >
                    <template
                        v-if="$device.isMobile"
                        #button-content="{ active }"
                    >
                        <fa-layers
                            class="fa-lg"
                            fixed-width
                        >
                            <fa :icon="[active ? 'fas' : 'fal', item.icon]" />
                            <fa-text
                                v-if="item.options && item.options.count"
                                counter
                                :value="item.options.count"
                                class="bg-danger"
                                style="
                                    --fa-counter-scale: .8;
                                    --fa-top: -.5rem;
                                    --fa-right: -.8rem;
                                    --fa-counter-padding: .2em;
                                "
                            />
                        </fa-layers>
                        <span
                            class="text-truncate"
                            v-text="item.label"
                        />
                    </template>
                </navigation-item-renderer>
                <!-- Mobile overflow drawer item -->
                <navigation-item-renderer
                    v-if="overflowItem"
                    :popper-opts="popperOpts"
                    :value="overflowItem"
                >
                    <template
                        v-if="overflowItem"
                        #button-content="{ totalCount }"
                    >
                        <fa-layers
                            fixed-width
                            class="fa-lg"
                        >
                            <fa :icon="['fal', overflowItem.icon]" />
                            <fa-text
                                v-if="totalCount"
                                counter
                                :value="totalCount"
                                class="bg-danger"
                                style="
                                    --fa-counter-scale: .8;
                                    --fa-top: -.5rem;
                                    --fa-right: -.8rem;
                                    --fa-counter-padding: .2em;
                                "
                            />
                        </fa-layers>
                        <span v-text="overflowItem.label" />
                    </template>
                    <template #header="{ hide }">
                        <navigation-user-header @click.native="hide" />
                    </template>
                    <template #after>
                        <navigation-user-actions show-icons />
                    </template>
                </navigation-item-renderer>
                <!-- Custom items when navigation is not overflowing -->
                <template v-if="!overflowItem">
                    <!-- Spacer -->
                    <div class="ml-auto" />

                    <!-- Achievements bar -->
                    <achievements-info-bar
                        v-if="$hasFeatures('Achievement') && $device.isDesktop"
                        class="border-right pr-3"
                    />

                    <!-- Offset items -->
                    <navigation-item-renderer
                        v-for="(item, index) in offsetItems"
                        :key="index + item.label"
                        :popper-opts="popperOpts"
                        :value="item"
                    >
                        <template #button-content="{ totalCount }">
                            <fa-layers
                                fixed-width
                                class="fa-lg"
                            >
                                <fa :icon="['fal', item.icon]" />
                                <fa-text
                                    v-if="totalCount"
                                    counter
                                    :value="totalCount"
                                    class="bg-danger"
                                    style="
                                        --fa-counter-scale: .5;
                                        --fa-top: -.4rem;
                                        --fa-right: -.2rem;
                                        --fa-counter-padding: .3em;
                                    "
                                />
                            </fa-layers>
                        </template>
                    </navigation-item-renderer>

                    <!-- User Item -->
                    <navigation-item-renderer
                        :popper-opts="popperOpts"
                        right
                        :value="{
                            label: authUser.profile.combined_name
                        }"
                    >
                        <template #button-content>
                            <avatar
                                size="sm"
                                :value="authUser.image_path"
                                :user-name="authUser.profile.combined_name"
                            />
                        </template>
                        <template #header="{ hide }">
                            <navigation-user-header
                                @click.native="hide"
                            />
                        </template>
                        <template #before>
                            <navigation-user-actions show-icons />
                        </template>
                    </navigation-item-renderer>
                </template>
            </div>
            <slot />
        </div>
    </nav>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { get } from 'vuex-pathify'
    import { NavigationItem } from './@typings'
    import ItemGetterMixin from './item-getter.mixin'
    import NavigationItemRenderer from './NavigationItemRenderer.vue'
    import NavigationUserHeader from './NavigationUserHeader.vue'
    import NavigationUserActions from './NavigationUserActions.vue'
    import Avatar from '@common/Images/Avatar.vue'
    import ImageLazy from '@common/Images/ImageLazy.vue'
    import AchievementsInfoBar from '@modules/Achievements/components/AchievementsInfoBar.vue'

    export default (Vue as VueConstructor<Vue
        & InstanceType<typeof ItemGetterMixin>
    >).extend({
        components: {
            NavigationItemRenderer,
            NavigationUserHeader,
            NavigationUserActions,
            Avatar,
            ImageLazy,
            AchievementsInfoBar,
        },
        mixins: [ItemGetterMixin],

        props: {
            innerClass: {
                type: [String, Object, Array] as PropType<string | object | any[]>,
                default: null,
            },
        },

        data() {
            return {
                popperOpts: {
                    modifiers: {
                        preventOverflow: {
                            padding: 0, // https://github.com/popperjs/popper-core/issues/704
                        },
                    },
                },
            }
        },

        computed: {
            currentWhitelabel: get<WhitelabelConfigModel>('Whitelabels/current'),
            authUser: get<UserModel>('Auth/authUser'),

            variant(): 'primary' | 'secondary' | 'link' {
                const current = this.currentWhitelabel.navType

                if (current === 'light')
                    return 'link'

                return current
            },

            displayItems(): NavigationItem[]  {
                if (this.$device.isMobile) {
                    return this.children.slice(0, 4)
                }

                return this.children.filter((item) => !item.offset)
            },

            offsetItems(): NavigationItem[] {
                return this.children.filter((item) => item.offset)
            },

            overflowItem(): NavigationItem | null {
                if (!this.$device.isMobile)
                    return null

                return {
                    icon: 'bars',
                    label: this.$t('HEADER.MENU.MAIN') as string,
                    children: this.children.slice(4),
                }
            },
        },

    })
</script>

<style lang="scss" scoped>
    @import './styles.scss';

    $nav-height: $main-nav-width * 1.33333;
    $box-shadow-reverse-xs: 0 -.125rem .675rem rgba($black, .08);

    // Offset content by fixed navigation on desktop
    @include media-breakpoint-up(md) {
        .navigation-topbar ::v-deep + .content {
            padding-top: $nav-height;
        }
    }
    // Offset content by fixed navigation on mobile
    @include media-breakpoint-down(sm) {
        .navigation-topbar ::v-deep + .content {
            margin-bottom: $main-nav-width;
        }
    }

    .navigation-topbar {
        background-color: $white;
        z-index: $zindex-fixed;
        padding-bottom: env(safe-area-inset-bottom, 0);
        box-shadow: $box-shadow-reverse-xs;

        @include media-breakpoint-up(md) {
            padding-bottom: unset;
            box-shadow: $box-shadow-xs;
        }

        &__inner {
            height: $main-nav-width;
            @include media-breakpoint-up(md) {
                height: $nav-height;
            }
        }

        &__logo {
            height: $nav-height / 2;
            width: $nav-height / 2;
        }

        /** Variants */
        &--secondary {
            @include nav-variant('secondary');
            // Hover/active state underline color
            ::v-deep .main-item .navigation-item__btn:after {
                background: whitelabel-color('secondary-txt');
            }
            // Achievements bar overwrites
            ::v-deep .achievements-bar {
                color: whitelabel-color('secondary-txt');

                &.border-right {
                    border-color: whitelabel-color('primary-txt', .2) !important;
                }

                .dropdown-toggle .text-muted {
                    color: whitelabel-color('secondary-txt', .75) !important;
                }
            }
        }

        &--primary {
            @include nav-variant('primary');
            // Hover/active state underline color
            ::v-deep .main-item .navigation-item__btn:after {
                background: whitelabel-color('primary-txt');
            }
            // Achievements bar overwrites
            ::v-deep .achievements-bar {
                color: whitelabel-color('primary-txt');

                &.border-right {
                    border-color: whitelabel-color('primary-txt', .2) !important;
                }

                .dropdown-toggle .text-muted {
                    color: whitelabel-color('primary-txt', .75) !important;
                }
            }
        }

        &--link {
            ::v-deep .navigation-item__btn {
                color: $body-color;
            }
        }
    }

    //----------------------------------------------------
    // Navigation item overwrites fitted for this nav type
    //----------------------------------------------------
    ::v-deep .navigation-item {
        @include media-breakpoint-down(sm) {
            width: 20%;
        }

        &__btn {
            // Animated underline on active an hover states
            .main-item & {
                &:after {
                    @include centerer(true, false);
                    content: '';
                    width: 0;
                    height: $border-width * 2;
                    bottom: -$border-width * 2;
                    background: whitelabel-color('primary');
                    transition: all 200ms ease-in-out;
                }

                @include media-breakpoint-up(md) {
                    border-radius: 0;
                    padding: $btn-padding-y 0;
                    margin: 0 $btn-padding-x;

                    &.active, &:hover {
                        &:after {
                            width: 100%;
                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                height: $main-nav-width;
                padding-left: 0;
                padding-right: 0;
                width: 100%;
                /* stylelint-disable-next-line declaration-no-important */
                z-index: $zindex-dropdown + 1 !important;

                span {
                    width: 100%;
                    margin-top: spacer(2);
                    /* stylelint-disable-next-line unit-blacklist */
                    font-size: 10px;
                }
            }
        }
    }

</style>
