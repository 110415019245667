<template>
    <froala
        v-model="valueLocal"
        class="froala-editor"
        :class="rootClasses"
        :on-manual-controller-ready="initialize"
        :config="configLocal"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import VueFroala from 'vue-froala-wysiwyg'
    import { getFroalaBaseConfig, FR_PLUGINS } from './utils/froala'
    Vue.use(VueFroala)

    interface FroalaConfig {
        type: null;
        events: Record<string, any>;
    }

    /**
     * Froala events to be bound to the emitter of this component
     * We map froala event names to our own event names
     * Record<eventName, froalaEventName>
     */
    const boundEvents = {
        click: 'click',
        blur: 'blur',
        focus: 'focus',
        change: 'contentChanged',
    }

    export default Vue.extend({
        props: {
            /**
             * @model
             */
            value: {
                type: String as PropType<string>,
                default: '',
            },

            /**
             * Text to show when model is empty.
             * Supports markup
             */
            placeholder: {
                type: String as PropType<string>,
                default: 'Type something',
            },

            /**
             * Disable editing
             */
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Fixed height of editor
             */
            height: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Maximum height of text area
             */
            heightMax: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Minimum height of text area
             */
            heightMin: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Allows new line to be inserted when Enter key is hit
             */
            multiLine: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Set enabled supported plugins on editor.
             * Defaults to all included plugins
             */
            plugins: {
                type: Array as PropType<string[]>,
                default: (): string[] => FR_PLUGINS,
            },

            /**
             * Exclude plugins from `plugins` prop.
             * This will remove corresponding toolbar buttons
             * and any shortcut keys
             */
            excludePlugins: {
                type: Array as PropType<string[]>,
                default: (): never[] => [],
                validator: (value: string[]): boolean => value.every((plugin) => FR_PLUGINS.includes(plugin)),
            },

            /**
             * Include any additional plugins that are not enabled by default
             */
            includePlugins: {
                type: Array as PropType<string[]>,
                default: (): never[] => [],
            },

            /**
             * Any config options from froala
             */
            config: {
                type: Object as PropType<FroalaConfig>,
                default: (): FroalaConfig => ({ type: null, events: {} }),
            },

            /**
             * Upload options for s3, this will activate image & video uploaing
             * capabilities on the text editor.
             */
            uploadOptions: {
                type: Object as PropType<ResourceFroalaS3Params>,
                default: null,
                validator: ({ location, location_id }): boolean =>
                    !!location && !!location_id,
            },

            initialized: {
                type: Function as PropType<(editor: any) => void>,
                default: null,
            },
        },

        data() {
            return {
                editor: null as null | any,
                initControls: null as null | any,
                s3UploadConfig: false as false | object,
            }
        },

        computed: {
            pluginsLocal(): any {
                const excludePlugins = [...this.excludePlugins]

                // exclude image & video plugins if no uploadOptions are provided
                if (!this.uploadOptions) {
                    excludePlugins.push('image', 'video')
                }
                if (!this.$hasFeatures('OpenAI')) {
                    excludePlugins.push('openai')
                }

                return this.plugins
                    .filter((plugin) => !excludePlugins.includes(plugin))
                    .concat(this.includePlugins)
            },

            configLocal(): any {
                const FR_BASE_CONFIG = getFroalaBaseConfig()
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const vm = this

                const whitelabelColors = this.$store.getters['Whitelabels/currentHexList']


                const configLocal = {
                    requestHeaders: {
                        'x-amz-server-side-encryption': 'AES256',
                        'x-access-token': this.$store.get('Auth/token'),
                    },
                    imageUploadToS3: this.s3UploadConfig,
                    videoUploadToS3: this.s3UploadConfig,
                    toolbarVisibleWithoutSelection: !!this.uploadOptions,
                    pluginsEnabled: this.pluginsLocal,
                    placeholderText: this.placeholder,
                    height: this.height,
                    heightMin: this.heightMin,
                    heightMax: this.heightMax,
                    multiLine: this.multiLine,
                    colorsBackground: [
                        ...whitelabelColors,
                        ...FR_BASE_CONFIG.colorsBackground,
                    ],
                    colorsText: [
                        ...whitelabelColors,
                        ...FR_BASE_CONFIG.colorsText,
                    ],
                    openai: this.$hasFeatures('OpenAI') ? (data: { message: string; command: OpenAICommands }): void => {
                        this.onClick('openai', data)
                    } : false,
                }

                // Transport event to vue method
                if (!this.config.events)
                    Object.assign(this.config, { events: {} })

                const defaultEvents = {
                    initialized(): void { vm.onInitialized(this) },
                }
                Object.assign(this.config.events, defaultEvents)

                return {
                    ...FR_BASE_CONFIG,
                    ...configLocal,
                    ...this.config,
                }
            },

            rootClasses(): any {
                return {
                    'froala-editor__inline': !!this.configLocal.toolbarInline,
                }
            },

            valueLocal: {
                get(): string {
                    return this.value
                },
                set(value: string): void {
                    this.$emit('input', value)
                },
            },
        },

        watch: {
            disabled(value): void {
                if (value)
                    this.editor.edit.off()
                else
                    this.editor.edit.on()
            },
        },

        methods: {
            /**
             * Manual initialization of froala.
             */
            async initialize(initControls: any): Promise<void> {
                this.initControls = initControls
                if (this.uploadOptions) {
                    this.s3UploadConfig = await this.$store
                        .dispatch('Resource/froalaSigning', this.uploadOptions)
                }

                this.$nextTick(() => initControls.initialize())
            },

            /**
             * Re initialize the editor
             * @public
             */
            reInitialize(): void {
                this.initControls?.destroy()
                this.initialize(this.initControls)
            },

            /**
             * Froala initialized event
             */
            onInitialized(editor: any): void {
                this.editor = editor

                this.initialized?.(editor)
                // Set disabled state of editor
                this.disabled && this.editor.edit.off()

                // Bind froala events to vue emitter
                Object.entries(boundEvents).forEach(([event, froalaEvent]) =>
                    this.editor.events.on(froalaEvent, (...args: any[]) =>
                        this.$emit(event, editor, ...args)))
            },

            onClick(type: string, selection: string | object): void {
                this.$emit(type, this.editor, selection)
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    // Make placeholder of froala editor not absolute (froala default)
    // on order to support multi-line placeholders and not flow over
    // content below.
    //? This only applies to editors with toolbarInline option enabled
    .froala-editor__inline {
        ::v-deep .fr-wrapper.show-placeholder {
            .fr-element.fr-view {
                position: absolute;
                inset: 0;
            }

            .fr-placeholder {
                position: relative;
                white-space: normal;

                > *:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    button[data-cmd="openaiCmd"] svg {
        background: linear-gradient(120deg, $lb-soft-indigo, $lb-soft-apricot) !important;
        mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432 32C440.8 32 448 39.16 448 48V96H496C504.8 96 512 103.2 512 112C512 120.8 504.8 128 496 128H448V176C448 184.8 440.8 192 432 192C423.2 192 416 184.8 416 176V128H368C359.2 128 352 120.8 352 112C352 103.2 359.2 96 368 96H416V48C416 39.16 423.2 32 432 32zM432 320C440.8 320 448 327.2 448 336V384H496C504.8 384 512 391.2 512 400C512 408.8 504.8 416 496 416H448V464C448 472.8 440.8 480 432 480C423.2 480 416 472.8 416 464V416H368C359.2 416 352 408.8 352 400C352 391.2 359.2 384 368 384H416V336C416 327.2 423.2 320 432 320zM123.3 321.8L9.292 269.1C3.627 266.5 0 260.8 0 254.6C0 248.3 3.627 242.6 9.292 240L123.3 187.3L176 73.29C178.6 67.63 184.3 64 190.6 64C196.8 64 202.5 67.63 205.1 73.29L257.8 187.3L371.8 240C377.5 242.6 381.1 248.3 381.1 254.6C381.1 260.8 377.5 266.5 371.8 269.1L257.8 321.8L205.1 435.8C202.5 441.5 196.8 445.1 190.6 445.1C184.3 445.1 178.6 441.5 176 435.8L123.3 321.8zM54.16 254.6L136.8 292.7C143.7 295.9 149.2 301.4 152.4 308.3L190.6 390.9L228.7 308.3C231.9 301.4 237.4 295.9 244.3 292.7L326.9 254.6L244.3 216.4C237.4 213.2 231.9 207.7 228.7 200.8L190.6 118.2L152.4 200.8C149.2 207.7 143.7 213.2 136.8 216.4L54.16 254.6z"></path></svg>');
        mask-repeat: no-repeat;
        mask-size: contain;
        color: transparent !important;
    }
</style>
