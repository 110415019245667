<template>
    <card
        class="development-plan-card"
        :class="{
            'card-hover pointer': clickable,
        }"
        :body-class="{ 'pt-2 pb-0': !!horizontalLocal }"
        :footer-class="{ 'pb-2': !!horizontalLocal }"
        :horizontal="horizontalLocal"
        border="none"
        v-bind="$attrs"
        @click="onClick"
    >
        <!-- Header image -->
        <template #before>
            <div class="position-relative">
                <development-plan-image
                    :src="value.thumbnailPath"
                    :type="value.type"
                    :no-lazy-img="noLazyImg"
                    :image-class="[
                        horizontalLocal ? 'rounded-left' : 'rounded-top',
                        { 'grayscale': context && context.status === 'locked' }
                    ]"
                    fill-blur
                />

                <div
                    class="position-absolute bg-white p-2 shadow-sm"
                    :class="{
                        'bottom-n2 left-3 rounded': !horizontalLocal,
                        'top-0 left-0 rounded-bottom-right': horizontalLocal
                    }"
                >
                    <fa
                        :icon="['fal', icon]"
                        size="lg"
                        fixed-width
                    />
                </div>

                <div
                    v-if="label"
                    class="position-absolute right-0 bottom-0 badge badge-pill rounded-0 rounded-top-left mw-100 text-truncate"
                    :class="`badge-${label.variant}`"
                    v-text="label.text"
                />
            </div>
        </template>
        <template #default>
            <small
                v-if="!horizontalLocal || learningInfo"
                class="text-muted"
                v-text="$t(`SKILL_DEVELOPER.${value.type}.TITLE`)"
            />
            <h6
                v-line-clamp:[lineClampArg]="2"
                class="development-plan-card__title mb-0"
                v-text="value.name"
            />
        </template>

        <template #footer>
            <!-- Learning attributes -->
            <ul
                v-if="!horizontalLocal || learningInfo"
                class="inline-list small text-muted mb-0"
            >
                <!-- Milestone count -->
                <li v-text="$tc(`SKILL_DEVELOPER.${value.type}.MILESTONE.COUNT`, milestoneCount)" />
            </ul>

            <!-- Progress bar -->
            <div
                v-if="context && context.stats && context.stats.completionRate > 0
                    && context.stats.completionRate !== 100"
                class="d-flex align-items-center"
            >
                <b-progress
                    class="flex-grow-1"
                    :value="context.stats.completionRate"
                    variant="primary"
                    height="6px"
                />
                <small
                    class="font-weight-bold ml-2"
                    v-text="$filter('pct')(Math.round(context.stats.completionRate))"
                />
            </div>
        </template>
    </card>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { Route } from 'vue-router'
    import { get } from 'vuex-pathify'

    import Card, { HorizontalOptions } from '@common/Card/Card.vue'
    import LearningStatusMixin from '@modules/Learning/mixins/learning-status.mixin'
    import { SKILL_DEVELOPER_TYPES_ICONS, SKILL_DEVELOPER_TYPES_ILLUSTRATIONS } from '../../utils'
    import DevelopmentPlanImage from './DevelopmentPlanImage.vue'

    export default (Vue as VueConstructor<
        Vue
        & InstanceType<typeof LearningStatusMixin>
    >).extend({
        components: {
            Card,
            DevelopmentPlanImage,
        },

        mixins: [LearningStatusMixin],

        props: {
            value: {
                type: Object as PropType<DevelopmentPlanDataModel>,
                required: true,
            },

            context: {
                type: Object as PropType<DevelopmentPlanContextModel>,
                default: null,
            },

            userId: {
                type: Number as PropType<number>,
                default: null,
            },

            clickable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            noLazyImg: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            horizontal: {
                type: [Boolean, Object] as PropType<boolean | HorizontalOptions>,
                default: false,
            },

            /**
             * Learning info to display learning duration, content count
             */
            learningInfo: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            authUserId: get<number>('Auth/authUser@id'),

            lineClampArg(): string | null {
                return this.$device.isDesktop ? 'fixed' : null
            },

            horizontalLocal(): boolean | HorizontalOptions {
                if (typeof this.horizontal === 'object')
                    return this.horizontal

                return this.horizontal
                    ? { before: 'col-5' }
                    : false
            },

            milestoneCount(): number {
                return this.context?.stats.milestoneCount ?? this.value.counts?.milestones ?? 0
            },

            skillCount(): number {
                return this.context?.stats.skillCount ?? this.value.counts?.skills ?? 0
            },

            icon(): string {
                return SKILL_DEVELOPER_TYPES_ICONS[this.value.type] || 'question-circle'
            },

            illustration(): SVGElement | null {
                return SKILL_DEVELOPER_TYPES_ILLUSTRATIONS[this.value.type] || null
            },

            label(): ModuleStatusData | null  {
                return this.getModuleStatusData(this.context)
            },
        },

        methods: {
            async onClick(): Promise<Route | void> {
                if (!this.clickable)
                    return

                return this.$router.push({
                    name: 'learning.developmentPlans.details',
                    params: {
                        developmentPlanId: this.value.id.toString(),
                    },
                    query: {
                        containerId: String(this.context?.containerId),
                        ...(this.authUserId !== this.userId && { userId: this.userId?.toString() }),
                    },
                })
            },
        },
    })
</script>
