import Vue, { VNode } from 'vue'
import { NavigationItem } from './@typings'

/**
 * @mixin ItemGetter Mixin
 *
 * This mixin will fetch all NavigationItem data in the default slot
 * of the component this mixin is applied to.
 */
export default Vue.extend({
    inheritAttrs: false,
    data() {
        return {
            children: [] as NavigationItem[],
        }
    },
    mounted() {
        if (!this.$slots.default) return

        const navigationItems = this.$slots.default
            .filter((data: VNode) => data.componentInstance)
            .map((data: any) => data.componentInstance)
            .filter((data: any) => data.getItem)

        this.children = navigationItems
            .map((data: any) => data.getItem())
            .filter((data) => data.visible)

        /** Set watcher on all props of navigation item components */
        navigationItems.forEach((actionCom) => {
            Object.keys(actionCom.$options.props).forEach(
                (prop) => actionCom.$watch(prop, () => {
                    this.children = navigationItems
                        .map((data: any) => data.getItem())
                        .filter((data) => data.visible)
                }),
            )
        })
    },
})